import * as React from 'react';
import { StyleSheet } from 'react-native';

import { RootTabScreenProps } from '../types';
import Tournament from '../components/Tournament';

export default function TournamentScreen({ navigation }: RootTabScreenProps<'Tournament'>) {
    return (
       <Tournament />
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        //alignItems: 'center',
        //justifyContent: 'center',
    },
});
