import { FontAwesome } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import React, { Fragment } from "react";
import { Image, Pressable, ScrollView, View } from "react-native";
import MiniCards1 from "../../../collections/MiniCards1";
import MiniCards2 from "../../../collections/MiniCards2";
import MiniCards3 from "../../../collections/MiniCards3";
import { useTable } from "../../../contexts/Table";
import { useWebSocket } from "../../../contexts/WebSocket";
import { MultitableType } from "../../../types";
import SideMenu from "../../modals/SideMenu";
import PanelButton from "../Social/PanelButton";
import HandHistoryIcon from "../../../assets/images/theme/default/table/hand_history.png";
import ChatIcon from "../../../assets/images/theme/default/table/chat.png";

import { styles } from "./styles";
import { useDimensions } from "../../../contexts/Dimensions";
import { useGameOptions } from "../../../contexts/GameOptions";
import { useChat } from "../../../contexts/Chat";

export default function () {
  const { frontDeck, autoTableChange } = useGameOptions();
  const { orientation } = useDimensions();
  const { setTableId, socket, tableId } = useWebSocket();
  const { showNewMessageBadge, setShowNewMessageBadge } = useChat();
  const navigation = useNavigation();

  const { setPanelSelectedTab, setPanelVisible, currentPlayer, table } =
    useTable();

  const [multitable, setMultitable] = React.useState<MultitableType[]>([]);
  const [MiniCards, setMiniCards] = React.useState<any>(MiniCards1);

  const [tableLocked, setTableLocked] = React.useState<boolean>(false);

  //const [updateInterval, setUpdateInterval] = React.useState<NodeJS.Timer | undefined>();

  function handleInfoButtonPress(tabToOpen: number) {
    setShowNewMessageBadge(false);
    setPanelSelectedTab(tabToOpen);
    setPanelVisible(true);

    //console.log(tabToOpen, 'panel');
    if (tabToOpen === 2 && socket && socket.connected) {
      socket.emit("history list", tableId);
    }
  }

  React.useEffect(() => {
    if (socket.connected) {
      socket.on("multitable", (myTables: MultitableType[]) => {
        setMultitable(myTables);

        if (autoTableChange) {
          if (
            !tableLocked &&
            currentPlayer &&
            table.currentPosition != currentPlayer.seatNumber
          ) {
            let changeTable = myTables.find((myTable) => myTable.currentActor);

            if (changeTable && changeTable.tableId != tableId && !tableLocked) {
              setTableLocked(true);
              socket.off("update table");
              socket.off("action time");
              socket.off("emoji");
              socket.emit("leave table", tableId);

              setTableId(changeTable.tableId);

              setTimeout(() => {
                setTableLocked(false);
              }, 2000);
            }
          }
        }
      });
    }

    return () => {
      if (socket.connected) {
        socket.off("multitable");
      }
    };
  }, [socket, table, autoTableChange, tableLocked]);

  React.useEffect(() => {
    if (frontDeck === 2) {
      setMiniCards(MiniCards2);
    } else if (frontDeck === 3) {
      setMiniCards(MiniCards3);
    } else {
      setMiniCards(MiniCards1);
    }
  }, [frontDeck]);

  return (
    <View style={styles.container}>
      <View style={styles.rowOne}>
        <SideMenu />
        <ScrollView
          style={{ flex: 1, flexDirection: "row" /*backgroundColor: 'red'*/ }}
          horizontal
        >
          {multitable &&
            multitable.length > 0 &&
            multitable.map((table, index) => (
              <Pressable
                key={index}
                onPress={() => {
                  if (tableId !== undefined && tableId != table.tableId) {
                    socket.off("update table");
                    socket.off("action time");
                    //socket.off("chat message");
                    socket.off("emoji");
                    socket.emit("leave table", tableId);

                    setTableId(table.tableId);
                  }
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    borderWidth: 2,
                    borderColor:
                      table.tableId === tableId
                        ? "rgba(255, 255, 255, 0.8)"
                        : table.currentActor
                        ? "rgba(255, 215, 0, 1)"
                        : "rgba(255, 255, 255, 0.2)",
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    borderRadius: 8,
                    paddingHorizontal: 2,
                    paddingVertical: 2,
                    marginRight: 6,
                    marginTop: 2,
                  }}
                >
                  {table.holeCards.map((card, index) => (
                    <Image
                      key={index}
                      style={{ width: 20, height: 30, resizeMode: "contain" }}
                      source={MiniCards[card]}
                      fadeDuration={0}
                    />
                  ))}
                </View>
              </Pressable>
            ))}
        </ScrollView>
        <View style={{}}>
          <Pressable
            style={{ padding: 10, marginTop: 5, marginRight: 5 }}
            onPress={() => navigation.navigate("Root", { screen: "Lobby" })}
          >
            <FontAwesome name="plus-square-o" size={25} color="#FFFFFF" />
          </Pressable>
        </View>

      </View>
      {orientation === "portrait" && currentPlayer && (
        <View style={styles.rowTwo}>
          <PanelButton
            image={HandHistoryIcon}
            style={{ width: 50, height: 50 }}
            onPress={() => handleInfoButtonPress(2)}
          />
          <PanelButton
            image={ChatIcon}
            style={{ width: 50, height: 50 }}
            onPress={() => handleInfoButtonPress(1)}
            showBadge={showNewMessageBadge}
          />
        </View>
      )}
    </View>
  );
}
