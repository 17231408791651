import {StyleSheet} from "react-native";

export const styles = StyleSheet.create({
    container: {
        //borderRadius:8,
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-between",
        marginVertical: 2,
        alignItems: "center",
        borderBottomColor: "rgba(255, 255, 255, 0.2)",
        borderBottomWidth: 1,
    },
    text: {
        color: "white",
        fontSize: 14,
        textAlign: "right",
    },
    myCardsArea: {
        flex: 1,
        flexDirection: "row",
    },
    boardArea: {
        flex: 1,
        flexDirection: "row",
    },
    potArea: {
        flex: 1,
        borderColor: "white",
    },
    handNumber: {
        flex: 1,
        borderColor: "white",
        maxWidth: 50,
    },
    separator: {
        height: 2,
        width: "100%",
        borderBottomWidth: 1,
        borderBottomColor: "#FFF2",
    },
    cards: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        maxHeight: "40",
        overflow: "hidden",
    },
    card: {
        minWidth: 24,
        //marginTop: 0,
        minHeight: 33,
    },
});
