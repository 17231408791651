// import { Platform, StyleSheet, TextStyle, ViewStyle } from 'react-native';
// import { useDimensions } from '../../../../../../../contexts/Dimensions';

import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '49%',
        justifyContent: 'center',
        alignContent: 'center',
    },
    text: {
        color: 'white',
        fontWeight: '500',
        textAlign: 'center',
        textAlignVertical: 'center',
    },
})


// export const styleSeatWithPosition = (seatNumber: number, numberOfSeats: number): ViewStyle => {
//     return StyleSheet.flatten([styles.container, ]);
// }

// export const styles = StyleSheet.create({
//     container: {
//         flex: 1,

//         width: '80%',
//         height: '100%',
//         marginLeft: '10%',
//         fontWeight: '400',
//         color: '#99B7FC',

//         //overflow: 'visible'
//         //minHeight: '100%',
//         //width: '100%',
//         //height: '100%',
//         textAlign: 'center',
//         //backgroundColor: 'blue'
//         textAlignVertical: 'center',
//         //backgroundColor: 'blue',
//         //marginBottom: '8%',
//         //backgroundColor: 'yellow',
//     },
// });


// export const styleWithFontScale = (fontSizeWeb: number, fontSizeDefault: number): ViewStyle => {
//     return StyleSheet.flatten([styles.container, getFontSize(fontSizeWeb, fontSizeDefault)]);
// }

// export function textSize(fontSizeWeb: number, fontSizeDefault: number) {

//     const { orientation, width, height } = useDimensions();

//     if (Platform.OS === 'web') {
//         if (orientation === 'landscape') {
//             if (height >= 780 && height <= 880) {
//                 fontSizeWeb -= 4;
//             } else if (height >= 680 && height < 780) {
//                 fontSizeWeb -= 8;
//             } else if (height < 680) {
//                 fontSizeWeb -= 12;
//             } 
//         } else {
//             if (width > 360 && width < 540) {
//                 fontSizeWeb -= 12;
//             } else if (width <= 360) {
//                 fontSizeWeb -= 12;
//             }
//         }

//         return fontSizeWeb;
//     }

//     return fontSizeDefault;
// }

// export const getFontSize = (fontSizeWeb: number, fontSizeDefault: number): TextStyle => {

//     let font = textSize(fontSizeWeb, fontSizeDefault);

//     return {
//         fontSize: font
//     }
// }