import React from 'react';
import {Modal, ModalProps, Pressable, Text, View} from 'react-native';
import {FontAwesome} from '@expo/vector-icons';

import {styleModal, styles} from './styles';

type Props = ModalProps & {
    title: string;
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    children: React.ReactNode;
}

export default function ({title, modalVisible, setModalVisible, children, ...rest}: Props) {
    //const [modalVisible, setModalVisible] = React.useState(false);

    //React.useEffect(() => {
    //  setModalVisible(show);
    //}, [show]);

    return (
        <Modal
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
                setModalVisible(!modalVisible);
            }}
            {...rest}
        >
            <View style={styles.centeredView}>
                <View style={styleModal('bottom')}>
                    <View style={styles.modalTitle}>
                        <Text style={styles.modalTitleText}>{title}</Text>
                        <Pressable
                            style={[styles.button]}
                            onPress={() => setModalVisible(!modalVisible)}
                        >
                            <FontAwesome name="close" size={30} color="#FFFFFF"/>
                        </Pressable>
                    </View>
                    <View style={styles.modalContent}>
                        {children}
                    </View>
                </View>
            </View>
        </Modal>
    );
}