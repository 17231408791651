import { StyleSheet, ViewStyle } from "react-native";
import { useDimensions } from "../../../contexts/Dimensions";

export const styles = StyleSheet.create({
  container: {
    position: "absolute",
    //backgroundColor: 'turquoise',
    flexDirection: "row",
    //opacity: 0.6
  },
  image: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
});

export const styleDeckPosition = (): ViewStyle => {
  return StyleSheet.flatten([styles.container, getDeckPosition()]);
};

export const getDeckPosition = (): ViewStyle => {
  const { orientation } = useDimensions();

  switch (orientation) {
    case "landscape":
      return {
        width: "44%",
        height: "18%",
        left: "28%",
        top: "39%",
      };
    case "portrait":
      return {
        width: "66%",
        height: "12%",
        left: "17%",
        top: "42.5%",
      };
    default:
      return {
        display: "none",
      };
  }
};
