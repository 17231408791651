import Slider from "@react-native-community/slider";
import React from "react";
import { Button, Pressable, Text, View } from "react-native";
import { useAccount } from "../../../contexts/Account";
import { useWebSocket } from "../../../contexts/WebSocket";
import { monetaryValue } from "../../../util";
import Modal from "../Modal";
import SignIn from "../SignIn";
import { useTranslation } from "react-i18next";
import { useTable } from "../../../contexts/Table";

import { styles, styleWithFontScale } from "./styles";
import { useNavigation } from "@react-navigation/native";
import { PlayerProps } from "../../../types";

type Props = {
  modalVisible: boolean;
  setModalVisible: (modalVisible: boolean) => void;
  minBuyIn: number;
  maxBuyIn: number;
  tableId: number | undefined;
  currentPlayer: PlayerProps | null;
};

function AddChips({
  modalVisible,
  setModalVisible,
  minBuyIn,
  maxBuyIn,
  tableId,
  currentPlayer
}: Props) {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { socket } = useWebSocket();
  const { signed, user, wallet } = useAccount();

  const [sliderChanged, setSliderChanged] = React.useState<boolean>(false);
  const [bonus, setBonus] = React.useState<boolean>(false);

  const [sliderMinBuyIn, setSliderMinBuyIn] = React.useState<number>(0);
  const [sliderMaxBuyIn, setSliderMaxBuyIn] = React.useState<number>(0);
  const [sliderBuyIn, setSliderBuyIn] = React.useState<number>(0);

  const [helpText, setHelpText] = React.useState<string>(
    t("ModalAddChips.youAreBuyingInFor", {
      value: monetaryValue(sliderBuyIn).toString(),
    })
  );

  const handleAddChipsPress = (chips: number, tableId: number | undefined) => {
    if (tableId === undefined) return;
    if (!socket.connected) return;
    if (!signed) return;

    socket.emit("add chips", chips, tableId);

    setModalVisible(!modalVisible);
  };

  const onChangeSliderBuyIn = (amount: number) => {
    setSliderChanged(true);
    const value = Math.round(Number(amount));
    setSliderBuyIn(value);
  };

  React.useEffect(() => {
    if (modalVisible) {
      setSliderChanged(false);
    }
  }, [modalVisible]);

  React.useEffect(() => {
    setSliderMinBuyIn(minBuyIn);
    setSliderMaxBuyIn(maxBuyIn);

    if (!sliderChanged || sliderBuyIn < minBuyIn || sliderBuyIn > maxBuyIn) {
      setSliderBuyIn(maxBuyIn);
    }

    if (currentPlayer && currentPlayer.useBonus) {
      setBonus(true);
      if (wallet !== undefined && wallet.bonus < maxBuyIn) {
        setSliderMaxBuyIn(wallet.bonus);
        setSliderBuyIn(wallet.bonus);
      }
    } else {
      setBonus(false);
      if (wallet !== undefined && wallet.balance < maxBuyIn) {
        setSliderMaxBuyIn(wallet.balance);
        setSliderBuyIn(wallet.balance);
      }
    }
    
  }, [wallet, minBuyIn, maxBuyIn]);

  React.useEffect(() => {
    setHelpText(
      t("ModalAddChips.youAreAdding", {
        value: monetaryValue(sliderBuyIn).toString(),
      })
    );
  }, [sliderBuyIn]);

  return (
    <React.Fragment>
      {signed ? (
        user && wallet && (wallet.balance || 0) < minBuyIn && (wallet.bonus || 0) < minBuyIn ? (
          <Modal
            title={t("ModalAddChips.insufficientFunds")}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            animationType="none"
          >
            <View style={{ flex: 1 }}>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    fontSize: 22,
                  }}
                >
                  {t("ModalAddChips.currentBalance")}
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    color: "lightblue",
                    fontSize: 20,
                  }}
                >
                  {monetaryValue(wallet?.balance || 0)}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    fontSize: 22,
                  }}
                >
                  {t("ModalAddChips.buyIn")}
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    color: "lightblue",
                    fontSize: 22,
                  }}
                >
                  {monetaryValue(minBuyIn)}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    textAlign: "center",
                    color: "#CCCCCC",
                    fontSize: 18,
                  }}
                >
                  {t("ModalAddChips.balanceIsRunningLow")}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Button
                  title={t("ModalAddChips.buttonBuyChips")}
                  onPress={() => {
                    setModalVisible(false);
                    navigation.navigate("Cashier");
                  }}
                />
              </View>
            </View>
          </Modal>
        ) : (
          <Modal
            title={t("ModalAddChips.addChips")}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            animationType="none"
            showClose={true}
          >
            <View style={{ flex: 1 }}>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    fontSize: 22,
                  }}
                >
                  {t("ModalAddChips.currentBalance")}
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    color: "lightblue",
                    fontSize: 20,
                  }}
                >
                  {bonus ? monetaryValue(wallet?.bonus || 0) : monetaryValue(wallet?.balance || 0)}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text
                    style={{
                      textAlign: "left",
                      color: "#FFFFFF",
                      fontSize: 18,
                    }}
                  >
                    {t("ModalAddChips.min")}
                  </Text>
                  <Text
                    style={{
                      textAlign: "left",
                      color: "#FFFFFF",
                      fontSize: 20,
                    }}
                  >
                    {monetaryValue(minBuyIn)}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      textAlign: "center",
                      color: "#FFFFFF",
                      fontSize: 20,
                    }}
                  >
                    {t("ModalAddChips.add")}
                  </Text>
                  <Text
                    style={{
                      textAlign: "center",
                      color: "lightblue",
                      fontSize: 22,
                    }}
                  >
                    {monetaryValue(sliderBuyIn)}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      textAlign: "right",
                      color: "#FFFFFF",
                      fontSize: 18,
                    }}
                  >
                    {t("ModalAddChips.max")}
                  </Text>
                  <Text
                    style={{
                      textAlign: "right",
                      color: "#FFFFFF",
                      fontSize: 20,
                    }}
                  >
                    {monetaryValue(maxBuyIn)}
                  </Text>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <Slider
                  style={{ width: "100%", paddingVertical: 10 }}
                  minimumValue={sliderMinBuyIn}
                  maximumValue={sliderMaxBuyIn}
                  value={sliderBuyIn}
                  step={100}
                  onValueChange={onChangeSliderBuyIn}
                  minimumTrackTintColor={"#004bF7"}
                  maximumTrackTintColor={"#636363"}
                  thumbTintColor={"#FFF"}
                />
              </View>
              <View>
                <Text
                  style={{
                    textAlign: "center",
                    color: "#CCCCCC",
                    fontSize: 18,
                  }}
                >
                  {helpText}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    textAlign: "center",
                    color: "#CCCCCC",
                    fontSize: 18,
                  }}
                >
                  {`\n`}
                  {t("ModalAddChips.instruction")}
                </Text>
              </View>
              <View style={{ flex: 1, paddingTop: 10 }}>
                <Pressable
                  style={styles.button}
                  onPress={() => handleAddChipsPress(sliderBuyIn, tableId)}
                >
                  <Text style={[styleWithFontScale(20, 14)]}>
                    {t("ModalAddChips.buttonAdd")}
                  </Text>
                </Pressable>
              </View>
            </View>
          </Modal>
        )
      ) : (
        <SignIn
          showButton={false}
          modalVisibleExternal={modalVisible}
          setModalVisibleExternal={setModalVisible}
        />
      )}
    </React.Fragment>
  );
}

export default AddChips;
