import React from "react";
import {View} from "react-native";
import {ScrollView} from "react-native-gesture-handler";
import PanelButton from "../../Social/PanelButton";
import {styles} from "./styles";
import Tab from "./Tab";
import QuestionIcon from "../../../../assets/images/theme/default/table/question_icon.png";
import {useTable} from "../../../../contexts/Table";

type TabProps = {
    id: number,
    title: string,
    isActive: boolean,
    onPress: () => void
}

export default function TabBar({
                                   tabs,
                                   activeTab,
                                   onTabClicked
                               }: { tabs: TabProps[], activeTab: number, onTabClicked: (value: number) => void }) {
    const {setPanelVisible, setShowMinimumStayModal} = useTable();
    const handleTabClicked = (value: number) => {
        onTabClicked(value);
    }

    function handleQuestionPress() {
        setPanelVisible(false);
        setShowMinimumStayModal(true);
    }

    return (
        <View style={styles.container}>
            <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
            >
                {tabs.map((tab, index) => <Tab key={tab.id} {...tab} isActive={index === activeTab}
                                               onPress={handleTabClicked}/>)}
            </ScrollView>
            <PanelButton image={QuestionIcon} style={{width: 32, height: 32, marginLeft: 10}}
                         onPress={handleQuestionPress}/>
        </View>
    )
}