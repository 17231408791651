import React from "react";
import { ImageBackground, Pressable, Text, View } from "react-native";
import { useGameOptions } from "../../../../../contexts/GameOptions";
import { useTable } from "../../../../../contexts/Table";
import { monetaryValue } from "../../../../../util";
import { useTranslation } from "react-i18next";

import { styleWithFontScale } from "./styles";

type Props = {
  currentBettingValue: number;
};

export default function ({ currentBettingValue }: Props) {
  const { t } = useTranslation();
  const { showBigBlind } = useGameOptions();
  const { table } = useTable();
  const betValueLabel = showBigBlind
    ? `${Math.round((currentBettingValue / table?.bigBlind) * 10) / 10} BB`
    : monetaryValue(currentBettingValue);
  const { handleBetActionPress } = useTable();

  return (
    <Pressable
      onPress={() => handleBetActionPress(currentBettingValue)}
      style={({ pressed }) => [
        {
          opacity: pressed ? 1 : 1,
        },
        { width: "33.3%", height: "100%" },
      ]}
    >
      {({ pressed }) => (
        <ImageBackground
          style={{
            minWidth: "100%",
            minHeight: "100%",
            flex: 1,
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
          }}
          source={require("../../../../../assets/images/theme/default/table/action.png")}
          resizeMode={"contain"}
          fadeDuration={0}
        >
          <View style={{ marginBottom: 18 }}>
            <Text
              adjustsFontSizeToFit={true}
              style={[
                styleWithFontScale(20, 14),
                {
                  textAlign: "center",
                  textAlignVertical: "center",
                  paddingHorizontal: 10,
                },
              ]}
            >
              {t("TableScreen.Controls.BET", { value: betValueLabel })}
            </Text>
          </View>
        </ImageBackground>
      )}
    </Pressable>
  );
}
