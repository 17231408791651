import {StyleSheet} from "react-native";

export const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 10,
        alignItems: "center",
        justifyContent: 'center',
    },
    titleText: {
        color: "white",
        fontSize: 20,
    }
})