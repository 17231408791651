import * as Localization from "expo-localization";
import AsyncStorage from "@react-native-async-storage/async-storage";

const getLangData = async () => {
  try {
    const value = await AsyncStorage.getItem("LANGUAGE");
    if (value !== null) {
      // value previously stored
      return value;
    }
  } catch (e) {
    // error reading value
  }
};

function successCallback(result: string | undefined) {
  // Localization.locale will get back a string like "en-US".
  return result !== undefined ? result : Localization.locale; //.split('-')[0];
}

export const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: async (callback: (arg0: string) => void) => {
    callback(await getLangData().then(successCallback));
  },
  init: () => {},
  cacheUserLanguage: () => {
    "LANGUAGE";
  },
};
