import React from "react";
import { Text, View } from "react-native";
import { useGameOptions } from "../../../../contexts/GameOptions";
import { useTable } from "../../../../contexts/Table";
import { monetaryValue } from "../../../../util";

import { styles, styleWithFontScale } from "./styles";

type Props = {
  value: number;
};

export default function ({ value }: Props) {
  const { showBigBlind } = useGameOptions();
  const { table } = useTable();
  const tableBB = table?.bigBlind;
  const stackLabel = showBigBlind
    ? `${Math.round((value / tableBB) * 10) / 10} BB`
    : monetaryValue(value);

  return (
    <View style={styles.container}>
      <Text
        style={[
          styleWithFontScale(20, 14),
          { color: showBigBlind ? "#ff9900" : "#FFF" },
        ]}
      >
        {stackLabel}
      </Text>
    </View>
  );
}
