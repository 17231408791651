import React from "react";
import { Pressable, Text } from "react-native";
import { styles } from "./styles";

type TabProps = {
    id: number,
    title: string,
    isActive: boolean,
    onPress: (value: number) => void
}

export default function Tab({ id, title, isActive, onPress }: TabProps) {
    const handleOnPress = () => {
        onPress(id);
    }
    return (
        <Pressable
            style={[styles.container, { backgroundColor: isActive ? "#FFF4" : "transparent" }]}
            onPress={handleOnPress}
        >
            <Text style={styles.titleText}>{title}</Text>
        </Pressable>
    )
}