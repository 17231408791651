import React, { createContext, useContext } from "react";
import { ChatMessageProps } from "../types";
import { useAccount } from "./Account";
import { useTable } from "./Table";
import { useWebSocket } from "./WebSocket";

type ChatContextProps = {
  messages: ChatMessageProps[];
  handleChatMessage: (message: string) => void;
  showNewMessageBadge: boolean;
  setShowNewMessageBadge: (value: boolean) => void;
};

export const ChatContext = createContext({} as ChatContextProps);

type ChatProviderProps = {
  children: React.ReactNode;
};

export function ChatProvider({ children }: ChatProviderProps) {
  //const { panelVisible } = useTable();
  const { socket, tableId } = useWebSocket();
  const { user } = useAccount();

  const [showNewMessageBadge, setShowNewMessageBadge] =
    React.useState<boolean>(false);
  const [messages, setMessages] = React.useState<ChatMessageProps[]>([]);

  const handleChatMessage = (content: string) => {
    if (!socket.connected) return;
    if (!user) return;
    // console.log("send message", content);
    socket.emit("chat message", tableId, content);
  };

  React.useEffect(() => {
    if (!socket.connected) {
      return;
    }

    //console.log("chat connected");

    //if (tableId !== undefined) {
    //setMessages([]);

    socket.on("chat message", (message: ChatMessageProps) => {
      //console.log("new chat message" + message);

      setMessages((previousState) => [...previousState, message]);

      //console.log("chat", message.userId, user.id);

      if (
        message &&
        user &&
        tableId &&
        message.userId !== user.id &&
        message.tableId === tableId
      ) {
        setShowNewMessageBadge(true);
      }
    });

    //socket.emit("update chat", tableId);
    //}

    return () => {
      socket.off("chat message");
    };
  }, [socket, tableId, user]);

  return (
    <ChatContext.Provider
      value={{
        messages,
        handleChatMessage,
        showNewMessageBadge,
        setShowNewMessageBadge,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export function useChat() {
  const context = useContext(ChatContext);

  if (context === undefined) {
    throw new Error("Context provider undefined.");
  }

  return context;
}
