import React from 'react';
import {LayoutChangeEvent, PanResponder, PanResponderGestureState, StyleSheet, Text, View,} from 'react-native';

type StateType = {
    barWidth: number | null,
    deltaValue: number,
    value: number,
}

const CIRCLE_PERCENTAGE = 0.6;

export default class HSlider extends React.Component<{
    showLabel?: boolean | undefined,
    currentValue: number,
    minimumValue: number,
    maximumValue: number,
    steps?: number,
    onValueChange: (value: number) => void,
}, StateType> {

    width:number = 27;
    height:number = 27;
    CircleWidth:number = 27;
    CircleHeight:number = 27;
    
    find_dimesions(layout: any) {
        const {width, height} = layout;
        this.width = width;
        this.height = height;
        this.CircleWidth = (height*CIRCLE_PERCENTAGE);
        this.CircleHeight = (height*CIRCLE_PERCENTAGE);
    }

    state = {
        barWidth: null,
        deltaValue: 0,
        value: this.props.currentValue,
    };

    panResponder = PanResponder.create({
        onMoveShouldSetPanResponderCapture: () => true,
        onPanResponderMove: (_, gestureState) => this.onMove(gestureState),
        onPanResponderRelease: () => this.onEndMove(),
    });

    getValueFromLeftOffset = (
        offset: number,
        barWidth: number | null,
        rangeMin: number,
        rangeMax: number,
    ) => {
        if (barWidth === null) return 0;
        return ((rangeMax - rangeMin) * offset) / barWidth;
    };

    setValueWithSteps(value: number) {
        if (!this.props.steps) return value;
        let stepRange = (this.props.maximumValue - this.props.minimumValue) / this.props.steps;
        let sobra = value % stepRange;
        let inteiro = Math.round((value - sobra) / stepRange);
        let completar = 0;
        if (sobra - (stepRange / 2) > 0) completar = 1;
        inteiro = inteiro + completar;
        return inteiro * stepRange;
    }

    onMove(gestureState: PanResponderGestureState) {
        const {value, barWidth, deltaValue} = this.state;
        const newDeltaValue = this.getValueFromLeftOffset(
            gestureState.dx,
            barWidth,
            this.props.minimumValue,
            this.props.maximumValue,
        );
        this.props.onValueChange(
            this.setValueWithSteps(
                this.capValueWithinRange(
                    value + newDeltaValue, 
                    [this.props.minimumValue, this.props.maximumValue]
                )
            )
        );
        this.setState({deltaValue: newDeltaValue})
    };

    onEndMove() {
        const {value, deltaValue} = this.state;

        let tempValue = value + deltaValue;
        if (tempValue > this.props.maximumValue) {
            tempValue = this.props.maximumValue
        } else if (tempValue < this.props.minimumValue) {
            tempValue = this.props.minimumValue
        }
        this.setState({value: tempValue, deltaValue: 0});
    }

    capValueWithinRange = (value: number, range: number[]) => {
        if (value < range[0]) return range[0];
        if (value > range[1]) return range[1];
        return value;
    }

    onBarLayout = (event: LayoutChangeEvent) => {
        const {width: barWidth} = event.nativeEvent.layout;
        this.setState({barWidth: barWidth});
    };

    getLeftOffsetFromValue = (
        value: number,
        rangeMin: number,
        rangeMax: number,
        barWidth: number | null
    ) => {
        if (barWidth === null) return 0;
        const valueOffset = value - rangeMin;
        const totalRange = rangeMax - rangeMin;
        const percentage = valueOffset / totalRange;
        return barWidth * percentage;
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps: { currentValue: number; }, prevState: any) {
        if (prevProps.currentValue != this.props.currentValue) {
            this.setState({value: this.props.currentValue})
        }
    }

    render() {

        const {value, deltaValue, barWidth: barWidth} = this.state;
        const cappedValue = this.capValueWithinRange(value + deltaValue, [this.props.minimumValue, this.props.maximumValue]);
        const leftOffset = this.getLeftOffsetFromValue(cappedValue, this.props.minimumValue, this.props.maximumValue, barWidth);

        return (
            <View
                style={styles.pageContainer}
                onLayout={(event) => {this.find_dimesions(event.nativeEvent.layout)}}
                >
                <View style={styles.container}>
                    <View style={[styles.barContainer, {
                        height: this.CircleHeight,
                        paddingHorizontal: this.CircleHeight / 2,
                    }]}>
                        <View onLayout={this.onBarLayout} style={styles.bar}/>
                        <View {...this.panResponder.panHandlers} style={[styles.circle, {
                            left: leftOffset ? leftOffset : 0,
                            borderRadius: 999999,
                            width: this.CircleWidth,
                            height: this.CircleHeight,
                            borderWidth: this.CircleHeight * 0.03,
                        }]}
                        >
                            {
                                this.props.showLabel ? (
                                    <>
                                        <View style={{
                                            position: 'absolute', alignSelf: 'center',
                                            backgroundColor: 'white',
                                            width: this.CircleHeight / 4,
                                            height: this.CircleHeight / 4,
                                            top: this.CircleHeight + 5,
                                            transform: [{rotateZ: '45deg'}]
                                        }}/>
                                        <View style={styles.label}>
                                            <Text selectable={false}
                                                style={styles.value}>{Math.floor(cappedValue)}</Text>
                                        </View>
                                    </>
                                ) : (
                                    null
                                )
                            }
                        </View>
                    </View>
                </View>
            </View>
        )
    };
}

const styles = StyleSheet.create({
    pageContainer: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        justifyContent: 'center',
        alignSelf: 'stretch',
        alignItems: 'center',
        height: "100%",
    },
    container: {
        flexGrow: 1,
        alignSelf: 'stretch',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    value: {
        color: 'black',
        fontSize: 24,
        fontWeight: 'bold',
    },
    barContainer: {
        justifyContent: 'center',
        marginVertical: 20,
    },
    bar: {
        maxHeight: 2,
        backgroundColor: '#FFF',
        flexGrow: 1,
    },
    circle: {
        backgroundColor: '#0D54F7',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'flex-end',
        left: 0,
        bottom: 0,
        borderColor: '#ffffff',

    },
    handleImage: {
        width: '100%',
        height: '100%',
        resizeMode: 'contain',
    },
    label: {
        borderRadius: 6,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
    }
})