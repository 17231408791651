import React, { Fragment } from "react";
import { View, Text } from "react-native";
import { styleHandNamePosition } from "./styles";
import { getFontSize } from "../../../../../../util/GlobalStyles";

type Props = {
  handName: string | undefined;
};

export default function ({ handName }: Props) {
  const fontSize = getFontSize(18, 16);
  return handName ? (
    <View style={styleHandNamePosition()}>
      <Text
        style={{
          color: "rgba(255, 255, 255, 0.8)",
          fontSize: fontSize,
        }}
      >
        {handName}
      </Text>
    </View>
  ) : (
    <Fragment />
  );
}
