export default {
    "portrait": {
        "0": require('../assets/images/theme/default/chips/portrait/0.png'),
        "1": require('../assets/images/theme/default/chips/portrait/1.png'),
        "5": require('../assets/images/theme/default/chips/portrait/5.png'),
        "25": require('../assets/images/theme/default/chips/portrait/25.png'),
        "100": require('../assets/images/theme/default/chips/portrait/100.png'),
        "500": require('../assets/images/theme/default/chips/portrait/500.png'),
        "1000": require('../assets/images/theme/default/chips/portrait/1000.png'),
        "2500": require('../assets/images/theme/default/chips/portrait/2500.png'),
        "10000": require('../assets/images/theme/default/chips/portrait/10000.png'),
        "50000": require('../assets/images/theme/default/chips/portrait/50000.png'),
        "100000": require('../assets/images/theme/default/chips/portrait/100000.png'),
        "250000": require('../assets/images/theme/default/chips/portrait/250000.png'),
        "500000": require('../assets/images/theme/default/chips/portrait/500000.png'),
        "2500000": require('../assets/images/theme/default/chips/portrait/2500000.png'),
        "10000000": require('../assets/images/theme/default/chips/portrait/10000000.png'),
        "50000000": require('../assets/images/theme/default/chips/portrait/50000000.png'),
        "100000000": require('../assets/images/theme/default/chips/portrait/100000000.png'),
        "500000000": require('../assets/images/theme/default/chips/portrait/500000000.png'),
        "2500000000": require('../assets/images/theme/default/chips/portrait/2500000000.png'),
        "100000000000": require('../assets/images/theme/default/chips/portrait/100000000000.png'),
        "500000000000": require('../assets/images/theme/default/chips/portrait/500000000000.png'),
        "2500000000000": require('../assets/images/theme/default/chips/portrait/2500000000000.png'),
    },
    "landscape": {
        "0": require('../assets/images/theme/default/chips/landscape/0.png'),
        "1": require('../assets/images/theme/default/chips/landscape/1.png'),
        "5": require('../assets/images/theme/default/chips/landscape/5.png'),
        "25": require('../assets/images/theme/default/chips/landscape/25.png'),
        "100": require('../assets/images/theme/default/chips/landscape/100.png'),
        "500": require('../assets/images/theme/default/chips/landscape/500.png'),
        "1000": require('../assets/images/theme/default/chips/landscape/1000.png'),
        "2500": require('../assets/images/theme/default/chips/landscape/2500.png'),
        "10000": require('../assets/images/theme/default/chips/landscape/10000.png'),
        "50000": require('../assets/images/theme/default/chips/landscape/50000.png'),
        "100000": require('../assets/images/theme/default/chips/landscape/100000.png'),
        "250000": require('../assets/images/theme/default/chips/landscape/250000.png'),
        "500000": require('../assets/images/theme/default/chips/landscape/500000.png'),
        "2500000": require('../assets/images/theme/default/chips/landscape/2500000.png'),
        "10000000": require('../assets/images/theme/default/chips/landscape/10000000.png'),
        "50000000": require('../assets/images/theme/default/chips/landscape/50000000.png'),
        "100000000": require('../assets/images/theme/default/chips/landscape/100000000.png'),
        "500000000": require('../assets/images/theme/default/chips/landscape/500000000.png'),
        "2500000000": require('../assets/images/theme/default/chips/landscape/2500000000.png'),
        "100000000000": require('../assets/images/theme/default/chips/landscape/100000000000.png'),
        "500000000000": require('../assets/images/theme/default/chips/landscape/500000000000.png'),
        "2500000000000": require('../assets/images/theme/default/chips/landscape/2500000000000.png'),
    }
} as any