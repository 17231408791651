import React from "react";
import {
  ActivityIndicator,
  Button,
  Pressable,
  ScrollView,
  Text,
  TextInput,
  View,
} from "react-native";
import { useAccount } from "../../../contexts/Account";
import Modal from "../Modal";
//import Joi, {ValidationError} from "joi";
import { styles, styleWithFontScale } from "./styles";
import { useTranslation } from "react-i18next";

type Props = {
  showButton: boolean;
  modalVisibleExternal?: boolean;
  setModalVisibleExternal?: (modalVisibleExternal: boolean) => void;
};

export default function ({
  showButton,
  modalVisibleExternal,
  setModalVisibleExternal,
}: Props) {
  const { t } = useTranslation();

  //const navigation = useNavigation();
  //const { signed } = useAccount();
  //const {table, currentPlayer} = useWebSocket();
  const { signUp, error } = useAccount();

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);

  const nextFocus = React.useRef<TextInput>(null);

  const focusNextField = () => {
    if (nextFocus.current != null) {
      nextFocus.current.focus();
    }
  };

  //const [error, setError] = React.useState<ValidationError | undefined>();

  const [username, onChangeUsername] = React.useState<string>("");
  const [email, onChangeEmail] = React.useState<string>("");
  const [newPassword, onChangeNewPassword] = React.useState<string>("");

  const [loading, setLoading] = React.useState<boolean>(false);

  //const userSignUpSchema = Joi.object({
  //    username: Joi.string().max(30).required(),
  //    email: Joi.string().email({ tlds: { allow: false } }).required(),
  //    newPassword: Joi.string().min(8).max(20).required(),
  //});

  const signUpSubmit = () => {
    setLoading(true);
    //const { error } = userSignUpSchema.validate({ username, email, newPassword });
    //if (error) {
    //    setError(error);
    //} else {
    signUp(username, email, newPassword);
    //}
  };

  React.useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  React.useEffect(() => {
    if (!showButton && modalVisibleExternal !== undefined) {
      setModalVisible(modalVisibleExternal);
    }
  }, [modalVisibleExternal]);

  React.useEffect(() => {
    if (!showButton && setModalVisibleExternal !== undefined) {
      setModalVisibleExternal(modalVisible);
    }
  }, [modalVisible]);

  return (
    <React.Fragment>
      <Modal
        title={t("ModalSignUp.signUp")}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      >
        <View style={{ marginBottom: 10 }}>
          {loading ? <ActivityIndicator /> : <></>}
          {error?.map((err, index) => (
            <Text key={index} style={{ color: "#FFFFFF" }}>{`${
              index + 1
            }. ${err}`}</Text>
          ))}
        </View>
        <ScrollView>
          <View style={{ flex: 1 }}>
            <View style={{ flex: 1 }}>
              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalSignUp.username")}
                </Text>
                <TextInput
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeUsername}
                  maxLength={15}
                  value={username}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalSignUp.emailAddress")}
                </Text>
                <TextInput
                  textContentType="emailAddress"
                  keyboardType="email-address"
                  clearTextOnFocus={true}
                  ref={nextFocus}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeEmail}
                  value={email}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalSignUp.password")}
                </Text>
                <TextInput
                  textContentType="newPassword"
                  secureTextEntry={true}
                  clearTextOnFocus={true}
                  ref={nextFocus}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeNewPassword}
                  value={newPassword}
                />
              </View>

              {/*<Button title='Continue' onPress={signUpSubmit} />*/}

              <Pressable style={styles.button} onPress={signUpSubmit}>
                <Text style={[styleWithFontScale(20, 14)]}>
                  {t("ModalSignUp.buttonSignUp")}
                </Text>
              </Pressable>
            </View>
          </View>
        </ScrollView>
      </Modal>

      {showButton && (
        <Pressable
          style={[styles.button, { backgroundColor: "#333333" }]}
          onPress={() => setModalVisible(!modalVisible)}
        >
          <Text style={[styleWithFontScale(20, 14)]}>
            {t("ModalSignUp.buttonSignUp")}
          </Text>
        </Pressable>
      )}
    </React.Fragment>
  );
}
