import React from "react";
import { PlayerProps } from "../../../../../../../types";
import { useTable } from "../../../../../../../contexts/Table";
import { Image, View } from "react-native";
import { MotiView } from "moti";

export default ({ player }: { player: PlayerProps }) => {
    const { table } = useTable();
    //const { isShowdownBeforeRiver } = table;

    //const [delay, setDelay] = React.useState<number>(0);

    //React.useEffect(() => {
    //    setDelay(table.isShowdownBeforeRiver ? 5000 : 0)
    //}, [table.isShowdownBeforeRiver]);

    const [showImage, setShowImage] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (player.winner) {
            let delay = 500;

            if (table.isShowdownBeforeRiver) {
                delay = 5000;
            }

            setTimeout(() => {
                setShowImage(true);
            }, delay);
        } else {
            setShowImage(false);
        }
    }, [player.winner, table.isShowdownBeforeRiver]);

    return (
        <View style={{ position: 'absolute', flex: 1, width: '94%', height: '100%' }}>
            {player.winner ?
                <Image
                    style={[{ resizeMode: 'stretch', width: '100%', height: '100%' }, showImage ? { display: 'flex' } : { display: 'none' }]}
                    source={require('../../../../../../../assets/images/theme/default/table/pod_winnerEffect.png')}
                /*from={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                // transition={{type:'timing',duration:200,delay:delay}}
                transition={{ type: 'timing', duration: 500 }}*/
                />
                :
                table.currentPosition == player.seatNumber ?
                    <Image
                        style={{ resizeMode: 'stretch', width: '100%', height: '100%' }}
                        source={require('../../../../../../../assets/images/theme/default/table/pod_activeEffect.png')}
                    /*from={{ opacity: 1 }}
                    animate={{ opacity: [0, 1, 0] }}
                    transition={{ type: 'timing', duration: 500 }}*/
                    />
                    :
                    <></>}
        </View>
    )
}