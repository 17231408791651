import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Pressable,
  Image,
  ImageBackground,
} from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { useDimensions } from "../../contexts/Dimensions";
import { useWebSocket } from "../../contexts/WebSocket";
import { BlindSchedule, PayoutSchedule, PlayerListType, PokerBettingLimitAbbr, PokerBettingLimitDescription, PokerVariantAbbr, PokerVariantDescription, RegistrationStatus, Registration as RegistrationType, TableListType, TableTheme, TournamentStatus, TournamentTableListType, Tournament as TournamentType } from "../../types";
import { theme } from "../../util/GlobalStyles";
import { useTranslation } from "react-i18next";
import { useSetting } from "../../contexts/Setting";
import { monetaryValue } from "../../util";
import { useGameOptions } from "../../contexts/GameOptions";
import { useAccount } from "../../contexts/Account";

export default function Tournament() {
  const { t } = useTranslation();

  const { showLevel } = useGameOptions();
  const { orientation } = useDimensions();
  const { socket, tableId, setTableId } = useWebSocket();
  const { settings } = useSetting();
  const navigation = useNavigation();
  const { user } = useAccount();

  const [selectedTournamentIndex, setSelectedTournamentIndex] = React.useState<number>(0);


  const [tournaments, setTournaments] = React.useState<TournamentType[]>([]);
  const [registrations, setRegistrations] = React.useState<RegistrationType[]>([]);

  const [blindSchedules, setBlindSchedules] = React.useState<BlindSchedule[]>([]);
  const [payoutSchedules, setPayoutSchedules] = React.useState<PayoutSchedule[]>([]);

  const [tables, setTables] = React.useState<TournamentTableListType[]>([]);

  const [maintenance, setMaintenance] = React.useState<boolean>(false);
  const [maintenanceMessage, setMaintenanceMessage] =
    React.useState<string>("");

  const [showTournament, setShowTournament] =
    React.useState<boolean>(false);

  const [selectedTournamentTab, setSelectedTournamentTab] =
    React.useState<"OVERVIEW" | "PLAYERS" | "TABLES" | "AUDIT_LOG" | "STRUCTURE">("OVERVIEW");

  const [registered, setRegistered] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (settings.maintenance) {
      setMaintenance(settings.maintenance);
      setMaintenanceMessage(settings.maintenanceMessage);
    } else if (settings.scheduledMaintenance) {
      setMaintenance(settings.scheduledMaintenance);
      setMaintenanceMessage(settings.scheduledMaintenanceMessage);
    } else {
      setMaintenance(false);
      setMaintenanceMessage("");
    }
  }, [settings]);
  //const [updateInterval, setUpdateInterval] = React.useState<NodeJS.Timer | undefined>();

  React.useEffect(() => {
    if (socket.connected) {
      // Tournaments -----------------------------------------------------------
      socket.on("tournament:list", (listTournaments: TournamentType[]) => {
        if (settings.maintenance) {
          setTournaments([]);
        } else {
          setTournaments(listTournaments)
        }

        //console.log(listTournaments);
      });
      socket.emit("tournament:list");
    }

    return () => {
      if (socket.connected) {
        socket.off("tournament:list");
      }
    };
  }, [socket, settings]);

  React.useEffect(() => {
    if (socket.connected && tournaments.length > 0) {
      // Tables ----------------------------------------------------------------
      socket.on("tournament:tables:list", (listTables: TournamentTableListType[]) => {
        if (settings.maintenance) {
          setTables([]);
        } else {
          setTables(listTables);
        }

        //console.log(listTables);
      });
      socket.emit("tournament:tables:list", tournaments[selectedTournamentIndex].id);

      // Registrations ---------------------------------------------------------
      socket.on("tournament:registration:list", (listRegistrations: RegistrationType[]) => {
        if (settings.maintenance) {
          setRegistrations([]);
        } else {
          setRegistrations(listRegistrations);

          if (user && listRegistrations.find(registration => registration.userId === user.id && registration.status === RegistrationStatus.REGISTERED)) {
            setRegistered(true);
          } else {
            setRegistered(false);
          }
        }

        //console.log(listRegistrations);
      });
      socket.emit("tournament:registration:list", tournaments[selectedTournamentIndex].id);

      // blindSchedules ---------------------------------------------------------
      socket.on("tournament:blindSchedules:list", (listBlindSchedules: BlindSchedule[]) => {
        if (settings.maintenance) {
          setBlindSchedules([]);
        } else {
          setBlindSchedules(listBlindSchedules);
        }

        //console.log(listBlindSchedules);
      });
      socket.emit("tournament:blindSchedules:list", tournaments[selectedTournamentIndex].blindStructureId);

      // payoutSchedules ---------------------------------------------------------
      socket.on("tournament:payoutSchedules:list", (listPayoutSchedules: PayoutSchedule[]) => {
        if (settings.maintenance) {
          setPayoutSchedules([]);
        } else {
          setPayoutSchedules(listPayoutSchedules);
        }

        //console.log(listPayoutSchedules);
      });
      socket.emit("tournament:payoutSchedules:list", tournaments[selectedTournamentIndex].payoutStructureId);
    }

    return () => {
      if (socket.connected) {
        socket.off("tournament:registration:list");
        socket.off("tournament:tables:list");
        socket.off("tournament:blindSchedules:list");
        socket.off("tournament:payoutSchedules:list");
      }
    } 
  }, [selectedTournamentIndex, tournaments, user]);

  React.useEffect(() => {
    if (tournaments.length > 0 && selectedTournamentIndex === undefined) {
      setSelectedTournamentIndex(0);
    }
  }, [tournaments]);

  function handleSelectTournamentIndex(index: number) {
    setSelectedTournamentIndex(index);

    if (orientation === "portrait") {
      setShowTournament(true);
    }
  }

  function backButtonShowTournament() {
    setShowTournament(false);
  }

  function registerIn() {
    if (socket.connected) {
      if (registered) {
        socket.emit("tournament:registration:remove", tournaments[selectedTournamentIndex].id);
      } else {
        socket.emit("tournament:registration:create", tournaments[selectedTournamentIndex].id);
      }
    }
  }

  /*
    React.useEffect(() => {
        //socket.emit("tables");

        console.log("socket.emit(tables);");

        setUpdateInterval(setTimeout(() => {
            socket.emit("tables");
          }, 5000));
  
          return () => {
            clearTimeout(updateInterval);
            setUpdateInterval(undefined);
          };
    });
    */

  return (
    <View style={{ flex: 1 }}>
      {maintenance ? (
        <View
          style={{
            marginHorizontal: 10,
            marginBottom: 15,
            borderRadius: 8,
            borderColor: "orange",
            borderWidth: 2,
            backgroundColor: "orange",
            padding: 15,
          }}
        >
          <Text
            style={{ color: "rgba(0, 0, 0, 1)", textAlign: "center", fontSize: 22, fontWeight: "bold" }}
          >
            {maintenanceMessage}
          </Text>
        </View>
      ) : (
        <View style={{ display: "none" }} />
      )}

      {tournaments.length > 0 ? (
        <View style={{ flex: 1, flexDirection: "row" }}>
          <View style={[{ 
              flex: 1, 
              maxWidth: orientation === "landscape" ? '60%' : "100%" 
            },
            orientation === "portrait" && showTournament ? {
              display: "none",
            } : {}]}>
            <View style={{ paddingHorizontal: 3 }}>
              <View
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.20)",
                  borderBottomWidth: 2,
                  borderBottomColor: "rgba(255, 255, 255, 0.10)",
                  marginBottom: 5,
                  padding: 10,
                  borderRadius: 5,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ flex: 1, justifyContent: 'center', display: orientation === 'portrait' ? 'none' : 'flex' }}>
                        <Text style={{ color: 'white', fontWeight: 'bold' }}>{t("TournamentScreen.STATUS")}</Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: 'center', display: orientation === 'portrait' ? 'none' : 'flex' }}>
                        <Text style={{ color: 'white', fontWeight: 'bold' }}>{t("TournamentScreen.NAME")}</Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: 'center' }}>
                        <Text style={{ color: 'white', fontWeight: 'bold' }}>{t("TournamentScreen.TYPE")}</Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: 'center' }}>
                        <Text style={{ color: 'white', fontWeight: 'bold' }}>{t("TournamentScreen.GAMETYPE")}</Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: 'center'}}>
                        <Text style={{ color: 'white', fontWeight: 'bold', textAlign: "center" }}>{t("TournamentScreen.BUY-IN")}</Text>
                    </View>
              </View>
            </View>
            <ScrollView
              style={{ flex: 1, paddingHorizontal: 3, marginVertical: 1 }}
            >
              {tournaments.map((tournament, index) => (
                <View key={tournament.id}>
                  <Pressable onPress={() => handleSelectTournamentIndex(index)}>
                    <View
                      style={[
                        {
                          backgroundColor: "rgba(255, 255, 255, 0.1)",
                          borderBottomWidth: 2,
                          borderBottomColor: "rgba(255, 255, 255, 0.1)",
                          marginBottom: 2,
                          paddingHorizontal: 5,
                          paddingVertical: 5,
                          borderRadius: 5,
                          flexDirection: "row",
                          justifyContent: "space-between",
                        },
                        selectedTournamentIndex === index && {
                          borderBottomColor: "white",
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                        },
                      ]}
                    >
                      <View
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          paddingLeft: 5,
                          display: orientation === "portrait" ? "none" : "flex",
                        }}
                      >
                        <Text style={{ color: "white" }}>
                          {tournament.status}
                        </Text>
                      </View>
                      <View style={{ flex: 1, justifyContent: "center", display: orientation === 'portrait' ? 'none' : 'flex' }}>
                        <Text style={{ color: "white" }}>{tournament.name}</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          justifyContent: "center",
                        }}
                      >
                        <Text style={{ color: "white" }}>{tournament.pokerTournamentVariant}</Text>
                      </View>
                      <View style={{ flex: 1, justifyContent: "center" }}>
                        <Text style={{ color: "white" }}>{PokerBettingLimitAbbr[tournament.pokerBettingLimit]}{PokerVariantAbbr[tournament.pokerVariant]}</Text>
                      </View>
                      <View style={{ flex: 1, justifyContent: "center"}}>
                        <Pressable
                          onPress={() => handleSelectTournamentIndex(index)}
                        >
                          <View
                            style={{
                              backgroundColor: theme.colors.primary,
                              borderRadius: 15,
                              padding: 6,
                            }}
                          >
                            <Text
                              style={{
                                color: "white",
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              <FontAwesome
                                name="play"
                                size={16}
                                color="rgba(255, 255, 255, 0.8)"
                                style={{ marginRight: 10 }}
                              />
                              {monetaryValue(tournament.minBuyIn)}
                            </Text>
                          </View>
                        </Pressable>
                      </View>
                    </View>
                  </Pressable>
                </View>
              ))}
            </ScrollView>
          </View>

            <View style={[{ 
              flex: 1, 
              maxWidth: orientation === "landscape" ? '40%' : "100%", 
              paddingBottom: 10 
            },
            orientation === "portrait" && !showTournament ? {
              display: "none",
            } : {}]}>
              <View
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.20)",
                  borderBottomWidth: 2,
                  borderBottomColor: "rgba(255, 255, 255, 0.10)",
                  marginBottom: 5,
                  padding: 10,
                  borderRadius: 5,
                  position: "relative",
                }}
              >
                {orientation === "portrait" && (
                  <View style={{position:"absolute", top: 5, left: 10}}>
                    <Pressable onPress={() => backButtonShowTournament()}>
                      <FontAwesome
                        name="chevron-circle-left"
                        size={30}
                        color="rgba(255, 255, 255, 0.9)"
                      />
                    </Pressable>
                  </View>
                )}
                
                <Text
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {tournaments[selectedTournamentIndex].name}
                </Text>
              </View>

              <View style={{
                flexDirection: "row",
                      justifyContent: "space-between",
                      
              }}>
              
                  <View style={[{
                      flex: 1, 
                      padding: 10, 
                      borderRadius: 10, 
                      backgroundColor: "rgba(255, 255, 255, 0.1)", 
                      margin: 2.5
                    }, 
                    selectedTournamentTab === "OVERVIEW" ? {
                      borderBottomWidth: 2,
                      borderBottomColor: theme.colors.primary,
                    } : {}]}>
                    <Pressable onPress={() => setSelectedTournamentTab("OVERVIEW")}>
                      <Text style={[{
                        color: "white", 
                        textAlign: "center"
                      }, selectedTournamentTab === "OVERVIEW" ? {
                        fontWeight: "bold", 
                      } : {}]}>OVERVIEW</Text>
                    </Pressable>
                  </View>

                  <View style={[{
                      flex: 1, 
                      padding: 10, 
                      borderRadius: 10, 
                      backgroundColor: "rgba(255, 255, 255, 0.1)", 
                      margin: 2.5
                    }, 
                    selectedTournamentTab === "TABLES" ? {
                      borderBottomWidth: 2,
                      borderBottomColor: theme.colors.primary,
                    } : {}]}>
                    <Pressable onPress={() => setSelectedTournamentTab("TABLES")}>
                      <Text style={[{
                        color: "white", 
                        textAlign: "center"
                      }, selectedTournamentTab === "TABLES" ? {
                        fontWeight: "bold", 
                      } : {}]}>TABLES</Text>
                    </Pressable>
                  </View>

                
                  <View style={[{
                      flex: 1, 
                      padding: 10, 
                      borderRadius: 10, 
                      backgroundColor: "rgba(255, 255, 255, 0.1)", 
                      margin: 2.5
                    }, 
                    selectedTournamentTab === "PLAYERS" ? {
                      borderBottomWidth: 2,
                      borderBottomColor: theme.colors.primary,
                    } : {}]}>
                    <Pressable onPress={() => setSelectedTournamentTab("PLAYERS")}>
                      <Text style={[{
                        color: "white", 
                        textAlign: "center"
                      }, selectedTournamentTab === "PLAYERS" ? {
                        fontWeight: "bold", 
                      } : {}]}>PLAYERS</Text>
                    </Pressable>
                  </View>
                
                  <View style={[{
                      flex: 1, 
                      padding: 10, 
                      borderRadius: 10, 
                      backgroundColor: "rgba(255, 255, 255, 0.1)", 
                      margin: 2.5
                    }, 
                    selectedTournamentTab === "STRUCTURE" ? {
                      borderBottomWidth: 2,
                      borderBottomColor: theme.colors.primary,
                    } : {}]}>
                    <Pressable onPress={() => setSelectedTournamentTab("STRUCTURE")}>
                      <Text style={[{
                        color: "white", 
                        textAlign: "center"
                      }, selectedTournamentTab === "STRUCTURE" ? {
                        fontWeight: "bold", 
                      } : {}]}>STRUCTURE</Text>
                    </Pressable>
                  </View>
                  
              </View>

              
              {selectedTournamentTab === "TABLES" && (
                <View style={{ flex: 1}}>
                <View style={{ paddingHorizontal: 3 }}>
                <View
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.20)",
                    borderBottomWidth: 2,
                    borderBottomColor: "rgba(255, 255, 255, 0.10)",
                    marginBottom: 5,
                    padding: 10,
                    borderRadius: 5,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                    }}
                  >
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      TABLE
                    </Text>
                  </View>

                  <View style={{ flex: 1, justifyContent: "center" }}>
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      PLAYERS
                    </Text>
                  </View>

                  <View style={{ flex: 1, justifyContent: "center" }}>
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      BIGGER STACK
                    </Text>
                  </View>

                  <View style={{ flex: 1, justifyContent: "center" }}>
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      SMALLEST STACK
                    </Text>
                  </View>
                </View>
              </View>
              <ScrollView
                style={{ flex: 1, paddingHorizontal: 3, marginVertical: 1 }}
              >
                {tables.map((table, index) => (
                  <View key={table.id}>
                    <Pressable 
                      onPress={() => {
                      if (tableId) {
                        socket.off("update table");
                        socket.off("action time");
                        //socket.off("chat");
                        socket.off("emoji");
                        socket.emit("leave table", tableId);
                      }

                      setTableId(table.id);
                      navigation.navigate("Table");
                    }}>
                      <View
                        style={[
                          {
                            backgroundColor: table.isOnTable
                              ? "rgba(255, 255, 255, 0.2)"
                              : "rgba(255, 255, 255, 0.1)",
                            borderBottomWidth: 2,
                            borderBottomColor: "rgba(255, 255, 255, 0.1)",
                            marginBottom: 2,
                            paddingHorizontal: 5,
                            paddingVertical: 5,
                            borderRadius: 5,
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }
                        ]}
                      >
                        <View
                          style={{
                            flex: 1,
                            justifyContent: "center",
                            paddingLeft: 5,
                          }}
                        >
                          <Text style={{ color: "white", textAlign: "center" }}>
                            {table.tournamentTableId}
                          </Text>
                        </View>
                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Text style={{ color: "white", textAlign: "center" }}>{table.playersCount}</Text>
                        </View>

                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Text style={{ color: "white", textAlign: "center" }}>{monetaryValue(table.biggerStack)}</Text>
                        </View>

                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Text style={{ color: "white", textAlign: "center" }}>{monetaryValue(table.smallestStack)}</Text>
                        </View>
                      </View>
                    </Pressable>
                  </View>
                ))}
              </ScrollView>
              </View>
              )}


              {selectedTournamentTab === "PLAYERS" && (
                <View style={{ flex: 1}}>
                <View style={{ paddingHorizontal: 3 }}>
                <View
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.20)",
                    borderBottomWidth: 2,
                    borderBottomColor: "rgba(255, 255, 255, 0.10)",
                    marginBottom: 5,
                    padding: 10,
                    borderRadius: 5,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                    }}
                  >
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      RANK
                    </Text>
                  </View>
                  <View style={{ flex: 1, justifyContent: "center" }}>
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      PLAYER
                    </Text>
                  </View>

                  <View style={{ flex: 1, justifyContent: "center" }}>
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      FLAG
                    </Text>
                  </View>

                  <View style={{ flex: 1, justifyContent: "center" }}>
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      STACK
                    </Text>
                  </View>
                </View>
              </View>
              <ScrollView
                style={{ flex: 1, paddingHorizontal: 3, marginVertical: 1 }}
              >
                {registrations.map((registration, index) => (
                  <View key={registration.id}>
                    <Pressable onPress={() => {}}>
                      <View
                        style={[
                          {
                            backgroundColor: registration.userId === 0
                              ? "rgba(255, 255, 255, 0.2)"
                              : "rgba(255, 255, 255, 0.1)",
                            borderBottomWidth: 2,
                            borderBottomColor: "rgba(255, 255, 255, 0.1)",
                            marginBottom: 2,
                            paddingHorizontal: 5,
                            paddingVertical: 5,
                            borderRadius: 5,
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }
                        ]}
                      >
                        <View
                          style={{
                            flex: 1,
                            justifyContent: "center",
                            paddingLeft: 5,
                          }}
                        >
                          <Text style={{ color: "white", textAlign: "center" }}>
                          {registration.position ?? (index + 1)}
                          </Text>
                        </View>

                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Text style={{ color: "white", textAlign: "center" }}>{registration.user.username}</Text>
                        </View>

                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Text style={{ color: "white", textAlign: "center" }}></Text>
                        </View>

                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Text style={{ color: "white", textAlign: "center" }}>{(registration.stack || registration.stack === 0) ? monetaryValue(registration.stack) : "FINISHED"}</Text>
                        </View>
                      </View>
                    </Pressable>
                  </View>
                ))}
              </ScrollView>
              </View>
              )}

              {selectedTournamentTab === "STRUCTURE" && (
                <View style={{ flex: 1}}>
                <View style={{ paddingHorizontal: 3 }}>
                <View
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.20)",
                    borderBottomWidth: 2,
                    borderBottomColor: "rgba(255, 255, 255, 0.10)",
                    marginBottom: 5,
                    padding: 10,
                    borderRadius: 5,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                    }}
                  >
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      LEVEL
                    </Text>
                  </View>
                  <View style={{ flex: 1, justifyContent: "center" }}>
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      REBUY
                    </Text>
                  </View>
                  <View style={{ flex: 1, justifyContent: "center" }}>
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      BLINDS
                    </Text>
                  </View>
                  <View style={{ flex: 1, justifyContent: "center" }}>
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      ANTE
                    </Text>
                  </View>
                  <View style={{ flex: 1, justifyContent: "center" }}>
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      DURATION
                    </Text>
                  </View>
                </View>
              </View>
              <ScrollView
                style={{ flex: 1, paddingHorizontal: 3, marginVertical: 1 }}
              >
                {blindSchedules.map((blindSchedule, index) => (
                  <View key={blindSchedule.id}>
                    <Pressable onPress={() => {}}>
                      <View
                        style={[
                          {
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                            borderBottomWidth: 2,
                            borderBottomColor: "rgba(255, 255, 255, 0.1)",
                            marginBottom: 2,
                            paddingHorizontal: 5,
                            paddingVertical: 5,
                            borderRadius: 5,
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }
                        ]}
                      >
                        <View
                          style={{
                            flex: 1,
                            justifyContent: "center",
                            paddingLeft: 5,
                          }}
                        >
                          <Text style={{ color: "white", textAlign: "center" }}>
                          {blindSchedule.id}
                          </Text>
                        </View>
                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Text style={{ color: "white", textAlign: "center" }}>{blindSchedule.rebuy ? "true" : "false"}</Text>
                        </View>
                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Text style={{ color: "white", textAlign: "center" }}>{`${monetaryValue(blindSchedule.smallBlind)}/${monetaryValue(blindSchedule.bigBlind)}`}</Text>
                        </View>
                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Text style={{ color: "white", textAlign: "center" }}>{blindSchedule.ante ? monetaryValue(blindSchedule.ante) : ""}</Text>
                        </View>
                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Text style={{ color: "white", textAlign: "center" }}>{blindSchedule.duration ?? "∞"}</Text>
                        </View>
                      </View>
                    </Pressable>
                  </View>
                ))}
              </ScrollView>
              </View>
              )}


              {selectedTournamentTab === "OVERVIEW" && (
                <View style={{ flex: 1}}>
                  <View style={{ flex: 1, paddingHorizontal: 15}}>
                  <View style={{marginVertical: 15}}>
                    <Text style={{color: "white", textAlign: "center"}}>{tournaments[selectedTournamentIndex].description}</Text>
                  </View>
                  <View style={{marginVertical: 10}}>
                    <Text style={{color: "#ff0", fontWeight: "bold", textAlign: "center", fontSize: 30}}>{tournaments[selectedTournamentIndex].status}</Text>
                  </View>
                  <View style={{marginVertical: 10}}>
                    <Text style={{color: "white", textAlign: "center"}}>{PokerBettingLimitDescription[tournaments[selectedTournamentIndex].pokerBettingLimit]} {PokerVariantDescription[tournaments[selectedTournamentIndex].pokerVariant]}</Text>
                  </View>
                  <View style={{flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10}}>
                    <View>
                      <Text style={{color: "white"}}>Registrations {registrations.length} / {tournaments[selectedTournamentIndex].minRegistrations}</Text>
                    </View>
                    <View style={{display: "none"}}>
                      <Text style={{color: "white"}}>Next break of 5 minutes in 06:40</Text>
                    </View>
                  </View>
                  <View style={{flexDirection: 'row', justifyContent: 'space-between',marginVertical: 10}}>
                    <View>
                      <Text style={{color: "white", fontWeight: "bold"}}>
                        Blinds {monetaryValue(tournaments[selectedTournamentIndex].blindSchedule.smallBlind)} / {monetaryValue(tournaments[selectedTournamentIndex].blindSchedule.bigBlind)} (Ante: {monetaryValue(tournaments[selectedTournamentIndex].blindSchedule.ante || 0)}) @ Level {tournaments[selectedTournamentIndex].blindSchedule.level}</Text>
                    </View>
                    <View>
                      <Text style={{color: "white"}}>Next level in {tournaments[selectedTournamentIndex].blindIncreaseTimer}:00</Text>
                    </View>
                  </View>
                  <View style={{flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10}}>
                    <View>
                      <Text style={{color: "white"}}>Stack de torneio</Text>
                    </View>
                    <View>
                      <Text style={{color: "rgba(255, 255, 255, .6)"}}>Maior</Text>
                      <Text style={{color: "white"}}>{monetaryValue(tournaments[selectedTournamentIndex].biggerStack || 0)}</Text>
                    </View>
                    <View>
                      <Text style={{color: "rgba(255, 255, 255, .6)"}}>Média</Text>
                      <Text style={{color: "white"}}>{monetaryValue(tournaments[selectedTournamentIndex].averageStack || 0)}</Text>
                    </View>
                    <View>
                      <Text style={{color: "rgba(255, 255, 255, .6)"}}>Menor</Text>
                      <Text style={{color: "white"}}>{monetaryValue(tournaments[selectedTournamentIndex].smallestStack || 0)}</Text>
                    </View>
                  </View>

                  <View style={{marginVertical: 10}}>
                    <Text style={{color: "white"}}>Fichas iniciais: {monetaryValue(tournaments[selectedTournamentIndex].defaultPlayerStack)}</Text>
                  </View>
                </View>
              <View>
              <View style={{ paddingHorizontal: 3 }}>
              <View
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.20)",
                  borderBottomWidth: 2,
                  borderBottomColor: "rgba(255, 255, 255, 0.10)",
                  marginBottom: 5,
                  padding: 10,
                  borderRadius: 5,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                    POSITION
                  </Text>
                </View>
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                    PAYOUT
                  </Text>
                </View>
              </View>
                 </View>
              <ScrollView
                style={{ flex: 1, paddingHorizontal: 3, marginVertical: 1 }}
              >
                {payoutSchedules.filter((p => p.minEntries <= tournaments[selectedTournamentIndex].minRegistrations && p.maxEntries >= tournaments[selectedTournamentIndex].minRegistrations)).map((payoutStructure, index) => (
                  <View key={payoutStructure.id}>
                      <View
                        style={[
                          {
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                            borderBottomWidth: 2,
                            borderBottomColor: "rgba(255, 255, 255, 0.1)",
                            marginBottom: 2,
                            paddingHorizontal: 5,
                            paddingVertical: 5,
                            borderRadius: 5,
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }
                        ]}
                      >
                        <View
                          style={{
                            flex: 1,
                            justifyContent: "center",
                            paddingLeft: 5,
                          }}
                        >
                          <Text style={{ color: "white", textAlign: "center" }}>
                          {payoutStructure.position}
                          </Text>
                        </View>
                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Text style={{ color: "white", textAlign: "center"}}>({payoutStructure.percentage}%)</Text>
                        </View>
                      </View>
                  </View>
                ))}
              </ScrollView>
              </View>
              </View>
              )}


              {tournaments[selectedTournamentIndex].status === TournamentStatus.REGISTERING && (
              <View style={{}}>
                <Pressable
                  onPress={() => registerIn()}
                >
                  <View
                    style={{
                      backgroundColor: theme.colors.primary,
                      borderRadius: 20,
                      padding: 10,
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      <FontAwesome
                        name="play"
                        size={16}
                        color="rgba(255, 255, 255, 0.8)"
                        style={{ marginRight: 10 }}
                      />
                      {registered ? "CANCEL PARTICIPATION" : "REGISTER IN"} 
                    </Text>
                  </View>
                </Pressable>
              </View>
              )}

            </View>
        </View>
      ) : (
        <View style={{ display: "none" }} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //alignItems: 'center',
    //justifyContent: 'center',
  },
});
