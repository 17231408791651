import React from 'react';
import {View} from 'react-native';
import {useTable} from '../../../../contexts/Table';
import {PreAction} from '../../../../types';
import Call from './Call';
import CallAny_Check from './CallAny_Check';
import Check from './Check';
import Fold from './Fold';
import Fold_Check from './Fold_Check';

import {styleActionsPosition} from './styles';

type Props = {
    currentCallValue: number;
    currentBettingValue: number;
}

export default function ({currentCallValue, currentBettingValue}: Props) {
    const {table, currentPlayer} = useTable();

    const [playerBet, setPlayerBet] = React.useState<number>(0);
    const [tableBet, setTableBet] = React.useState<number>(0);

    const [preAction, setPreAction] = React.useState<PreAction | undefined>(undefined);

    React.useEffect(() => {
        if (currentPlayer) {
            setPlayerBet(currentPlayer.bet);
            setPreAction(currentPlayer.preAction);
        } else {
            setPlayerBet(0);
            setPreAction(undefined);
        }

        if (table.currentBet !== null && table.currentBet !== undefined && table.currentBet > 0) {
            setTableBet(table.currentBet);
        } else {
            setTableBet(0);
        }

    }, [currentPlayer, table.currentBet, table.currentRound]);

    return (
        <View style={styleActionsPosition()}>
            <Fold preAction={preAction}/>

            {tableBet <= playerBet && currentPlayer?.action === undefined ?
                <Fold_Check preAction={preAction}/> : <React.Fragment/>
            }

            {tableBet <= playerBet && currentPlayer?.action === undefined ?
                <Check preAction={preAction}/> : <React.Fragment/>
            }

            {tableBet > playerBet ?
                <Call preAction={preAction} currentCallValue={(tableBet - playerBet)}/> : <React.Fragment/>
            }

            {tableBet > playerBet || (currentPlayer?.action !== undefined && tableBet <= playerBet) ?
                <CallAny_Check preAction={preAction}/> : <React.Fragment/>
            }
        </View>
    );
}