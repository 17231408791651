import React from "react";
import {
  ActivityIndicator,
  Button,
  Platform,
  Pressable,
  ScrollView,
  Text,
  TextInput,
  View,
} from "react-native";
import { TextInputMask } from "react-native-masked-text";
import { useAccount } from "../../../contexts/Account";
// import { useDimensions } from '../../../contexts/Dimensions';
import Modal from "../Modal";
// import Joi, {ValidationError} from "joi";
import { styles, styleWithFontScale } from "./styles";
import { useTranslation } from "react-i18next";
import { Switch } from "react-native-gesture-handler";
import { theme } from "../../../util/GlobalStyles";

export default function () {
  const { t } = useTranslation();

  //const navigation = useNavigation();
  //const { signed } = useAccount();
  //const {table, currentPlayer} = useWebSocket();

  const { user, personalInfo, error } = useAccount();

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);

  const nextFocus = React.useRef<TextInput>(null);

  const focusNextField = () => {
    if (nextFocus.current != null) {
      nextFocus.current.focus();
    }
  };

  //const [error, setError] = React.useState<ValidationError | undefined>();
  const [name, onChangeName] = React.useState<string>("");

  const [phoneNumber, onChangePhoneNumber] = React.useState<string>("");
  const [document, onChangeDocument] = React.useState<string>("");

  const [birthDateError, setBirthDateError] = React.useState<boolean>(false);
  const [birthDate, onChangeBirthDate] = React.useState<string>("");

  const [address, onChangeAddress] = React.useState<string>("");
  const [city, onChangeCity] = React.useState<string>("");
  const [state, onChangeState] = React.useState<string>("");
  const [country, onChangeCountry] = React.useState<string>("");
  const [zipcode, onChangeZipcode] = React.useState<string>("");

  const [acceptedTermsError, setAcceptedTermsError] =
    React.useState<boolean>(false);
  const [acceptedTerms, setAcceptedTerms] = React.useState<boolean>(false);
  const [emailMarketing, setEmailMarketing] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleToggleAcceptedTerms = (value: boolean) => {
    setAcceptedTermsError(false);
    setAcceptedTerms(value);
  };

  const handleToggleEmailMarketing = (value: boolean) => {
    setEmailMarketing(value);
  };

  const handleOpenServiceTerms = () => {
    if (Platform.OS === "web") {
      window.open("https://ace2ace.poker/termos-de-servico", "_blank");
    }
  };

  const isOver18 = (dateOfBirth: Date) => {
    const date18YrsAgo = new Date();
    date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
    return dateOfBirth <= date18YrsAgo;
  };

  //const userSignUpSchema = Joi.object({
  //    username: Joi.string().max(30).required(),
  //    email: Joi.string().email({ tlds: { allow: false } }).required(),
  //    newPassword: Joi.string().min(8).max(20).required(),
  //});

  const personalInfoSubmit = () => {
    if (!acceptedTerms) {
      setBirthDateError(false);
      setAcceptedTermsError(true);
      return;
    }

    if (birthDate != "") {
      let splitBirth = birthDate.split("/");
      let dateBirth = new Date(
        `${splitBirth[2]}-${splitBirth[1]}-${splitBirth[0]}`
      );

      if (!isOver18(dateBirth)) {
        setBirthDateError(true);
        return;
      }
    }

    if (!user) return;

    setLoading(true);
    //const { error } = userSignUpSchema.validate({ username, email, newPassword });
    //if (error) {
    //    setError(error);
    //} else {
    personalInfo(
      name,
      document,
      address,
      city,
      state,
      country,
      zipcode,
      phoneNumber,
      birthDate,
      acceptedTerms,
      emailMarketing
    );
    //}
  };

  React.useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  React.useEffect(() => {
    if (user) {
      onChangeName(user.name);

      onChangePhoneNumber(user.phoneNumber);
      onChangeDocument(user.document);
      onChangeBirthDate(user.birthDate);

      onChangeAddress(user.address);
      onChangeCity(user.city);
      onChangeState(user.state);
      onChangeCountry(user.country);
      onChangeZipcode(user.zipcode);

      setAcceptedTerms(user.acceptedTerms);
      setEmailMarketing(user.emailMarketing);

      if (!user.profileComplete || !user.acceptedTerms) {
        setModalVisible(true);
      } else {
        setModalVisible(false);
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      <Modal
        title={t("ModalPersonalInfo.personalInfo")}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        type="fullheight"
      >
        <View style={{ marginBottom: 10 }}>
          {loading ? <ActivityIndicator /> : <></>}
          {error?.map((err, index) => (
            <Text key={index} style={{ color: "red" }}>{`${
              index + 1
            }. ${err}`}</Text>
          ))}

          {acceptedTermsError ? (
            <Text style={{ color: "red" }}>
              {t("ModalPersonalInfo.acceptedTermsError")}
            </Text>
          ) : null}
          {birthDateError ? (
            <Text style={{ color: "red" }}>
              {t("ModalPersonalInfo.birthDateError")}
            </Text>
          ) : null}
        </View>
        <ScrollView>
          <View style={{ flex: 1 }}>
            <View style={{ flex: 1, paddingBottom: 20 }}>
              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalPersonalInfo.name")}
                </Text>
                <TextInput
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeName}
                  maxLength={255}
                  value={name}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalPersonalInfo.CPF")}
                </Text>
                <TextInputMask
                  type={"cpf"}
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeDocument}
                  maxLength={255}
                  value={document}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalPersonalInfo.birthDate")}
                </Text>
                <TextInputMask
                  type={"datetime"}
                  options={{
                    format: "DD/MM/YYYY",
                  }}
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeBirthDate}
                  maxLength={10}
                  value={birthDate}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalPersonalInfo.phoneNumber")}
                </Text>
                <TextInputMask
                  type={"cel-phone"}
                  options={{
                    maskType: "BRL",
                    withDDD: true,
                    dddMask: "(99) ",
                  }}
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangePhoneNumber}
                  maxLength={255}
                  value={phoneNumber}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalPersonalInfo.address")}
                </Text>
                <TextInput
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeAddress}
                  maxLength={255}
                  value={address}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalPersonalInfo.city")}
                </Text>
                <TextInput
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeCity}
                  maxLength={255}
                  value={city}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalPersonalInfo.state")}
                </Text>
                <TextInput
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeState}
                  maxLength={255}
                  value={state}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalPersonalInfo.country")}
                </Text>
                <TextInput
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeCountry}
                  maxLength={255}
                  value={country}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalPersonalInfo.zipCode")}
                </Text>
                <TextInputMask
                  type={"zip-code"}
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeZipcode}
                  maxLength={255}
                  value={zipcode}
                />
              </View>

              <View
                style={{ flex: 1, flexDirection: "row", paddingVertical: 10 }}
              >
                <Switch
                  trackColor={{ false: "#767577", true: theme.colors.primary }}
                  thumbColor={emailMarketing ? "#FFF" : "#888"}
                  ios_backgroundColor="#3e3e3e"
                  onValueChange={handleToggleEmailMarketing}
                  value={emailMarketing}
                  style={{ marginRight: 5 }}
                />
                <Text style={{ color: "#FFFFFF", fontSize: 14 }}>
                  {t("ModalPersonalInfo.emailMarketing")}
                </Text>
              </View>

              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  paddingTop: 10,
                  paddingBottom: 30,
                }}
              >
                <Switch
                  trackColor={{ false: "#767577", true: theme.colors.primary }}
                  thumbColor={acceptedTerms ? "#FFF" : "#888"}
                  ios_backgroundColor="#3e3e3e"
                  onValueChange={handleToggleAcceptedTerms}
                  value={acceptedTerms}
                  style={{ marginRight: 5 }}
                />
                <Text style={{ color: "#FFFFFF", fontSize: 14 }}>
                  {t("ModalPersonalInfo.acceptedTerms.text1")}
                  <Pressable onPress={handleOpenServiceTerms}>
                    <Text style={{ color: theme.colors.primary }}>
                      {t("ModalPersonalInfo.acceptedTerms.text2")}
                    </Text>
                  </Pressable>
                  {t("ModalPersonalInfo.acceptedTerms.text3")}
                </Text>
              </View>

              <Pressable style={styles.button} onPress={personalInfoSubmit}>
                <Text style={[styleWithFontScale(20, 14)]}>
                  {t("ModalPersonalInfo.confirm")}
                </Text>
              </Pressable>
            </View>
          </View>
        </ScrollView>
      </Modal>
      {/*<Button title='Personal Info' color={'#666666'} onPress={() => setModalVisible(!modalVisible)} />*/}
    </React.Fragment>
  );
}
