export default {
  "2c": require("../assets/images/theme/default/cards/3/2c.png"),
  "2d": require("../assets/images/theme/default/cards/3/2d.png"),
  "2h": require("../assets/images/theme/default/cards/3/2h.png"),
  "2s": require("../assets/images/theme/default/cards/3/2s.png"),
  "3c": require("../assets/images/theme/default/cards/3/3c.png"),
  "3d": require("../assets/images/theme/default/cards/3/3d.png"),
  "3h": require("../assets/images/theme/default/cards/3/3h.png"),
  "3s": require("../assets/images/theme/default/cards/3/3s.png"),
  "4c": require("../assets/images/theme/default/cards/3/4c.png"),
  "4d": require("../assets/images/theme/default/cards/3/4d.png"),
  "4h": require("../assets/images/theme/default/cards/3/4h.png"),
  "4s": require("../assets/images/theme/default/cards/3/4s.png"),
  "5c": require("../assets/images/theme/default/cards/3/5c.png"),
  "5d": require("../assets/images/theme/default/cards/3/5d.png"),
  "5h": require("../assets/images/theme/default/cards/3/5h.png"),
  "5s": require("../assets/images/theme/default/cards/3/5s.png"),
  "6c": require("../assets/images/theme/default/cards/3/6c.png"),
  "6d": require("../assets/images/theme/default/cards/3/6d.png"),
  "6h": require("../assets/images/theme/default/cards/3/6h.png"),
  "6s": require("../assets/images/theme/default/cards/3/6s.png"),
  "7c": require("../assets/images/theme/default/cards/3/7c.png"),
  "7d": require("../assets/images/theme/default/cards/3/7d.png"),
  "7h": require("../assets/images/theme/default/cards/3/7h.png"),
  "7s": require("../assets/images/theme/default/cards/3/7s.png"),
  "8c": require("../assets/images/theme/default/cards/3/8c.png"),
  "8d": require("../assets/images/theme/default/cards/3/8d.png"),
  "8h": require("../assets/images/theme/default/cards/3/8h.png"),
  "8s": require("../assets/images/theme/default/cards/3/8s.png"),
  "9c": require("../assets/images/theme/default/cards/3/9c.png"),
  "9d": require("../assets/images/theme/default/cards/3/9d.png"),
  "9h": require("../assets/images/theme/default/cards/3/9h.png"),
  "9s": require("../assets/images/theme/default/cards/3/9s.png"),
  "Tc": require("../assets/images/theme/default/cards/3/10c.png"),
  "Td": require("../assets/images/theme/default/cards/3/10d.png"),
  "Th": require("../assets/images/theme/default/cards/3/10h.png"),
  "Ts": require("../assets/images/theme/default/cards/3/10s.png"),
  "Jc": require("../assets/images/theme/default/cards/3/Jc.png"),
  "Jd": require("../assets/images/theme/default/cards/3/Jd.png"),
  "Jh": require("../assets/images/theme/default/cards/3/Jh.png"),
  "Js": require("../assets/images/theme/default/cards/3/Js.png"),
  "Qc": require("../assets/images/theme/default/cards/3/Qc.png"),
  "Qd": require("../assets/images/theme/default/cards/3/Qd.png"),
  "Qh": require("../assets/images/theme/default/cards/3/Qh.png"),
  "Qs": require("../assets/images/theme/default/cards/3/Qs.png"),
  "Kc": require("../assets/images/theme/default/cards/3/Kc.png"),
  "Kd": require("../assets/images/theme/default/cards/3/Kd.png"),
  "Kh": require("../assets/images/theme/default/cards/3/Kh.png"),
  "Ks": require("../assets/images/theme/default/cards/3/Ks.png"),
  "Ac": require("../assets/images/theme/default/cards/3/Ac.png"),
  "Ad": require("../assets/images/theme/default/cards/3/Ad.png"),
  "Ah": require("../assets/images/theme/default/cards/3/Ah.png"),
  "As": require("../assets/images/theme/default/cards/3/As.png"),
  "CardBack": require("../assets/images/theme/default/cards/3/cardback.png"),
  "cardback": require("../assets/images/theme/default/cards/3/cardback.png"),
} as any;
