import { FontAwesome } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Pressable, Text, View } from "react-native";
import { useTable } from "../../../contexts/Table";
import Aside from "../Aside";
import BuyIn from "../BuyIn";
import { useTranslation } from "react-i18next";
import AddChips from "../AddChips";

import { useWebSocket } from "../../../contexts/WebSocket";
import Rebuy from "../Rebuy";

export default function () {
  const { t } = useTranslation();
  const { setTableId, socket, tableId } = useWebSocket();
  const navigation = useNavigation();

  const { currentPlayer, handleStandUpPress, table, handleSitOutPress } =
    useTable();

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [modalBuyInVisible, setModalBuyInVisible] =
    React.useState<boolean>(false);

  const [modalAddVisible, setModalAddVisible] = React.useState<boolean>(false);
  const [showAddChips, setShowAddChips] = React.useState<boolean>(false);

  const handleSetModalAddVisible = (visible: boolean) => {
    setModalVisible(false);
    setModalAddVisible(visible);
  };

  React.useEffect(() => {
    if (
      currentPlayer &&
      table.addChipsPlayers &&
      table.addChipsPlayers.filter(
        (player) => player && player.id === currentPlayer.id
      ).length === 0 &&
      currentPlayer.stackSize < table.maxBuyIn
    ) {
      setShowAddChips(true);
    } else {
      setShowAddChips(false);
    }
  }, [currentPlayer]);

  return (
    <React.Fragment>
      <Aside
        title=""
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        animationType="none"
      >
        <View style={{ flex: 1 }}>
          <View style={{ flex: 1 }}>
            <Pressable
              style={{ paddingHorizontal: 20, paddingVertical: 15 }}
              onPress={() => {
                setModalVisible(!modalVisible);
                if (tableId) {
                  socket.off("update table");
                  socket.off("action time");
                  //socket.off("chat message");
                  socket.off("emoji");
                  socket.emit("leave table", tableId);
                  //}
                }
                setTableId(undefined);
                navigation.navigate("Root", { screen: "Lobby" });
              }}
            >
              <Text style={{ color: "#FFFFFF", fontSize: 18 }}>
                {t("TableScreen.sideMenu.goToLobby")}
              </Text>
            </Pressable>

            {currentPlayer && showAddChips && !table.tournamentMode ? (
              <Pressable
                style={{ paddingHorizontal: 20, paddingVertical: 15 }}
                onPress={() => handleSetModalAddVisible(!modalAddVisible)}
              >
                <Text style={{ color: "#FFFFFF", fontSize: 18 }}>
                  {t("TableScreen.sideMenu.addChips")}
                </Text>
              </Pressable>
            ) : null}

            {currentPlayer && !currentPlayer.waitingBigBlind && !table.tournamentMode ? (
              <Pressable
                style={{ paddingHorizontal: 20, paddingVertical: 15 }}
                onPress={() => {
                  setModalVisible(!modalVisible);
                  handleSitOutPress();
                }}
              >
                <Text style={{ color: "#FFFFFF", fontSize: 18 }}>
                  {currentPlayer.sitOutNextHand
                    ? t("TableScreen.sideMenu.imBack")
                    : t("TableScreen.sideMenu.sitOut")}
                </Text>
              </Pressable>
            ) : null}

            {currentPlayer === null && !table.tournamentMode && (
              <Pressable
                style={{ paddingHorizontal: 20, paddingVertical: 15 }}
                onPress={() => {
                  setModalVisible(!modalVisible);
                  setModalBuyInVisible(!modalBuyInVisible);
                }}
              >
                <Text style={{ color: "#FFFFFF", fontSize: 18 }}>
                  {t("TableScreen.sideMenu.buyin")}
                </Text>
              </Pressable>
            )} 
            {currentPlayer && !currentPlayer.left && (
              <React.Fragment>
                <Pressable
                  style={{ paddingHorizontal: 20, paddingVertical: 15 }}
                  onPress={() => {
                    setModalVisible(!modalVisible);
                    handleStandUpPress();
                  }}
                >
                  <Text style={{ color: "#FFFFFF", fontSize: 18 }}>
                    {t("TableScreen.sideMenu.leaveGame")}
                  </Text>
                </Pressable>
              </React.Fragment>
            )}
          </View>
        </View>
      </Aside>
      <View style={{}}>
        <Pressable
          style={{ padding: 10 }}
          onPress={() => setModalVisible(!modalVisible)}
        >
          <FontAwesome name="navicon" size={25} color="#FFFFFF" />
        </Pressable>
      </View>

      <Rebuy
        minBuyIn={table.minBuyIn}
        maxBuyIn={table.maxBuyIn}
        tableId={table.id}
      />

      <BuyIn
        modalVisible={modalBuyInVisible}
        setModalVisible={setModalBuyInVisible}
        minBuyIn={table.minBuyIn}
        maxBuyIn={table.maxBuyIn}
        tableId={table.id}
        waitingList={false}
      />

      <AddChips
        modalVisible={modalAddVisible}
        setModalVisible={setModalAddVisible}
        minBuyIn={table.minBuyIn}
        maxBuyIn={table.maxBuyIn - (currentPlayer?.stackSize || 0)}
        tableId={table.id}
        currentPlayer={currentPlayer}
      />
    </React.Fragment>
  );
}
