import {Platform, StyleSheet, TextStyle} from 'react-native';
import {useDimensions} from '../../../contexts/Dimensions';
import {getFontSize} from '../../../util/GlobalStyles';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        // alignItems: 'center',
        //justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        //backgroundColor: 'blue',
        //height: 40,
        //borderRadius: 5,

        //paddingVertical: 8,
        //paddingHorizontal: 15
    },
    rowOne: {
        //backgroundColor: 'rgba(0,0,0,0.2)',
        flexDirection: 'row',
    },
    rowTwo: {
        flexDirection: 'row',
        justifyContent: "space-between",
        width: "100%",
        height: 40,
    },
    title: {
        //fontWeight: 'bold',
    },
});

export const styleWithFontScale = (fontSizeWeb: number, fontSizeDefault: number): TextStyle => {
    return StyleSheet.flatten([styles.title, {fontSize: getFontSize(fontSizeWeb, fontSizeDefault)}]);
}

export function textSize(fontSizeWeb: number, fontSizeDefault: number) {

    const {orientation, width, height} = useDimensions();

    if (Platform.OS === 'web') {
        if (orientation === 'landscape') {
            if (height >= 780 && height <= 880) {
                fontSizeWeb -= 4;
            } else if (height >= 680 && height < 780) {
                fontSizeWeb -= 7;
            } else if (height < 680) {
                fontSizeWeb -= 12;
            }
        } else {
            if (width > 360 && width < 540) {
                fontSizeWeb -= 8;
            } else if (width <= 360) {
                fontSizeWeb -= 12;
            }
        }

        return fontSizeWeb;
    }

    return fontSizeDefault;
}

// export const getFontSize = (fontSizeWeb: number, fontSizeDefault: number): TextStyle => {

//     let font = textSize(fontSizeWeb, fontSizeDefault);

//     return {
//         fontSize: font
//     }
// }