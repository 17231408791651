import {StyleSheet, ViewStyle} from 'react-native';
import {useDimensions} from '../../../../contexts/Dimensions';

export const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        //alignItems: 'center',
        justifyContent: 'center',
        //backgroundColor: 'turquoise',
    },
    image: {
        flex: 1,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        //alignItems: 'center',
        justifyContent: 'center'
    },
    title: {
        fontSize: 22,
        fontWeight: '500',
        color: '#FFB800',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        //backgroundColor: 'blue'
        textAlignVertical: 'center'
    },
});


export const stylePotPosition = (potNumber: number, numberOfPots: number): ViewStyle => {
    return StyleSheet.flatten([styles.container, getPotPosition(potNumber, numberOfPots)]);
}

export const getPotPosition = (potNumber: number, numberOfPots: number): ViewStyle => {

    const {orientation} = useDimensions();

    switch (orientation) {
        case 'landscape':
            switch (potNumber) {
                case 0:
                    return {
                        width: '15%',
                        height: '8%',
                        left: '42.5%',
                        bottom: '38.5%',
                    }
                case 1:
                    return {
                        width: '15%',
                        height: '8%',
                        left: '32%',
                        bottom: '36%',
                    }
                case 2:
                    return {
                        width: '15%',
                        height: '8%',
                        right: '32%',
                        bottom: '36%',
                    }
                default:
                    return {
                        display: 'none',
                    }
            }
        case 'portrait':
            switch (potNumber) {
                case 0:
                    return {
                        width: '26%',
                        height: '6%',
                        left: '37%',
                        bottom: '42%',
                    }
                case 1:
                    return {
                        width: '26%',
                        height: '6%',
                        left: '37%',
                        bottom: '32%',
                    }
                case 2:
                    return {
                        width: '26%',
                        height: '6%',
                        left: '37%',
                        bottom: '20%',
                    }
                default:
                    return {
                        display: 'none',
                    }
            }
        default:
            return {
                display: 'none',
            }
    }

}
