import { AvatarProps } from "../types";

export default [
  {
    id: "0",
    name: "No avatar",
    code: "na",
    sprite: require("../assets/images/theme/default/avatars/thumbnails/avatar_placeholder.png"),
  },
  {
    id: "1",
    name: "Animal 1",
    code: "an0",
    sprite: require("../assets/images/theme/default/avatars/thumbnails/an0.png"),
  },
  {
    id: "2",
    name: "Animal 2",
    code: "an1",
    sprite: require("../assets/images/theme/default/avatars/thumbnails/an1.png"),
  },
  {
    id: "3",
    name: "Animal 3",
    code: "an2",
    sprite: require("../assets/images/theme/default/avatars/thumbnails/an2.png"),
  },
  {
    id: "4",
    name: "Hooded Girl 1",
    code: "hg0",
    sprite: require("../assets/images/theme/default/avatars/thumbnails/hg0.png"),
  },
  {
    id: "5",
    name: "Hooded Girl 2",
    code: "hg1",
    sprite: require("../assets/images/theme/default/avatars/thumbnails/hg1.png"),
  },
  {
    id: "6",
    name: "Hooded man 1",
    code: "hm0",
    sprite: require("../assets/images/theme/default/avatars/thumbnails/hm0.png"),
  },
  {
    id: "7",
    name: "Hooded man 2",
    code: "hm1",
    sprite: require("../assets/images/theme/default/avatars/thumbnails/hm1.png"),
  },
  {
    id: "8",
    name: "Mobster Girl 1",
    code: "mg0",
    sprite: require("../assets/images/theme/default/avatars/thumbnails/mg0.png"),
  },
  {
    id: "9",
    name: "Mobster Man 1",
    code: "mm0",
    sprite: require("../assets/images/theme/default/avatars/thumbnails/mm0.png"),
  },
  {
    id: "10",
    name: "Mobster Man 2",
    code: "mm1",
    sprite: require("../assets/images/theme/default/avatars/thumbnails/mm1.png"),
  },
] as AvatarProps[];
