import React from "react";
import { Text, View } from "react-native";
import { useDimensions } from "../../../../../../../contexts/Dimensions";
import { getFontSize } from "../../../../../../../util/GlobalStyles";
import { styles } from "./styles";

type Props = {
  username: string;
};

export default function ({ username }: Props) {
  const fontSize = getFontSize(20, 14);

  return (
    <View style={styles.container}>
      <Text
        numberOfLines={1}
        adjustsFontSizeToFit={true}
        allowFontScaling={true}
        lineBreakMode={"clip"}
        style={[styles.text, { fontSize: fontSize }]}
      >
        {username}
      </Text>
    </View>
  );
}
