import { FontAwesome } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageBackground,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import Level from "../../collections/Level";
import Tables from "../../collections/Tables";
import { useDimensions } from "../../contexts/Dimensions";
import { useGameOptions } from "../../contexts/GameOptions";
import { useSetting } from "../../contexts/Setting";
import { useWebSocket } from "../../contexts/WebSocket";
import {
  PokerBettingLimitAbbr,
  PokerVariantAbbr,
  TableListType,
  TableTheme,
} from "../../types";
import { monetaryValue } from "../../util";
import { theme } from "../../util/GlobalStyles";

export default function Lobby() {
  const { t } = useTranslation();

  const { showLevel } = useGameOptions();
  const { orientation } = useDimensions();
  const { socket, tableId, setTableId } = useWebSocket();
  const { settings } = useSetting();
  const navigation = useNavigation();

  const [selectedTableIndex, setSelectedTableIndex] = React.useState<number>(0);
  const [tables, setTables] = React.useState<TableListType[]>([]);
  const [maintenance, setMaintenance] = React.useState<boolean>(false);
  const [maintenanceMessage, setMaintenanceMessage] =
    React.useState<string>("");

  React.useEffect(() => {
    if (settings.maintenance) {
      setMaintenance(settings.maintenance);
      setMaintenanceMessage(settings.maintenanceMessage);
    } else if (settings.scheduledMaintenance) {
      setMaintenance(settings.scheduledMaintenance);
      setMaintenanceMessage(settings.scheduledMaintenanceMessage);
    } else {
      setMaintenance(false);
      setMaintenanceMessage("");
    }
  }, [settings]);

  React.useEffect(() => {
    if (socket.connected) {
      socket.on("tables", (listTables: TableListType[]) => {
        if (settings.maintenance) {
          setTables([]);
        } else {
          setTables(listTables);
        }
        //console.log("tables");
      });

      socket.emit("tables");
    }

    return () => {
      if (socket.connected) {
        socket.off("tables");
      }
    };
  }, [socket, settings]);

  React.useEffect(() => {
    if (tables.length > 0 && selectedTableIndex === undefined) {
      setSelectedTableIndex(tables[0].id);
    }
  }, [tables]);

  return (
    <View style={{ flex: 1 }}>
      {maintenance ? (
        <View
          style={{
            marginHorizontal: 10,
            marginBottom: 15,
            borderRadius: 8,
            borderColor: "orange",
            borderWidth: 2,
            backgroundColor: "orange",
            padding: 15,
          }}
        >
          <Text
            style={{
              color: "rgba(0, 0, 0, 1)",
              textAlign: "center",
              fontSize: 22,
              fontWeight: "bold",
            }}
          >
            {maintenanceMessage}
          </Text>
        </View>
      ) : (
        <View style={{ display: "none" }} />
      )}

      {tables.length > 0 ? (
        <View style={{ flex: 1, flexDirection: "row" }}>
          <View style={{ flex: 1 }}>
            <View style={{ paddingHorizontal: 3 }}>
              <View
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.20)",
                  borderBottomWidth: 2,
                  borderBottomColor: "rgba(255, 255, 255, 0.10)",
                  marginBottom: 5,
                  padding: 10,
                  borderRadius: 5,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    display: orientation === "portrait" ? "none" : "flex",
                  }}
                >
                  <Text style={{ color: "white", fontWeight: "bold" }}>
                    {t("Lobby.tableName")}
                  </Text>
                </View>
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <Text style={{ color: "white", fontWeight: "bold" }}>
                    {t("Lobby.game")}
                  </Text>
                </View>
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <Text style={{ color: "white", fontWeight: "bold" }}>
                    {t("Lobby.stakes")}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    display: orientation === "portrait" ? "none" : "flex",
                  }}
                >
                  <Text style={{ color: "white", fontWeight: "bold" }}>
                    {t("Lobby.type")}
                  </Text>
                </View>
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <Text style={{ color: "white", fontWeight: "bold" }}>
                    {t("Lobby.plrs")}
                  </Text>
                </View>
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <Text
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {t("Lobby.buyIn")}
                  </Text>
                </View>
              </View>
            </View>
            <ScrollView
              style={{ flex: 1, paddingHorizontal: 3, marginVertical: 1 }}
            >
              {tables.map((table, index) => (
                <View key={table.id}>
                  <Pressable onPress={() => setSelectedTableIndex(index)}>
                    <View
                      style={[
                        {
                          backgroundColor: table.playing
                            ? "rgba(255, 255, 255, 0.2)"
                            : "rgba(255, 255, 255, 0.1)",
                          borderBottomWidth: 2,
                          borderBottomColor:
                            table.theme === TableTheme.POKERFI
                              ? "rgba(218, 165, 32, 0.50)"
                              : table.theme === TableTheme.JULIALUZ
                              ? "rgba(178, 34, 34, 0.50)"
                              : table.theme === TableTheme.JULIALUZ2
                              ? "rgba(102, 51, 153, 0.50)"
                              : "rgba(255, 255, 255, 0.1)",
                          marginBottom: 2,
                          paddingHorizontal: 5,
                          paddingVertical: 5,
                          borderRadius: 5,
                          flexDirection: "row",
                          justifyContent: "space-between",
                        },
                        selectedTableIndex === index && {
                          borderBottomColor: "white",
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                        },
                      ]}
                    >
                      <View
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          paddingLeft: 5,
                          display: orientation === "portrait" ? "none" : "flex",
                        }}
                      >
                        <Text style={{ color: "white" }}>{table.name}</Text>
                      </View>
                      <View style={{ flex: 1, justifyContent: "center" }}>
                        <Text style={{ color: "white" }}>
                          {PokerBettingLimitAbbr[table.bettingLimit]}
                          {PokerVariantAbbr[table.variant]}
                        </Text>
                      </View>
                      <View style={{ flex: 1, justifyContent: "center" }}>
                        <Text style={{ color: "white" }}>{table.stacks}</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          display: orientation === "portrait" ? "none" : "flex",
                        }}
                      >
                        <Text style={{ color: "white" }}>{table.type}</Text>
                      </View>
                      <View style={{ flex: 1, justifyContent: "center" }}>
                        <Text style={{ color: "white" }}>{table.players}</Text>
                      </View>
                      <View style={{ flex: 1, justifyContent: "center" }}>
                        <Pressable
                          onPress={() => {
                            if (tableId) {
                              socket.off("update table");
                              socket.off("action time");
                              //socket.off("chat");
                              socket.off("emoji");
                              socket.emit("leave table", tableId);
                            }

                            setTableId(table.id);
                            navigation.navigate("Table");
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: theme.colors.primary,
                              borderRadius: 15,
                              padding: 6,
                            }}
                          >
                            <Text
                              style={{
                                color: "white",
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              <FontAwesome
                                name="play"
                                size={16}
                                color="rgba(255, 255, 255, 0.8)"
                                style={{ marginRight: 10 }}
                              />
                              {table.buyIn}
                            </Text>
                          </View>
                        </Pressable>
                      </View>
                    </View>
                  </Pressable>
                </View>
              ))}
            </ScrollView>
          </View>

          {orientation === "landscape" && (
            <View style={{ flex: 1, maxWidth: 300, paddingBottom: 10 }}>
              <View
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.20)",
                  borderBottomWidth: 2,
                  borderBottomColor: "rgba(255, 255, 255, 0.10)",
                  marginBottom: 5,
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <Text
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {tables[selectedTableIndex].name}
                </Text>
              </View>

              <ScrollView
                style={{ flex: 1, paddingHorizontal: 3, marginVertical: 1 }}
              >
                <View style={{ flex: 1, minHeight: 450, display: "none" }}>
                  <ImageBackground
                    source={Tables["ace2ace_portrait"]}
                    resizeMode={"contain"}
                    style={{ width: "100%", height: "100%" }}
                    fadeDuration={0}
                  ></ImageBackground>
                </View>
                <View style={{ flex: 1 }}>
                  <View
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: "rgba(255, 255, 255, 0.10)",
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      //marginBottom: 5,
                      padding: 10,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                      }}
                    >
                      <Text style={{ color: "white", fontWeight: "bold" }}>
                        {t("Lobby.player")}
                      </Text>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        maxWidth: "30%",
                      }}
                    >
                      <Text style={{ color: "white", fontWeight: "bold" }}>
                        {t("Lobby.chips")}
                      </Text>
                    </View>
                  </View>

                  {tables[selectedTableIndex].playerList?.map(
                    (player, index) => (
                      <View key={index}>
                        <View
                          style={[
                            {
                              borderBottomWidth: 1,
                              backgroundColor: "rgba(255, 255, 255, 0.06)",
                              borderBottomColor: "rgba(255, 255, 255, 0.10)",
                              //marginBottom: 5,
                              padding: 10,
                              flexDirection: "row",
                              justifyContent: "space-between",
                            },
                            index % 2 === 0 && {
                              backgroundColor: "rgba(255, 255, 255, 0)",
                            },
                          ]}
                        >
                          <View
                            style={{
                              flex: 1,
                              justifyContent: "flex-start",
                              flexDirection: "row",
                            }}
                          >
                            {showLevel && (
                              <Image
                                source={Level[player.level]}
                                style={{
                                  width: 28,
                                  height: 28,
                                  marginRight: 5,
                                  marginTop: -3,
                                }}
                                resizeMode={"contain"}
                              />
                            )}
                            <Text
                              style={{ color: "white", fontWeight: "bold" }}
                            >
                              {player.username}
                            </Text>
                            {player.dealer && (
                              <Image
                                source={require("../../assets/images/theme/default/table/dealer_button_landscape.png")}
                                style={{
                                  width: 20,
                                  height: 20,
                                  marginLeft: 8,
                                  marginTop: 0,
                                }}
                                resizeMode={"contain"}
                              />
                            )}
                          </View>
                          <View
                            style={{
                              flex: 1,
                              justifyContent: "center",
                              maxWidth: "30%",
                            }}
                          >
                            <Text
                              style={{ color: "white", fontWeight: "bold" }}
                            >
                              {monetaryValue(player.stacksize | 0)}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )
                  )}
                </View>
              </ScrollView>

              <View style={{}}>
                <Pressable
                  onPress={() => {
                    if (tableId) {
                      socket.off("update table");
                      socket.off("action time");
                      //socket.off("chat");
                      socket.off("emoji");
                      socket.emit("leave table", tableId);
                    }

                    setTableId(tables[selectedTableIndex].id);
                    navigation.navigate("Table");
                  }}
                >
                  <View
                    style={{
                      backgroundColor: theme.colors.primary,
                      borderRadius: 20,
                      padding: 10,
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      <FontAwesome
                        name="play"
                        size={16}
                        color="rgba(255, 255, 255, 0.8)"
                        style={{ marginRight: 10 }}
                      />
                      {t("Lobby.joinTable")}
                    </Text>
                  </View>
                </Pressable>
              </View>
            </View>
          )}
        </View>
      ) : (
        <View style={{ display: "none" }} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //alignItems: 'center',
    //justifyContent: 'center',
  },
});
