import React from 'react';
import {Image, View} from 'react-native';
import Dealer from '../../../collections/Dealer';
import {useDimensions} from '../../../contexts/Dimensions';

import {styleDealerPosition, styles} from './styles';

type Props = {
    seatNumber: number;
    numberOfSeats: number;
}

export default function ({seatNumber, numberOfSeats}: Props) {

    const {orientation} = useDimensions();

    return (
        <View style={styleDealerPosition(seatNumber, numberOfSeats)}>
            <Image
                style={styles.image}
                resizeMode={'contain'}
                source={Dealer[orientation]}
                fadeDuration={0}
            />
            {/*<Text>{`${seatNumber}x${numberOfSeats}`}</Text>*/}
        </View>
    );
}