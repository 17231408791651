import {Platform, StyleSheet, TextStyle, ViewStyle} from 'react-native';
import {useDimensions} from '../../../../../contexts/Dimensions';

export const styles = StyleSheet.create({
    timeBankContainer: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        borderWidth: 1,
        borderColor: 'white',
        position: 'absolute',
        borderRadius: 8,
        marginHorizontal: 8,
        marginVertical: 20,
        right: "1%",
        bottom: "82%",
    },
    timeBankContent: {
        fontWeight: 'bold',
        color: '#FFFFFF',
        width: '100%',
        height: '100%',
        textAlign: 'center',
    },
    title: {
        fontWeight: 'bold',
        color: '#FFFFFF',
        width: '100%',
        height: '100%',
        textAlign: 'center'
    },
});

export const styleTimeBankPosition = (): ViewStyle => {
    return StyleSheet.flatten([styles.timeBankContainer, getTimeBankPosition()]);
}

export const getTimeBankPosition = (): ViewStyle => {

    const {orientation} = useDimensions();

    switch (orientation) {
        case 'landscape':
            return {
                width: "18%",
            }
        case 'portrait':
            return {
                width: "22%",
            }
        default:
            return {
                display: 'none',
            }
    }
}

export const styleWithFontScale = (fontSizeWeb: number, fontSizeDefault: number): TextStyle => {
    return StyleSheet.flatten([styles.title, {fontSize: textSize(fontSizeWeb, fontSizeDefault)}]);
}

export function textSize(fontSizeWeb: number, fontSizeDefault: number) {

    const {orientation, width, height} = useDimensions();

    if (Platform.OS === 'web') {
        if (orientation === 'landscape') {
            if (height >= 780 && height <= 880) {
                fontSizeWeb -= 4;
            } else if (height >= 680 && height < 780) {
                fontSizeWeb -= 7;
            } else if (height < 680) {
                fontSizeWeb -= 12;
            }
        } else {
            if (width > 360 && width < 540) {
                fontSizeWeb -= 8;
            } else if (width <= 360) {
                fontSizeWeb -= 12;
            }
        }

        return fontSizeWeb;
    }

    return fontSizeDefault;
}