import { FontAwesome } from '@expo/vector-icons';
import React from 'react';
import { Pressable, Text, View } from 'react-native';
import { useTable } from '../../../../../contexts/Table';
import { useTranslation } from 'react-i18next';

import { styles, styleWithFontScale } from './styles';

type Props = {
    muckCards: boolean;
}

export default function ({ muckCards }: Props) {
    const {t} = useTranslation()
    const { handleMuckCardsPress } = useTable();

    return (
        <Pressable
            onPress={handleMuckCardsPress}
            style={({ pressed }) => [
                {
                    opacity: pressed
                        ? 0.5
                        : 1
                },
            ]}>
            {({ pressed }) => (

                <View style={styles.container}>
                    <Text style={styleWithFontScale(20, 14)}>
                        {/*<FontAwesome name={muckCards ? "square-o" : "check-square-o"} size={16} color="white" />*/}
                        {t("TableScreen.Controls.showCards")}
                    </Text>
                </View>
            )}
        </Pressable>
    );
}