import {Platform} from 'react-native';
import {useDimensions} from '../contexts/Dimensions';

const theme = {
    colors: {
        primary: '#004DFF',
        background100: '#131517',
        background90: '#2B2C2E',
        background80: '#424445',
        background70: '#5A5B5D',
        textHighlight: '#E6F1F3',
        textSecondary: '#92999B',
        textOptional: '#3D4143',
    },
    defaultFontSizes: {
        modalTitle: 20,
    }

}

function getFontSize(baseFontSize: number, defaultFontSize: number) {
    const {orientation, width, height} = useDimensions();
    switch (Platform.OS) {
        case 'android':
            switch (orientation) {
                case 'landscape':
                    if (height >= 780 && height <= 880) {
                        baseFontSize -= 4;
                    } else if (height >= 680 && height < 780) {
                        baseFontSize -= 6;
                    } else if (height < 680) {
                        baseFontSize -= 10;
                    }
                    break;
                case 'portrait':
                    if (width < 720 && width > 541) {
                        baseFontSize -= 4;
                    } else if (width > 360 && width < 540) {
                        baseFontSize -= 6;
                    } else if (width <= 360) {
                        baseFontSize -= 10;
                    }
                    break;
            }
            break;
        case 'ios':
            switch (orientation) {
                case 'landscape':
                    if (height >= 780 && height <= 880) {
                        baseFontSize -= 4;
                    } else if (height >= 680 && height < 780) {
                        baseFontSize -= 6;
                    } else if (height < 680) {
                        baseFontSize -= 10;
                    }
                    break;
                case 'portrait':
                    if (width < 720 && width > 541) {
                        baseFontSize -= 4;
                    } else if (width > 360 && width < 540) {
                        baseFontSize -= 6;
                    } else if (width <= 360) {
                        baseFontSize -= 10;
                    }
                    break;
            }
            break;
        case 'web':
            switch (orientation) {
                case 'landscape':
                    if (height >= 780 && height <= 880) {
                        baseFontSize -= 4;
                    } else if (height >= 680 && height < 780) {
                        baseFontSize -= 6;
                    } else if (height < 680) {
                        baseFontSize -= 10;
                    }
                    break;
                case 'portrait':
                    if (width < 720 && width > 541) {
                        baseFontSize -= 4;
                    } else if (width > 360 && width < 540) {
                        baseFontSize -= 6;
                    } else if (width <= 360) {
                        baseFontSize -= 10;
                    }
                    break;
            }
            break;
        case 'windows':
            switch (orientation) {
                case 'landscape':
                    if (height >= 780 && height <= 880) {
                        baseFontSize -= 4;
                    } else if (height >= 680 && height < 780) {
                        baseFontSize -= 6;
                    } else if (height < 680) {
                        baseFontSize -= 10;
                    }
                    break;
                case 'portrait':
                    if (width > 360 && width < 540) {
                        baseFontSize -= 6;
                    } else if (width <= 360) {
                        baseFontSize -= 10;
                    }
                    break;
            }
        default:
            return defaultFontSize;
    }
    return baseFontSize;
}


export {getFontSize, theme};