import React from "react";
import { View, Text } from "react-native";
import { useTable } from "../../../contexts/Table";
import { useAccount } from "../../../contexts/Account";
//import { useWebSocket } from '../../../contexts/WebSocket';
import Actions from "./Actions";
import Betting from "./Betting";

import { styleControlsPosition } from "./styles";
import WaitingBigBlind from "./WaitingBigBlind";
import WaitingList from "./WaitingList";
import PanelButton from "../Social/PanelButton";
import { useDimensions } from "../../../contexts/Dimensions";
import PreActions from "./PreActions";
import { styleBettingPosition } from "./Betting/styles";
import { useWebSocket } from "../../../contexts/WebSocket";
import { TableProps } from "../../../types";
import MuckCards from "./MuckCards";
import { useChat } from "../../../contexts/Chat";
import OtherActions from "./OtherActions";

export default function () {
  const { orientation } = useDimensions();
  const { socket, tableId } = useWebSocket();
  const { wallet } = useAccount();
  const { showNewMessageBadge, setShowNewMessageBadge } = useChat();
  const { table, currentPlayer, setPanelSelectedTab, setPanelVisible } =
    useTable();
  const [currentCallValue, setCurrentCallValue] = React.useState<number>(0);
  const [currentBettingValue, setCurrentBettingValue] =
    React.useState<number>(0);
  const [emptySeats, setEmptySeats] = React.useState<number>(0);
  const [onGamePlayers, setOnGamePlayers] = React.useState<number>(0);

  React.useEffect(() => {
    if (currentPlayer && socket && socket.connected) {
      socket.emit("history list", tableId);
    }
  }, [table.handNumber]);

  React.useEffect(() => {
    if (table.betAmounts) {
      setCurrentBettingValue(table.betAmounts.raise.minimum);
      setCurrentCallValue(table.betAmounts.call);
    }

    if (table.players) {
      setEmptySeats(table.players.filter((player) => player === null).length);
      setOnGamePlayers(
        table.players.filter((player) => player !== null).length
      );
    }
  }, [table]);

  function handleSocialPanel(select: number) {
    setPanelSelectedTab(select);
    setPanelVisible(true);
    if (select === 1) {
      setShowNewMessageBadge(false);
    }

    if (select === 2 && socket && socket.connected) {
      socket.emit("history list", tableId);
    }
  }

  return (
    <React.Fragment>
      <View style={styleControlsPosition()}>
        {currentPlayer &&
        !currentPlayer.sitOut &&
        !currentPlayer.reBuy &&
        table.currentPosition === currentPlayer.seatNumber ? (
          <React.Fragment>
            <Betting
              currentBettingValue={currentBettingValue}
              onChangeCurrentBettingValue={setCurrentBettingValue}
            />
            <Actions
              currentCallValue={currentCallValue}
              currentBettingValue={currentBettingValue}
            />
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}

        {currentPlayer &&
        !currentPlayer.sitOut &&
        !currentPlayer.reBuy &&
        !currentPlayer.waitingBigBlind &&
        !currentPlayer.folded &&
        currentPlayer.stackSize > 0 &&
        !table.isShowdown &&
        !(table.currentPosition === currentPlayer.seatNumber) &&
        table.currentRound ? (
          <React.Fragment>
            <View style={styleBettingPosition()}></View>
            <PreActions
              currentCallValue={currentCallValue}
              currentBettingValue={currentBettingValue}
            />
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}

        {currentPlayer &&
        !currentPlayer.waitingBigBlind &&
        currentPlayer.sitOut &&
        currentPlayer.sitOutNextHand ? (
          <React.Fragment>
            <View style={styleBettingPosition()}></View>
            <OtherActions />
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}

        {currentPlayer && currentPlayer.waitingBigBlind && onGamePlayers > 3 ? (
          <React.Fragment>
            <View style={styleBettingPosition()}></View>
            <WaitingBigBlind
              currentCallValue={currentCallValue}
              currentBettingValue={currentBettingValue}
            />
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}

        {!currentPlayer && emptySeats === 0 && table.currentRound ? (
          <React.Fragment>
            <View style={styleBettingPosition()}></View>
            <WaitingList
              currentCallValue={currentCallValue}
              currentBettingValue={currentBettingValue}
            />
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}

        {currentPlayer &&
        !currentPlayer.showCards &&
        !currentPlayer.waitingBigBlind &&
        !currentPlayer.sitOut &&
        !currentPlayer.reBuy &&
        !currentPlayer.left &&
        table.isShowdown ? (
          <React.Fragment>
            <View style={styleBettingPosition()}></View>
            <MuckCards />
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}

        {currentPlayer ? (
          orientation === "portrait" ? (
            <View
              style={{
                position: "absolute",
                width: "10%",
                height: "25%",
                left: "33%",
                bottom: "48%",
              }}
            >
              <PanelButton
                onPress={() => {
                  setPanelSelectedTab(3);
                  setPanelVisible(true);
                }}
                image={require("../../../assets/images/theme/default/table/emoji.png")}
                style={{ width: "100%", height: "100%" }}
              />
            </View>
          ) : (
            <View
              style={{
                position: "absolute",
                width: "35%",
                height: "35%",
                left: "-70%",
                bottom: "30%",
                flexDirection: "row",
              }}
            >
              <PanelButton
                onPress={() => {
                  handleSocialPanel(2);
                }}
                image={require("../../../assets/images/theme/default/table/hand_history.png")}
                style={{ width: "30%", height: "100%" }}
              />
              <PanelButton
                onPress={() => {
                  handleSocialPanel(3);
                }}
                image={require("../../../assets/images/theme/default/table/emoji.png")}
                style={{ width: "30%", height: "100%" }}
              />
              <PanelButton
                onPress={() => {
                  handleSocialPanel(1);
                }}
                image={require("../../../assets/images/theme/default/table/chat.png")}
                style={{ width: "30%", height: "100%" }}
                showBadge={showNewMessageBadge}
              />
            </View>
          )
        ) : (
          <React.Fragment />
        )}
      </View>

      {/*
      <BuyIn
        modalVisible={modalBuyInVisible}
        setModalVisible={setModalBuyInVisible}
        minBuyIn={table.minBuyIn}
        maxBuyIn={table.maxBuyIn}
        tableId={table.id}
        waitingList={false}
        timer={30}
      />
        */}
    </React.Fragment>
  );
}
