import React, {useRef} from "react";
import {ScrollView, Text, View} from "react-native";
import {useHistory} from "../../../../contexts/History";
import {TableHistory} from "../../../../types";
import HistoryItem from "./HistoryItem";
import {styles} from "./styles";
import { useTranslation } from "react-i18next";

export default function HistoryPanel() {
    const { t } = useTranslation();

    const {tableHistory} = useHistory();
    const scrollRef = useRef<ScrollView>(null);

    const [histories, setHistories] = React.useState<TableHistory[]>([]);

    React.useEffect(() => {
        if (tableHistory && tableHistory.length > 0) {
            setHistories(tableHistory);
            //console.log(tableHistory);
        }
    }, [tableHistory]);

    return (
        <View style={styles.container}>
            <View style={styles.tableHeader}>
                <Text style={{color: "#FFF8", flex: 1, maxWidth: 60, textAlign: "center"}}>{t("TableScreen.historyPanel.handNumber")}</Text>
                <Text style={{color: "#FFF8", flex: 1, textAlign: "center"}}>{t("TableScreen.historyPanel.board")}</Text>
                <Text style={{color: "#FFF8", flex: 1, textAlign: "center"}}>{t("TableScreen.historyPanel.winners")}</Text>
            </View>

            <ScrollView
                ref={scrollRef}
                style={styles.historyArea}
                showsVerticalScrollIndicator={false}
                onContentSizeChange={() => {
                    scrollRef.current?.scrollToEnd({animated: true})
                }}
            >
                {histories.map((history, index) => <HistoryItem key={index} history={history}/>)}
            </ScrollView>
        </View>
    )
}