import AsyncStorage from "@react-native-async-storage/async-storage";
import { RouteProp, useRoute } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import {
  PERSISTENCE_KEY_ACCESS_TOKEN,
  PERSISTENCE_KEY_REFRESH_TOKEN,
  useWebSocket,
} from "../contexts/WebSocket";

import { useAccount } from "../contexts/Account";
import { ActivityIndicator, View, Image } from "react-native";

import { RootStackParamList, RootStackScreenProps } from "../types";

export default function PlayBondsScreen({
  navigation,
  route,
}: RootStackScreenProps<"PlayBonds">) {
  const { socket, refresh } = useWebSocket();
  const { user, providerAuth } = useAccount();

  React.useEffect(() => {
    const storageAccessToken = async () => {
      //console.log(route.params);

      let token = route.params?.token;
      if (token) {
        await AsyncStorage.multiSet([
          [PERSISTENCE_KEY_ACCESS_TOKEN, token],
          [PERSISTENCE_KEY_REFRESH_TOKEN, token],
        ]);

        //console.log("call refresh");
        refresh();

        //console.log("call provider auth");
        providerAuth();

        //console.log("call refresh");
        refresh();
      }
    };

    storageAccessToken();
  }, [route]);

  React.useEffect(() => {
    if (user) {
      window.location.replace("/");
    }
  }, [user]);

  return (
    <View style={styles.container}>
      <View
        style={{
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
          backgroundColor: "black",
        }}
      >
        <Image
          source={require("../assets/images/playbonds.png")}
          style={{ width: 240, height: 100 }}
          resizeMode="contain"
          resizeMethod="auto"
        />
        <ActivityIndicator />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
