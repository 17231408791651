import React from "react";
import {
  ActivityIndicator,
  Pressable,
  ScrollView,
  Text,
  TextInput,
  View,
} from "react-native";
import { TextInputMask } from "react-native-masked-text";
import { useAccount } from "../../../contexts/Account";
import Modal from "../Modal";
import { styles, styleWithFontScale } from "./styles";
import { useTranslation } from "react-i18next";

type Props = {
  modalVisible: boolean;
  setModalVisible: (modalVisible: boolean) => void;
};

export default function ({ modalVisible, setModalVisible }: Props) {
  const { t } = useTranslation();

  const { user, changeEmailPhone, success, error } = useAccount();

  const nextFocus = React.useRef<TextInput>(null);

  const focusNextField = () => {
    if (nextFocus.current != null) {
      nextFocus.current.focus();
    }
  };

  const [email, onChangeEmail] = React.useState<string>("");
  const [phoneNumber, onChangePhoneNumber] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const changeEmailPhoneSubmit = () => {
    if (!user) return;
    setLoading(true);

    changeEmailPhone(email, phoneNumber);
  };

  React.useEffect(() => {
    if (user) {
      onChangeEmail(user.email);
      onChangePhoneNumber(user.phoneNumber);
    }
  }, [user]);

  React.useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  React.useEffect(() => {
    if (success) {
      setLoading(false);
      setModalVisible(false);
    }
  }, [success]);

  return (
    <React.Fragment>
      <Modal
        title={t("ProfileScreen.modalAccountSettings.changeEmailPhone")}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        type="window"
      >
        <View style={{ marginBottom: 10 }}>
          {loading ? <ActivityIndicator /> : <></>}
          {error?.map((err, index) => (
            <Text key={index} style={{ color: "red" }}>{`${
              index + 1
            }. ${err}`}</Text>
          ))}
        </View>
        <ScrollView>
          <View style={{ flex: 1 }}>
            <View style={{ flex: 1, paddingBottom: 20 }}>
              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalSignUp.emailAddress")}
                </Text>
                <TextInput
                  textContentType="emailAddress"
                  keyboardType="email-address"
                  clearTextOnFocus={true}
                  ref={nextFocus}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeEmail}
                  value={email}
                />
              </View>
              <View>
                <View>
                  <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                    {t("ModalPersonalInfo.phoneNumber")}
                  </Text>
                  <TextInputMask
                    type={"cel-phone"}
                    options={{
                      maskType: "BRL",
                      withDDD: true,
                      dddMask: "(99) ",
                    }}
                    onSubmitEditing={() => focusNextField()}
                    style={styles.textInput}
                    placeholderTextColor={"#cccccc"}
                    onChangeText={onChangePhoneNumber}
                    maxLength={255}
                    value={phoneNumber}
                  />
                </View>
              </View>

              <Pressable
                style={styles.button}
                onPress={() => changeEmailPhoneSubmit()}
              >
                <Text style={[styleWithFontScale(20, 14)]}>
                  {t("ModalPersonalInfo.confirm")}
                </Text>
              </Pressable>
            </View>
          </View>
        </ScrollView>
      </Modal>
      {/*<Button title='Personal Info' color={'#666666'} onPress={() => setModalVisible(!modalVisible)} />*/}
    </React.Fragment>
  );
}
