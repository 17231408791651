export default {
  "2c": require("../assets/images/theme/default/cards/2/2c.png"),
  "2d": require("../assets/images/theme/default/cards/2/2d.png"),
  "2h": require("../assets/images/theme/default/cards/2/2h.png"),
  "2s": require("../assets/images/theme/default/cards/2/2s.png"),
  "3c": require("../assets/images/theme/default/cards/2/3c.png"),
  "3d": require("../assets/images/theme/default/cards/2/3d.png"),
  "3h": require("../assets/images/theme/default/cards/2/3h.png"),
  "3s": require("../assets/images/theme/default/cards/2/3s.png"),
  "4c": require("../assets/images/theme/default/cards/2/4c.png"),
  "4d": require("../assets/images/theme/default/cards/2/4d.png"),
  "4h": require("../assets/images/theme/default/cards/2/4h.png"),
  "4s": require("../assets/images/theme/default/cards/2/4s.png"),
  "5c": require("../assets/images/theme/default/cards/2/5c.png"),
  "5d": require("../assets/images/theme/default/cards/2/5d.png"),
  "5h": require("../assets/images/theme/default/cards/2/5h.png"),
  "5s": require("../assets/images/theme/default/cards/2/5s.png"),
  "6c": require("../assets/images/theme/default/cards/2/6c.png"),
  "6d": require("../assets/images/theme/default/cards/2/6d.png"),
  "6h": require("../assets/images/theme/default/cards/2/6h.png"),
  "6s": require("../assets/images/theme/default/cards/2/6s.png"),
  "7c": require("../assets/images/theme/default/cards/2/7c.png"),
  "7d": require("../assets/images/theme/default/cards/2/7d.png"),
  "7h": require("../assets/images/theme/default/cards/2/7h.png"),
  "7s": require("../assets/images/theme/default/cards/2/7s.png"),
  "8c": require("../assets/images/theme/default/cards/2/8c.png"),
  "8d": require("../assets/images/theme/default/cards/2/8d.png"),
  "8h": require("../assets/images/theme/default/cards/2/8h.png"),
  "8s": require("../assets/images/theme/default/cards/2/8s.png"),
  "9c": require("../assets/images/theme/default/cards/2/9c.png"),
  "9d": require("../assets/images/theme/default/cards/2/9d.png"),
  "9h": require("../assets/images/theme/default/cards/2/9h.png"),
  "9s": require("../assets/images/theme/default/cards/2/9s.png"),
  "Tc": require("../assets/images/theme/default/cards/2/10c.png"),
  "Td": require("../assets/images/theme/default/cards/2/10d.png"),
  "Th": require("../assets/images/theme/default/cards/2/10h.png"),
  "Ts": require("../assets/images/theme/default/cards/2/10s.png"),
  "Jc": require("../assets/images/theme/default/cards/2/Jc.png"),
  "Jd": require("../assets/images/theme/default/cards/2/Jd.png"),
  "Jh": require("../assets/images/theme/default/cards/2/Jh.png"),
  "Js": require("../assets/images/theme/default/cards/2/Js.png"),
  "Qc": require("../assets/images/theme/default/cards/2/Qc.png"),
  "Qd": require("../assets/images/theme/default/cards/2/Qd.png"),
  "Qh": require("../assets/images/theme/default/cards/2/Qh.png"),
  "Qs": require("../assets/images/theme/default/cards/2/Qs.png"),
  "Kc": require("../assets/images/theme/default/cards/2/Kc.png"),
  "Kd": require("../assets/images/theme/default/cards/2/Kd.png"),
  "Kh": require("../assets/images/theme/default/cards/2/Kh.png"),
  "Ks": require("../assets/images/theme/default/cards/2/Ks.png"),
  "Ac": require("../assets/images/theme/default/cards/2/Ac.png"),
  "Ad": require("../assets/images/theme/default/cards/2/Ad.png"),
  "Ah": require("../assets/images/theme/default/cards/2/Ah.png"),
  "As": require("../assets/images/theme/default/cards/2/As.png"),
  "CardBack": require("../assets/images/theme/default/cards/2/cardback.png"),
  "cardback": require("../assets/images/theme/default/cards/2/cardback.png"),
} as any;
