export default {
  "0": require("../assets/images/theme/default/avatars/thumbnails/avatar_placeholder.png"),
  "1": require("../assets/images/theme/default/avatars/thumbnails/an0.png"),
  "2": require("../assets/images/theme/default/avatars/thumbnails/an1.png"),
  "3": require("../assets/images/theme/default/avatars/thumbnails/an2.png"),
  "4": require("../assets/images/theme/default/avatars/thumbnails/hg0.png"),
  "5": require("../assets/images/theme/default/avatars/thumbnails/hg1.png"),
  "6": require("../assets/images/theme/default/avatars/thumbnails/hm0.png"),
  "7": require("../assets/images/theme/default/avatars/thumbnails/hm1.png"),
  "8": require("../assets/images/theme/default/avatars/thumbnails/mg0.png"),
  "9": require("../assets/images/theme/default/avatars/thumbnails/mm0.png"),
  "10": require("../assets/images/theme/default/avatars/thumbnails/mm1.png"),
} as any;
