import {FontAwesome} from '@expo/vector-icons';
import React from 'react';
import {Pressable, Text, View} from 'react-native';
import {useTable} from '../../../../../contexts/Table';
import { useTranslation } from 'react-i18next';

import {styles, styleWithFontScale} from './styles';

type Props = {
    payBigBlind: boolean;
}

export default function ({payBigBlind}: Props) {
    const {t} = useTranslation();
    const {handlePayBigBlindActionPress} = useTable();

    return (
        <Pressable
            onPress={handlePayBigBlindActionPress}
            style={({pressed}) => [
                {
                    opacity: pressed
                        ? 0.5
                        : 1
                },
            ]}>
            {({pressed}) => (

                <View style={styles.container}>
                    <Text style={styleWithFontScale(20, 14)}>
                        <FontAwesome name={payBigBlind ? "check-square-o" : "square-o"} size={16} color="white"/> 
                        {t("TableScreen.Controls.payBigBlind")}
                    </Text>
                </View>
            )}
        </Pressable>
    );
}