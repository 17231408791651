import {StyleSheet} from "react-native";

export const styles = StyleSheet.create({
    container: {
        position: "absolute",
        width: "14%",
        height: "8%",
    },
    image: {
        width: "100%",
        height: "100%",
        resizeMode: "contain"
    },
    badge: {
        position: "absolute",
        right: "10%",
        top: "50%",
        width: 15,
        height: 15,
        resizeMode: "contain"
    }
})