import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';
//import * as Localization from 'expo-localization';
import {languageDetector} from './util/languageDetector';

import en from './locale/en/en.json'
import es from './locale/es/es.json'
import ptbr from './locale/pt-BR/ptbr.json'

const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage']
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    compatibilityJSON: 'v3', //<--- add this line.
    debug: false,
    detection: DETECTION_OPTIONS,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        "en": {
            translation: en
        },
        "pt-BR": {
            translation: ptbr
        },
        "es": {
            translation: es
        },
    }
});

export default i18n;
