import React from 'react';
import { View, } from 'react-native';
import { PotProps } from '../../../../types';
import Stack from '../../Stack';
import { stylePotPosition } from './styles';

type Props = {
    potNumber: number;
    numberOfPots: number;
    pot: PotProps;
}

export default function ({ potNumber, numberOfPots, pot }: Props) {

    const [potValue, setPotValue] = React.useState<number>(0);

    React.useEffect(() => {
        if (pot.previousAmount) {
            setPotValue(pot.previousAmount);
        } else {
            setPotValue(pot.amount);
        }
    }, [pot]);

    return (
        <View style={stylePotPosition(potNumber, numberOfPots)}>
            <Stack size={potValue} />
        </View>
    );
}