import { ScrollView } from "moti";
import React from "react";
import { FontAwesome } from "@expo/vector-icons";
import {
  ActivityIndicator,
  Button,
  NativeSyntheticEvent,
  Pressable,
  Text,
  TextInput,
  TextInputKeyPressEventData,
  View,
  Image,
} from "react-native";
import * as Clipboard from "expo-clipboard";
import { PaymentData, useAccount } from "../../../contexts/Account";
import Modal from "../Modal";
//import Joi, {ValidationError} from "joi";
import { styles, styleWithFontScale } from "./styles";
import { TextInputMask } from "react-native-masked-text";
import { useTranslation } from "react-i18next";

type Props = {
  showButton: boolean;
  modalVisibleExternal?: boolean;
  setModalVisibleExternal?: (modalVisibleExternal: boolean) => void;
};

export default function ({
  showButton,
  modalVisibleExternal,
  setModalVisibleExternal,
}: Props) {
  const { t } = useTranslation();

  //const navigation = useNavigation();
  //const { signed } = useAccount();
  //const {table, currentPlayer} = useWebSocket();

  //const { socket } = useWebSocket();
  const { user, wallet, data, setData, error, setError, handleWithdraw } =
    useAccount();

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);

  const [transactionWithdrawConfirmed, setTransactionWithdrawConfirmed] =
    React.useState<boolean>(false);
  const [pixWithdrawShow, setPixWithdrawShow] = React.useState<boolean>(true);
  const [pagstarWithdrawShow, setPagstarWithdrawShow] =
    React.useState<boolean>(false);
  const [transactionWithdraw, setTransactionWithdraw] = React.useState<
    PaymentData | undefined
  >(undefined);

  //const [modalVisibleExternal, setModalVisibleExternal] = React.useState<boolean>(false);

  const nextFocus = React.useRef<TextInput>(null);

  const focusNextField = () => {
    if (nextFocus.current != null) {
      nextFocus.current.focus();
    }
  };

  //const [error, setError] = React.useState<ValidationError | undefined>();
  const [amount, onChangeAmount] = React.useState<string>("40,00");
  const [amountMessage, setAmountMessage] = React.useState<string>("");

  const [loading, setLoading] = React.useState<boolean>(false);

  //const userSignInSchema = Joi.object({
  //    email: Joi.string().email({ tlds: { allow: false } }).required(),
  //    password: Joi.string().min(6).max(20).required(),
  //});

  const withdrawEvent = () => {
    if (!user || !wallet) {
      return;
    }

    let saque = Number(amount.replaceAll(".", "").replaceAll(",", ""));

    if (saque < 4000) {
      setAmountMessage(t("ProfileScreen.modalWithdraw.withdraw_error_1"));
      return;
    }

    if (saque > wallet.balance) {
      setAmountMessage(t("ProfileScreen.modalWithdraw.withdraw_error_2"));
      return;
    }

    if (!user.canWithdrawDeposit) {
      setAmountMessage(t("ProfileScreen.modalWithdraw.withdraw_error_3"));
      return;
    }

    setLoading(true);
    handleWithdraw(saque, pixWithdrawShow ? "PIX" : "PAGSTAR", user?.name || '', user?.document || '' );
    setLoading(false);
    setTransactionWithdrawConfirmed(true);
  }

  const withdrawSubmit = () => {
    withdrawEvent();
  };

  const handleKeyboardEvent = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    if (e.nativeEvent.key === "Enter") {
      withdrawEvent();
    }
  };

  React.useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  React.useEffect(() => {
    if (!showButton && modalVisibleExternal !== undefined) {
      setModalVisible(modalVisibleExternal);
    }
  }, [modalVisibleExternal]);

  React.useEffect(() => {
    if (!showButton && setModalVisibleExternal !== undefined) {
      setModalVisibleExternal(modalVisible);
    }
  }, [modalVisible]);

  React.useEffect(() => {
    setTransactionWithdraw(data);

    return () => {
      setTransactionWithdraw(undefined);
    };
  }, [data]);

  React.useEffect(() => {
    if (modalVisible === false) {
      setError(undefined);
      setData(undefined);
      setTransactionWithdraw(undefined);
      setTransactionWithdrawConfirmed(false);
    }
  }, [modalVisible]);

  return (
    <React.Fragment>
      <Modal
        title={t("ProfileScreen.modalWithdraw.withdraw")}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        type="fullheight"
      >
        <View style={{ marginBottom: 10 }}>
          {loading ? <ActivityIndicator /> : <></>}
          {error?.map((err, index) => (
            <Text key={index} style={{ color: "#FFFFFF" }}>{`${err}`}</Text>
          ))}
        </View>
        <View
          style={{
            display: transactionWithdrawConfirmed ? "flex" : "none",
            flex: 1,
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ fontSize: 30, textAlign: "center", color: "white" }}>
            {t("ProfileScreen.modalWithdraw.withdrawCompleted")}
          </Text>
        </View>
        <ScrollView
          style={{ display: transactionWithdrawConfirmed ? "none" : "flex" }}
        >
          <View style={{ flex: 1, paddingHorizontal: 10 }}>
            <View>
              <View
                style={{
                  display: transactionWithdraw?.external_reference
                    ? "none"
                    : "flex",
                }}
              >
                <View style={{ paddingVertical: 5 }}>
                  <Text
                    style={{
                      color: "#FFFFFF",
                      fontSize: 18,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {t("ProfileScreen.modalWithdraw.withdrawMetod")}
                  </Text>
                </View>
                <View style={{ flexDirection: "column" }}>
                  <Pressable
                    style={{ flex: 1, marginVertical: 5 }}
                    onPress={() => {
                      setPixWithdrawShow(true);
                      setPagstarWithdrawShow(false);
                    }}
                  >
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: "rgba(255, 255, 255, 0.2)",
                        borderRadius: 4,
                        paddingHorizontal: 10,
                        paddingVertical: 10,
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Image
                          style={{ width: 70, height: 25 }}
                          source={require("../../../assets/images/pix-bc.png")}
                        />
                      </View>
                      <View
                        style={{ display: pixWithdrawShow ? "flex" : "none" }}
                      >
                        <FontAwesome name="check" size={20} color="white" />
                      </View>
                    </View>
                  </Pressable>
                  <Pressable
                    style={{ flex: 1, marginVertical: 5 }}
                    onPress={() => {
                      setPagstarWithdrawShow(true);
                      setPixWithdrawShow(false);
                    }}
                  >
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: "rgba(255, 255, 255, 0.2)",
                        borderRadius: 4,
                        paddingHorizontal: 10,
                        paddingVertical: 10,
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Image
                          style={{ width: 110, height: 25 }}
                          source={require("../../../assets/images/pagstar.png")}
                        />
                      </View>

                      <View
                        style={{
                          display: pagstarWithdrawShow ? "flex" : "none",
                        }}
                      >
                        <FontAwesome name="check" size={20} color="white" />
                      </View>
                    </View>
                  </Pressable>
                </View>
                <View
                  style={{
                    flex: 1,
                    display:
                      pixWithdrawShow || pagstarWithdrawShow ? "flex" : "none",
                  }}
                >
                  <View style={{ flexDirection: "column" }}>
                    <View style={{ paddingVertical: 15 }}>
                      <View
                        style={{ display: pixWithdrawShow ? "flex" : "none" }}
                      >
                        <View>
                          <Text
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            {t("ProfileScreen.modalWithdraw.pix.details")}
                          </Text>
                        </View>
                        <View style={{ paddingVertical: 10 }}>
                          <View style={{ padding: 5 }}>
                            <Text style={{ color: "white" }}>
                              {t(
                                "ProfileScreen.modalWithdraw.pix.instructions1"
                              )}
                            </Text>
                          </View>
                          <View style={{ padding: 5 }}>
                            <Text style={{ color: "white" }}>
                              {t(
                                "ProfileScreen.modalWithdraw.pix.instructions2"
                              )}
                            </Text>
                            <Text style={{ color: "white" }}>
                              {t(
                                "ProfileScreen.modalWithdraw.pix.instructions3"
                              )}
                            </Text>
                            <Text style={{ color: "white" }}>
                              {t(
                                "ProfileScreen.modalWithdraw.pix.instructions4"
                              )}
                            </Text>
                            <Text style={{ color: "white" }}>
                              {t(
                                "ProfileScreen.modalWithdraw.pix.instructions5"
                              )}
                            </Text>
                          </View>
                          <View style={{ padding: 5 }}>
                            <Text style={{ color: "white" }}>
                              {t(
                                "ProfileScreen.modalWithdraw.pix.instructions6"
                              )}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View
                        style={{
                          display: pagstarWithdrawShow ? "flex" : "none",
                        }}
                      >
                        <View>
                          <Text
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            {t("ProfileScreen.modalWithdraw.pagstar.details")}
                          </Text>
                        </View>
                      </View>
                    </View>

                    {/* 
                    <TextInput
                      editable={false}
                      placeholder={user?.document}
                      style={[styles.textInput, { backgroundColor: "black" }]}
                      placeholderTextColor={"#CCCCCC"}
                    />

                    <TextInput
                      editable={false}
                      placeholder={user?.name}
                      style={[styles.textInput, { backgroundColor: "black" }]}
                      placeholderTextColor={"#CCCCCC"}
                    />
                    */}

                    <View style={{ marginTop: 15, marginBottom: 10 }}>
                      <Text style={{ color: "red", fontSize: 16, fontWeight: "bold", }}>{amountMessage}</Text>
                    </View>
                    <TextInputMask
                      type={"money"}
                      options={{
                        precision: 2,
                        separator: ",",
                        delimiter: ".",
                        unit: "",
                        suffixUnit: "",
                      }}
                      keyboardType="numbers-and-punctuation"
                      onSubmitEditing={() => focusNextField()}
                      placeholder="40,00"
                      style={[
                        styles.textInput,
                        {
                          borderWidth: 1,
                          borderColor: amountMessage ? "red" : "white",
                          marginBottom: 0,
                        },
                      ]}
                      placeholderTextColor={"#cccccc"}
                      onChangeText={onChangeAmount}
                      onKeyPress={handleKeyboardEvent}
                      value={amount}
                    />
                    
                  </View>

                  <View style={{ marginBottom: 5 }}>
                    <Pressable style={styles.button} onPress={withdrawSubmit}>
                      <Text style={[styleWithFontScale(20, 14)]}>
                        {t("ProfileScreen.modalWithdraw.withdraw")}
                      </Text>
                    </Pressable>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal>
      {showButton ? (
        <Pressable style={styles.button} onPress={() => setModalVisible(true)}>
          <Text style={[styleWithFontScale(20, 14)]}>
            {t("ProfileScreen.modalWithdraw.withdraw")}
          </Text>
        </Pressable>
      ) : null}
      {/* <Button title='Login' onPress={() => setModalVisible(!modalVisible)} /> */}
    </React.Fragment>
  );
}
