import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    container: {
        height: "100%",
        width: '90%',
        marginLeft: '5%',
        maxHeight: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        //alignContent: 'center',
        //backgroundColor: 'yellow',
        //marginBottom: '-30%',
        //opacity: 0.6,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    image: {
        flex: 1,
        width: '100%',
        height: '100%'
    },
});