import React, { useEffect, useRef, useState } from "react";
import { FlatList, Platform, Text, View } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import ChatMessage from "./ChatMessage";
import { styles } from "./styles";
import { FontAwesome } from "@expo/vector-icons";
import { useChat } from "../../../../contexts/Chat";
import { ChatMessageProps } from "../../../../types";
import { useTranslation } from "react-i18next";
import { useWebSocket } from "../../../../contexts/WebSocket";

export default function ChatPanel() {
  const { t } = useTranslation();
  const { tableId } = useWebSocket();

  const { messages, handleChatMessage } = useChat();

  const scrollRef = useRef<FlatList>(null);
  const [message, setMessage] = useState<string>("");
  const isMobile =
    Platform.OS === "web" || Platform.OS === "windows" ? false : true;

  const [chatMessages, setChatMessages] = useState<ChatMessageProps[]>([]);

  function handleSendPress() {
    sendMessage(message);
  }

  function handleSmileyPress() {
    // abrir caixa de emoji de chat
  }

  function handleKeyPress(keyPressed: { code: any }) {
    const { code } = keyPressed;
    if (message.trim().length > 0)
      if (code === "Enter" || code === "NumpadEnter") {
        //enviar mensagem
        sendMessage(message);
      }
  }

  function sendMessage(message: string) {
    //envia mensagem
    if (message !== "") {
      handleChatMessage(message);
      //console.log('chat message: ' + message);
      setMessage("");
    }
  }

  useEffect(() => {
    //console.log("table chat messages ", message);
    //console.log(`chat on table ${tableId}`);

    let tableMessages = messages.filter(
      (message) => message && message.tableId == tableId
    );

    //console.log(tableMessages);
    setChatMessages(tableMessages);
  }, [messages, tableId]);

  return (
    <View style={styles.container}>
      <FlatList
        ref={scrollRef}
        data={chatMessages}
        renderItem={({ item }) => <ChatMessage {...item} />}
        style={styles.messagesArea}
        showsVerticalScrollIndicator={false}
        onContentSizeChange={() => {
          scrollRef.current?.scrollToEnd({ animated: true });
        }}
        keyExtractor={(item, index) => index.toString()}
      />

      <View
        style={[
          styles.inputArea,
          { borderTopWidth: 1, borderTopColor: "#FFF5", width: "100%" },
        ]}
      >
        <Text style={{ color: "#FFF3" }}>
          {t("TableScreen.chatPanel.message")}
        </Text>
        {/* <View style={{flex:1,flexGrow:1,justifyContent:"center",alignItems:"center", maxWidth:50}}>
                    <FontAwesome name="smile-o" size={24} color="rgba(255, 255, 255, 0.4)" onPress={handleSmileyPress}/>
                </View> */}
        <View style={styles.inputBox}>
          <TextInput
            maxLength={120}
            style={styles.input}
            onChangeText={setMessage}
            blurOnSubmit={isMobile}
            /*onKeyPress={handleKeyPress}*/ value={message}
            keyboardType={"ascii-capable"}
            onSubmitEditing={() => sendMessage(message)}
          />
        </View>
        <View
          style={{
            flex: 1,
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            maxWidth: 50,
          }}
        >
          <FontAwesome
            name="send"
            size={20}
            color="rgba(255, 255, 255, 0.4)"
            onPress={handleSendPress}
          />
        </View>
      </View>
    </View>
  );
}
