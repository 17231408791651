import {StyleSheet, ViewStyle} from 'react-native';
import {useDimensions} from '../../../contexts/Dimensions';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        //alignItems: 'center',
        //justifyContent: 'center',
        //backgroundColor: 'red',
        position: 'absolute',

        bottom: 0,
        right: 0
        //opacity: 0.6
    },
    title: {
        fontSize: 22,
        fontWeight: '500',
        color: '#FFFFFF',
    },
});


export const styleControlsPosition = (): ViewStyle => {
    return StyleSheet.flatten([styles.container, getControlsPosition()]);
}

export const getControlsPosition = (): ViewStyle => {

    const {orientation} = useDimensions();

    switch (orientation) {
        case 'landscape':
            return {
                width: '42%',
                height: '17%',
            }
        case 'portrait':
            return {
                width: '100%',
                height: '24%',
            }
        default:
            return {
                display: 'none',
            }
    }
}