import { FontAwesome } from "@expo/vector-icons";
import React from "react";
import { ImageBackground, Pressable, Text, View } from "react-native";
import { useTable } from "../../../../../contexts/Table";
import { PreAction } from "../../../../../types";
import { useTranslation } from "react-i18next";

import { styleWithFontScale } from "./styles";

type Props = {
  preAction: PreAction | undefined;
};

export default function ({ preAction }: Props) {
  const { t } = useTranslation();

  const { handlePreActionPress } = useTable();

  return (
    <Pressable
      onPress={() => handlePreActionPress(PreAction.CALL_ANY_CHECK)}
      style={({ pressed }) => [
        {
          opacity: pressed ? 1 : 1,
        },
        { width: "33.3%", height: "100%" },
      ]}
    >
      {({ pressed }) => (
        <ImageBackground
          style={{
            minWidth: "100%",
            minHeight: "100%",
            flex: 1,
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
          }}
          source={require("../../../../../assets/images/theme/default/table/preAction.png")}
          resizeMode={"contain"}
          fadeDuration={0}
        >
          <View style={{ marginBottom: 18 }}>
            <Text
              adjustsFontSizeToFit={true}
              style={[
                styleWithFontScale(20, 14),
                {
                  textAlign: "center",
                  textAlignVertical: "center",
                  paddingHorizontal: 10,
                },
              ]}
            >
              <FontAwesome
                name={
                  preAction === PreAction.CALL_ANY_CHECK
                    ? "check-square-o"
                    : "square-o"
                }
                size={16}
                color="white"
              />{" "}
              {t("TableScreen.Controls.PRECALLANY")}
            </Text>
          </View>
        </ImageBackground>
      )}
    </Pressable>
  );
}
