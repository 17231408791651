import { AnimatePresence, MotiImage } from "moti";
import React, { Fragment } from "react";
import { View } from "react-native";
import Emojis from "../../../collections/Emojis";
import { useTable } from "../../../contexts/Table";
import { useWebSocket } from "../../../contexts/WebSocket";
import { EmojiProps } from "../../../types";
import { styles, styleSeatWithPosition } from "./styles";

type Props = {
  numberOfSeats: number;
};

export default function Emoji({ numberOfSeats }: Props) {
  const { socket, tableId } = useWebSocket();
  const { table, currentPlayer } = useTable();

  const [sprite, setSprite] = React.useState<string>();
  const [seatNumber, setSeatNumber] = React.useState<number>(0);
  const timeOuts = [] as NodeJS.Timeout[];

  React.useEffect(() => {
    if (!socket.connected) {
      return;
    }

    socket.on("emoji", (data: EmojiProps) => {
      //if (currentPlayer && data.seatNumber === currentPlayer.seatNumber) {
      //    setSprite(data.emoji);
      //    setSeatNumber(0);
      //} else if (table && table.players) {
      let playerEmoji = table.players.filter(
        (player) => player && player.seatNumber === data.seatNumber
      );

      if (playerEmoji.length > 0) {
        let playerRotated = table.players.indexOf(playerEmoji[0]);

        if (playerRotated !== -1) {
          setSprite(data.emoji);
          setSeatNumber(playerRotated);
        }
      }
      //}
    });

    return () => {
      socket.off("emoji");
    };
  }, [socket, tableId, table, currentPlayer]);

  React.useEffect(() => {
    timeOuts.push(
      setTimeout(() => {
        setSprite("");
      }, 2600)
    );
    return () => {
      timeOuts.forEach((timeOut) => clearTimeout(timeOut));
    };
  }, [sprite]);

  if (sprite == undefined && sprite == "") {
    return <Fragment></Fragment>;
  } else {
    return (
      <View
        pointerEvents="none"
        style={styleSeatWithPosition(seatNumber, numberOfSeats)}
      >
        <AnimatePresence>
          {sprite && (
            <MotiImage
              source={Emojis[sprite]}
              style={styles.image}
              from={{ opacity: 0, transform: [{ scale: 0.9 }] }}
              animate={{ opacity: 1, transform: [{ scale: 0.95 }] }}
              transition={{ type: "timing", duration: 200 }}
              exit={{ opacity: 0 }}
              exitTransition={{ type: "timing", duration: 200 }}
            />
          )}
        </AnimatePresence>
      </View>
    );
  }
}
