import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    flex: 1,
    //alignItems: 'center',
    //justifyContent: 'center',
    // backgroundColor: 'pink',
    //position: 'absolute',
    marginLeft: "-1%",
    marginRight: "-1%",
    maxWidth: "22%",
    maxHeight: "100%",
    //marginHorizontal: '1%',
    //backgroundColor: 'black',
    //borderRadius: 14
  },
  image: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
});
