import React from "react";
import { ImageBackground, Pressable, Text } from "react-native";
import { useTable } from "../../../../../contexts/Table";
import BuyIn from "../../../../modals/BuyIn";
import { useTranslation } from "react-i18next";

import { styles, styleWithFontScale } from "./styles";

type Props = {
  seatNumber: number;
  numberOfSeats: number;
};

export default function ({ seatNumber, numberOfSeats }: Props) {
  const { t } = useTranslation();
  //const { tableId } = useWebSocket();
  const { table } = useTable();

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);

  return (
    <ImageBackground
      style={styles.image}
      resizeMode={"contain"}
      source={require("../../../../../assets/images/theme/default/table/take_seat.png")}
      fadeDuration={0}
    >
      <BuyIn
        waitingList={false}
        minBuyIn={table.minBuyIn}
        maxBuyIn={table.maxBuyIn}
        seatNumber={seatNumber}
        tableId={table.id}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />

      <Pressable
        onPress={() => setModalVisible(!modalVisible)}
        style={({ pressed }) => [
          {
            opacity: pressed ? 0.8 : 1,
          },
          { flex: 1, justifyContent: "center", alignItems: "center" },
        ]}
      >
        {({ pressed }) => (
          <Text style={styleWithFontScale(18, 14)}>
            {t("TableScreen.tableComponents.takeSeat")}
          </Text>
        )}
      </Pressable>
    </ImageBackground>
  );
}
