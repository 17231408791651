import {StyleSheet, TextStyle} from 'react-native';
import {getFontSize, theme} from '../../../util/GlobalStyles';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    textInput: {
        height: 40,
        //borderColor: "#FFFFFF",
        //borderBottomWidth: 1,
        paddingHorizontal: 10,
        marginBottom: 10,
        //borderRadius: 8,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        color: '#FFFFFF',
        borderRadius: 4,
        margin: 2,
    },
    btnContainer: {
        //backgroundColor: "white",
        marginTop: 12
    },
    button: {
        flex: 1,
        backgroundColor: theme.colors.primary,
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 10,
        margin: 2,
        borderRadius: 4,
        minHeight: 40,
        maxHeight: 40,
        minWidth: 100,
    },
});
export const styleWithFontScale = (fontSizeWeb: number, fontSizeDefault: number): TextStyle => {
    return StyleSheet.flatten([{color: theme.colors.textHighlight}, {fontSize: getFontSize(fontSizeWeb, fontSizeDefault)}]);
}