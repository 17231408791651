import * as React from "react";
import {
  Pressable,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  View,
  Image,
  Modal,
  Button,
} from "react-native";
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";
import { RootTabScreenProps } from "../types";
import { useGameOptions } from "../contexts/GameOptions";
import Slider from "@react-native-community/slider";
import AvatarCollection from "../constants/AvatarCollection";
import Cards1 from "../collections/Cards1";
import Cards2 from "../collections/Cards2";
import Cards3 from "../collections/Cards3";
import { useAccount } from "../contexts/Account";
import { useDimensions } from "../contexts/Dimensions";
import ProfileChangePassword from "../components/modals/ProfileChangePassword";
import ProfileChangeEmailPhone from "../components/modals/ProfileChangeEmailPhone";
import ProfileChangeAddress from "../components/modals/ProfileChangeAddress";
import { theme } from "../util/GlobalStyles";
import NoAvatar from "../assets/images/theme/default/avatars/thumbnails/avatar_placeholder.png";

export default function ProfileScreen({
  navigation,
}: RootTabScreenProps<"Profile">) {
  const { t, i18n } = useTranslation();
  const {
    soundVolume,
    handleSetSoundVolume,
    soundEnabled,
    handleSetSoundEnabled,
    showBigBlind,
    handleSetShowBigBlind,
    hideBalance,
    handleSetHideBalance,
    language,
    handleSetLanguage,
    frontDeck,
    handleSetFrontDeck,
    autoTableChange,
    handleSetAutoTableChange,
    showLevel,
    handleSetShowLevel,
  } = useGameOptions();
  const { orientation } = useDimensions();
  const { changeAvatar, signed, user, signOut } = useAccount();

  const [showSection1, setShowSection1] = React.useState<boolean>(true);
  const [showSection2, setShowSection2] = React.useState<boolean>(false);
  const [showSection3, setShowSection3] = React.useState<boolean>(false);

  const [selectedAvatar, setSelectedAvatar] = React.useState<number>(0);

  const [modalChangePasswordVisible, setModalChangePasswordVisible] =
    React.useState<boolean>(false);

  const [modalChangeAddressVisible, setModalChangeAddressVisible] =
    React.useState<boolean>(false);

  const [modalChangeEmailPhoneVisible, setModalChangeEmailPhoneVisible] =
    React.useState<boolean>(false);

  const [showSignoutConfirmation, setShowSignoutConfirmation] =
    React.useState<boolean>(false);

  const handleSignout = () => {
    setShowSignoutConfirmation(false);
    signOut();
    navigation.navigate("Lobby");
  };

  const handleShowSection = (section: number) => {
    setShowSection1(section === 1);
    setShowSection2(section === 2);
    setShowSection3(section === 3);
    /*
    if (section === 1) {
      setShowSection1((prevState) => !prevState);
      setShowSection2(false);
      setShowSection3(false);
    } else if (section === 2) {
      setShowSection1(false);
      setShowSection2((prevState) => !prevState);
      setShowSection3(false);
    } else if (section === 3) {
      setShowSection1(false);
      setShowSection2(false);
      setShowSection3((prevState) => !prevState);
    }
    */
  };

  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    handleSetLanguage(language);
  };

  const handleChangeAvatar = (avatar: number) => {
    if (signed) {
      changeAvatar(avatar.toString());
      setSelectedAvatar(avatar);
    }
  };

  React.useEffect(() => {
    if (user) {
      setSelectedAvatar(Number(user.avatar));
    }
  }, [user]);

  return (
    <View style={{ flex: 1, width: "100%", height: "100%" }}>
      <ScrollView
        contentContainerStyle={[
          styles.scrollContainer,
          { flexDirection: "row" },
        ]}
      >
        {orientation === "landscape" && (
          <View style={{ flex: 1, maxWidth: 300, minHeight: "100%" }}>
            {signed && (
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <Image
                  source={
                    user?.avatar !== null &&
                    user?.avatar !== undefined &&
                    user.avatar !== "" &&
                    !user.avatar.startsWith("http")
                      ? AvatarCollection[Number(user.avatar)].sprite
                      : NoAvatar
                  }
                  resizeMode={"contain"}
                  style={{
                    width: 140,
                    height: 140,
                    borderRadius: 70,
                    borderWidth: 2,
                    borderColor: "#333333",
                    alignSelf: "center",
                    marginBottom: 15,
                  }}
                />
                <Text
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  {user?.username}
                </Text>
              </View>
            )}

            <View>
              <Pressable onPress={() => handleShowSection(1)}>
                <View style={styles.sectionTitle}>
                  <Text
                    style={[
                      styles.title,
                      { color: showSection1 ? "rgb(0, 77, 255)" : "#999999" },
                    ]}
                  >
                    {t("ProfileScreen.menuTitle1")}{" "}
                    <FontAwesome
                      name={showSection1 ? "chevron-right" : "chevron-left"}
                      size={16}
                      color={showSection1 ? "rgb(0, 77, 255)" : "#999999"}
                    />
                  </Text>
                </View>
              </Pressable>
            </View>

            <View>
              <Pressable onPress={() => handleShowSection(2)}>
                <View style={styles.sectionTitle}>
                  <Text
                    style={[
                      styles.title,
                      { color: showSection2 ? "rgb(0, 77, 255)" : "#999999" },
                    ]}
                  >
                    {t("ProfileScreen.menuTitle2")}{" "}
                    <FontAwesome
                      name={showSection2 ? "chevron-right" : "chevron-left"}
                      size={16}
                      color={showSection2 ? "rgb(0, 77, 255)" : "#999999"}
                    />
                  </Text>
                </View>
              </Pressable>
            </View>

            <View>
              <Pressable onPress={() => handleShowSection(3)}>
                <View
                  style={[
                    styles.sectionTitle,
                    !signed && !showSection3 ? { borderWidth: 0 } : {},
                  ]}
                >
                  <Text
                    style={[
                      styles.title,
                      { color: showSection3 ? "rgb(0, 77, 255)" : "#999999" },
                    ]}
                  >
                    {t("ProfileScreen.menuTitle3")}{" "}
                    <FontAwesome
                      name={showSection3 ? "chevron-right" : "chevron-left"}
                      size={16}
                      color={showSection3 ? "rgb(0, 77, 255)" : "#999999"}
                    />
                  </Text>
                </View>
              </Pressable>
            </View>
            {signed && (
              <View>
                <Pressable onPress={() => setShowSignoutConfirmation(true)}>
                  <View style={styles.sectionLink}>
                    <Text
                      style={[styles.titleLink, { textTransform: "uppercase" }]}
                    >
                      {t("ProfileScreen.signout")}
                    </Text>
                  </View>
                </Pressable>
              </View>
            )}
          </View>
        )}
        <View style={styles.container}>
          <View style={showSection1 ? { flex: 1 } : {}}>
            {orientation === "portrait" && (
              <Pressable onPress={() => handleShowSection(1)}>
                <View style={styles.sectionTitle}>
                  <Text
                    style={[
                      styles.title,
                      { color: showSection1 ? "rgb(0, 77, 255)" : "#999999" },
                    ]}
                  >
                    {t("ProfileScreen.menuTitle1")}{" "}
                    <FontAwesome
                      name={showSection1 ? "chevron-down" : "chevron-up"}
                      size={16}
                      color={showSection1 ? "rgb(0, 77, 255)" : "#999999"}
                    />
                  </Text>
                </View>
              </Pressable>
            )}
            {showSection1 && (
              <View
                style={[
                  styles.sectionContent,
                  showSection1 && orientation === "landscape"
                    ? { backgroundColor: "#222222", borderRadius: 10 }
                    : {},
                ]}
              >
                <View style={styles.sectionOption}>
                  <Text style={styles.optionTitle}>
                    <FontAwesome name="language" size={20} color="white" />{" "}
                    {t("ProfileScreen.language")}
                  </Text>
                  <View
                    style={[
                      styles.optionContent,
                      { justifyContent: "space-between" },
                    ]}
                  >
                    <Pressable onPress={() => handleChangeLanguage("pt-BR")}>
                      <Text
                        style={[
                          styles.optionText,
                          {
                            color:
                              language === "pt-BR"
                                ? "rgb(0, 77, 255)"
                                : "white",
                          },
                        ]}
                      >
                        <FontAwesome
                          name={
                            language === "pt-BR" ? "check-circle" : "circle-o"
                          }
                          size={18}
                          color={
                            language === "pt-BR" ? "rgb(0, 77, 255)" : "white"
                          }
                        />{" "}
                        Português
                      </Text>
                    </Pressable>
                    <Pressable onPress={() => handleChangeLanguage("es")}>
                      <Text
                        style={[
                          styles.optionText,
                          {
                            color:
                              language === "es" ? "rgb(0, 77, 255)" : "white",
                          },
                        ]}
                      >
                        <FontAwesome
                          name={language === "es" ? "check-circle" : "circle-o"}
                          size={18}
                          color={
                            language === "es" ? "rgb(0, 77, 255)" : "white"
                          }
                        />{" "}
                        Espanhol
                      </Text>
                    </Pressable>
                    <Pressable onPress={() => handleChangeLanguage("en")}>
                      <Text
                        style={[
                          styles.optionText,
                          {
                            color:
                              language === "en" ? "rgb(0, 77, 255)" : "white",
                          },
                        ]}
                      >
                        <FontAwesome
                          name={language === "en" ? "check-circle" : "circle-o"}
                          size={18}
                          color={
                            language === "en" ? "rgb(0, 77, 255)" : "white"
                          }
                        />{" "}
                        Inglês
                      </Text>
                    </Pressable>
                  </View>
                </View>

                <View
                  style={[
                    styles.sectionOption,
                    { flexDirection: "row", justifyContent: "space-between" },
                  ]}
                >
                  <View>
                    <Text style={styles.optionTitle}>
                      <FontAwesome name="music" size={20} color="white" />{" "}
                      {t("ProfileScreen.modalGameSettings.sounds")}
                    </Text>
                  </View>
                  <View style={styles.optionContent}>
                    <View style={{ marginTop: 5 }}>
                      <Switch
                        trackColor={{ false: "#767577", true: "#004bF7" }}
                        thumbColor={soundEnabled ? "#FFF" : "#888"}
                        onValueChange={handleSetSoundEnabled}
                        ios_backgroundColor="#3e3e3e"
                        value={soundEnabled}
                      />
                    </View>
                  </View>
                </View>

                <View style={styles.sectionOption}>
                  <Text style={styles.optionTitle}>
                    <FontAwesome name="volume-up" size={20} color="white" />{" "}
                    {t("ProfileScreen.modalGameSettings.volume")} (
                    {(soundVolume * 100).toFixed()})
                  </Text>
                  <View style={styles.optionContent}>
                    <Slider
                      value={soundVolume}
                      maximumValue={1}
                      minimumValue={0}
                      onValueChange={handleSetSoundVolume}
                      onSlidingComplete={handleSetSoundVolume}
                      minimumTrackTintColor={"#004bF7"}
                      maximumTrackTintColor={"#636363"}
                      thumbTintColor={"#FFF"}
                      style={{ flex: 1 }}
                    />
                  </View>
                </View>
              </View>
            )}
          </View>

          <View style={showSection2 ? { flex: 1 } : {}}>
            {orientation === "portrait" && (
              <Pressable onPress={() => handleShowSection(2)}>
                <View style={styles.sectionTitle}>
                  <Text
                    style={[
                      styles.title,
                      { color: showSection2 ? "rgb(0, 77, 255)" : "#999999" },
                    ]}
                  >
                    {t("ProfileScreen.menuTitle2")}{" "}
                    <FontAwesome
                      name={showSection2 ? "chevron-down" : "chevron-up"}
                      size={16}
                      color={showSection2 ? "rgb(0, 77, 255)" : "#999999"}
                    />
                  </Text>
                </View>
              </Pressable>
            )}
            {showSection2 && (
              <View
                style={[
                  styles.sectionContent,
                  showSection2 && orientation === "landscape"
                    ? { backgroundColor: "#222222", borderRadius: 10 }
                    : {},
                ]}
              >
                {signed && (
                  <View
                    style={[
                      styles.sectionOption,
                      { flexDirection: "row", justifyContent: "space-between" },
                    ]}
                  >
                    <View>
                      <Text style={styles.optionTitle}>
                        <FontAwesome name="eye-slash" size={20} color="white" />{" "}
                        {t("ProfileScreen.modalAccountSettings.hideBalance")}
                      </Text>
                    </View>
                    <View style={styles.optionContent}>
                      <View style={{ marginTop: 5 }}>
                        <Switch
                          trackColor={{ false: "#767577", true: "#004bF7" }}
                          thumbColor={hideBalance ? "#FFF" : "#888"}
                          onValueChange={handleSetHideBalance}
                          ios_backgroundColor="#3e3e3e"
                          value={hideBalance}
                        />
                      </View>
                    </View>
                  </View>
                )}

                <View
                  style={[
                    styles.sectionOption,
                    { flexDirection: "row", justifyContent: "space-between" },
                  ]}
                >
                  <View>
                    <Text style={styles.optionTitle}>
                      <FontAwesome name="certificate" size={20} color="white" />{" "}
                      {t("ProfileScreen.modalAccountSettings.showLevel")}
                    </Text>
                  </View>
                  <View style={styles.optionContent}>
                    <View style={{ marginTop: 5 }}>
                      <Switch
                        trackColor={{ false: "#767577", true: "#004bF7" }}
                        thumbColor={showLevel ? "#FFF" : "#888"}
                        onValueChange={handleSetShowLevel}
                        ios_backgroundColor="#3e3e3e"
                        value={showLevel}
                      />
                    </View>
                  </View>
                </View>

                {signed && (
                  <View style={styles.sectionOption}>
                    <Text style={styles.optionTitle}>
                      <FontAwesome
                        name="user-circle-o"
                        size={20}
                        color="white"
                      />{" "}
                      {t("ProfileScreen.modalAccountSettings.avatar")}
                    </Text>
                    <View style={styles.optionContent}>
                      <ScrollView
                        contentContainerStyle={{ flexDirection: "row" }}
                        horizontal={true}
                        showsHorizontalScrollIndicator={true}
                      >
                        {AvatarCollection.map(
                          (avatar, index) =>
                            avatar.id !== "10" && (
                              <Pressable
                                key={avatar.id}
                                onPress={() => handleChangeAvatar(index)}
                                style={{
                                  borderRadius: 6,
                                  borderWidth: 2,
                                  borderColor:
                                    selectedAvatar === index
                                      ? "rgb(0, 77, 255)"
                                      : "#333333",
                                  marginBottom: 10,
                                  marginRight: 10,
                                }}
                              >
                                <Image
                                  source={avatar.sprite}
                                  resizeMode={"contain"}
                                  style={{
                                    borderRadius: 5,
                                    width: 80,
                                    height: 80,
                                  }}
                                />
                              </Pressable>
                            )
                        )}
                      </ScrollView>
                    </View>
                  </View>
                )}

                {signed && user && user.provider !== "playbonds" && (
                  <View>
                    <View>
                      <Pressable
                        onPress={() =>
                          setModalChangePasswordVisible(
                            !modalChangePasswordVisible
                          )
                        }
                      >
                        <View>
                          <Text
                            style={[
                              styles.optionTitle,
                              { color: "rgb(0, 77, 255)" },
                            ]}
                          >
                            <FontAwesome
                              name="lock"
                              size={20}
                              color="rgb(0, 77, 255)"
                            />{" "}
                            {t(
                              "ProfileScreen.modalAccountSettings.changePassword"
                            )}
                          </Text>
                        </View>
                      </Pressable>

                      <ProfileChangePassword
                        modalVisible={modalChangePasswordVisible}
                        setModalVisible={setModalChangePasswordVisible}
                      />
                    </View>

                    <View>
                      <Pressable
                        onPress={() =>
                          setModalChangeEmailPhoneVisible(
                            !modalChangeEmailPhoneVisible
                          )
                        }
                      >
                        <View>
                          <Text
                            style={[
                              styles.optionTitle,
                              { color: "rgb(0, 77, 255)" },
                            ]}
                          >
                            <FontAwesome
                              name="envelope-o"
                              size={20}
                              color="rgb(0, 77, 255)"
                            />{" "}
                            {t(
                              "ProfileScreen.modalAccountSettings.changeEmailPhone"
                            )}
                          </Text>
                        </View>
                      </Pressable>

                      <ProfileChangeEmailPhone
                        modalVisible={modalChangeEmailPhoneVisible}
                        setModalVisible={setModalChangeEmailPhoneVisible}
                      />
                    </View>

                    <View>
                      <Pressable
                        onPress={() =>
                          setModalChangeAddressVisible(
                            !modalChangeAddressVisible
                          )
                        }
                      >
                        <View>
                          <Text
                            style={[
                              styles.optionTitle,
                              { color: "rgb(0, 77, 255)" },
                            ]}
                          >
                            <FontAwesome
                              name="map-marker"
                              size={20}
                              color="rgb(0, 77, 255)"
                            />{" "}
                            {t(
                              "ProfileScreen.modalAccountSettings.changeAddress"
                            )}
                          </Text>
                        </View>
                      </Pressable>

                      <ProfileChangeAddress
                        modalVisible={modalChangeAddressVisible}
                        setModalVisible={setModalChangeAddressVisible}
                      />
                    </View>
                  </View>
                )}
              </View>
            )}
          </View>

          <View style={showSection3 ? { flex: 1 } : {}}>
            {orientation === "portrait" && (
              <Pressable onPress={() => handleShowSection(3)}>
                <View
                  style={[
                    styles.sectionTitle,
                    !signed && !showSection3 ? { borderWidth: 0 } : {},
                  ]}
                >
                  <Text
                    style={[
                      styles.title,
                      { color: showSection3 ? "rgb(0, 77, 255)" : "#999999" },
                    ]}
                  >
                    {t("ProfileScreen.menuTitle3")}{" "}
                    <FontAwesome
                      name={showSection3 ? "chevron-down" : "chevron-up"}
                      size={16}
                      color={showSection3 ? "rgb(0, 77, 255)" : "#999999"}
                    />
                  </Text>
                </View>
              </Pressable>
            )}
            {showSection3 && (
              <View
                style={[
                  styles.sectionContent,
                  showSection3 && orientation === "landscape"
                    ? { backgroundColor: "#222222", borderRadius: 10 }
                    : {},
                  !signed ? { borderWidth: 0 } : {},
                ]}
              >
                <View
                  style={[
                    styles.sectionOption,
                    { flexDirection: "row", justifyContent: "space-between" },
                  ]}
                >
                  <View>
                    <Text style={styles.optionTitle}>
                      <FontAwesome name="dollar" size={20} color="white" />{" "}
                      {t("ProfileScreen.modalGameSettings.showBB")}
                    </Text>
                  </View>
                  <View style={styles.optionContent}>
                    <View style={{ marginTop: 5 }}>
                      <Switch
                        trackColor={{ false: "#767577", true: "#004bF7" }}
                        thumbColor={showBigBlind ? "#FFF" : "#888"}
                        onValueChange={handleSetShowBigBlind}
                        ios_backgroundColor="#3e3e3e"
                        value={showBigBlind}
                      />
                    </View>
                  </View>
                </View>

                <View
                  style={[
                    styles.sectionOption,
                    { flexDirection: "row", justifyContent: "space-between" },
                  ]}
                >
                  <View>
                    <Text style={styles.optionTitle}>
                      <FontAwesome name="exchange" size={20} color="white" />{" "}
                      {t("ProfileScreen.modalGameSettings.showTableinTurn")}
                    </Text>
                  </View>
                  <View style={styles.optionContent}>
                    <View style={{ marginTop: 5 }}>
                      <Switch
                        trackColor={{ false: "#767577", true: "#004bF7" }}
                        thumbColor={autoTableChange ? "#FFF" : "#888"}
                        onValueChange={handleSetAutoTableChange}
                        ios_backgroundColor="#3e3e3e"
                        value={autoTableChange}
                      />
                    </View>
                  </View>
                </View>

                <View style={styles.sectionOption}>
                  <Text style={styles.optionTitle}>
                    <MaterialCommunityIcons
                      name="cards-playing-outline"
                      size={22}
                      color="white"
                    />{" "}
                    {t("ProfileScreen.modalGameSettings.frontDeck")}
                  </Text>
                  <View style={styles.optionContent}>
                    <ScrollView
                      contentContainerStyle={{ flexDirection: "row" }}
                      horizontal={true}
                      showsHorizontalScrollIndicator={true}
                    >
                      <Pressable
                        onPress={() => handleSetFrontDeck(1)}
                        style={{
                          borderRadius: 5,
                          borderWidth: 2,
                          borderColor:
                            frontDeck === 1 ? "rgb(0, 77, 255)" : "#333333",
                          marginBottom: 10,
                          marginRight: 10,
                        }}
                      >
                        <Image
                          source={Cards1["Ac"]}
                          resizeMode={"contain"}
                          style={{ borderRadius: 5, width: 80, height: 110 }}
                        />
                      </Pressable>

                      <Pressable
                        onPress={() => handleSetFrontDeck(2)}
                        style={{
                          borderRadius: 5,
                          borderWidth: 2,
                          borderColor:
                            frontDeck === 2 ? "rgb(0, 77, 255)" : "#333333",
                          marginBottom: 10,
                          marginRight: 10,
                        }}
                      >
                        <Image
                          source={Cards2["Ac"]}
                          resizeMode={"contain"}
                          style={{ borderRadius: 5, width: 80, height: 110 }}
                        />
                      </Pressable>

                      <Pressable
                        onPress={() => handleSetFrontDeck(3)}
                        style={{
                          borderRadius: 5,
                          borderWidth: 2,
                          borderColor:
                            frontDeck === 3 ? "rgb(0, 77, 255)" : "#333333",
                          marginBottom: 10,
                          marginRight: 10,
                        }}
                      >
                        <Image
                          source={Cards3["Ac"]}
                          resizeMode={"contain"}
                          style={{ borderRadius: 5, width: 80, height: 110 }}
                        />
                      </Pressable>
                    </ScrollView>
                  </View>
                </View>
              </View>
            )}
          </View>

          {orientation === "portrait" && signed ? (
            <View>
              <Pressable onPress={() => setShowSignoutConfirmation(true)}>
                <View style={styles.sectionLink}>
                  <Text
                    style={[styles.titleLink, { textTransform: "uppercase" }]}
                  >
                    {t("ProfileScreen.signout")}
                  </Text>
                </View>
              </Pressable>
            </View>
          ) : (
            <View style={{ height: 20 }}></View>
          )}
        </View>
      </ScrollView>

      <Modal
        animationType="none"
        visible={showSignoutConfirmation}
        onRequestClose={() => {
          setShowSignoutConfirmation(false);
        }}
        transparent
      >
        <View
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#0008",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "80%",
              maxWidth: 440,
              height: 256,
              backgroundColor: theme.colors.background100,
              borderColor: theme.colors.background90,
              borderWidth: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                margin: 10,
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "white",
                  textTransform: "uppercase",
                  fontSize: theme.defaultFontSizes.modalTitle,
                }}
              >
                {t("ProfileScreen.modalSignout.confirmation")}
              </Text>
              <FontAwesome
                name="close"
                size={30}
                color="#FFFFFF"
                onPress={() => setShowSignoutConfirmation(false)}
              />
            </View>
            <View
              style={{
                height: "100%",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "100%",
                flex: 1,
                paddingVertical: 36,
              }}
            >
              <View style={{ width: 160 }}>
                <Button
                  title={t("ProfileScreen.modalSignout.no")}
                  onPress={() => setShowSignoutConfirmation(false)}
                />
              </View>
              <View style={{ width: 160 }}>
                <Button
                  title={t("ProfileScreen.modalSignout.yes")}
                  color={"#F33"}
                  onPress={handleSignout}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    width: "100%",
    maxWidth: 960,
    alignSelf: "center",
  },
  container: {
    flex: 1,
    width: "100%",

    paddingHorizontal: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#999999",
    textAlign: "center",
  },
  section: {
    flex: 1,
    maxWidth: 600,
    alignSelf: "center",
  },
  sectionTitle: {
    padding: 10,
    borderBottomWidth: 2,
    borderBottomColor: "#666666",
  },
  sectionContent: {
    flex: 1,
    padding: 20,
    borderBottomWidth: 2,
    borderBottomColor: "#333333",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  sectionLink: {
    padding: 30,
  },
  titleLink: {
    fontSize: 18,
    //fontWeight: "bold",
    color: "rgb(0, 77, 255)",
    textAlign: "center",
  },
  linkText: {
    fontSize: 14,
    color: "rgb(0, 77, 255)",
  },
  sectionOption: {
    paddingHorizontal: 5,
    paddingVertical: 10,
  },
  optionTitle: {
    fontSize: 18,
    color: "white",
    marginBottom: 10,
  },
  optionText: {
    fontSize: 16,
    color: "white",
  },
  optionContent: {
    flexDirection: "row",
  },
});
