import React from "react";
import { View } from "react-native";
import ImBack from "./ImBack";

import { styleActionsPosition } from "./styles";

export default function () {
  return (
    <View style={styleActionsPosition()}>
      <ImBack />
    </View>
  );
}
