import { FontAwesome } from "@expo/vector-icons";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Pressable, Text, View } from "react-native";
import Deposit from "../components/modals/Deposit";
import Withdraw from "../components/modals/Withdraw";
import { useAccount } from "../contexts/Account";
import { useWebSocket } from "../contexts/WebSocket";
import { RootStackScreenProps } from "../types";
import { monetaryValue } from "../util";

export default function CashierScreen({
  navigation,
}: RootStackScreenProps<"Cashier">) {
  const { t } = useTranslation();
  const { socket } = useWebSocket();
  const { wallet, transactions, signed, user } = useAccount();
  const [tab, setTab] = React.useState<number>(2);
  const [option, setOption] = React.useState<number>(0);

  const [showModalDeposit, setShowModalDeposit] =
    React.useState<boolean>(false);
  const [showModalWithdraw, setShowModalWithdraw] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (socket.connected) {
      socket.emit("transactions");
    }
  }, [socket, showModalDeposit, showModalWithdraw]);

  React.useEffect(() => {
    if (!signed) {
      navigation.navigate("Root");
    }
  }, [signed]);

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          width: "100%",
          maxWidth: 800,
          paddingHorizontal: 20,
          alignSelf: "center",
        }}
      >
        <View>
          <Pressable onPress={() => navigation.navigate("Root")}>
            <View
              style={{
                flexDirection: "row",
                alignContent: "center",
                paddingVertical: 15,
              }}
            >
              <FontAwesome
                name="chevron-left"
                size={20}
                color="white"
                style={{ marginRight: 10, marginTop: 5 }}
              />
              <Text
                style={{ color: "white", fontSize: 20, fontWeight: "bold" }}
              >
                {t("Cashier.back")}
              </Text>
            </View>
          </Pressable>
        </View>
        <View
          style={{
            flexDirection: "row",
            //borderWidth: 2,
            //borderColor: "rgba(255, 255, 255, 0.2)",
            paddingHorizontal: 20,
            paddingVertical: 15,
            borderRadius: 10,
            marginBottom: 20,
            backgroundColor: "rgba(255, 255, 255, 0.2)",
          }}
        >
          <View
            style={{
              backgroundColor: "rgb(0, 77, 255)",
              paddingHorizontal: 14,
              paddingVertical: 8,
              borderRadius: 20,
              marginRight: 20,
            }}
          >
            <FontAwesome name="dollar" size={24} color="white" />
          </View>
          <View style={{ marginRight: 30 }}>
            <Text style={{ color: "white", fontWeight: "bold", fontSize: 14 }}>
              {t("Cashier.balance")}
            </Text>
            <Text style={{ color: "white", fontSize: 16 }}>
              <Text
                style={{
                  backgroundColor: "yellow",
                  color: "black",
                  fontSize: 10,
                  //paddingVertical: 2,
                  paddingHorizontal: 5,
                  borderRadius: 10,
                }}
              >
                BRL
              </Text>{" "}
              {monetaryValue(wallet?.balance || 0)}
            </Text>
          </View>
          <View>
            <Text style={{ color: "white", fontWeight: "bold", fontSize: 14 }}>
              {t("Cashier.bonus")}
            </Text>
            <Text style={{ color: "white", fontSize: 16 }}>
              <Text
                style={{
                  backgroundColor: "yellow",
                  color: "black",
                  fontSize: 10,
                  //paddingVertical: 2,
                  paddingHorizontal: 5,
                  borderRadius: 10,
                }}
              >
                BRL
              </Text>{" "}
              {monetaryValue(wallet?.bonus || 0)}
            </Text>
          </View>
        </View>
        <View>
          <View
            style={{
              flexDirection: "row",
              alignContent: "space-around",
              marginBottom: 20,
            }}
          >
            {(user?.provider === "ACE2ACE_POKER" || user?.provider === "") && (
              <>
                <Pressable onPress={() => setShowModalDeposit(true)}>
                  <View
                    style={[
                      {
                        paddingVertical: 10,
                        paddingHorizontal: 25,
                        borderRadius: 25,
                        borderWidth: 2,
                        borderColor: "rgba(255, 255, 255, 0.2)",
                        marginRight: 20,
                      },
                      tab === 0 && {
                        backgroundColor: "rgb(0, 77, 255)",
                        borderColor: "rgb(0, 77, 255)",
                      },
                    ]}
                  >
                    <Text style={{ color: "white", fontWeight: "bold" }}>
                      {t("Cashier.deposit")}
                    </Text>
                  </View>
                </Pressable>

                <Pressable onPress={() => setShowModalWithdraw(true)}>
                  <View
                    style={[
                      {
                        paddingVertical: 10,
                        paddingHorizontal: 25,
                        borderRadius: 25,
                        borderWidth: 2,
                        borderColor: "rgba(255, 255, 255, 0.2)",
                        marginRight: 20,
                      },
                      tab === 1 && {
                        backgroundColor: "rgb(0, 77, 255)",
                        borderColor: "rgb(0, 77, 255)",
                      },
                    ]}
                  >
                    <Text style={{ color: "white", fontWeight: "bold" }}>
                      {t("Cashier.withdraw")}
                    </Text>
                  </View>
                </Pressable>
              </>
            )}

            <Pressable onPress={() => {}}>
              <View
                style={[
                  {
                    paddingVertical: 10,
                    paddingHorizontal: 25,
                    borderRadius: 25,
                    borderWidth: 2,
                    borderColor: "rgba(255, 255, 255, 0.2)",
                    marginRight: 20,
                  },
                  tab === 2 && {
                    backgroundColor: "rgb(0, 77, 255)",
                    borderColor: "rgb(0, 77, 255)",
                  },
                ]}
              >
                <Text style={{ color: "white", fontWeight: "bold" }}>
                  {t("Cashier.transactions")}
                </Text>
              </View>
            </Pressable>
          </View>
        </View>
        <View style={{}}>
          {/*tab === 0 && (
            <View>
              <Text style={{ color: "white" }}>DEPOSIT OPTIONS</Text>
            </View>
          )*/}
          {/*tab === 1 && (
            <View>
              <Text style={{ color: "white" }}>WITHDRAW OPTIONS</Text>
            </View>
          )*/}
          <View>
            {transactions?.map((transaction) => (
              <View key={transaction.id}>
                <View
                  style={{
                    borderBottomWidth: 1,
                    borderBottomColor: "rgba(255, 255, 255, 0.2)",
                    padding: 10,
                  }}
                >
                  <View>
                    <Text style={{ color: "rgba(255, 255, 255, 0.6)" }}>
                      {new Date(transaction.createdAt).toLocaleString()}
                    </Text>
                  </View>
                  <View>
                    <View>
                      <Text style={{ color: "white", fontWeight: "bold" }}>
                        {t("Cashier.deposit")}
                      </Text>
                      <Text style={{ color: "lightgreen" }}>
                        {`+${monetaryValue(transaction.amount)}`}
                      </Text>
                    </View>
                    <View></View>
                  </View>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>

      <Deposit
        showButton={false}
        modalVisibleExternal={showModalDeposit}
        setModalVisibleExternal={setShowModalDeposit}
      />
      <Withdraw
        showButton={false}
        modalVisibleExternal={showModalWithdraw}
        setModalVisibleExternal={setShowModalWithdraw}
      />
    </View>
  );
}
