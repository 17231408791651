import AsyncStorage from "@react-native-async-storage/async-storage";

export function monetaryValue(value: number) {
  if (!value) {
    return "0";
  }

  let chipsString = Math.round(value).toString();

  if (chipsString.length === 1) {
    chipsString = `0.0${chipsString}`;
  } else if (chipsString.length === 2) {
    chipsString = `0.${chipsString}`;
  } else if (chipsString.length >= 3) {
    chipsString = `${chipsString.substring(
      0,
      chipsString.length - 2
    )}.${chipsString.substring(chipsString.length - 2)}`;

    if (chipsString.length > 6) {
      chipsString = `${chipsString.substring(
        0,
        chipsString.length - 6
      )},${chipsString.substring(chipsString.length - 6)}`;
    }

    if (chipsString.length > 10) {
      chipsString = `${chipsString.substring(
        0,
        chipsString.length - 10
      )},${chipsString.substring(chipsString.length - 10)}`;
    }

    if (chipsString.length > 14) {
      chipsString = `${chipsString.substring(
        0,
        chipsString.length - 14
      )},${chipsString.substring(chipsString.length - 14)}`;
    }
  }

  if (chipsString.substring(chipsString.length - 3) === ".00") {
    chipsString = `${chipsString.substring(0, chipsString.length - 3)}`;
  }

  return chipsString;
}

export function monetaryValueOrBigBlind(
  value: number,
  bigBlind: number,
  showBigBlind: boolean
) {
  if (!value) {
    return "0";
  }

  let bb = (value / bigBlind).toFixed(2);

  let bbString = bb.toString();

  if (bbString.substring(bbString.length - 3) === ".00") {
    bbString = `${bbString.substring(0, bbString.length - 3)}`;
  }

  return `${bbString} BB`;
}

export async function storeObject(key: string, value: {}) {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(key, jsonValue);
    //console.log(key, ' saved!');
  } catch (e) {
    // saving error
  }
}

export async function getStoredObject(key: string) {
  try {
    const jsonValue = await AsyncStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    //console.log('Error getting data', e)
    return;
  }
}

export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function zeroPad(number: any, length: any) {
  length -= number.toString().length;

  if (length > 0) {
    return new Array(length + (/\./.test(number) ? 2 : 1)).join("0") + number;
  }

  return `${number}`; // always return a string
}

export function lengthAsClock(n: number) {
  let s = Math.floor(n % 60);
  let m = Math.floor((n % 3600) / 60);
  let h = Math.floor(n / 3600);
  if (h) {
    return `${h}:${zeroPad(m, 2)}:${zeroPad(s, 2)}`;
  }
  return `${m}:${zeroPad(s, 2)}`;
}

export function clockAsLength(string: string) {
  let parts = string.split(":");
  let s = parseInt(parts[parts.length - 1], 10);
  let m = parseInt(parts[parts.length - 2], 10);
  let h = 0;

  if (parts.length > 2) {
    h = parseInt(parts[parts.length - 3], 10);
  }

  return s + 60 * m + 60 * 60 * h;
}
