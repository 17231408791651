import {StyleSheet, ViewStyle} from 'react-native';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        //backgroundColor: 'pink',
        position: 'absolute',
        width: '20%',
        height: '100%',
    },
    image: {
        flex: 1,
        width: '100%',
        height: '100%'
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
});


export const styleStackPosition = (stackNumber: number, numberOfStacks: number): ViewStyle => {
    return StyleSheet.flatten([styles.container, getStackPosition(stackNumber, numberOfStacks)]);
}

export const getStackPosition = (stackNumber: number, numberOfStacks: number): ViewStyle => {

    switch (numberOfStacks) {
        case 1:
            switch (stackNumber) {
                case 0:
                    return {
                        left: '40%',
                        top: '0%',
                    }
                default:
                    return {
                        display: 'none',
                    }
            }
        case 2:
            switch (stackNumber) {
                case 0:
                    return {
                        left: '30%',
                        top: '0%',
                    }
                case 1:
                    return {
                        right: '30%',
                        top: '0%',
                    }
                default:
                    return {
                        display: 'none',
                    }
            }
        case 3:
            switch (stackNumber) {
                case 0:
                    return {
                        left: '20%',
                        top: '0%',
                    }
                case 1:
                    return {
                        left: '40%',
                        top: '0%',
                    }
                case 2:
                    return {
                        right: '20%',
                        top: '0%',
                    }
                default:
                    return {
                        display: 'none',
                    }
            }
        case 4:
            switch (stackNumber) {
                case 0:
                    return {
                        left: '10%',
                        top: '0%',
                    }
                case 1:
                    return {
                        left: '30%',
                        top: '0%',
                    }
                case 2:
                    return {
                        right: '30%',
                        top: '0%',
                    }
                case 3:
                    return {
                        right: '10%',
                        top: '0%',
                    }
                default:
                    return {
                        display: 'none',
                    }
            }
        case 5:
            switch (stackNumber) {
                case 0:
                    return {
                        left: '30%',
                        bottom: '60%',
                    }
                case 1:
                    return {
                        right: '30%',
                        bottom: '60%',
                    }
                case 2:
                    return {
                        left: '20%',
                        top: '0%',
                    }
                case 3:
                    return {
                        left: '40%',
                        top: '0%',
                    }
                case 4:
                    return {
                        right: '20%',
                        top: '0%',
                    }
                default:
                    return {
                        display: 'none',
                    }
            }
        default:
            return {
                display: 'none',
            }


    }
}