import "react-native-reanimated";
import * as NavigationBar from "expo-navigation-bar";
import { setStatusBarHidden, StatusBar } from "expo-status-bar";
import React, { useEffect } from "react";
import { Image, View, Platform } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { useKeepAwake } from "expo-keep-awake";
import * as Font from "expo-font";
import { Asset } from "expo-asset";
import { FontAwesome } from "@expo/vector-icons";
import { AccountProvider } from "./contexts/Account";
import { DimensionsProvider } from "./contexts/Dimensions";
import { WebSocketProvider } from "./contexts/WebSocket";

import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./navigation";
import { GameOptionsProvider } from "./contexts/GameOptions";
import "./App.module.css";

import Chips from "./collections/Chips";
import Tables from "./collections/Tables";
import Dealer from "./collections/Dealer";
import MiniCards1 from "./collections/MiniCards1";
import MiniCards2 from "./collections/MiniCards2";
import MiniCards3 from "./collections/MiniCards3";
import Emojis from "./collections/Emojis";
import Avatar from "./collections/Avatar";

import "./i18n";
import Cards1 from "./collections/Cards1";
import Cards2 from "./collections/Cards2";
import Cards3 from "./collections/Cards3";
import { ChatProvider } from "./contexts/Chat";
import { SettingProvider } from "./contexts/Setting";

export function disableReactDevToolsAndContextMenu() {
  // Check if the React Developer Tools global hook exists
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== "object") {
    return;
  }

  for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    if (prop === "renderers") {
      // this line will remove that one console error

      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
    } else {
      // Replace all of its properties with a no-op function or a null value
      // depending on their types

      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
        typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === "function"
          ? () => {}
          : null;
    }
  }

  window.document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });
}

disableReactDevToolsAndContextMenu();

console.log(
  "%cDeveloped by Victor Oliveira (@victor.onl)",
  "color: white; background: black; padding: 10px; font-size: 20px; font-weight: bold;"
);
console.log(
  "%cWhatsApp +55 11 91000-4061",
  "color: white; background: #128C7E; padding: 10px; font-size: 20px; font-weight: bold;"
);
console.log(
  "%cSTOP!",
  "color: white; background: red; padding: 10px; font-size: 20px; font-weight: bold;"
);
console.log(
  "%cThis is a browser feature intended for developers. If someone told you to copy and paste something here to enable a feature or 'hack' someone's account, it is a scam and will give them access to your account.",
  "color: black; background: white; font-size: 18px; font-weight: bold;"
);
console.log(
  "%cPARE!",
  "color: white; background: red; padding: 10px; font-size: 20px; font-weight: bold;"
);
console.log(
  "%cEste é um recurso do navegador destinado a desenvolvedores. Se alguém lhe disser para copiar e colar algo aqui para habilitar um recurso ou 'hackear' a conta de alguém, isso é uma farsa e dará acesso à sua conta.",
  "color: black; background: white; font-size: 18px; font-weight: bold;"
);

//import Modal from './components/Modal';
//import MaintenanceScreen from './screens/MaintenanceScreen';

/*
Sentry.init({
  dsn: 'https://e6a1ffaae78b4d268179690b22d4bff4@o1046084.ingest.sentry.io/6251376',
  enableInExpoDevelopment: true,
  debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});
*/

if (Platform.OS === "android") {
  NavigationBar.setPositionAsync("absolute");
  NavigationBar.setVisibilityAsync("hidden");
  NavigationBar.setBehaviorAsync("overlay-swipe");
  setStatusBarHidden(true, "none");
}

function cacheImages(images: any[]) {
  return images.map((image) => {
    if (typeof image === "string") {
      return Image.prefetch(image);
    } else {
      return Asset.fromModule(image).downloadAsync();
    }
  });
}

function cacheFonts(fonts: any[]) {
  return fonts.map((font) => Font.loadAsync(font));
}

export default function App() {
  useKeepAwake();
  const colorScheme = useColorScheme();
  const [isLoadingComplete, setIsLoadingComplete] = React.useState<boolean>(
    Platform.OS === "web" ? false : true
  );
  const visibility = NavigationBar.useVisibility();

  const loadAssetsAsync = async () => {
    if (Platform.OS === "web") {
      const imageAssets = cacheImages([
        require("./assets/images/logo-header.png"),
        require("./assets/images/playbonds.png"),
        require("./assets/images/acepoints.png"),
        require("./assets/images/pagstar.png"),
        require("./assets/images/pix-bc.png"),
        require("./assets/images/rakeback/background.png"),
        require("./assets/images/rakeback/piramide.png"),
        require("./assets/images/rakeback/progresso.png"),
        require("./assets/images/rakeback/recompensas.png"),
        require("./assets/images/theme/default/rakeback/level/ACE_BLUE.png"),
        require("./assets/images/theme/default/rakeback/level/BLUE_ACE.png"),
        require("./assets/images/theme/default/rakeback/level/DIAMOND.png"),
        require("./assets/images/theme/default/rakeback/level/GOLD.png"),
        require("./assets/images/theme/default/rakeback/level/IRON.png"),
        require("./assets/images/theme/default/rakeback/level/PLATINUM.png"),
        require("./assets/images/theme/default/rakeback/level/SILVER.png"),
        require("./assets/images/theme/default/rakeback/level/STONE.png"),
        require("./assets/images/theme/default/rakeback/progress/bar.png"),
        require("./assets/images/theme/default/rakeback/progress/mark.png"),
        require("./assets/images/theme/default/rakeback/progress/new.png"),
        require("./assets/images/theme/default/rakeback/progress/progress.png"),
        require("./assets/images/theme/default/rakeback/progress/reached.png"),
        require("./assets/images/theme/default/rakeback/raffle/spread.png"),
        require("./assets/images/theme/default/rakeback/raffle/ACE_BLUE/arrow.png"),
        require("./assets/images/theme/default/rakeback/raffle/ACE_BLUE/pyramid.png"),
        require("./assets/images/theme/default/rakeback/raffle/ACE_BLUE/roulette.png"),
        require("./assets/images/theme/default/rakeback/raffle/DIAMOND/arrow.png"),
        require("./assets/images/theme/default/rakeback/raffle/DIAMOND/pyramid.png"),
        require("./assets/images/theme/default/rakeback/raffle/DIAMOND/roulette.png"),
        require("./assets/images/theme/default/rakeback/raffle/GOLD/arrow.png"),
        require("./assets/images/theme/default/rakeback/raffle/GOLD/pyramid.png"),
        require("./assets/images/theme/default/rakeback/raffle/GOLD/roulette.png"),
        require("./assets/images/theme/default/rakeback/raffle/IRON/arrow.png"),
        require("./assets/images/theme/default/rakeback/raffle/IRON/pyramid.png"),
        require("./assets/images/theme/default/rakeback/raffle/IRON/roulette.png"),
        require("./assets/images/theme/default/rakeback/raffle/PLATINUM/arrow.png"),
        require("./assets/images/theme/default/rakeback/raffle/PLATINUM/pyramid.png"),
        require("./assets/images/theme/default/rakeback/raffle/PLATINUM/roulette.png"),
        require("./assets/images/theme/default/rakeback/raffle/SILVER/arrow.png"),
        require("./assets/images/theme/default/rakeback/raffle/SILVER/pyramid.png"),
        require("./assets/images/theme/default/rakeback/raffle/SILVER/roulette.png"),
        require("./assets/images/theme/default/rakeback/raffle/STONE/arrow.png"),
        require("./assets/images/theme/default/rakeback/raffle/STONE/pyramid.png"),
        require("./assets/images/theme/default/rakeback/raffle/STONE/roulette.png"),
        require("./assets/images/theme/default/table/background_landscape.jpg"),
        require("./assets/images/theme/default/table/take_seat.png"),
        require("./assets/images/theme/default/table/avatar_frame.png"),
        require("./assets/images/theme/default/table/pod.png"),
        require("./assets/images/theme/default/table/pod_winnerEffect.png"),
        require("./assets/images/theme/default/table/pod_activeEffect.png"),
        require("./assets/images/theme/default/table/timer_background.png"),
        require("./assets/images/theme/default/table/timer_timebank_fill.png"),
        require("./assets/images/theme/default/table/timer_half.png"),
        require("./assets/images/theme/default/table/hand_history.png"),
        require("./assets/images/theme/default/table/emoji.png"),
        require("./assets/images/theme/default/table/chat.png"),
        require("./assets/images/theme/default/table/action.png"),
        require("./assets/images/theme/default/table/betting_input.png"),
        require("./assets/images/theme/default/table/betting_shortcut.png"),
        require("./assets/images/theme/default/table/preAction.png"),
        ...Object.values(Avatar),
        ...Object.values(Tables),
        ...Object.values(Cards1),
        ...Object.values(Cards2),
        ...Object.values(Cards3),
        ...Object.values(Chips["portrait"]),
        ...Object.values(Chips["landscape"]),
        ...Object.values(Dealer),
        ...Object.values(MiniCards1),
        ...Object.values(MiniCards2),
        ...Object.values(MiniCards3),
        ...Object.values(Emojis),
      ]);

      const fontAssets = cacheFonts([FontAwesome.font]);

      await Promise.all([...imageAssets, ...fontAssets]);
    } else {
      await Promise.resolve();
    }
  };

  React.useEffect(() => {
    if (Platform.OS === "android" && visibility === "visible") {
      const interval = setTimeout(() => {
        NavigationBar.setVisibilityAsync("hidden");
        setStatusBarHidden(true, "none");
      }, 3000);

      return () => {
        clearTimeout(interval);
      };
    }
  }, [visibility]);

  useEffect(() => {
    loadAssetsAsync();
  }, []);

  //const isLoadingComplete = useCachedResources();

  /*
  if (!isLoadingComplete) {
    //return <ActivityIndicator />;
    return (
      <View
        style={{
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
          backgroundColor: "black",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {Platform.OS === "web" ? (
            <AppLoading
              startAsync={loadAssetsAsync}
              onFinish={() => setIsLoadingComplete(true)}
              onError={console.warn}
            />
          ) : (
            <Image
              style={{ width: 200, height: 200 }}
              source={require("./assets/images/icon.png")}
            />
          )}
        </View>
      </View>
    );
  } else {
    */
  return (
    <SafeAreaProvider style={{ backgroundColor: "black" }}>
      <GameOptionsProvider>
        <DimensionsProvider>
          <WebSocketProvider>
            <SettingProvider>
              <AccountProvider>
                <ChatProvider>
                  <Navigation colorScheme={colorScheme} />
                </ChatProvider>
              </AccountProvider>
            </SettingProvider>
          </WebSocketProvider>
        </DimensionsProvider>
      </GameOptionsProvider>
      <StatusBar hidden={true} />
    </SafeAreaProvider>
  );
  /*}*/
}
