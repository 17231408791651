import {StyleSheet, ViewStyle} from "react-native";
import {useDimensions} from "../../../../../../contexts/Dimensions";

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        //alignItems: 'center',
        //justifyContent: 'center',
        //backgroundColor: 'blue',
        position: "absolute",
    },
    title: {
        fontSize: 22,
        fontWeight: "500",
        color: "#FFFFFF",
    },
});

export const styleBetPosition = (
    seatNumber: number,
    numberOfSeats: number
): ViewStyle => {
    return StyleSheet.flatten([
        styles.container,
        getBetPosition(seatNumber, numberOfSeats),
    ]);
};

export const getBetPosition = (
    seatNumber: number,
    numberOfSeats: number
): ViewStyle => {
    const {orientation} = useDimensions();

    switch (orientation) {
        case "landscape":
            switch (numberOfSeats) {
                case 2:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "0%",
                                top: "-32%",
                            };
                        case 1:
                            return {
                                width: "100%",
                                height: "38%",
                                left: "0%",
                                bottom: "-32%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                case 6:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "0%",
                                top: "-45%",
                            };
                        case 1:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "-80%",
                                top: "-14%",
                            };
                        case 2:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "-35%",
                                bottom: "-52%",
                            };
                        case 3:
                            return {
                                width: "100%",
                                height: "38%",
                                left: "0%",
                                bottom: "-52%",
                            };
                        case 4:
                            return {
                                width: "100%",
                                height: "38%",
                                left: "-35%",
                                bottom: "-52%",
                            };
                        case 5:
                            return {
                                width: "100%",
                                height: "38%",
                                left: "-80%",
                                top: "-14%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                case 8:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "0%",
                                top: "-32%",
                            };
                        case 1:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "-80%",
                                top: "0%",
                            };
                        case 2:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "-78.5%",
                                bottom: "25%",
                            };
                        case 3:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "-35%",
                                bottom: "-32%",
                            };
                        case 4:
                            return {
                                width: "100%",
                                height: "38%",
                                left: "0%",
                                bottom: "-32%",
                            };

                        case 5:
                            return {
                                width: "100%",
                                height: "38%",
                                left: "-35%",
                                bottom: "-32%",
                            };
                        case 6:
                            return {
                                width: "100%",
                                height: "38%",
                                left: "-78.5%",
                                bottom: "25%",
                            };
                        case 7:
                            return {
                                width: "100%",
                                height: "38%",
                                left: "-80%",
                                top: "0%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                case 9:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "0%",
                                top: "-42%",
                            };
                        case 1:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "-75%",
                                top: "-14%",
                            };
                        case 2:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "-90%",
                                bottom: "15%",
                            };
                        case 3:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "-75%",
                                bottom: "-45%",
                            };
                        case 4:
                            return {
                                width: "100%",
                                height: "38%",
                                left: "0%",
                                bottom: "-52%",
                            };
                        case 5:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "0%",
                                bottom: "-52%",
                            };
                        case 6:
                            return {
                                width: "100%",
                                height: "38%",
                                left: "-75%",
                                bottom: "-45%",
                            };
                        case 7:
                            return {
                                width: "100%",
                                height: "38%",
                                left: "-90%",
                                bottom: "15%",
                            };
                        case 8:
                            return {
                                width: "100%",
                                height: "38%",
                                left: "-75%",
                                top: "-14%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                default:
                    return {
                        display: "none",
                    };
            }
        case "portrait":
            switch (numberOfSeats) {
                case 2:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "-75%",
                                top: "-14%",
                            };
                        case 1:
                            return {
                                width: "110%",
                                height: "44%",
                                right: "20%",
                                bottom: "-26%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                case 6:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "-75%",
                                top: "-14%",
                            };
                        case 1:
                            return {
                                width: "110%",
                                height: "44%",
                                right: "-95%",
                                bottom: "28%",
                            };
                        case 2:
                            return {
                                width: "110%",
                                height: "44%",
                                right: "-95%",
                                bottom: "30%",
                            };
                        case 3:
                            return {
                                width: "110%",
                                height: "44%",
                                right: "-5%",
                                bottom: "-55%",
                            };
                        case 4:
                            return {
                                width: "110%",
                                height: "44%",
                                left: "-95%",
                                bottom: "30%",
                            };
                        case 5:
                            return {
                                width: "110%",
                                height: "44%",
                                left: "-95%",
                                bottom: "28%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                case 8:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "-75%",
                                top: "-14%",
                            };
                        case 1:
                            return {
                                width: "110%",
                                height: "44%",
                                right: "-60%",
                                bottom: "28%",
                            };
                        case 2:
                            return {
                                width: "110%",
                                height: "44%",
                                right: "-60%",
                                bottom: "28%",
                            };
                        case 3:
                            return {
                                width: "110%",
                                height: "44%",
                                right: "-60%",
                                bottom: "28%",
                            };
                        case 4:
                            return {
                                width: "110%",
                                height: "44%",
                                right: "10%",
                                bottom: "-26%",
                            };
                        case 5:
                            return {
                                width: "110%",
                                height: "44%",
                                left: "-60%",
                                bottom: "28%",
                            };
                        case 6:
                            return {
                                width: "110%",
                                height: "44%",
                                left: "-60%",
                                bottom: "28%",
                            };
                        case 7:
                            return {
                                width: "110%",
                                height: "44%",
                                left: "-60%",
                                bottom: "28%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                case 9:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "100%",
                                height: "38%",
                                right: "-90%",
                                top: "-14%",
                            };
                        case 1:
                            return {
                                width: "110%",
                                height: "44%",
                                right: "-100%",
                                bottom: "20%",
                            };
                        case 2:
                            return {
                                width: "110%",
                                height: "44%",
                                right: "-100%",
                                bottom: "28%",
                            };
                        case 3:
                            return {
                                width: "110%",
                                height: "44%",
                                right: "-100%",
                                bottom: "-20%",
                            };
                        case 4:
                            return {
                                width: "110%",
                                height: "44%",
                                right: "-30%",
                                bottom: "-60%",
                            };
                        case 5:
                            return {
                                width: "110%",
                                height: "44%",
                                left: "-30%",
                                bottom: "-60%",
                            };
                        case 6:
                            return {
                                width: "110%",
                                height: "44%",
                                left: "-100%",
                                bottom: "-20%",
                            };
                        case 7:
                            return {
                                width: "110%",
                                height: "44%",
                                left: "-100%",
                                bottom: "28%",
                            };
                        case 8:
                            return {
                                width: "110%",
                                height: "44%",
                                left: "-100%",
                                bottom: "20%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                default:
                    return {
                        display: "none",
                    };
            }
        default:
            return {
                display: "none",
            };
    }
};
