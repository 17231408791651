import React from "react";
import {
  ActivityIndicator,
  Button,
  NativeSyntheticEvent,
  Platform,
  Pressable,
  ScrollView,
  Text,
  TextInput,
  TextInputKeyPressEventData,
  View,
} from "react-native";
import { TextInputMask } from "react-native-masked-text";
import { useAccount } from "../../../contexts/Account";
// import { useDimensions } from '../../../contexts/Dimensions';
import Modal from "../Modal";
// import Joi, {ValidationError} from "joi";
import { styles, styleWithFontScale } from "./styles";
import { useTranslation } from "react-i18next";

type Props = {
  modalVisible: boolean;
  setModalVisible: (modalVisible: boolean) => void;
};

export default function ({ modalVisible, setModalVisible }: Props) {
  const { t } = useTranslation();
  const { user, changePassword, success, error } = useAccount();

  const nextFocus = React.useRef<TextInput>(null);

  const [currentPassword, onChangeCurrentPassword] = React.useState<string>("");
  const [newPassword, onChangeNewPassword] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const changePasswordSubmit = () => {
    if (!user) return;

    setLoading(true);
    changePassword(currentPassword, newPassword);
  };

  React.useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  React.useEffect(() => {
    if (success) {
      setLoading(false);
      setModalVisible(false);
    }
  }, [success]);

  return (
    <React.Fragment>
      <Modal
        title={t("ProfileScreen.modalAccountSettings.changePassword")}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        type="window"
      >
        <View style={{ marginBottom: 10 }}>
          {loading ? <ActivityIndicator /> : <></>}
          {error?.map((err, index) => (
            <Text key={index} style={{ color: "red" }}>{`${
              index + 1
            }. ${err}`}</Text>
          ))}
        </View>
        <ScrollView>
          <View style={{ flex: 1 }}>
            <View style={{ flex: 1, paddingBottom: 20 }}>
              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalResetPassword.password")}
                </Text>
                <TextInput
                  textContentType="password"
                  clearTextOnFocus={true}
                  secureTextEntry={true}
                  ref={nextFocus}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeCurrentPassword}
                  value={currentPassword}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalResetPassword.newPassword")}
                </Text>
                <TextInput
                  textContentType="password"
                  clearTextOnFocus={true}
                  secureTextEntry={true}
                  ref={nextFocus}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeNewPassword}
                  value={newPassword}
                />
              </View>

              <Pressable
                style={styles.button}
                onPress={() => changePasswordSubmit()}
              >
                <Text style={[styleWithFontScale(20, 14)]}>
                  {t("ModalPersonalInfo.confirm")}
                </Text>
              </Pressable>
            </View>
          </View>
        </ScrollView>
      </Modal>
      {/*<Button title='Personal Info' color={'#666666'} onPress={() => setModalVisible(!modalVisible)} />*/}
    </React.Fragment>
  );
}
