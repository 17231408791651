import React from "react";
import { View } from "react-native";
import { PlayerProps } from "../../../../types";
import Empty from "./Empty";
import Player from "./Player";
import { styleSeatWithPosition } from "./styles";

type Props = {
  seatNumber: number;
  numberOfSeats: number;
  player: PlayerProps | null;
  currentPlayer: PlayerProps | null;
  disableSit: boolean;
};
// <Empty seatNumber={seatNumber} numberOfSeats={numberOfSeats} />

export default function ({
  seatNumber,
  numberOfSeats,
  player,
  currentPlayer,
  disableSit,
}: Props) {
  if (currentPlayer && player === null) {
    return null;
  }

  return (
    <View style={styleSeatWithPosition(seatNumber, numberOfSeats)}>
      {player === null ? (
        disableSit ? (
          <View style={{ display: "none" }} />
        ) : (
          <Empty seatNumber={seatNumber} numberOfSeats={numberOfSeats} />
        )
      ) : (
        <Player
          seatNumber={seatNumber}
          numberOfSeats={numberOfSeats}
          player={player}
        />
      )}
    </View>
  );
}
