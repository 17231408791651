import { StyleSheet, ViewStyle } from "react-native";
import { useDimensions } from "../../../contexts/Dimensions";
import { theme } from "../../../util/GlobalStyles";

type ModalType = "window" | "fullscreen" | "bottom";

export const styleModal = (type: ModalType): ViewStyle => {
  return StyleSheet.flatten([styles.modalView, getStyleModal(type)]);
};

export const getStyleModal = (type: ModalType): ViewStyle => {
  const { orientation } = useDimensions();

  switch (orientation) {
    case "landscape":
      switch (type) {
        case "window":
          return {
            maxWidth: 360,
            width: "100%",
            height: "100%",
          };
        case "fullscreen":
          return {
            maxWidth: 360,
            width: "100%",
            height: "100%",
          };
        case "bottom":
          return {
            maxWidth: 360,
            width: "100%",
            height: "100%",
          };
        default:
          return {
            display: "none",
          };
      }
    case "portrait":
      switch (type) {
        case "window":
          return {
            maxWidth: 380,
            width: "100%",
            height: "100%",
          };
        case "fullscreen":
          return {
            maxWidth: 380,
            width: "100%",
            height: "100%",
          };
        case "bottom":
          return {
            maxWidth: "60%",
            width: "100%",
            height: "100%",
          };
        default:
          return {
            display: "none",
          };
      }
    default:
      return {
        display: "none",
      };
  }
};

export const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    //justifyContent: 'flex-start',
    //alignItems: "center",
    //marginTop: 22,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  modalView: {
    width: "100%",
    height: "100%",
    //margin: 20,
    backgroundColor: theme.colors.background100,
    //borderRadius: 8,
    borderRightColor: theme.colors.background90,
    borderRightWidth: 1,
    //padding: 35,
    //alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.25,
    shadowRadius: 24,
    elevation: 5,
  },
  modalContent: {
    flex: 1,
    padding: 5,
  },
  button: {
    //borderRadius: 20,
    alignSelf: "flex-end",
    paddingHorizontal: 14,
    paddingVertical: 10,
    //elevation: 2
  },
  buttonOpen: {
    backgroundColor: "green",
  },
  buttonClose: {
    //backgroundColor: "#2196F3",
    position: "absolute",
    top: 0,
    right: 0,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalTitle: {
    marginBottom: 15,
    height: 50,
    //textAlign: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    //color: "white",
    // backgroundColor: 'rgba(0, 0, 0, 0.2)',
    //borderTopLeftRadius: 8,
    //borderTopRightRadius: 8,
  },
  modalTitleText: {
    //marginBottom: 15,
    textAlign: "center",
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
  },
});
