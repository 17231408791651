import {StyleSheet, ViewStyle} from 'react-native';
import {useDimensions} from '../../../../../contexts/Dimensions';

export const styleCardAnimationContainer = (): ViewStyle => {
    return StyleSheet.flatten([getContainerStyle()]);
}

export const getImageAngleString = (cardNumber: number, handSize: number): string => {
    switch (handSize) {
        case 2:
            switch (cardNumber) {
                case 0:
                    return '-8deg'
                case 1:
                    return '8deg'
            }
        case 4:
            switch (cardNumber) {
                case 0:
                    return '-14deg'
                case 1:
                    return '-7deg'
                case 2:
                    return '7deg'
                case 3:
                    return '14deg'
            }
        default:
            return '0'

    }
}

const getContainerStyle = (): ViewStyle => {
    const {orientation} = useDimensions();
    switch (orientation) {
        case 'landscape':
            return {
                position: 'absolute',
                bottom: '48%',
                left: '48%',
                width: '10%',
                height: '15%',
            }
        case 'portrait':
            return {
                position: 'absolute',
                bottom: "48%",
                left: "48%",
                width: '13%',
                height: '14%',
            }
    }
}