import { FontAwesome } from "@expo/vector-icons";
import React from "react";
import { Image, Pressable, Text, View } from "react-native";
import { useDimensions } from "../../../contexts/Dimensions";
import { useTable } from "../../../contexts/Table";
import { styles } from './styles';
import { useTranslation } from 'react-i18next';

export default function HelpModal() {
    const { t } = useTranslation();
    const { orientation } = useDimensions();
    const { showMinimumStayModal, setShowMinimumStayModal } = useTable();
    const timeouts = [] as NodeJS.Timeout[];

    function handleCloseButtonPress() {
        timeouts.push(setTimeout(() => {
            setShowMinimumStayModal(false);
        }, 130))
    }

    React.useEffect(() => {
        return () => timeouts.forEach(timeout => clearTimeout(timeout))
    }, [])

    return (
        showMinimumStayModal ? (
            <View style={{
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%"
            }}>
                <Pressable
                    style={styles.container}
                    onPress={handleCloseButtonPress}
                >
                </Pressable>
                <View
                    style={styles.panel}
                >
                    <View style={styles.titleBar}>
                        <View style={{ flexDirection: "row" }}>
                            <Image style={{ maxWidth: 20, height: 20, resizeMode: "contain" }}
                                source={require("../../../assets/images/theme/default/table/question_icon.png")} />
                            <Text style={styles.title}>{t("TableScreen.helpModal.hint")}</Text>
                        </View>
                        <FontAwesome name={"close"} size={27} color={"white"} onPress={handleCloseButtonPress} />
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.subtitle}>{t("TableScreen.helpModal.stayTime")}</Text>
                    </View>
                    <Text style={styles.infoText}>
                        {t("TableScreen.helpModal.modalText")}
                    </Text>
                </View>
            </View>
        ) : (
            <></>
        )
    )

}