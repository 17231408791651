import React from 'react';
import {View} from 'react-native';
import Chips from './Chips';

import styles from './styles';

type Props = {
    value: number;
}

class Chip {
    constructor(public value: number, public quantity: number) {
    }
}

class Stacks {
    public chips: Chip[] = [];
}

export default function ({value}: Props) {

    const [numberOfStacks, setNumberOfStacks] = React.useState<number>(0);
    const [chips, setChips] = React.useState<Chip[]>([]);

    const bills = [
        1,
        5,
        25,

        100,
        500,
        /*10,*/
        2500,
        10000,
        50000,
        100000,
        250000,
        500000,
        2500000,
        10000000,
        50000000,
        100000000,
        500000000,
        2500000000,
        100000000000,
        500000000000,
        2500000000000
    ];

    const stacks = new Stacks();

    function moneyChanger(money: number, bills: number[]) {
        if (bills[0] < bills[1]) bills.reverse();
        const change: any = {};
        bills.map(b => {
            change[b] = Math.floor(money / b);
            money -= b * change[b];
        });
        return change;
    }

    React.useEffect(() => {

        stacks.chips = [];

        const obj = moneyChanger(value, bills);
        const reversedKeys = Object.keys(obj).reverse();

        reversedKeys.map((key) => {
            if (obj[key] > 0) {
                if (stacks.chips.length >= 5) {
                    return;
                }

                stacks.chips.push(new Chip(Number(key), obj[key]));
            }
        });

        setNumberOfStacks(stacks.chips.length);

        /*
        if (stacks.chips.length > 4) {
            stacks.chips.reverse();
        }
        */

        setChips(stacks.chips);

        //console.log('*** STACKS ***');
        //console.log(stacks.chips);

    }, [value]);

    return (
        <View style={styles.container}>
            {chips.map((chip: Chip, index) => (
                <Chips key={index} stackNumber={index} numberOfStacks={numberOfStacks} value={chip.value}
                       quantity={chip.quantity}/>
            ))}
        </View>
    );
}