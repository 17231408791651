//import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import React from "react";
//import { View, Text, ScrollView, Pressable } from "react-native";
import { useDimensions } from "../../../contexts/Dimensions";
import { HistoryProvider } from "../../../contexts/History";
//import { useTable } from "../../../contexts/Table";
import LandscapePanel from "./LandscapePanel";
import PortraitPanel from "./PortraitPanel";

//import { styles, stylePanelPosition } from "./styles";

export default function () {
  const { orientation } = useDimensions();

  return (
    // <React.Fragment>
    //     {modalVisible ?
    //         <View style={stylePanelPosition()}>
    //             <ScrollView contentContainerStyle={{flex:1, alignItems: 'flex-start', /*justifyContent: 'flex-end'*/}} ref={scrollViewRef}
    //                     onContentSizeChange={() => scrollViewRef.current?.scrollToEnd({ animated: true })}>
    //                 {table.history?.map((text, index) => (
    //                     <View key={index} style={{paddingHorizontal: 15, paddingVertical:4, backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: 20, marginBottom: 5}}>
    //                         <Text style={{fontSize: 14, color: 'rgba(255, 255, 255, 0.6)'}}>{text}</Text>
    //                     </View>
    //                 ))}
    //             </ScrollView>
    //         </View>
    //     : null}
    //     <View style={{position: 'absolute', top: 0, right: 0}}>
    //         <Pressable style={{padding: 10}} onPress={() => setModalVisible(!modalVisible)}>
    //             <FontAwesome name='history' size={30} color="#FFFFFF" />
    //         </Pressable>
    //     </View>
    // </React.Fragment>
    <HistoryProvider>
      {orientation === "landscape" ? (
        <LandscapePanel />
      ) : (
        <>
          {/* <Pressable
                            style={styles.mobileTab}
                            onPress={()=>{setPanelVisible(true)}}
                        >
                            <FontAwesome name="chevron-right" size={27} color={"#FFF8"}/>
                        </Pressable> */}
          <PortraitPanel />
        </>
      )}
    </HistoryProvider>
  );
}
