import React from "react";
import { Text, View } from "react-native";
import { TableHistory, TableHistoryPotProps } from "../../../../../types";
import { monetaryValue } from "../../../../../util";
import { styles } from "./styles";

type HistoryItemProps = {
  history: TableHistory;
};

export default function HistoryItem({ history }: HistoryItemProps) {
  //const [buildHistory, setBuildHistory] = React.useState<HandHistoryProps[]>([]);
  //const [message, setMessage] = useState<string>('');

  /*React.useEffect(() => {
        setBuildHistory([]);

        let tableHistoryArray: HandHistoryProps[] = [];

        if (tableHistory && tableHistory.length > 0) {
            tableHistory.forEach(item => {
                tableHistoryArray.push({
                    handId: item.handNumber,
                    board: item.content.communityCards,
                    pots: item.content.pots
                });
            });
        }

        console.log(tableHistoryArray);
        setBuildHistory(tableHistoryArray);

    }, [tableHistory]);*/

  //const [winners, setWinners] = React.useState<string>('');
  //const [winnersCards, setWinnersCards] = React.useState<string[]>([]);

  const [handNumber, setHandNumber] = React.useState<number>(0);
  const [communityCards, setCommunityCards] = React.useState<string[]>([]);
  const [pots, setPots] = React.useState<TableHistoryPotProps[]>([]);

  //let winners = "";
  //let winnersCards: string[] = [];

  React.useEffect(() => {
    if (history) {
      setHandNumber(history.handNumber);
      setCommunityCards(history.content?.communityCards || []);
      setPots(history.content?.pots || []);
    }

    /*
        let aux_winners = "";
        let aux_winnersCards: string[] = [];

        history.content?.pots?.map((pot, index) => {
            if (pot.amount > 0) {
                let aux_winnersNames = "";
                if (pot.winners !== undefined && pot.winners !== null) {
                    pot.winners?.length > 1
                        ?
                        pot.winners?.map((winner, index) => {
                            aux_winnersNames += winner.username + ",";
                            winner.holeCards.map((card, index) => {
                                aux_winnersCards.push(`${card}`);
                            })
                        })
                        :
                        aux_winnersNames += pot.winners[0]?.username;
                    pot.winners[0]?.holeCards.map((card, index) => {
                        aux_winnersCards.push(`${card}`);
                    });
                }
                if (aux_winnersNames !== "" || aux_winnersNames !== undefined || aux_winnersNames !== "undefined") {
                    aux_winners += "\n" + aux_winnersNames + " coletou " + monetaryValue(pot.amount) + " com ";
                }
            }
        });

        setWinners(aux_winners);
        setWinnersCards(aux_winnersCards);

        */
  }, [history]);

  return (
    <View style={styles.container}>
      <View style={styles.handNumber}>
        <Text style={styles.text}>{handNumber}</Text>
      </View>
      <View>
        <Text style={styles.text}>{communityCards?.join(", ")}</Text>
      </View>
      <View>
        {pots.map((pot, index) =>
          pot.winners?.map((winner) => (
            <View key={index}>
              <Text style={styles.text}>
                {winner.username}: collected{" "}
                {monetaryValue(pot.amount / pot.winners!.length)}
              </Text>
              <Text style={styles.text}>{winner.holeCards?.join(", ")}</Text>
            </View>
          ))
        )}
      </View>
      {/*
            <View style={styles.cards}>
                {communityCards.map((card, index) => (
                    <Image key={index} source={MiniCards[card]} style={styles.card} />
                ))}
            </View>

            <View style={styles.cards}>
                <Text style={styles.text}>{winners}</Text>
                {winnersCards.map((card, cardIndex) => (
                    <Image key={cardIndex} source={MiniCards[card]} style={styles.card} />
                ))}
            </View>
            */}
    </View>
  );
}
