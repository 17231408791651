import { Platform, StyleSheet, TextStyle, ViewStyle } from "react-native";
import { useDimensions } from "../../../../../../../contexts/Dimensions";
import { getFontSize } from "../../../../../../../util/GlobalStyles";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    //alignItems: 'center',
    //width: '100%',
    //marginLeft: '20%',
    //height: '20%',
    position: "absolute",
    top: "-28%",
    //borderWidth: 2,
    //left: '10%',
    //flex: 1,
    //width: '100%',
    //height: '100%',
    //maxHeight: '100%',
    //justifyContent: 'center'
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    borderWidth: 2,
    paddingHorizontal: 16,
    paddingVertical: 6,
    borderRadius: 10,
  },
  content: {
    //flex: 1,
    //marginHorizontal: '10%',
    //marginVertical: '10%',
    width: "70%",
    marginLeft: "15%",
  },
  title: {
    //flex: 1,
    fontWeight: "bold",
    color: "#FFFFFF",
    textAlign: "center",
    //height: '100%',
    //minHeight: '100%',
    //textAlignVertical: 'center',
    //height: '100%',
    //marginTop: '7%',
    //marginHorizontal: '10%',
    //height: '100%',
    //backgroundColor: 'yellow',
  },
});

export const styleTextColor = (action: string): TextStyle => {
  return StyleSheet.flatten([styles.title, { color: getColor(action) }]);
};

export const styleContainerColor = (action: string): ViewStyle => {
  return StyleSheet.flatten([
    styles.container,
    { borderColor: getColor(action) },
  ]);
};

export const getColor = (action: string): string => {
  switch (action) {
    case "all-in":
      return "#ff0000";
    case "bet":
      return "#339AF9";
    case "call":
      return "#F7C100";
    case "check":
      return "#F7C100";
    case "fold":
      return "#918D9B";
    case "raise":
      return "#339AF9";
    case "sitout":
      return "#918D9B";
    case "left":
      return "#918D9B";
    default:
      return "rgba(0, 0, 0, 0)";
  }
};

export const styleWithFontScale = (
  fontSizeWeb: number,
  fontSizeDefault: number,
  action: string
): TextStyle => {
  return StyleSheet.flatten([
    styles.title,
    { fontSize: getFontSize(fontSizeWeb, fontSizeDefault) },
    styleTextColor(action),
  ]);
};

export function textSize(fontSizeWeb: number, fontSizeDefault: number) {
  const { orientation, width, height } = useDimensions();

  if (Platform.OS === "web") {
    if (orientation === "landscape") {
      if (height >= 780 && height <= 880) {
        fontSizeWeb -= 4;
      } else if (height >= 680 && height < 780) {
        fontSizeWeb -= 7;
      } else if (height < 680) {
        fontSizeWeb -= 12;
      }
    } else {
      if (width > 360 && width < 540) {
        fontSizeWeb -= 8;
      } else if (width <= 360) {
        fontSizeWeb -= 12;
      }
    }

    return fontSizeWeb;
  }

  return fontSizeDefault;
}

// export const getFontSize = (fontSizeWeb: number, fontSizeDefault: number): TextStyle => {

//     let font = textSize(fontSizeWeb, fontSizeDefault);

//     return {
//         fontSize: font
//     }
// }
