import React from "react";
import {Image, ImageSourcePropType, Pressable, PressableProps} from "react-native";
import {styles} from "./styles";
import BadgeIcon from "../../../../assets/images/newBadge.png";

type Props = PressableProps & {
    image: ImageSourcePropType;
    showBadge?: boolean;
}

export default function PanelButton({image, showBadge, ...rest}: Props) {

    return (
        <Pressable
            {...rest}
        >
            <Image
                style={styles.image}
                source={image}
            />
            {
                showBadge &&
                <Image
                    style={styles.badge}
                    source={BadgeIcon}
                />
            }
        </Pressable>
    )
}