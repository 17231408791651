import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    container: {
        flex: 1,
        //flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        //backgroundColor: 'cyan',
        //marginLeft: '15%',
        width: '100%',
        height: '140%',
        position: 'absolute',
        //top: '-60%',
        top: '-62%',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
});