import React from "react";
import { ImageBackground, Pressable, Text, View } from "react-native";
import { useTable } from "../../../../../contexts/Table";
import { useTranslation } from "react-i18next";

import { styleWithFontScale } from "./styles";

type Props = {
  children?: React.ReactNode;
};

export default function ({ children }: Props) {
  const { t } = useTranslation();
  const { handleCheckActionPress } = useTable();

  return (
    <Pressable
      onPress={handleCheckActionPress}
      style={({ pressed }) => [
        {
          opacity: pressed ? 1 : 1,
        },
        { width: "33.3%", height: "100%" },
      ]}
    >
      {({ pressed }) => (
        <ImageBackground
          style={{
            minWidth: "100%",
            minHeight: "100%",
            flex: 1,
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
          }}
          source={require("../../../../../assets/images/theme/default/table/action.png")}
          resizeMode={"contain"}
          fadeDuration={0}
        >
          <View style={{ marginBottom: 18 }}>
            <Text
              adjustsFontSizeToFit={true}
              style={[
                styleWithFontScale(20, 14),
                {
                  textAlign: "center",
                  textAlignVertical: "center",
                  paddingHorizontal: 10,
                },
              ]}
            >
              {t("TableScreen.Controls.CHECK")}
            </Text>
          </View>
        </ImageBackground>
      )}
    </Pressable>
  );
}
