export default {
  "2c": require("../assets/images/theme/default/cards/1/2c.png"),
  "2d": require("../assets/images/theme/default/cards/1/2d.png"),
  "2h": require("../assets/images/theme/default/cards/1/2h.png"),
  "2s": require("../assets/images/theme/default/cards/1/2s.png"),
  "3c": require("../assets/images/theme/default/cards/1/3c.png"),
  "3d": require("../assets/images/theme/default/cards/1/3d.png"),
  "3h": require("../assets/images/theme/default/cards/1/3h.png"),
  "3s": require("../assets/images/theme/default/cards/1/3s.png"),
  "4c": require("../assets/images/theme/default/cards/1/4c.png"),
  "4d": require("../assets/images/theme/default/cards/1/4d.png"),
  "4h": require("../assets/images/theme/default/cards/1/4h.png"),
  "4s": require("../assets/images/theme/default/cards/1/4s.png"),
  "5c": require("../assets/images/theme/default/cards/1/5c.png"),
  "5d": require("../assets/images/theme/default/cards/1/5d.png"),
  "5h": require("../assets/images/theme/default/cards/1/5h.png"),
  "5s": require("../assets/images/theme/default/cards/1/5s.png"),
  "6c": require("../assets/images/theme/default/cards/1/6c.png"),
  "6d": require("../assets/images/theme/default/cards/1/6d.png"),
  "6h": require("../assets/images/theme/default/cards/1/6h.png"),
  "6s": require("../assets/images/theme/default/cards/1/6s.png"),
  "7c": require("../assets/images/theme/default/cards/1/7c.png"),
  "7d": require("../assets/images/theme/default/cards/1/7d.png"),
  "7h": require("../assets/images/theme/default/cards/1/7h.png"),
  "7s": require("../assets/images/theme/default/cards/1/7s.png"),
  "8c": require("../assets/images/theme/default/cards/1/8c.png"),
  "8d": require("../assets/images/theme/default/cards/1/8d.png"),
  "8h": require("../assets/images/theme/default/cards/1/8h.png"),
  "8s": require("../assets/images/theme/default/cards/1/8s.png"),
  "9c": require("../assets/images/theme/default/cards/1/9c.png"),
  "9d": require("../assets/images/theme/default/cards/1/9d.png"),
  "9h": require("../assets/images/theme/default/cards/1/9h.png"),
  "9s": require("../assets/images/theme/default/cards/1/9s.png"),
  "Tc": require("../assets/images/theme/default/cards/1/10c.png"),
  "Td": require("../assets/images/theme/default/cards/1/10d.png"),
  "Th": require("../assets/images/theme/default/cards/1/10h.png"),
  "Ts": require("../assets/images/theme/default/cards/1/10s.png"),
  "Jc": require("../assets/images/theme/default/cards/1/Jc.png"),
  "Jd": require("../assets/images/theme/default/cards/1/Jd.png"),
  "Jh": require("../assets/images/theme/default/cards/1/Jh.png"),
  "Js": require("../assets/images/theme/default/cards/1/Js.png"),
  "Qc": require("../assets/images/theme/default/cards/1/Qc.png"),
  "Qd": require("../assets/images/theme/default/cards/1/Qd.png"),
  "Qh": require("../assets/images/theme/default/cards/1/Qh.png"),
  "Qs": require("../assets/images/theme/default/cards/1/Qs.png"),
  "Kc": require("../assets/images/theme/default/cards/1/Kc.png"),
  "Kd": require("../assets/images/theme/default/cards/1/Kd.png"),
  "Kh": require("../assets/images/theme/default/cards/1/Kh.png"),
  "Ks": require("../assets/images/theme/default/cards/1/Ks.png"),
  "Ac": require("../assets/images/theme/default/cards/1/Ac.png"),
  "Ad": require("../assets/images/theme/default/cards/1/Ad.png"),
  "Ah": require("../assets/images/theme/default/cards/1/Ah.png"),
  "As": require("../assets/images/theme/default/cards/1/As.png"),
  "CardBack": require("../assets/images/theme/default/cards/1/cardback.png"),
  "cardback": require("../assets/images/theme/default/cards/1/cardback.png"),
} as any;
