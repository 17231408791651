import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    container: {
        flex: 1,
        //alignItems: 'center',
        //justifyContent: 'center',
        //backgroundColor: 'blue',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
});