import React from 'react';
import { MotiImage, MotiView, useAnimationState } from 'moti';
import { Image, View } from 'react-native';
import Cards1 from '../../../../../../../../collections/Cards1';
import Cards2 from '../../../../../../../../collections/Cards2';
import Cards3 from '../../../../../../../../collections/Cards3';
import { styleCardPosition, styles } from './styles';

type Props = {
    frontDeck: number;
    seatNumber: number;
    card: string;
    cardNumber: number;
    numberOfCards: number;
    dealAnimation?: boolean;
    showDownAnimation?: boolean;
    foldAnimation?: boolean;
    currentPlayer?: boolean;
    grayOut?: boolean;
}

export default React.memo(function ({
    frontDeck,
    seatNumber,
    card,
    cardNumber,
    numberOfCards,
    currentPlayer,
    grayOut,
    showDownAnimation,
    foldAnimation,
    dealAnimation
}: Props) {

    const [Cards, setCards] = React.useState<any>(Cards1);

    const showDownAnimationStates = useAnimationState({
        from: {
            transform: [{ rotateY: '90deg' }]
        },
        to: {
            transform: [{ rotateY: '0deg' }]
        }
    })

    const foldAnimationStates = useAnimationState({
        from: {
            top: "0%",
            opacity: 1,
        },
        to: {
            top: "5%",
            opacity: 1,
        },
        finish: {
            top: "5%",
            opacity: 0
        },
        restart: {
            top: '0%',
            opacity: 0
        }
    })

    const handleFoldAnimationReset = () => {
        if (foldAnimationStates.current === 'to') {
            foldAnimationStates.transitionTo('finish');
        }
    }

    const renderCards = (animation: string) => {
        const cardOffset = { top: currentPlayer ? '-10%' : 0 };
        switch (animation) {
            case 'showDown':
                return (
                    <MotiView
                        style={styles.image}
                        state={showDownAnimationStates}
                        from={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ type: 'timing', duration: 250 }}
                    >
                        <Image
                            resizeMode={'contain'}
                            style={[styles.image]}
                            source={Cards[card]}
                            fadeDuration={0}
                        />
                    </MotiView>
                )
            case 'fold':
                return (
                    <MotiView
                        style={[styles.image]}
                        state={foldAnimationStates}
                        transition={{ type: 'timing', duration: 500 }}
                        onDidAnimate={handleFoldAnimationReset}
                    >
                        <Image
                            resizeMode={'contain'}
                            style={[styles.image]}
                            source={Cards["CardBack"]}
                            fadeDuration={0}
                        />
                    </MotiView>
                )
            case 'normal':
                return (
                    <>
                        <Image
                            resizeMode={'contain'}
                            style={[styles.image, cardOffset]}
                            source={Cards[card]}
                            fadeDuration={0}
                        />
                        {
                            grayOut &&
                            <Image
                                source={require('../../../../../../../../assets/images/theme/default/card-grayout.png')}
                                style={[styles.image, { opacity: 0.5 }]}
                                resizeMode={'contain'}
                                fadeDuration={0}
                            />
                        }
                    </>
                )
            case 'deal':
                const delayAmount = ((seatNumber * 100) + (cardNumber * 100) + 300);
                return (
                    <MotiImage
                        resizeMode={'contain'}
                        style={[styles.image, cardOffset]}
                        source={Cards[card]}
                        from={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ type: 'timing', duration: 1 }}
                        delay={delayAmount}
                    />
                )
            default:
                return (
                    <></>
                )
        }
    }

    React.useEffect(() => {
        if (foldAnimationStates.current === 'finish') {
            foldAnimationStates.transitionTo('restart');
        }
    }, [card]);

    React.useEffect(() => {
        if (frontDeck === 2) {
            setCards(Cards2);
        } else if (frontDeck === 3) {
            setCards(Cards3);
        } else {
            setCards(Cards1);
        }
       }, [frontDeck]);

    return (
        <View style={styleCardPosition(cardNumber, numberOfCards)}>
            {
                showDownAnimation ? (
                    renderCards('showDown')
                ) : (
                    foldAnimation ? (
                        renderCards('fold')
                    ) : (
                        dealAnimation ? (
                            renderCards('deal')
                        ) : (
                            renderCards('normal')
                        )
                    )
                )
            }
        </View>
    );
})