import { StyleSheet, ViewStyle } from "react-native";
import { useDimensions } from "../../../../../../../../contexts/Dimensions";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: 'pink',
    position: "absolute",
  },
  image: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
});

export const styleCardPosition = (
  cardNumber: number,
  numberOfCards: number
): ViewStyle => {
  return StyleSheet.flatten([
    styles.container,
    getCardPosition(cardNumber, numberOfCards),
  ]);
};

export const getCardPosition = (
  cardNumber: number,
  numberOfCards: number
): ViewStyle => {
  const { orientation } = useDimensions();

  switch (orientation) {
    case "landscape":
      switch (numberOfCards) {
        case 2:
          switch (cardNumber) {
            case 0:
              return {
                width: "100%",
                height: "100%",
                left: "-14%",
                top: "-10%",
                transform: [{ rotate: "-8deg" }],
              };
            case 1:
              return {
                width: "100%",
                height: "100%",
                right: "-14%",
                top: "-10%",
                transform: [{ rotate: "8deg" }],
              };
            default:
              return {
                display: "none",
              };
          }
        case 4:
          switch (cardNumber) {
            case 0:
              return {
                width: "90%",
                height: "90%",
                left: "-17%",
                top: "-4%",
                transform: [{ rotate: "-15deg" }],
              };
            case 1:
              return {
                width: "90%",
                height: "90%",
                left: "-3.5%",
                top: "-7%",
                transform: [{ rotate: "-6deg" }],
              };
            case 2:
              return {
                width: "90%",
                height: "90%",
                left: "10%",
                top: "-7%",
                transform: [{ rotate: "6deg" }],
              };
            case 3:
              return {
                width: "90%",
                height: "90%",
                left: "26%",
                top: "-4%",
                transform: [{ rotate: "15deg" }],
              };
            default:
              return {
                display: "none",
              };
          }
        case 5:
          switch (cardNumber) {
            case 0:
              return {
                width: "90%",
                height: "90%",
                left: "-24%",
                top: "0%",
                transform: [{ rotate: "-30deg" }],
              };
            case 1:
              return {
                width: "90%",
                height: "90%",
                left: "-9%",
                top: "-11%",
                transform: [{ rotate: "-15deg" }],
              };
            case 2:
              return {
                width: "90%",
                height: "90%",
                left: "6%",
                top: "-14%",
                transform: [{ rotate: "0deg" }],
              };
            case 3:
              return {
                width: "90%",
                height: "90%",
                left: "20%",
                top: "-10%",
                transform: [{ rotate: "10deg" }],
              };
            case 4:
              return {
                width: "90%",
                height: "90%",
                left: "34%",
                top: "0%",
                transform: [{ rotate: "30deg" }],
              };
            default:
              return {
                display: "none",
              };
          }
        default:
          return {
            display: "none",
          };
      }
    case "portrait":
      switch (numberOfCards) {
        case 2:
          switch (cardNumber) {
            case 0:
              return {
                width: "100%",
                height: "100%",
                left: "-14%",
                top: "-10%",
                transform: [{ rotate: "-8deg" }],
              };
            case 1:
              return {
                width: "100%",
                height: "100%",
                right: "-14%",
                top: "-10%",
                transform: [{ rotate: "8deg" }],
              };
            default:
              return {
                display: "none",
              };
          }
        case 4:
          switch (cardNumber) {
            case 0:
              return {
                width: "90%",
                height: "90%",
                left: "-17%",
                top: "-4%",
                transform: [{ rotate: "-15deg" }],
              };
            case 1:
              return {
                width: "90%",
                height: "90%",
                left: "-3.5%",
                top: "-7%",
                transform: [{ rotate: "-6deg" }],
              };
            case 2:
              return {
                width: "90%",
                height: "90%",
                left: "10%",
                top: "-7.5%",
                transform: [{ rotate: "6deg" }],
              };
            case 3:
              return {
                width: "90%",
                height: "90%",
                left: "26%",
                top: "-4%",
                transform: [{ rotate: "15deg" }],
              };
            default:
              return {
                display: "none",
              };
          }
        case 5:
          switch (cardNumber) {
            case 0:
              return {
                width: "90%",
                height: "90%",
                left: "-20%",
                top: "-2%",
                transform: [{ rotate: "-30deg" }],
              };
            case 1:
              return {
                width: "90%",
                height: "90%",
                left: "-9%",
                top: "-11%",
                transform: [{ rotate: "-15deg" }],
              };
            case 2:
              return {
                width: "90%",
                height: "90%",
                left: "6%",
                top: "-14%",
                transform: [{ rotate: "0deg" }],
              };
            case 3:
              return {
                width: "90%",
                height: "90%",
                left: "20%",
                top: "-10%",
                transform: [{ rotate: "10deg" }],
              };
            case 4:
              return {
                width: "90%",
                height: "90%",
                left: "32%",
                top: "0%",
                transform: [{ rotate: "30deg" }],
              };
            default:
              return {
                display: "none",
              };
          }
        default:
          return {
            display: "none",
          };
      }
    default:
      return {
        display: "none",
      };
  }
};
