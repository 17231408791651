import React from "react";
import { Text, View } from "react-native";
import { useGameOptions } from "../../../contexts/GameOptions";
import { useTable } from "../../../contexts/Table";
import { monetaryValue } from "../../../util";
import { useTranslation } from "react-i18next";

import { styleTotalPotPosition, styleWithFontScale } from "./styles";

type Props = {
  totalPot: number;
};

export default function ({ totalPot }: Props) {
  const { t } = useTranslation();
  const { table } = useTable();
  const { showBigBlind } = useGameOptions();
  const tableBB = table?.bigBlind;
  const totalPotLabel = showBigBlind
    ? `${Math.round((totalPot / tableBB) * 10) / 10} BB`
    : monetaryValue(totalPot);

  if (totalPot === 0) {
    return null;
  }

  return (
    <View style={styleTotalPotPosition()}>
      <Text style={styleWithFontScale(20, 18)}>
        {t("TableScreen.tableComponents.potAmount", { value: totalPotLabel })}
      </Text>
    </View>
  );
}
