import React from "react";
import {Image, ImageSourcePropType, Pressable, PressableProps, Text} from "react-native";
import {styles} from "./styles";

type Props = PressableProps & {
    id: string;
    name: string;
    code: string;
    sprite: ImageSourcePropType;
}

export default ({id, name, code, sprite, ...rest}: Props) => {
    return (
        <Pressable
            style={({pressed}) => styles.container}
            {...rest}
        >
            <Image
                source={sprite}
                style={[styles.image]}
                fadeDuration={0}
            />
            <Text style={{color: "white"}}>{name}</Text>
        </Pressable>
    )
}