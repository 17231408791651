import { StyleSheet } from "react-native";
import { theme } from "../../../../util/GlobalStyles";

export const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
  },
  panel: {
    flexDirection: "row",
    position: "absolute",
    left: 0,
    bottom: "0%",
    width: 490,
    height: "90%",
    backgroundColor: theme.colors.background100,
    alignSelf: "flex-start",
    borderWidth: 1,
    borderColor: theme.colors.background90,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    opacity: 0.8,
  },
  contentArea: {
    backgroundColor: "#FFF2",
    flex: 1,
  },
  contentText: {
    color: "#FFF6",
    fontSize: 14,
  },
  closeTab: {
    backgroundColor: theme.colors.background100,
    height: "100%",
    maxWidth: 40,
    borderLeftWidth: 0,
    borderRightWidth: 1,
    borderTopEndRadius: 16,
    justifyContent: "flex-end",
    borderColor: "#FFF3",
    marginRight: -15,
  },
});
