import AsyncStorage from "@react-native-async-storage/async-storage";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import { ActivityIndicator, Platform, View } from "react-native";
import EmojiCollection from "../constants/EmojiCollection";
import {
  StackProps,
  TableEmoji,
  TransactionProps,
  UserProps,
  WalletProps,
} from "../types";
import {
  PERSISTENCE_KEY_ACCESS_TOKEN,
  PERSISTENCE_KEY_REFRESH_TOKEN,
  useWebSocket,
} from "./WebSocket";

type AccountContextProps = {
  signed: boolean;
  depositSaveValue: string;
  onChangeDepositSaveValue: Dispatch<SetStateAction<string>>;
  user: UserProps | undefined;
  data: PaymentData | undefined;
  setData: (value: PaymentData | undefined) => void;
  wallet: WalletProps | undefined;
  stack: StackProps | undefined;
  signIn: (email: string, password: string) => void;
  signUp: (username: string, email: string, newPassword: string) => void;
  personalInfo: (
    name: string,
    document: string,
    address: string,
    city: string,
    state: string,
    country: string,
    zipcode: string,
    phoneNumber: string,
    birthDate: string,
    acceptedTerms: boolean,
    emailMarketing: boolean
  ) => void;
  changeAvatar: (avatar: string) => void;
  verificationCode: (email: string) => void;
  resetPassword: (
    email: string,
    verificationCode: string,
    newPassword: string
  ) => void;
  signOut: () => void;
  handlePayment: (
    amount: number,
    type: string,
    name: string,
    cpf: string
  ) => void;
  handleWithdraw: (
    amount: number,
    type: string,
    name: string,
    document: string
  ) => void;
  error: string[] | undefined;
  setError: (value: string[] | undefined) => void;
  emojiList?: TableEmoji[] | any;
  success: boolean;
  setSuccess: (value: boolean) => void;
  changeAddress: (
    address: string,
    city: string,
    state: string,
    country: string,
    zipcode: string
  ) => void;
  changePassword: (currentPassword: string, newPassword: string) => void;
  changeEmailPhone: (email: string, phoneNumber: string) => void;
  providerAuth: () => void;
  transactions: TransactionProps[];
  setTransactions: (value: TransactionProps[]) => void;
};

export const AccountContext = createContext({} as AccountContextProps);

type AccountProviderProps = {
  children: React.ReactNode;
};

export const PERSISTENCE_KEY_SIGNED_USER = "SIGNED_USER";
export const PERSISTENCE_KEY_WALLET = "WALLET";

export type StatusResponse = {
  status: "OK" | "NOK";
  error: string[] | undefined;
};

export type AccountCallbackResponse = {
  status: "OK" | "NOK";
  data: {
    user: UserProps;
    //wallet: WalletProps;
    accessToken: string;
    refreshToken: string;
  };
  error: string[] | undefined;
};

export type VerificationCodeCallbackResponse = {
  status: "OK" | "NOK";
  error: string[] | undefined;
};

export type PaymentCallbackResponse = {
  status: "OK" | "NOK";
  data: {
    qr_code_url?: string;
    pix_key?: string;
    favoured?: string;
    external_reference: string;
    checkout_url?: string;
  };
  error: string[] | undefined;
};

export type PaymentData = {
  qr_code_url?: string;
  pix_key?: string;
  favoured?: string;
  external_reference: string;
  checkout_url?: string;
};

export function AccountProvider({ children }: AccountProviderProps) {
  const { socket, refresh } = useWebSocket();
  const [isReady, setIsReady] = React.useState<boolean>(false);
  const [signed, setSigned] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<UserProps | undefined>(
    {} as UserProps
  );
  const [data, setData] = React.useState<PaymentData | undefined>(
    {} as PaymentData
  );

  const [updateTablesInterval, setUpdateTablesInterval] = React.useState<
    NodeJS.Timer | undefined
  >();

  const [updateWalletInterval, setUpdateWalletInterval] = React.useState<
    NodeJS.Timer | undefined
  >();
  const [wallet, setWallet] = React.useState<WalletProps | undefined>(
    {} as WalletProps
  );
  const [transactions, setTransactions] = React.useState<TransactionProps[]>(
    []
  );

  const [stack, setStack] = React.useState<StackProps>({} as StackProps);
  const [error, setError] = React.useState<string[] | undefined>(undefined);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [depositSaveValue, onChangeDepositSaveValue] =
    React.useState<string>("40,00");

  const emojiList = EmojiCollection;

  const me = async (response: AccountCallbackResponse) => {
    //console.log(response.data);

    if (response.status === "OK") {
      setError(undefined);
      setSigned(true);
      setUser(response.data.user);
      //setWallet(response.data.wallet);

      await AsyncStorage.multiSet([
        [PERSISTENCE_KEY_ACCESS_TOKEN, response.data.accessToken],
        [PERSISTENCE_KEY_REFRESH_TOKEN, response.data.refreshToken],
      ]);

      //refresh();
    } else {
      setSigned(false);
      setUser(undefined);
      //setWallet(undefined);
      setError(response.error);

      await AsyncStorage.multiRemove([
        PERSISTENCE_KEY_ACCESS_TOKEN,
        PERSISTENCE_KEY_REFRESH_TOKEN,
      ]);

      refresh();
      //console.log("Error: " + response.error);
    }
  };

  const signIn = React.useCallback(
    async (email: string, password: string) => {
      if (socket.connected) {
        setError(undefined);

        socket.emit(
          "sign in",
          { email, password },
          async (response: AccountCallbackResponse) => {
            //console.log(response.status); // OK / NOK
            //console.log(response.data); // {}
            //console.log(response.error); // {}

            if (response.status === "OK") {
              setSigned(true);
              setUser(response.data.user);
              //setWallet(response.data.wallet);

              //const jsonUser = JSON.stringify(response.data?.user);
              //const jsonWallet = JSON.stringify(response.data?.wallet);

              await AsyncStorage.multiSet([
                [PERSISTENCE_KEY_ACCESS_TOKEN, response.data.accessToken],
                [PERSISTENCE_KEY_REFRESH_TOKEN, response.data.refreshToken],
              ]);

              refresh();

              //console.log("start wallet1");
              socket.emit("wallet");

              if (Platform.OS === "web") {
                try {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "Usuario_Logado",
                  });
                } catch (e: any) {
                  console.log(e);
                }
              }
            } else {
              setSigned(false);
              setUser(undefined);
              //setWallet(undefined);
              setError(response.error);
              //console.log("Error: " + response.error);
            }
          }
        );
      }

      //if (socket.connected) {
      //    socket.emit("message", "signIn");
      //}
    },
    [socket]
  );

  const signUp = React.useCallback(
    async (username: string, email: string, newPassword: string) => {
      if (socket.connected) {
        setError(undefined);

        socket.emit(
          "sign up",
          { username, email, newPassword },
          async (response: AccountCallbackResponse) => {
            //console.log(response.status); // OK / NOK
            //console.log(response.data); // {}
            //console.log(response.error); // {}

            if (response.status === "OK") {
              setSigned(true);
              setUser(response.data.user);
              //setWallet(response.data.wallet);

              //const jsonUser = JSON.stringify(response.data.user);
              //const jsonWallet = JSON.stringify(response.data?.wallet);

              await AsyncStorage.multiSet([
                [PERSISTENCE_KEY_ACCESS_TOKEN, response.data.accessToken],
                [PERSISTENCE_KEY_REFRESH_TOKEN, response.data.refreshToken],
              ]);

              refresh();

              if (Platform.OS === "web") {
                try {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "Novo_Cadastro_Realizado",
                  });

                  window.dataLayer.push({
                    event: "Usuario_Logado",
                  });
                } catch (e: any) {
                  console.log(e);
                }
              }
            } else {
              setSigned(false);
              setUser(undefined);
              //setWallet(undefined);
              setError(response.error);
              //console.log("Error: " + response.error);
            }
          }
        );
        //console.log("signUp message function");

        //socket.emit("message", "signUp");
      }
    },
    [socket]
  );

  const personalInfo = React.useCallback(
    async (
      name: string,
      document: string,
      address: string,
      city: string,
      state: string,
      country: string,
      zipcode: string,
      phoneNumber: string,
      birthDate: string,
      acceptedTerms: boolean,
      emailMarketing: boolean
    ) => {
      if (socket.connected) {
        setError(undefined);

        socket.emit(
          "personal info",
          {
            name,
            document,
            address,
            city,
            state,
            country,
            zipcode,
            phoneNumber,
            birthDate,
            acceptedTerms,
            emailMarketing,
          },
          async (response: AccountCallbackResponse) => {
            //console.log(response.status); // OK / NOK
            //console.log(response.data); // {}
            //console.log(response.error); // {}

            if (response.status === "OK") {
              setSigned(true);
              setUser(response.data.user);
              //setWallet(response.data.wallet);

              //const jsonUser = JSON.stringify(response.data.user);

              /*
                    await AsyncStorage.multiSet([
                        [PERSISTENCE_KEY_SIGNED_USER, jsonUser],
                        [PERSISTENCE_KEY_ACCESS_TOKEN, response.data.accessToken],
                        [PERSISTENCE_KEY_REFRESH_TOKEN, response.data.refreshToken]
                    ]);
                    */

              //refresh();

              if (Platform.OS === "web") {
                try {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "Confirmacao_Do_Cadastro_Realizado",
                  });
                } catch (e: any) {
                  console.log(e);
                }
              }
            } else {
              //setSigned(false);
              //setUser(undefined);
              setError(response.error);
              //console.log("Error: " + response.error);
            }
          }
        );
        //console.log("personalInfo message function");

        //socket.emit("message", "signUp");
      }
    },
    [socket]
  );

  const changeAvatar = React.useCallback(
    async (avatar: string) => {
      if (socket.connected) {
        setError(undefined);

        socket.emit(
          "user avatar update",
          { avatar },
          async (response: AccountCallbackResponse) => {
            if (response.status === "OK") {
              setSigned(true);
              setUser(response.data.user);
            } else {
              setError(response.error);
            }
          }
        );
      }
    },
    [socket]
  );

  const verificationCode = React.useCallback(
    async (email: string) => {
      if (socket.connected) {
        socket.emit(
          "verification code",
          { email },
          async (response: VerificationCodeCallbackResponse) => {
            if (response.status === "OK") {
            } else {
            }
          }
        );
      }
    },
    [socket]
  );

  const resetPassword = React.useCallback(
    async (email: string, verificationCode: string, newPassword: string) => {
      if (socket.connected) {
        //console.log(email, verificationCode, newPassword);

        setError(undefined);

        socket.emit(
          "reset password",
          { email, verificationCode, newPassword },
          async (response: AccountCallbackResponse) => {
            if (response.status === "OK") {
              setSigned(true);
              setUser(response.data.user);
              //setWallet(response.data.wallet);

              await AsyncStorage.multiSet([
                [PERSISTENCE_KEY_ACCESS_TOKEN, response.data.accessToken],
                [PERSISTENCE_KEY_REFRESH_TOKEN, response.data.refreshToken],
              ]);

              //refresh();
            } else {
              setSigned(false);
              setUser(undefined);
              //setWallet(undefined);
              setError(response.error);
            }
          }
        );
      }
    },
    [socket]
  );

  const changeAddress = React.useCallback(
    async (
      address: string,
      city: string,
      state: string,
      country: string,
      zipcode: string
    ) => {
      if (socket.connected) {
        setSuccess(false);
        setError(undefined);

        socket.emit(
          "change_address",
          {
            address,
            city,
            state,
            country,
            zipcode,
          },
          async (response: StatusResponse) => {
            if (response.status === "OK") {
              setSuccess(true);
              socket.emit("me", me);
            } else {
              setError(response.error);
            }
          }
        );
      }
    },
    [socket]
  );

  const providerAuth = React.useCallback(async () => {
    if (socket.connected) {
      //console.log("me");
      socket.emit("me", me);
    }
  }, [socket]);

  const changePassword = React.useCallback(
    async (currentPassword: string, newPassword: string) => {
      if (socket.connected) {
        setSuccess(false);
        setError(undefined);
        socket.emit(
          "change_password",
          { currentPassword, newPassword },
          async (response: StatusResponse) => {
            if (response.status === "OK") {
              setSuccess(true);
              socket.emit("me", me);
            } else {
              setError(response.error);
            }
          }
        );
      }
    },
    [socket]
  );

  const changeEmailPhone = React.useCallback(
    async (email: string, phoneNumber: string) => {
      if (socket.connected) {
        setSuccess(false);
        setError(undefined);
        socket.emit(
          "change_email_phone",
          { email, phoneNumber },
          async (response: StatusResponse) => {
            if (response.status === "OK") {
              setSuccess(true);
              socket.emit("me", me);
            } else {
              setError(response.error);
            }
          }
        );
      }
    },
    [socket]
  );

  const signOut = React.useCallback(async () => {
    await AsyncStorage.multiRemove([
      PERSISTENCE_KEY_ACCESS_TOKEN,
      PERSISTENCE_KEY_REFRESH_TOKEN,
    ]);

    setSigned(false);
    setUser(undefined);

    refresh();

    //console.log("refresh tables");
    socket.emit("tables");
    //console.log("signOut message function");
    //if (socket.connected) {
    //    socket.emit("message", "signOut");
    //}
  }, [socket]);

  const handlePayment = React.useCallback(
    async (amount: number, type: string, name: string, cpf: string) => {
      if (socket.connected) {
        setError(undefined);

        socket.emit(
          "pagstar",
          { amount, type, name, cpf },
          async (response: PaymentCallbackResponse) => {
            //console.log(response.status); // OK / NOK
            //console.log(response.data); // {}
            //console.log(response.error); // {}

            if (response.status === "OK") {
              setData(response.data);
            } else {
              setError(response.error);
              //console.log("Error: " + response.error);
            }
          }
        );
      }

      //console.log("pix message function");
      //if (socket.connected) {
      //    socket.emit("message", "signIn");
      //}
    },
    [socket]
  );

  const handleWithdraw = React.useCallback(
    async (amount: number, type: string, name: string, document: string) => {
      if (socket.connected) {
        setError(undefined);

        socket.emit("withdraw", amount, type, name, document);
        /*
            socket.emit("withdraw", { amount }, async (response: PaymentCallbackResponse) => {
                console.log(response.status); // OK / NOK
                console.log(response.data); // {}
                console.log(response.error); // {}

                if (response.status === "OK") {
                    setData(response.data);
                } else {
                    setError(response.error);
                    console.log("Error: " + response.error);
                }
            });
            */
      }

      //console.log("withdraw message function");
      //if (socket.connected) {
      //    socket.emit("message", "signIn");
      //}
    },
    [socket]
  );

  React.useEffect(() => {
    if (socket.connected) {
      socket.on("wallet.balance_changed", (data: WalletProps) => {
        setWallet(data);
      });

      socket.on("transactions", (data: TransactionProps[]) => {
        //console.log(data);
        setTransactions(data);
      });

      socket.on("stand_up.stack_size", (data: StackProps) => {
        //console.log(data);
        setStack(data);
      });
    }
  }, [socket]);

  React.useEffect(() => {
    if (socket.connected) {
      const restoreState = async () => {
        //console.log("restoreState");

        try {
          const accessToken = await AsyncStorage.getItem(
            PERSISTENCE_KEY_ACCESS_TOKEN
          );

          if (accessToken) {
            socket.emit("me", me);
            socket.emit("wallet");
          }
        } finally {
          setIsReady(true);
        }
      };

      if (!isReady) {
        restoreState();
      }
    }
  }, [isReady, socket]);

  React.useEffect(() => {
    if (socket.connected) {
      if (updateWalletInterval) {
        clearInterval(updateWalletInterval);
        setUpdateWalletInterval(undefined);
      }

      if (signed) {
        socket.emit("wallet");
        //console.log("start wallet!");

        setUpdateWalletInterval(
          setInterval(() => {
            socket.emit("wallet");
            //console.log("update wallet!");
          }, 10000)
        );
      }
    } else {
      if (updateWalletInterval) {
        clearInterval(updateWalletInterval);
        setUpdateWalletInterval(undefined);
      }
    }

    return () => {
      clearInterval(updateWalletInterval);
      setUpdateWalletInterval(undefined);
    };
  }, [signed, socket]);

  React.useEffect(() => {
    if (socket.connected) {
      if (updateTablesInterval) {
        clearInterval(updateTablesInterval);
        setUpdateTablesInterval(undefined);
      }

      socket.emit("tables");
      //console.log("start tables!");

      setUpdateTablesInterval(
        setInterval(() => {
          socket.emit("tables");
          //console.log("update tables!");
        }, 60000)
      );
    } else {
      if (updateTablesInterval) {
        clearInterval(updateTablesInterval);
        setUpdateTablesInterval(undefined);
      }
    }

    return () => {
      clearInterval(updateTablesInterval);
      setUpdateTablesInterval(undefined);
    };
  }, [signed, socket]);

  if (!isReady) {
    return (
      <View
        style={{
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
          backgroundColor: "black",
        }}
      >
        <ActivityIndicator />
      </View>
    );
  }

  return (
    <AccountContext.Provider
      value={{
        signed,
        depositSaveValue,
        onChangeDepositSaveValue,
        user,
        data,
        setData,
        wallet,
        stack,
        signIn,
        signUp,
        personalInfo,
        changeAvatar,
        verificationCode,
        resetPassword,
        signOut,
        handlePayment,
        handleWithdraw,
        error,
        setError,
        emojiList,
        success,
        setSuccess,
        changeAddress,
        changePassword,
        changeEmailPhone,
        providerAuth,
        transactions,
        setTransactions,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export function useAccount() {
  const context = useContext(AccountContext);

  if (context === undefined) {
    throw new Error("Context provider undefined.");
  }

  return context;
}
