import React from "react";
import { Text, View } from "react-native";
import { ChatMessageProps } from "../../../../../types";
import { styles } from "./styles";


export default function ChatMessage({ user, content, createdAt }: ChatMessageProps) {
    return (
        <View style={styles.container}>
            <Text style={[styles.text, , { alignSelf: "flex-start" }]}>{`(${user.username}): `}</Text>
            <Text style={[styles.text, { flex: 1 }]}>{content}</Text>
            <Text
                style={[styles.text, { alignSelf: "flex-end" }]}>{` (${new Date(createdAt).toLocaleTimeString()})`}</Text>
        </View>
    )
}