import { ScrollView } from "moti";
import React from "react";
import {
  ActivityIndicator,
  Button,
  NativeSyntheticEvent,
  Pressable,
  Text,
  TextInput,
  TextInputKeyPressEventData,
  View,
} from "react-native";
import { useAccount } from "../../../contexts/Account";
import Modal from "../Modal";
import SignUp from "../SignUp";
//import Joi, {ValidationError} from "joi";
import { styles, styleWithFontScale } from "./styles";
import { useTranslation } from "react-i18next";
import ResetPassword from "../ResetPassword";

type Props = {
  showButton: boolean;
  modalVisibleExternal?: boolean;
  setModalVisibleExternal?: (modalVisibleExternal: boolean) => void;
};

export default function ({
  showButton,
  modalVisibleExternal,
  setModalVisibleExternal,
}: Props) {
  const { t } = useTranslation();
  //const navigation = useNavigation();
  //const { signed } = useAccount();
  //const {table, currentPlayer} = useWebSocket();

  const { signIn, error } = useAccount();

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);

  //const [modalVisibleExternal, setModalVisibleExternal] = React.useState<boolean>(false);

  const nextFocus = React.useRef<TextInput>(null);

  const focusNextField = () => {
    if (nextFocus.current != null) {
      nextFocus.current.focus();
    }
  };

  //const [error, setError] = React.useState<ValidationError | undefined>();
  const [email, onChangeEmail] = React.useState<string>("");
  const [password, onChangePassword] = React.useState<string>("");

  const [loading, setLoading] = React.useState<boolean>(false);

  //const userSignInSchema = Joi.object({
  //    email: Joi.string().email({ tlds: { allow: false } }).required(),
  //    password: Joi.string().min(6).max(20).required(),
  //});

  const signInSubmit = () => {
    setLoading(true);
    //const { error } = userSignInSchema.validate({email, password});
    //if (error) {
    //  setError(error);
    //} else {
    signIn(email, password);
    //}
  };

  const handleKeyboardEvent = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    if (e.nativeEvent.key === "Enter") {
      signInSubmit();
    }
  };

  React.useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  React.useEffect(() => {
    if (!showButton && modalVisibleExternal !== undefined) {
      setModalVisible(modalVisibleExternal);
    }
  }, [modalVisibleExternal]);

  React.useEffect(() => {
    if (!showButton && setModalVisibleExternal !== undefined) {
      setModalVisibleExternal(modalVisible);
    }
  }, [modalVisible]);

  return (
    <React.Fragment>
      <Modal
        title={t("ModalSignIn.signIn")}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      >
        <View style={{ marginBottom: 10 }}>
          {loading ? <ActivityIndicator /> : <></>}
          {error?.map((err, index) => (
            <Text key={index} style={{ color: "#FFFFFF" }}>{`${
              index + 1
            }. ${err}`}</Text>
          ))}
        </View>
        <ScrollView>
          <View style={{ flex: 1, flexDirection: "column" }}>
            <View>
              <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                {t("ModalSignIn.email")}
              </Text>
              <TextInput
                textContentType="emailAddress"
                keyboardType="email-address"
                onSubmitEditing={() => focusNextField()}
                style={styles.textInput}
                placeholderTextColor={"#cccccc"}
                onChangeText={onChangeEmail}
                onKeyPress={handleKeyboardEvent}
                value={email}
              />
            </View>

            <View>
              <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                {t("ModalSignIn.password")}
              </Text>
              <TextInput
                textContentType="password"
                clearTextOnFocus={true}
                secureTextEntry={true}
                ref={nextFocus}
                style={styles.textInput}
                placeholderTextColor={"#cccccc"}
                onChangeText={onChangePassword}
                onKeyPress={handleKeyboardEvent}
                value={password}
              />
            </View>

            <View style={{ paddingTop: 5, paddingBottom: 15, paddingLeft: 2 }}>
              <ResetPassword showButton={true} />
            </View>

            <View style={{ marginBottom: 5 }}>
              {/*<Button title='Login' onPress={signInSubmit} />*/}

              <Pressable style={styles.button} onPress={signInSubmit}>
                <Text style={[styleWithFontScale(20, 14)]}>
                  {t("ModalSignIn.login")}
                </Text>
              </Pressable>

              <SignUp showButton={true} />
            </View>
          </View>
        </ScrollView>
      </Modal>
      {showButton && (
        <Pressable style={styles.button} onPress={() => setModalVisible(true)}>
          <Text style={[styleWithFontScale(20, 14)]}>
            {t("ModalSignIn.login")}
          </Text>
        </Pressable>
      )}
    </React.Fragment>
  );
}
