import { FontAwesome } from "@expo/vector-icons";
import React from "react";
import { Modal, Pressable, View } from "react-native";
import EmojiCollection from "../../../../constants/EmojiCollection";
import { useTable } from "../../../../contexts/Table";
import ChatPanel from "../ChatPanel";
import EmojiPanel from "../EmojiPanel";
import HistoryPanel from "../HistoryPanel";
import InfoPanel from "../InfoPanel";
import TabBar from "../TabBar";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";
import { TableEmoji } from "../../../../types";

export default function LandscapePanel() {
  const { t } = useTranslation();

  const {
    panelVisible,
    setPanelVisible,
    panelSelectedTab,
    setPanelSelectedTab,
  } = useTable();

  const handleTabSelectionChange = (value: number) => {
    setPanelSelectedTab(value);
  };

  const tabs = [
    {
      id: 0,
      title: t("TableScreen.panel.info"),
      isActive: false,
      onPress: () => {},
    },
    {
      id: 1,
      title: t("TableScreen.panel.chat"),
      isActive: false,
      onPress: () => {},
    },
    {
      id: 2,
      title: t("TableScreen.panel.history"),
      isActive: false,
      onPress: () => {},
    },
    {
      id: 3,
      title: t("TableScreen.panel.emoji"),
      isActive: false,
      onPress: () => {},
    },
  ];

  const userEmojis = ["a0", "a1", "a2", "a3", "a4"];
  const emojiCollection: TableEmoji[] = EmojiCollection();

  const demoEmojis = userEmojis.map(
    (code, item) => emojiCollection.filter((item) => item.code === code)[0]
  );

  function renderpanel() {
    switch (panelSelectedTab) {
      case 0:
        return <InfoPanel />;
      case 1:
        return <ChatPanel />;
      case 2:
        return <HistoryPanel />;
      case 3:
        return <EmojiPanel emojiList={demoEmojis} />;
    }
  }

  return (
    <Modal
      style={{ width: "100%", height: "100%" }}
      onRequestClose={() => setPanelVisible(false)}
      visible={panelVisible}
      transparent
      animationType="none"
    >
      <Pressable
        style={styles.container}
        onPress={() => setPanelVisible(false)}
      >
        <Pressable style={[styles.panel]} onPress={() => {}}>
          <View style={{ flex: 1 }}>
            <TabBar
              tabs={tabs}
              activeTab={panelSelectedTab}
              onTabClicked={handleTabSelectionChange}
            />
            {renderpanel()}
          </View>
          {panelVisible && (
            <View style={styles.closeTab}>
              <FontAwesome
                name="chevron-down"
                size={28}
                color={"#FFF6"}
                style={{ paddingHorizontal: 5 }}
                onPress={() => {
                  setPanelVisible(false);
                }}
              />
            </View>
          )}
        </Pressable>
      </Pressable>
    </Modal>
  );
}
