import React from 'react';
import {View} from 'react-native';
import Amount from './Amount';
import Stacks from './Stacks';

import styles from './styles';

type Props = {
    size: number;
}

export default function ({size}: Props) {

    //const [amount, setAmount] = React.useState<number>(0);

    //React.useEffect(() => {
    //    if (size >= 100) {
    //        setAmount(Math.round(size / 100));
    //    } else {
    //        setAmount(size);
    //    }
    //}, [size]);

    if (size === 0) {
        return null;
    }

    //size = 114;

    return (
        <View style={styles.container}>
            <Stacks value={size}/>
            <Amount value={size}/>
        </View>
    );
}