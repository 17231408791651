import {StyleSheet, ViewStyle} from 'react-native';
import {useDimensions} from '../../../../contexts/Dimensions';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        //justifyContent: 'space-between',
        alignItems: 'center',
        justifyContent: 'flex-start',
        //alignItems: 'center',
        //justifyContent: 'center',
        //backgroundColor: 'blue',
        //position: 'absolute',
        //bottom: 0,
        //opacity: 0.6,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#FFFFFF',
    },
});


export const styleActionsPosition = (): ViewStyle => {
    return StyleSheet.flatten([styles.container, getActionsPosition()]);
}

export const getActionsPosition = (): ViewStyle => {

    const {orientation} = useDimensions();

    switch (orientation) {
        case 'landscape':
            return {
                width: '100%',
                minHeight: '35%',
            }
        case 'portrait':
            return {
                width: '100%',
                minHeight: '24%',
                maxHeight: 90,
            }
        default:
            return {
                display: 'none',
            }
    }
}