/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
  useNavigation,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { ColorSchemeName, Image, Pressable, Text, View } from "react-native";
import PersonalInfo from "../components/modals/PersonalInfo";
import SignIn from "../components/modals/SignIn";
import { useAccount } from "../contexts/Account";
import { useGameOptions } from "../contexts/GameOptions";
import LobbyScreen from "../screens/LobbyScreen";
import ModalScreen from "../screens/ModalScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import ProfileScreen from "../screens/ProfileScreen";
import TableScreen from "../screens/TableScreen";
import TournamentScreen from "../screens/TournamentScreen";
import {
  RootStackParamList,
  RootTabParamList,
  RootTabScreenProps,
} from "../types";
import LinkingConfiguration from "./LinkingConfiguration";

// @ts-ignore
import NoAvatar from "../assets/images/theme/default/avatars/thumbnails/avatar_placeholder.png";

import { useTranslation } from "react-i18next";
import Level from "../collections/Level";
import SignUp from "../components/modals/SignUp";
import AvatarCollection from "../constants/AvatarCollection";
import { useDimensions } from "../contexts/Dimensions";
import { useWebSocket } from "../contexts/WebSocket";
import CashierScreen from "../screens/CashierScreen";
import PlayBondsScreen from "../screens/PlayBondsScreen";
import { theme } from "../util/GlobalStyles";

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator>
      {/*<Stack.Screen name="Maintenance" component={MaintenanceScreen} options={{ headerShown: false }} />*/}
      <Stack.Screen
        name="Root"
        component={BottomTabNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Table"
        component={TableScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Cashier"
        component={CashierScreen}
        options={{ header: NavHeader }}
      />
      <Stack.Screen
        name="PlayBonds"
        component={PlayBondsScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ title: "Oops!" }}
      />
      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function HeaderRightAuth() {
  const { t } = useTranslation();
  const { orientation } = useDimensions();
  const { socket } = useWebSocket();
  const { hideBalance, handleSetHideBalance, showLevel } = useGameOptions();
  const { signed, user, wallet } = useAccount();

  const navigation = useNavigation();

  const [displayBalance, setDisplayBalance] = React.useState<string>();

  const username = user?.username
    ? user.username.length > 15
      ? `${user?.username.slice(0, 12)}...`
      : user?.username
    : t("Navigation.unknown");

  React.useEffect(() => {
    if (wallet) {
      setDisplayBalance(
        new Intl.NumberFormat("en-US", {
          notation: "compact",
          compactDisplay: "short",
        }).format((wallet.balance + wallet.bonus) / 100)
      );
    }

    //setDisplayBalance(wallet ? monetaryValue(wallet.balance) : "0,00");
  }, [wallet]);

  //const displayBalance = wallet ? monetaryValue(wallet.balance) : '0,00';

  return (
    <View style={{ marginRight: 16, maxHeight: 100 }}>
      {signed ? (
        <View style={{ flex: 1, flexDirection: "row" }}>
          {user && (!user.profileComplete || !user.acceptedTerms) && (
            <PersonalInfo />
          )}

          {/* user && user.profileComplete && user.acceptedTerms && (
            <View
              style={{
                justifyContent: "center",
                alignContent: "center",
                paddingRight: 15,
              }}
            >
              {<Deposit showButton={true} />}
            </View>
          ) */}

          <View
            style={{
              justifyContent: "center",
              alignContent: "center",
              paddingRight: 10,
            }}
          >
            <View style={{ paddingRight: 5 }}>
              <Text
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "600",
                  textTransform: "uppercase",
                }}
              >
                {user?.username}
              </Text>
            </View>
            <Pressable onPress={() => navigation.navigate("Cashier")}>
              <View
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  flexDirection: "row",
                  justifyContent: "center",
                  borderRadius: 20,
                  paddingLeft: 8,
                  paddingRight: 4,
                  paddingVertical: 2,
                }}
              >
                <View style={{ padding: 2, marginRight: 5 }}>
                  <Pressable onPress={() => handleSetHideBalance(!hideBalance)}>
                    <FontAwesome
                      name={hideBalance ? "eye" : "eye-slash"}
                      size={22}
                      color="rgba(255, 255, 255, 0.5)"
                    />
                  </Pressable>
                </View>

                <Text
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 14,
                    paddingTop: 4,
                    paddingRight: 8,
                  }}
                >
                  <Text
                    style={{
                      backgroundColor: "yellow",
                      color: "black",
                      fontSize: 10,
                      paddingVertical: 2,
                      paddingHorizontal: 5,
                      borderRadius: 10,
                    }}
                  >
                    BRL
                  </Text>{" "}
                  {hideBalance
                    ? "*".repeat(3)
                    : wallet
                    ? displayBalance
                    : "***"}
                </Text>
                <FontAwesome
                  name="plus-circle"
                  size={30}
                  color="rgb(0, 77, 255)"
                />
              </View>
            </Pressable>
          </View>

          <View style={{ position: "relative" }}>
            <Pressable
              onPress={() => navigation.navigate("Root", { screen: "Profile" })}
            >
              <Image
                source={
                  user?.avatar !== null &&
                  user?.avatar !== undefined &&
                  user.avatar !== "" &&
                  !user.avatar.startsWith("http")
                    ? AvatarCollection[Number(user.avatar)].sprite
                    : NoAvatar
                }
                resizeMode={"contain"}
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: 60,
                  borderWidth: 2,
                  borderColor: "rgb(0, 77, 255)",
                }}
              />
              {user && showLevel && (
                <View
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: -12,
                    width: 25,
                    height: 35,
                    //backgroundColor: "pink",
                  }}
                >
                  <Image
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    source={Level[user.level]}
                    resizeMode="contain"
                  />
                </View>
              )}
            </Pressable>
          </View>

          {/*
            <View
              style={{
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                minWidth: orientation === "portrait" ? 60 : 160,
              }}
            >
              <Pressable
                style={{
                  backgroundColor: "#FFF2",
                  width: 60,
                  height: 60,
                  marginHorizontal: 10,
                  borderRadius: 60,
                  overflow: "hidden",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  borderWidth: 2,
                  borderColor: "rgb(0, 77, 255)",
                }}
                onPress={() =>
                  navigation.navigate("Root", { screen: "Profile" })
                }
              >
                <Image
                  source={
                    user?.avatar !== null &&
                    user?.avatar !== undefined &&
                    user.avatar !== "" &&
                    !user.avatar.startsWith("http")
                      ? AvatarCollection[Number(user.avatar)].sprite
                      : NoAvatar
                  }
                  resizeMode={"contain"}
                  style={{ width: "100%", height: "100%" }}
                />
              </Pressable>

              {user && showLevel && (
                <View
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 55,
                    width: 25,
                    height: 32,
                    //backgroundColor: "pink",
                  }}
                >
                  <Image
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    source={Level[user.level]}
                  />
                </View>
              )}

              <View
                style={{
                  justifyContent: "center",
                  width: 128,
                  display: orientation === "portrait" ? "none" : "flex",
                }}
              >
                <Text style={{ color: "white", marginRight: 10 }}>
                  {username}
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Pressable
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onPress={() => handleSetHideBalance(!hideBalance)}
                  >
                    {hideBalance ? (
                      <>
                        <Text style={{ color: "rgb(0, 77, 255)" }}>
                          {"*".repeat(4)}
                        </Text>
                        <FontAwesome
                          name="eye"
                          size={16}
                          color="#FFF5"
                          style={{ marginHorizontal: 5 }}
                        />
                      </>
                    ) : (
                      <>
                        <Text style={{ color: "rgb(0, 77, 255)" }}>
                          {displayBalance}
                        </Text>
                        <FontAwesome
                          name="eye-slash"
                          size={16}
                          color="#FFF5"
                          style={{ marginHorizontal: 5 }}
                        />
                      </>
                    )}
                  </Pressable>

                  <Pressable onPress={() => updateWallet()}>
                    {({ pressed }) => (
                      <FontAwesome
                        name="refresh"
                        size={16}
                        color={pressed ? "#FFFFFF" : "#FFF5"}
                        style={{ marginHorizontal: 5, marginTop: 2 }}
                      />
                    )}
                  </Pressable>
                </View>
              </View>
            </View>
          */}
        </View>
      ) : (
        <View style={{ flexDirection: "row" }}>
          <SignIn showButton={true} />
          {orientation === "landscape" && <SignUp showButton={true} />}
        </View>
      )}
    </View>
  );
}

function HeaderTitle({ title }: { title: string }) {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Image
        style={{
          width: 128,
          height: 80,
          resizeMode: "contain",
          marginRight: 10,
        }}
        source={require("../assets/images/logo-header.png")}
        fadeDuration={0}
      />
      {/*<Text style={{ color: 'white', fontWeight: 'bold', fontSize: getFontSize(20, 14) }}>{title}</Text>*/}
    </View>
  );
}

function NavHeader() {
  const { orientation } = useDimensions();
  const navigation = useNavigation();

  return (
    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
      <View style={{ position: "relative" }}>
        <View
          style={{
            display: "none",
            position: "absolute",
            top: 5,
            right: 0,
            backgroundColor: "cyan",
            paddingHorizontal: 5,
            borderRadius: 5,
          }}
        >
          <Text style={{ color: "black", fontSize: 12, fontWeight: "bold" }}>
            Beta
          </Text>
        </View>
        <Pressable
          onPress={() => navigation.navigate("Root", { screen: "Lobby" })}
        >
          <Image
            style={{
              width: orientation === "landscape" ? 188 : 160,
              height: 75,
              resizeMode: "contain",
              marginLeft: 10,
            }}
            source={require("../assets/images/logo-header.png")}
            fadeDuration={0}
          />
        </Pressable>
      </View>
      <View style={{}}>
        <Text style={{ color: "white" }}></Text>
      </View>
      <View style={{ justifyContent: "center" }}>
        <HeaderRightAuth />
      </View>
    </View>
  );
}

function BottomTabNavigator() {
  const { t } = useTranslation();

  //const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName="Lobby"
      screenOptions={{
        tabBarActiveTintColor: theme.colors.primary,
        header: NavHeader,
        unmountOnBlur: true,
      }}
    >
      <BottomTab.Screen
        name="Lobby"
        component={LobbyScreen}
        options={({ navigation }) => ({
          //headerShown: false,
          title: t("Navigation.cash"),
          //headerStyle: { height: 100 },
          tabBarIcon: ({ color }) => <TabBarIcon name="globe" color={color} />,
          //headerRight: () => <HeaderRightAuth />,
          //headerTitle: () => <HeaderTitle title={'Cash'} />
        })}
      />

      <BottomTab.Screen
        name="Tournament"
        component={TournamentScreen}
        options={({ navigation }) => ({
          //headerShown: false,
          title: t("Navigation.tournaments"),
          //headerStyle: { height: 100 },
          tabBarIcon: ({ color }) => <TabBarIcon name="trophy" color={color} />,
          //headerRight: () => <HeaderRightAuth />,
          //headerTitle: () => <HeaderTitle title={'Tournaments'} />
        })}
      />

      {/*
      <BottomTab.Screen
        name="Rakeback"
        component={RakebackScreen}
        options={({ navigation }) => ({
          //headerShown: false,
          title: t("Navigation.rakeback"),
          //headerStyle: { height: 100 },
          tabBarIcon: ({ color }) => <TabBarIcon name="gift" color={color} />,
          //headerRight: () => <HeaderRightAuth />,
          //headerTitle: () => <HeaderTitle title={'Rakeback'} />
        })}
      />
      */}

      {/*<BottomTab.Screen
        name="Rakeback"
        component={RakebackScreen}
        options={({ navigation }: RootTabScreenProps<'Rakeback'>) => ({
          headerShown: false,
          title: 'Rakeback',
          headerStyle: { height: 100 },
          tabBarIcon: ({ color }) => <TabBarIcon name="gift" color={color} />,
          headerRight: () => <HeaderRightAuth />,
          headerTitle: () => <HeaderTitle title={'Rakeback'} />
        })}
      />*/}

      <BottomTab.Screen
        name="Profile"
        component={ProfileScreen}
        options={({ navigation }: RootTabScreenProps<"Profile">) => ({
          //headerShown: false,
          title: t("Navigation.profile"),
          //headerStyle: { height: 100 },
          tabBarIcon: ({ color }) => <TabBarIcon name="gear" color={color} />,
          //headerTitle: () => <HeaderTitle title={'Profile'} />
          /*headerRight: () => (
                      <Pressable
                        onPress={() => navigation.navigate('Modal')}
                        style={({ pressed }) => ({
                          opacity: pressed ? 0.5 : 1,
                        })}>
                        <MaterialCommunityIcons
                          name="cog-outline"
                          size={25}
                          color={Colors[colorScheme].text}
                          style={{ marginRight: 15 }}
                        />
                      </Pressable>
                     ),*/
        })}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>["name"];
  color: string;
}) {
  return (
    <FontAwesome
      size={28}
      style={
        {
          /*marginBottom: -3*/
        }
      }
      {...props}
    />
  );
}
