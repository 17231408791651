import React from "react";
import {
  ActivityIndicator,
  Button,
  Platform,
  Pressable,
  ScrollView,
  Text,
  TextInput,
  View,
} from "react-native";
import { TextInputMask } from "react-native-masked-text";
import { useAccount } from "../../../contexts/Account";
// import { useDimensions } from '../../../contexts/Dimensions';
import Modal from "../Modal";
// import Joi, {ValidationError} from "joi";
import { styles, styleWithFontScale } from "./styles";
import { useTranslation } from "react-i18next";
import { Switch } from "react-native-gesture-handler";
import { theme } from "../../../util/GlobalStyles";

type Props = {
  modalVisible: boolean;
  setModalVisible: (modalVisible: boolean) => void;
};

export default function ({ modalVisible, setModalVisible }: Props) {
  const { t } = useTranslation();

  const { user, changeAddress, success, error } = useAccount();

  const nextFocus = React.useRef<TextInput>(null);

  const focusNextField = () => {
    if (nextFocus.current != null) {
      nextFocus.current.focus();
    }
  };

  const [address, onChangeAddress] = React.useState<string>("");
  const [city, onChangeCity] = React.useState<string>("");
  const [state, onChangeState] = React.useState<string>("");
  const [country, onChangeCountry] = React.useState<string>("");
  const [zipcode, onChangeZipcode] = React.useState<string>("");

  const [loading, setLoading] = React.useState<boolean>(false);

  const changeAddressSubmit = () => {
    if (!user) return;

    setLoading(true);
    changeAddress(address, city, state, country, zipcode);
  };

  React.useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  React.useEffect(() => {
    if (success) {
      setLoading(false);
      setModalVisible(false);
    }
  }, [success]);

  React.useEffect(() => {
    if (user) {
      onChangeAddress(user.address);
      onChangeCity(user.city);
      onChangeState(user.state);
      onChangeCountry(user.country);
      onChangeZipcode(user.zipcode);
    }
  }, [user]);

  return (
    <React.Fragment>
      <Modal
        title={t("ProfileScreen.modalAccountSettings.changeAddress")}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        type="fullheight"
      >
        <View style={{ marginBottom: 10 }}>
          {loading ? <ActivityIndicator /> : <></>}
          {error?.map((err, index) => (
            <Text key={index} style={{ color: "red" }}>{`${
              index + 1
            }. ${err}`}</Text>
          ))}
        </View>
        <ScrollView>
          <View style={{ flex: 1 }}>
            <View style={{ flex: 1, paddingBottom: 20 }}>
              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalPersonalInfo.address")}
                </Text>
                <TextInput
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeAddress}
                  maxLength={255}
                  value={address}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalPersonalInfo.city")}
                </Text>
                <TextInput
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeCity}
                  maxLength={255}
                  value={city}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalPersonalInfo.state")}
                </Text>
                <TextInput
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeState}
                  maxLength={255}
                  value={state}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalPersonalInfo.country")}
                </Text>
                <TextInput
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeCountry}
                  maxLength={255}
                  value={country}
                />
              </View>

              <View>
                <Text style={{ color: "white", fontSize: 14, marginLeft: 2 }}>
                  {t("ModalPersonalInfo.zipCode")}
                </Text>
                <TextInputMask
                  type={"zip-code"}
                  onSubmitEditing={() => focusNextField()}
                  style={styles.textInput}
                  placeholderTextColor={"#cccccc"}
                  onChangeText={onChangeZipcode}
                  maxLength={255}
                  value={zipcode}
                />
              </View>

              <Pressable
                style={styles.button}
                onPress={() => changeAddressSubmit()}
              >
                <Text style={[styleWithFontScale(20, 14)]}>
                  {t("ModalPersonalInfo.confirm")}
                </Text>
              </Pressable>
            </View>
          </View>
        </ScrollView>
      </Modal>
      {/*<Button title='Personal Info' color={'#666666'} onPress={() => setModalVisible(!modalVisible)} />*/}
    </React.Fragment>
  );
}
