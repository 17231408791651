import React, { createContext, useContext } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ActivityIndicator, View } from "react-native";
import { useTranslation } from "react-i18next";

export type GameOptionsContextProps = {
  soundEnabled: boolean;
  handleSetSoundEnabled: (enabled: boolean) => void;
  soundVolume: number;
  handleSetSoundVolume: (volume: number) => void;
  frontDeck: number;
  handleSetFrontDeck: (fontDeck: number) => void;
  backDeck: number;
  handleSetBackDeck: (backDeck: number) => void;
  showBigBlind: boolean;
  handleSetShowBigBlind: (showBigBlind: boolean) => void;
  hideBalance: boolean;
  handleSetHideBalance: (hideBalance: boolean) => void;
  language: string;
  handleSetLanguage: (language: string) => void;
  autoTableChange: boolean;
  handleSetAutoTableChange: (autoTableChange: boolean) => void;
  showLevel: boolean;
  handleSetShowLevel: (showLevel: boolean) => void;
};

export const GameOptionsContext = createContext({} as GameOptionsContextProps);

type GameOptionsProviderProps = {
  children: React.ReactNode;
};

export function GameOptionsProvider({ children }: GameOptionsProviderProps) {
  const { i18n } = useTranslation();
  const [isReady, setIsReady] = React.useState<boolean>(false);

  const [soundEnabled, setSoundEnabled] = React.useState<boolean>(true);
  const [soundVolume, setSoundVolume] = React.useState<number>(1);
  const [frontDeck, setFrontDeck] = React.useState<number>(1);
  const [backDeck, setBackDeck] = React.useState<number>(1);
  const [showBigBlind, setShowBigBlind] = React.useState<boolean>(false);
  const [hideBalance, setHideBalance] = React.useState<boolean>(false);
  const [language, setLanguage] = React.useState<string>(i18n.language);
  const [autoTableChange, setAutoTableChange] = React.useState<boolean>(false);
  const [showLevel, setShowLevel] = React.useState<boolean>(true);

  const handleSetSoundEnabled = async (enabled: boolean) => {
    setSoundEnabled(enabled);
    await AsyncStorage.setItem("SOUND_ENABLED", enabled.toString());
  };

  const handleSetSoundVolume = async (volume: number) => {
    let vol = volume.toFixed(2);
    setSoundVolume(Number(vol));
    await AsyncStorage.setItem("SOUND_VOLUME", vol.toString());
  };

  const handleSetFrontDeck = async (fontDeck: number) => {
    setFrontDeck(fontDeck);
    await AsyncStorage.setItem("FRONT_DECK", fontDeck.toString());
  };

  const handleSetBackDeck = async (backDeck: number) => {
    setBackDeck(backDeck);
    await AsyncStorage.setItem("BACK_DECK", backDeck.toString());
  };

  const handleSetShowBigBlind = async (showBigBlind: boolean) => {
    setShowBigBlind(showBigBlind);
    await AsyncStorage.setItem("SHOW_BIG_BLIND", showBigBlind.toString());
  };

  const handleSetHideBalance = async (hideBalance: boolean) => {
    setHideBalance(hideBalance);
    await AsyncStorage.setItem("HIDE_BALANCE", hideBalance.toString());
  };

  const handleSetLanguage = async (language: string) => {
    setLanguage(language);
    await AsyncStorage.setItem("LANGUAGE", language);
  };

  const handleSetAutoTableChange = async (autoTableChange: boolean) => {
    setAutoTableChange(autoTableChange);
    await AsyncStorage.setItem("AUTO_TABLE_CHANGE", autoTableChange.toString());
  };

  const handleSetShowLevel = async (showLevel: boolean) => {
    setShowLevel(showLevel);
    await AsyncStorage.setItem("SHOW_LEVEL", showLevel.toString());
  };

  React.useEffect(() => {
    const restoreState = async () => {
      try {
        const SOUND_ENABLED = await AsyncStorage.getItem("SOUND_ENABLED");
        if (SOUND_ENABLED !== null) {
          setSoundEnabled(String(true) === SOUND_ENABLED);
        }

        const SOUND_VOLUME = await AsyncStorage.getItem("SOUND_VOLUME");
        if (SOUND_VOLUME !== null) {
          setSoundVolume(Number(SOUND_VOLUME));
        }

        const FRONT_DECK = await AsyncStorage.getItem("FRONT_DECK");
        if (FRONT_DECK !== null) {
          setFrontDeck(Number(FRONT_DECK));
        }

        const BACK_DECK = await AsyncStorage.getItem("BACK_DECK");
        if (BACK_DECK !== null) {
          setBackDeck(Number(BACK_DECK));
        }

        const SHOW_BIG_BLIND = await AsyncStorage.getItem("SHOW_BIG_BLIND");
        if (SHOW_BIG_BLIND !== null) {
          setShowBigBlind(String(true) === SHOW_BIG_BLIND);
        }

        const HIDE_BALANCE = await AsyncStorage.getItem("HIDE_BALANCE");
        if (HIDE_BALANCE !== null) {
          setHideBalance(String(true) === HIDE_BALANCE);
        }

        const LANGUAGE = await AsyncStorage.getItem("LANGUAGE");
        if (LANGUAGE !== null) {
          setLanguage(LANGUAGE);
        }

        const AUTO_TABLE_CHANGE = await AsyncStorage.getItem(
          "AUTO_TABLE_CHANGE"
        );
        if (AUTO_TABLE_CHANGE !== null) {
          setAutoTableChange(String(true) === AUTO_TABLE_CHANGE);
        }

        const SHOW_LEVEL = await AsyncStorage.getItem("SHOW_LEVEL");
        if (SHOW_LEVEL !== null) {
          setShowLevel(String(true) === SHOW_LEVEL);
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, []);

  if (!isReady) {
    return (
      <View
        style={{
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
          backgroundColor: "black",
        }}
      >
        <ActivityIndicator />
      </View>
    );
  }

  return (
    <GameOptionsContext.Provider
      value={{
        soundEnabled,
        handleSetSoundEnabled,
        soundVolume,
        handleSetSoundVolume,
        frontDeck,
        handleSetFrontDeck,
        backDeck,
        handleSetBackDeck,
        showBigBlind,
        handleSetShowBigBlind,
        hideBalance,
        handleSetHideBalance,
        language,
        handleSetLanguage,
        autoTableChange,
        handleSetAutoTableChange,
        showLevel,
        handleSetShowLevel,
      }}
    >
      {children}
    </GameOptionsContext.Provider>
  );
}

export function useGameOptions() {
  const context = useContext(GameOptionsContext);
  if (context === undefined) {
    throw new Error("Context provider undefined");
  }
  return context;
}
