import {StyleSheet, ViewStyle} from 'react-native';
import {useDimensions} from '../../../../contexts/Dimensions';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        //alignItems: 'center',
        justifyContent: 'space-between',
        //bottom: 0,
        //opacity: 0.6
    },
    title: {
        fontSize: 16,
        fontWeight: '500',
        color: '#FFFFFF',
        textAlignVertical: 'center',
        //backgroundColor: 'yellow'
    },
    raiseOptions: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
    }

});


export const styleBettingPosition = (): ViewStyle => {
    return StyleSheet.flatten([styles.container, getBettingPosition()]);
}

export const getBettingPosition = (): ViewStyle => {

    const {orientation} = useDimensions();

    switch (orientation) {
        case 'landscape':
            return {
                width: '100%',
                minHeight: '35%',
                flexDirection: 'row',
            }
        case 'portrait':
            return {
                maxWidth: '60%',
                minHeight: '56%',
                flexDirection: 'column',
                marginLeft: '40%',
            }
        default:
            return {
                display: 'none',
            }
    }
}