import {StyleSheet, ViewStyle} from 'react-native';
import {useDimensions} from '../../../../contexts/Dimensions';
import {getSeatPosition} from '../../Seats/Seat/styles';

export const styleDestinyContainer = (seatNumber: number, numberOfSeats: number): ViewStyle => {
    return StyleSheet.flatten([getSeatPosition(seatNumber, numberOfSeats), {
        position: 'absolute',
        borderColor: 'yellow',
        borderWidth: 1
    }]);
}

export const getDestinationStyle = (seatNumber: number, cardNumber: number, numberOfSeats: number): ViewStyle => {
    return StyleSheet.flatten([getDestination(seatNumber, cardNumber, numberOfSeats), {
        position: 'absolute',
        width: '15%',
        height: '8%'
    }]);
}

const getDestination = (seatNumber: number, cardNumber: number, numberOfSeats: number): ViewStyle => {
    const {orientation} = useDimensions();
    const leftOffset = cardNumber * 2;
    switch (orientation) {
        case 'landscape':
            switch (numberOfSeats) {
                case 2:
                    switch (seatNumber) {
                        case 0:
                            return {
                                left: '42.5%',
                                bottom: '4.5%',
                            }
                        case 1:
                            return {
                                left: '43.5%',
                                top: '4%',
                            }
                        default:
                            return {
                                left: undefined,
                                top: undefined,
                            }
                    }
                case 6:
                    switch (seatNumber) {
                        case 0:
                            return {
                                left: `${42.5 + leftOffset}%`,
                                bottom: '7%',
                            }
                        case 1:
                            return {
                                left: `${13 + leftOffset}%`,
                                bottom: '21%',
                            }
                        case 2:
                            return {
                                left: `${10 + leftOffset}%`,
                                bottom: '70%',
                            }
                        case 3:
                            return {
                                left: `${43.5 + leftOffset}%`,
                                bottom: '78%',
                            }
                        case 4:
                            return {
                                left: `${76 + leftOffset}%`,
                                bottom: '70%',
                            }
                        case 5:
                            return {
                                left: `${73 + leftOffset}%`,
                                bottom: '21%',
                            }
                        default:
                            return {
                                left: undefined,
                                bottom: undefined,
                            }
                    }
                case 8:
                    switch (seatNumber) {
                        case 0:
                            return {
                                left: '42.5%',
                                bottom: '4.5%',
                            }
                        case 1:
                            return {
                                left: '13%',
                                bottom: '19%',
                            }
                        case 2:
                            return {
                                left: '1%',
                                top: '33%',
                            }
                        case 3:
                            return {
                                left: '16%',
                                top: '8%',
                            }
                        case 4:
                            return {
                                left: '43.5%',
                                top: '4%',
                            }
                        case 5:
                            return {
                                right: '16%',
                                top: '8%',
                            }
                        case 6:
                            return {
                                right: '1%',
                                top: '33%',
                            }
                        case 7:
                            return {
                                right: '13%',
                                bottom: '19%',
                            }
                        default:
                            return {
                                left: undefined,
                                bottom: undefined,
                            }
                    }
                case 9:
                    switch (seatNumber) {
                        case 0:
                            return {
                                left: `${42.5 + leftOffset}%`,
                                bottom: '7%',
                            }
                        case 1:
                            return {
                                left: `${13 + leftOffset}%`,
                                bottom: '22%',
                            }
                        case 2:
                            return {
                                left: `${1 + leftOffset}%`,
                                bottom: '50%',
                            }
                        case 3:
                            return {
                                left: `${11 + leftOffset}%`,
                                bottom: '72%',
                            }
                        case 4:
                            return {
                                left: `${33 + leftOffset}%`,
                                bottom: '77.5%',
                            }
                        case 5:
                            return {
                                left: `${52.5 + leftOffset}%`,
                                bottom: '77.5%',
                            }
                        case 6:
                            return {
                                left: `${74.5 + leftOffset}%`,
                                bottom: '72%',
                            }
                        case 7:
                            return {
                                left: `${85.5 + leftOffset}%`,
                                bottom: '50%',
                            }
                        case 8:
                            return {
                                left: `${73 + leftOffset}%`,
                                bottom: '22%',
                            }
                        default:
                            return {
                                left: undefined,
                                bottom: undefined,
                            }
                    }
                default:
                    return {
                        left: undefined,
                        bottom: undefined,
                    }

            }
        case 'portrait':
            switch (numberOfSeats) {
                case 2:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '30%',
                                height: '6%',
                                left: '2.5%',
                                bottom: '12%',
                            }
                        case 1:
                            return {
                                width: '23%',
                                height: '6%',
                                left: '39%',
                                top: '4%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 6:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '30%',
                                height: '6%',
                                left: `${2.5 + leftOffset}%`,
                                bottom: '14%',
                            }
                        case 1:
                            return {
                                width: '23%',
                                height: '6%',
                                left: `${3 + leftOffset}%`,
                                bottom: '35%',
                            }
                        case 2:
                            return {
                                width: '23%',
                                height: '6%',
                                left: `${3 + leftOffset}%`,
                                bottom: '64%',
                            }
                        case 3:
                            return {
                                width: '23%',
                                height: '6%',
                                left: `${39 + leftOffset}%`,
                                bottom: '84%',
                            }
                        case 4:
                            return {
                                width: '23%',
                                height: '6%',
                                left: `${74 + leftOffset}%`,
                                bottom: '64%',
                            }
                        case 5:
                            return {
                                width: '23%',
                                height: '6%',
                                left: `${74 + leftOffset}%`,
                                bottom: '35%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 8:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '30%',
                                height: '6%',
                                left: '2.5%',
                                bottom: '20%',
                            }
                        case 1:
                            return {
                                width: '23%',
                                height: '6%',
                                left: '3%',
                                bottom: '33.5%',
                            }
                        case 2:
                            return {
                                width: '23%',
                                height: '6%',
                                left: '3%',
                                top: '28.5%',
                            }
                        case 3:
                            return {
                                width: '23%',
                                height: '6%',
                                left: '3%',
                                top: '15.75%',
                            }
                        case 4:
                            return {
                                width: '23%',
                                height: '6%',
                                left: '39%',
                                top: '4%',
                            }
                        case 5:
                            return {
                                width: '23%',
                                height: '6%',
                                right: '3%',
                                top: '15.75%',
                            }
                        case 6:
                            return {
                                width: '23%',
                                height: '6%',
                                right: '3%',
                                top: '28.5%',
                            }
                        case 7:
                            return {
                                width: '23%',
                                height: '6%',
                                right: '3%',
                                bottom: '33.5%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 9:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '30%',
                                height: '6%',
                                left: `${2.5 + leftOffset}%`,
                                bottom: '14%',
                            }
                        case 1:
                            return {
                                width: '23%',
                                height: '6%',
                                left: `${3 + leftOffset}%`,
                                bottom: '33.5%',
                            }
                        case 2:
                            return {
                                width: '23%',
                                height: '6%',
                                left: `${3 + leftOffset}%`,
                                bottom: '59%',
                            }
                        case 3:
                            return {
                                width: '23%',
                                height: '6%',
                                left: `${3 + leftOffset}%`,
                                bottom: '75%',
                            }
                        case 4:
                            return {
                                width: '23%',
                                height: '6%',
                                left: `${25 + leftOffset}%`,
                                bottom: '85%',
                            }
                        case 5:
                            return {
                                width: '23%',
                                height: '6%',
                                left: `${52 + leftOffset}%`,
                                bottom: '85%',
                            }
                        case 6:
                            return {
                                width: '23%',
                                height: '6%',
                                left: `${74 + leftOffset}%`,
                                bottom: '75%',
                            }
                        case 7:
                            return {
                                width: '23%',
                                height: '6%',
                                left: `${74 + leftOffset}%`,
                                bottom: '59%'
                            }
                        case 8:
                            return {
                                width: '23%',
                                height: '6%',
                                left: `${74 + leftOffset}%`,
                                bottom: '33.5%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                default:
                    return {
                        display: 'none',
                    }
            }
        default:
            return {
                display: 'none',
            }
    }
}


