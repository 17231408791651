import * as React from "react";
import { StyleSheet } from "react-native";

import { RootTabScreenProps } from "../types";
import Lobby from "../components/Lobby";

export default function LobbyScreen({
  navigation,
}: RootTabScreenProps<"Lobby">) {
  return <Lobby />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
