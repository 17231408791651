import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    container: {
        //flex: 1,
        width: '72%',
        //marginLeft: '-10%',
        height: '10%',
        position: 'absolute',
        bottom: '5%',
        left: '14%',
        //flex: 1,
        //width: '100%',
        //height: '100%',
        //maxHeight: '100%',
        //justifyContent: 'center'
        //backgroundColor: 'green'
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'red'
    },
    image: {
        width: '100%',
        height: '100%'
    },
    fillEffect: {
        // position: 'absolute', 
        width: 5,
        height: '140%',


    },
    fillClip: {
        height: '60%',
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        overflow: 'hidden'
    }
});