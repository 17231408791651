import {Platform, StyleSheet, TextStyle} from 'react-native';
import {useDimensions} from '../../../../../contexts/Dimensions';
import {getFontSize} from '../../../../../util/GlobalStyles';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontWeight: 'bold',
        color: 'rgba(255, 255, 255, 0.8)'
    },
    image: {
        flex: 1,
        width: '100%',
        height: '100%',
        justifyContent: 'center'
    },
});

export const styleWithFontScale = (fontSizeWeb: number, fontSizeDefault: number): TextStyle => {
    return StyleSheet.flatten([styles.title, {fontSize: getFontSize(fontSizeWeb, fontSizeDefault)}]);
}

export function textSize(fontSizeWeb: number, fontSizeDefault: number) {

    const {orientation, width, height} = useDimensions();

    if (Platform.OS === 'web') {
        if (orientation === 'landscape') {
            if (height >= 780 && height <= 880) {
                fontSizeWeb -= 4;
            } else if (height >= 680 && height < 780) {
                fontSizeWeb -= 7;
            } else if (height < 680) {
                fontSizeWeb -= 12;
            }
        } else {
            if (width > 360 && width < 540) {
                fontSizeWeb -= 8;
            } else if (width <= 360) {
                fontSizeWeb -= 12;
            }
        }

        return fontSizeWeb;
    }

    return fontSizeDefault;
}

// export const getFontSize = (fontSizeWeb: number, fontSizeDefault: number): TextStyle => {

//     let font = textSize(fontSizeWeb, fontSizeDefault);

//     return {
//         fontSize: font
//     }
// }