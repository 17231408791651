import React from "react";
import { Modal, ModalProps, Pressable, Text, View } from "react-native";
import { FontAwesome } from "@expo/vector-icons";

import { styleModal, styles } from "./styles";

type ModalType = "window" | "fullscreen" | "bottom" | "fullheight";

type Props = ModalProps & {
  title: string;
  modalVisible: boolean;
  showClose?: boolean;
  type?: ModalType;
  setModalVisible: (visible: boolean) => void;
  children: React.ReactNode;
};

export default function ({
  title,
  showClose = true,
  modalVisible,
  setModalVisible,
  type = "bottom",
  children,
  ...rest
}: Props) {
  //const [modalVisible, setModalVisible] = React.useState(false);

  //React.useEffect(() => {
  //  setModalVisible(show);
  //}, [show]);

  return (
    <Modal
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible);
      }}
      {...rest}
    >
      <View style={styles.centeredView}>
        <View style={styleModal(type)}>
          <View style={styles.modalTitle}>
            <Text style={styles.modalTitleText}>{title}</Text>
            {showClose && (
              <Pressable
                style={[styles.button]}
                onPress={() => setModalVisible(!modalVisible)}
              >
                <FontAwesome
                  name="close"
                  size={25}
                  color="rgba(255, 255, 255, 0.4)"
                />
              </Pressable>
            )}
          </View>
          <View style={styles.modalContent}>{children}</View>
        </View>
      </View>
    </Modal>
  );
}
