import {StyleSheet} from "react-native";

export const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    historyArea: {
        flex: 1,
    },
    tableHeader: {
        flex: 1,
        flexDirection: "row",
        backgroundColor: "#000",
        paddingVertical: 5,
        maxHeight: 30
    }
})