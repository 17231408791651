import { FontAwesome } from "@expo/vector-icons";
import React from "react";
import { Pressable, Text } from "react-native";
import { styles, styleTimeBankPosition, styleWithFontScale } from "./styles";
import { useTable } from "../../../../../contexts/Table";

export default function () {
  const { currentPlayer, handleUseTimeBankActionPress, actionTime, timebank } =
    useTable();

  //const [useTimeBank, setUseTimeBank] = React.useState<boolean>(false);
  //const [timeBank, setTimeBank] = React.useState<number>();
  //const [usingTimebank, setUsingTimebank] = React.useState<boolean>(false);

  //React.useEffect(() => {

  //}, [currentPlayer]);

  /*
  React.useEffect(() => {
    if (actionTime <= 1) {
      setUsingTimebank(true);
    }
    if (useTimeBank && usingTimebank) {
      setTimeBank(actionTime);
    }
  }, [actionTime]);

  React.useEffect(() => {
    if (currentPlayer) {
      setUseTimeBank(currentPlayer.useTimeBank);
      setUsingTimebank(false);
      setTimeBank(currentPlayer.timeBank);

      if (
        !manualSetTimeBank &&
        currentPlayer.bet <= 0 &&
        currentPlayer.useTimeBank
      ) {
        handleUseTimeBankActionPress();
      }
    }
  }, [currentPlayer]);
  */

  return (
    <Pressable
      onPress={handleUseTimeBankActionPress}
      style={[styleTimeBankPosition(), { zIndex: 0.5 }]}
    >
      <Text style={styleWithFontScale(22, 16)}>
        <FontAwesome
          name={currentPlayer?.useTimeBank ? "check-square-o" : "square-o"}
          style={styleWithFontScale(20, 14)}
          color="white"
        />{" "}
        {` ${timebank ? actionTime : currentPlayer?.timeBank || 0}s`}
      </Text>
    </Pressable>
  );
}
