import {StyleSheet} from "react-native";
import {theme} from "../../../util/GlobalStyles";

export const styles = StyleSheet.create({
    container: {
        top: 0,
        left: 0,
        position: "absolute",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#0009",
    },
    panel: {
        position: "absolute",
        width: "80%",
        maxWidth: 960,
        borderWidth: 1,
        borderColor: theme.colors.background90,
        backgroundColor: theme.colors.background100,
        justifyContent: "space-between",
        alignSelf: "center",
    },
    titleBar: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 8,
    },
    title: {
        fontSize: theme.defaultFontSizes.modalTitle,
        color: theme.colors.textHighlight,
        fontWeight: "bold"
    },
    subtitle: {
        fontSize: 18,
        color: theme.colors.textSecondary,
    },
    section: {
        padding: 10,
    },
    infoText: {
        color: theme.colors.textHighlight,
        fontSize: 18,
        padding: 30,
        textAlign: "justify"
    }
});