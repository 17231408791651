//import { number } from 'joi';
import {StyleSheet} from 'react-native';
//import { getFontSize } from '../../../../../../../util/GlobalStyles';
// import { useDimensions } from '../../../../../../../contexts/Dimensions';

export const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '49%',
        justifyContent: 'center',
        //alignItems:'center',
        //backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    text: {
        color: 'white',
        fontWeight: '500',
        textAlign: 'center',
        textAlignVertical: 'center',
    },

})

// export const styles = StyleSheet.create({
//     container: {
//         flex: 1,
//         paddingBottom:2,
//         width: '80%',
//         height: '100%',
//         marginLeft: '10%',
//         marginTop:'-2%',
//         //line
//         borderBottomWidth:1,
//         borderBottomColor: 'rgba(255, 255, 255, 0.1)',
//         fontWeight: '500',
//         color: '#B9B9B9',
//         //width: '100%',
//         //minHeight: '100%',
//         textAlign: 'center',
//         textAlignVertical: 'center',
//         //backgroundColor: 'blue'
//         //justifyContent: 'center',
//         //marginTop: '7.5%',
//         //backgroundColor: 'pink',
//     },
// });

// export const styleWithFontScale = (fontSizeWeb: number, fontSizeDefault: number): ViewStyle => {
//     return StyleSheet.flatten([styles.container, getFontSize(fontSizeWeb, fontSizeDefault)]);
// }

// export function textSize(fontSizeWeb: number, fontSizeDefault: number) {

//     const { orientation, width, height } = useDimensions();

//     if (Platform.OS === 'web') {
//         if (orientation === 'landscape') {
//             if (height >= 780 && height <= 880) {
//                 fontSizeWeb -= 4;
//             } else if (height >= 680 && height < 780) {
//                 fontSizeWeb -= 7;
//             } else if (height < 680) {
//                 fontSizeWeb -= 12;
//             } 
//         } else {
//             if (width > 360 && width < 540) {
//                 fontSizeWeb -= 12;
//             } else if (width <= 360) {
//                 fontSizeWeb -= 12;
//             }
//         }

//         return fontSizeWeb;
//     }

//     return fontSizeDefault;
// }

// export const getFontSize = (fontSizeWeb: number, fontSizeDefault: number): TextStyle => {

//     let font = textSize(fontSizeWeb, fontSizeDefault);

//     return {
//         fontSize: font
//     }
// }