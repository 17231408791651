import React, { useState } from 'react';
import { View, } from 'react-native';
import { useTable } from '../../../contexts/Table';
import { BettingRound } from '../../../types';
import CommunityCard from './CommunityCard';
import { styleDeckPosition } from './styles';
import { Audio } from "expo-av";
import { useGameOptions } from '../../../contexts/GameOptions';

export default function () {
    const { soundVolume, soundEnabled, frontDeck } = useGameOptions();
    const { table } = useTable();
    const showdownFlop = table.isShowdownBeforeRiver;
    const cards = table.communityCards || [];
    //const numberOfCards = React.useMemo(() => cards.length, [cards]);
    const numberOfCards = cards.length;
    const [currentTable, setCurrentTable] = useState<number>();
    const [previousRound, setPreviuousRound] = useState<BettingRound>();
    const [animate, setAnimate] = useState<boolean>(false);

    const [sound, setSound] = React.useState<Audio.Sound>();

    async function playFlopSound() {
        if (!soundEnabled)
            return;

        const { sound } = await Audio.Sound.createAsync(
            require('../../../assets/audio/sfx/cardSlide5.mp3')
        );
        sound.setVolumeAsync(0.7 * soundVolume);
        setSound(sound);

        try {
            await sound.playAsync();
        }
        catch (e) {
            console.log("Failed to play cardSlide5 sfx");
            console.warn(e);
        }
    }

    async function playSound() {
        const { sound } = await Audio.Sound.createAsync(
            require('../../../assets/audio/sfx/cardSlide4.mp3')
        );
        sound.setVolumeAsync(0.7);
        setSound(sound);

        try {
            await sound.playAsync();
        }
        catch (e) {
            console.log("Failed to play cardSlide4 sfx");
            console.warn(e);
        }
    }

    React.useEffect(() => {
        if (animate === false && table && table.id && table.id === currentTable) {
            setAnimate(true);
        }
    }, [table]);

    React.useEffect(() => {
        if (table && table.id) {
            setCurrentTable(table.id);
            if (table.currentRound) {
                setPreviuousRound(table.currentRound);
            }
            setAnimate(false);
        }
    }, [table.id]);

    React.useEffect(() => {
        return sound
            ? () => {

                sound.unloadAsync();
            }
            : undefined;
    }, [sound]);

    React.useEffect(() => {
        if (table.currentRound && currentTable && table.id === currentTable) {
            setPreviuousRound(table.currentRound);
            setAnimate(true);
            if (table.currentRound === BettingRound.FLOP) {
                playFlopSound();
            }
            if (table.currentRound === BettingRound.TURN || table.currentRound === BettingRound.RIVER) {
                playSound();
            }
        }
    }, [table.currentRound])

    React.useEffect(() => {
        if (animate === true) setTimeout(() => {
            if (animate === true) setAnimate(false);
        }, 10000);
    }, [animate])


    //console.log(table.communityCards);

    function normalAnimation() {
        const component = [] as any;
        cards.map((card, cardNumber) => {
            component.push(<CommunityCard key={cardNumber} frontDeck={frontDeck} card={card} cardNumber={cardNumber} animate={animate} />)
        })
        return component;
    }

    function showdownBeforeRiverAnimation() {
        const component = [] as any;
        cards.map((card, cardNumber) => (
            component.push(<CommunityCard key={cardNumber} frontDeck={frontDeck} card={card} cardNumber={cardNumber} animate={true}
                delay={cardNumber > 2 ? cardNumber == 4 ? 5000 : 3000 : 0} />)
        ));
        return component;
    }

    return (
        <View style={[styleDeckPosition()]}>
            {/* {showdownFlop === true ?  (
                cards.map((card, cardNumber) => (
                    <CommunityCard key={cardNumber} card={card} cardNumber={cardNumber} delay={cardNumber > 2 ? cardNumber == 4 ? 5000 : 3000 : 0}/>
                ))
            ) : (
                cards.map((card, cardNumber) => (
                    <CommunityCard key={cardNumber} card={card} cardNumber={cardNumber}/>
                ))
            )} */}

            {
                showdownFlop ? showdownBeforeRiverAnimation() : normalAnimation()
            }
        </View>
    );
}