import { FontAwesome } from "@expo/vector-icons";
import React from "react";
import { Pressable, Text, View } from "react-native";
import { useTable } from "../../../../../contexts/Table";
import { useWebSocket } from "../../../../../contexts/WebSocket";
import BuyIn from "../../../../modals/BuyIn";
import { useTranslation } from "react-i18next";

import { styles, styleWithFontScale } from "./styles";

type Props = {
  waiting: boolean;
  position: number;
  players: number;
  buyIn: number;
};

export default function ({ waiting, position, players, buyIn }: Props) {
  const { t } = useTranslation();
  const { tableId } = useWebSocket();
  const { handleJoinWaitingPress, table } = useTable();
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);

  const handleJoinWaitingList = React.useCallback(
    (buyIn: number) => {
      if (waiting) {
        handleJoinWaitingPress(buyIn);
      } else {
        setModalVisible(true);
      }
    },
    [waiting]
  );

  return (
    <React.Fragment>
      <BuyIn
        waitingList={true}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        minBuyIn={table.minBuyIn}
        maxBuyIn={table.maxBuyIn}
        tableId={tableId}
      />
      <Pressable
        onPress={() => handleJoinWaitingList(buyIn)}
        style={({ pressed }) => [
          {
            opacity: pressed ? 0.5 : 1,
          },
        ]}
      >
        {({ pressed }) => (
          <View style={styles.container}>
            <Text style={styleWithFontScale(20, 14)}>
              <FontAwesome
                name={waiting ? "check-square-o" : "square-o"}
                size={16}
                color="white"
              />
              {waiting
                ? t("TableScreen.Controls.waiting", {
                    value1: position.toString(),
                    value2: players.toString(),
                  })
                : t("TableScreen.Controls.joinWaiting", {
                    value: players.toString(),
                  })}
            </Text>
          </View>
        )}
      </Pressable>
    </React.Fragment>
  );
}
