import * as React from "react";
import {
  ImageBackground,
  StyleSheet,
  View,
  Text,
  ActivityIndicator,
} from "react-native";
//import { useNavigation } from '@react-navigation/native';
import { useWebSocket } from "../contexts/WebSocket";
import { TableProvider, useTable } from "../contexts/Table";

import Header from "../components/Table/Header";
import Table from "../components/Table";
import Panel from "../components/Table/Panel";
import HelpModal from "../components/Table/HelpModal";
import { RootStackScreenProps } from "../types";

export default function TableScreen({
  route,
  navigation,
}: RootStackScreenProps<"Table">) {
  const { tableId } = useWebSocket();

  if (tableId === undefined) {
    navigation.navigate("Root");
  }

  return (
    <TableProvider>
      <ImageBackground
        source={require("../assets/images/theme/default/table/background_landscape.jpg")}
        resizeMode={"cover"}
        style={styles.container}
        fadeDuration={0}
      >
        <View
          style={{
            display: "none",
            position: "absolute",
            top: 20,
            right: 10,
            backgroundColor: "cyan",
            paddingHorizontal: 5,
            borderRadius: 5,
          }}
        >
          <Text style={{ color: "black", fontSize: 12, fontWeight: "bold" }}>
            Beta
          </Text>
        </View>
        <Table />
        <Header />
        <Panel />
        <HelpModal />
      </ImageBackground>
    </TableProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    overflow: "hidden",
  },
});
