import React from "react";
import { View } from "react-native";
import HSlider from "../../../Slider/HSlider";

type Props = {
  minBetting: number | 0;
  maxBetting: number | 1;
  currentBettingValue: number | 0;
  onChangeCurrentBettingValue: (amount: number) => void;
};

export default function ({
  minBetting,
  maxBetting,
  currentBettingValue,
  onChangeCurrentBettingValue,
}: Props) {
  const [sliderBetting, setSliderBetting] = React.useState<number>(0);

  const onChangeSliderBetting = (amount: number) => {
    const value = Math.round(Number(amount));

    setSliderBetting(value);
    //setInputBetting(value.toString());
    onChangeCurrentBettingValue(value);
  };

  React.useEffect(() => {
    setSliderBetting(currentBettingValue);
  }, [currentBettingValue]);

  return (
    <View style={{ flex: 1, width: "100%" }}>
      <View style={{ width: "100%", height: "100%" }}>
        <HSlider
          currentValue={minBetting}
          maximumValue={maxBetting}
          minimumValue={minBetting}
          onValueChange={onChangeSliderBetting}
          steps={maxBetting - minBetting}
        />
      </View>
      {/* <Slider
                style={{ width: '100%', height: '100%' }}
                minimumValue={minBetting}
                maximumValue={maxBetting}
                value={sliderBetting}
                onValueChange={onChangeSliderBetting}
                minimumTrackTintColor="#FFA500"
                maximumTrackTintColor="#FFFFFF"
            /> */}
    </View>
  );
}
