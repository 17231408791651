import React, { createContext, useContext } from "react";
import { TableHistory } from "../types";
import { useWebSocket } from "./WebSocket";

type HistoryContextProps = {
    tableHistory: TableHistory[];
};

export const HistoryContext = createContext({} as HistoryContextProps);

type HistoryProviderProps = {
    children: React.ReactNode;
};

export function HistoryProvider({ children }: HistoryProviderProps) {
    const { socket, tableId } = useWebSocket();
    const [tableHistory, setTableHistory] = React.useState<TableHistory[]>([]);

    React.useEffect(() => {
        if (!socket.connected) {
            return;
        }

        if (tableId !== undefined) {
            socket.on("history", (historyList: TableHistory[]) => {
                setTableHistory(historyList);
                //console.log(historyList);
            });

            //socket.emit("history list", tableId);
        }

        return () => {
            //setTableId(undefined);
            //socket.off("update table " + tableId);
            //socket.emit("leave all rooms");
            //socket.off("action time " + tableId);
            //socket.emit("leave all rooms");
        };
    }, [socket, tableId]);

    return (
        <HistoryContext.Provider value={{ tableHistory }}>
            {children}
        </HistoryContext.Provider>
    );
}

export function useHistory() {
    const context = useContext(HistoryContext);

    if (context === undefined) {
        throw new Error("Context provider undefined.");
    }

    return context;
}
