import React from "react";
import { View } from "react-native";
import DealCard from "./AnimatedCard";
import { useTable } from "../../../../contexts/Table";
import { getDestinationStyle } from "./styles";
import { PokerVariantQuantityCards } from "../../../../types";

export default ({
  onFinishedAnimating,
}: {
  onFinishedAnimating: () => void;
}) => {
  const { table } = useTable();
  const auxNumberOfSeats = table.players.length;
  const auxNumberOfCards = PokerVariantQuantityCards[table.variant];
  const auxHoleCardsArray = Array.apply(null, Array(auxNumberOfCards)).map(
    (a, index) => index
  );
  const [animationFinished, setAnimationFinished] =
    React.useState<boolean>(false);
  const [cardsAnimated, setCardsAnimated] = React.useState<number>(0);
  const [timeoutList, setTimeoutList] = React.useState<NodeJS.Timeout[]>();

  const handleOnCardFinished = (timeOut: NodeJS.Timeout) => {
    timeoutList?.push(timeOut);
    setTimeoutList(timeoutList);
    setCardsAnimated(cardsAnimated + 1);
  };

  React.useEffect(() => {
    return () => {
      timeoutList?.map((timeOut) => {
        clearTimeout(timeOut);
      });
    };
  }, []);

  React.useEffect(() => {
    if (cardsAnimated >= auxNumberOfCards * auxNumberOfSeats) {
      setAnimationFinished(true);
    }
  }, [cardsAnimated]);

  React.useEffect(() => {
    if (animationFinished) {
      onFinishedAnimating();
    }
  }, [animationFinished]);
  const renderInBulkAnimation = () => {
    const animationComponent = [] as any;
    table.players.map((player, seatNumber) => {
      player &&
        !player.waitingBigBlind &&
        !player.sitOut &&
        !player.reBuy &&
        !player.folded &&
        auxHoleCardsArray.map((card, cardIndex) => {
          animationComponent.push(
            <DealCard
              key={seatNumber.toString() + cardIndex.toString()}
              cardNumber={cardIndex}
              seatNumber={seatNumber}
              handSize={auxNumberOfCards}
              onFinishedAnimation={handleOnCardFinished}
            />
          );
        });
    });
    return animationComponent;
  };

  const randerRoundsAnimation = () => {
    const animationComponent = [] as any;
    for (let cardNumber = 0; cardNumber < auxNumberOfCards; cardNumber++) {
      for (let seatNumber = 0; seatNumber < auxNumberOfSeats; seatNumber++) {
        animationComponent.push(
          <DealCard
            key={seatNumber.toString() + cardNumber.toString()}
            cardNumber={cardNumber}
            seatNumber={seatNumber}
            handSize={auxNumberOfCards}
            onFinishedAnimation={handleOnCardFinished}
          />
        );
      }
    }
    return animationComponent;
  };

  const renderDestination = () => {
    const destinationComponent = [] as any;
    table.players.map((player, seatNumber) => {
      destinationComponent.push(
        <View style={[getDestinationStyle(seatNumber, 0, auxNumberOfSeats)]} />
      );
    });
    return destinationComponent;
  };

  return (
    <View
      pointerEvents="none"
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {renderInBulkAnimation()}
    </View>
  );
};
