import React from "react";  
import { FlatList, View } from "react-native";
import { useTable } from "../../../../contexts/Table";
import { useWebSocket } from "../../../../contexts/WebSocket";
import { TableEmoji } from "../../../../types";
import EmojiItem from "./EmojiItem";
import { styles } from "./styles";


export default function EmojiPanel({ emojiList, mobile }: { emojiList: TableEmoji[]; mobile?: boolean }) {

    const { socket, tableId } = useWebSocket();
    const { currentPlayer, setPanelVisible } = useTable();

    const handleEmoji = React.useCallback((emoji: string) => {
        if (!socket.connected) return;
        if (!currentPlayer) return;
        socket.emit('emoji', tableId, currentPlayer.seatNumber, emoji);
    }, []);

    const sendEmoji = (emoji: string) => {
        setPanelVisible(false);
        handleEmoji(emoji);
    }

    function renderComponent() {
        if (mobile) {
            return (
                <FlatList
                    data={emojiList}
                    renderItem={({ item }) => <EmojiItem {...item} onPress={() => {
                        sendEmoji(item.id)
                    }} />}
                    numColumns={3}
                    keyExtractor={(item, index) => index.toString()}
                />
            )
        } else {
            return (
                <FlatList
                    data={emojiList}
                    renderItem={({ item }) => <EmojiItem {...item} onPress={() => {
                        sendEmoji(item.id)
                    }} />}
                    keyExtractor={(item, index) => index.toString()}
                    horizontal
                />
            )
        }
    }

    return (
        <View style={styles.container}>
            {renderComponent()}
        </View>
    )
}