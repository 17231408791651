import {StyleSheet} from "react-native";

export const styles = StyleSheet.create({
    container: {
        borderRadius: 8,
        flexDirection: "row",
        padding: 5,
        justifyContent: "space-between",
        marginVertical: 2,
        alignItems: "center",
    },
    text: {
        color: "white",
        fontSize: 14,
        height: "100%",
    }
})