import React from 'react';
import { MotiView, useAnimationState } from 'moti';
import { stylePotPosition } from '../../Pots/Pot/styles';
import { PlayerProps, PotProps } from '../../../../types';
import Stack from '../../Stack';
import { Easing } from 'react-native-reanimated';
import { getPrizeAnimationDestination } from './styles';

const Prize = ({
    amount,
    seat,
    numberOfSeats,
    delay
}: { amount: number; seat: number; numberOfSeats: number; delay: number }) => {
    const winnerPosition = getPrizeAnimationDestination(seat, numberOfSeats);
    const isWinnerLeftOriented = winnerPosition.right === undefined ? true : false;
    const isWinnerTopOriented = winnerPosition.bottom === undefined ? true : false;
    const intPosXPercent = isWinnerLeftOriented ? winnerPosition.left : winnerPosition.right
    const intPosYPercent = isWinnerTopOriented ? winnerPosition.top : winnerPosition.bottom

    const startingPosition = {
        top: isWinnerTopOriented ? '52%' : undefined,
        left: isWinnerLeftOriented ? '42%' : undefined,
        right: isWinnerLeftOriented ? undefined : "42%",
        bottom: isWinnerTopOriented ? undefined : "38%"
    }
    const finalPosition = {
        top: isWinnerTopOriented ? intPosYPercent : undefined,
        left: isWinnerLeftOriented ? intPosXPercent : undefined,
        right: isWinnerLeftOriented ? undefined : intPosXPercent,
        bottom: isWinnerTopOriented ? undefined : intPosYPercent
    }

    const animationStates = useAnimationState({
        from: { ...startingPosition },
        to: { ...finalPosition },
        end: {
            opacity: 0,
        }
    })

    const handleAnimationFinished = () => {
        if (animationStates.current == "to") {
            animationStates.transitionTo('end')
        }
    }

    return (
        <MotiView
            style={[stylePotPosition(0, 1), { top: undefined, left: undefined, right: undefined, bottom: undefined }]}
            state={animationStates}
            transition={{ type: 'timing', duration: 3000, easing: Easing.inOut(Easing.elastic(0.5)) }}
            onDidAnimate={handleAnimationFinished}
            delay={delay}
        >
            <Stack size={amount} />
        </MotiView>
    )
}

export default ({
    players,
    pot,
    numberOfSeats,
    delay
}: { players: (PlayerProps | null)[]; pot: PotProps; numberOfSeats: number; delay: number }) => {
    let numberOfWinners = pot.winners ? pot.winners.length : 1;

    return (
        <>
            {
                players.map((player, seatNumber) => (
                    pot.winners?.filter((winner) => winner.id === player?.id).map(() => (
                        <Prize key={seatNumber} amount={Math.round((pot.previousAmount ? pot.previousAmount : pot.amount) / numberOfWinners)} seat={seatNumber}
                            numberOfSeats={numberOfSeats} delay={delay} />
                    ))
                ))
            }
        </>
    )
}