import Slider from "@react-native-community/slider";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Button, Pressable, Text, View } from "react-native";
import { useAccount } from "../../../contexts/Account";
import { useWebSocket } from "../../../contexts/WebSocket";
import { monetaryValue } from "../../../util";
import Modal from "../Modal";
import SignIn from "../SignIn";
import { useTranslation } from "react-i18next";

import { styles, styleWithFontScale } from "./styles";

type Props = {
  minBuyIn: number;
  maxBuyIn: number;
  tableId: number | undefined;
  seatNumber?: number;
  waitingList: boolean;
  modalVisible: boolean;
  timer?: number;
  setModalVisible: (modalVisible: boolean) => void;
};

export default function ({
  modalVisible,
  setModalVisible,
  waitingList,
  minBuyIn,
  maxBuyIn,
  tableId,
  seatNumber,
  timer,
}: Props) {
  const { t } = useTranslation();
  const navigation = useNavigation();
  //const { signed } = useAccount();
  const { socket, setTableId } = useWebSocket();
  const { signed, user, wallet, stack } = useAccount();

  const [sliderChanged, setSliderChanged] = React.useState<boolean>(false);
  const [bonus, setBonus] = React.useState<boolean>(false);

  const [sliderMinBuyIn, setSliderMinBuyIn] = React.useState<number>(0);
  const [sliderMaxBuyIn, setSliderMaxBuyIn] = React.useState<number>(0);

  const [sliderBuyIn, setSliderBuyIn] = React.useState<number>(0);

  const [rebuyTimer, setRebuyTimer] = React.useState<number | null>();
  const [helpText, setHelpText] = React.useState<string>(
    `You are buying in for ${monetaryValue(sliderBuyIn)}`
  );

  //const [modalSignInVisible, setModalSignInVisible] = React.useState<boolean>(false);

  const handleSitDownPress = (buyIn: number, tableId: number | undefined) => {
    if (tableId === undefined) return;
    if (!socket.connected) return;
    if (!user) return;

    if (waitingList) {
      socket.emit("join waiting", buyIn, tableId);
      //console.log('join waiting', buyIn, tableId);
    } else {
      //console.log(user.username, tableId, buyIn);
      socket.emit("sit down", tableId, buyIn, seatNumber);
    }

    setModalVisible(!modalVisible);
    setTableId(tableId);
    navigation.navigate("Table");
  };

  const onChangeSliderBuyIn = (amount: number) => {
    setSliderChanged(true);

    const value = Math.round(Number(amount));

    setSliderBuyIn(value);
    //setInputBuyIn(value.toString());
  };

  /*
    const handleModalVisible = () => {
        if (signed) {
            setModalVisible(!modalVisible);
        } else {
            
        }
    }
    */

  /*
    React.useEffect(() => {
        //if (!currentPlayer) {
        //    return;
        //}

        setSliderMinBuyIn(minBuyIn);
        setSliderMaxBuyIn(maxBuyIn);
        setSliderBuyIn(maxBuyIn);

        //setSliderBuyIn(currentBuyInValue);
        //setMinBuyIn(table.buyIn / 2);
        //setMaxBuyIn(table.buyIn);

        if (wallet !== undefined && wallet.balance < maxBuyIn) {
            setSliderMaxBuyIn(wallet.balance);
            setSliderBuyIn(wallet.balance);
        }


    }, [minBuyIn, maxBuyIn]);
    */

  React.useEffect(() => {
    if (timer && modalVisible) {
      let tmp = timer;
      setRebuyTimer(timer);
      const interval = setInterval(() => {
        tmp = tmp - 1;
        timer = tmp;
        setRebuyTimer(timer);
        if (tmp < 0) {
          clearInterval(interval);
          setModalVisible(false);
        }
      }, 1000);
    }

    if (modalVisible && socket.connected) {
      socket.emit("stack size", tableId);
    }

    if (modalVisible) {
      setSliderChanged(false);
    }
  }, [modalVisible]);

  React.useEffect(() => {
    if (
      stack &&
      wallet &&
      wallet.balance >= Math.round(stack.amount) &&
      Math.round(stack.amount) > maxBuyIn
    ) {
      let amount = Math.round(stack.amount);
      setSliderMinBuyIn(amount);
      setSliderMaxBuyIn(amount);
      setSliderBuyIn(amount);
    } else {
      setSliderMinBuyIn(minBuyIn);
      setSliderMaxBuyIn(maxBuyIn);

      if (!sliderChanged || sliderBuyIn < minBuyIn || sliderBuyIn > maxBuyIn) {
        setSliderBuyIn(maxBuyIn);
      }

      if (wallet !== undefined && wallet.balance > minBuyIn) {
        if (wallet !== undefined && wallet.balance < maxBuyIn) {
          setSliderMaxBuyIn(wallet.balance);
          setSliderBuyIn(wallet.balance);
        }
      } else {
        setBonus(true);
        if (wallet !== undefined && wallet.bonus < maxBuyIn) {
          setSliderMaxBuyIn(wallet.bonus);
          setSliderBuyIn(wallet.bonus);
        }
      }
    }
  }, [stack, wallet, minBuyIn, maxBuyIn]);

  React.useEffect(() => {
    if (rebuyTimer !== undefined && rebuyTimer !== null) {
      setHelpText(
        t("ModalBuyIn.youHaveTimeLeft", { value: rebuyTimer.toString() })
      );
    } else {
      setHelpText(
        t("ModalBuyIn.youAreBuyingInFor", {
          value: monetaryValue(sliderBuyIn).toString(),
        })
      );
    }
  }, [sliderBuyIn, rebuyTimer]);

  return (
    <React.Fragment>
      {signed ? (
        user && wallet && (wallet.balance || 0) < minBuyIn && (wallet.bonus || 0) < minBuyIn ? (
          <Modal
            title={t("ModalBuyIn.insufficientFunds")}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            animationType="none"
          >
            <View style={{ flex: 1 }}>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    fontSize: 22,
                  }}
                >
                  {t("ModalBuyIn.currentBalance")}
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    color: "lightblue",
                    fontSize: 20,
                  }}
                >
                  {monetaryValue(wallet?.balance || 0)}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    fontSize: 22,
                  }}
                >
                  {t("ModalBuyIn.buyIn")}
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    color: "lightblue",
                    fontSize: 22,
                  }}
                >
                  {monetaryValue(minBuyIn)}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    textAlign: "center",
                    color: "#CCCCCC",
                    fontSize: 18,
                  }}
                >
                  {t("ModalBuyIn.balanceIsRunningLow")}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Button
                  title={t("ModalBuyIn.buttonBuyChips")}
                  onPress={() => {
                    setModalVisible(false);
                    navigation.navigate("Cashier");
                  }}
                />
              </View>
            </View>
          </Modal>
        ) : (
          <Modal
            title={t("ModalBuyIn.playNow")}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            animationType="none"
          >
            <View style={{ flex: 1 }}>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    fontSize: 22,
                  }}
                >
                  {t("ModalBuyIn.currentBalance")}
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    color: "lightblue",
                    fontSize: 20,
                  }}
                >
                  {bonus ? monetaryValue(wallet?.bonus || 0) : monetaryValue(wallet?.balance || 0)}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text
                    style={{
                      textAlign: "left",
                      color: "#FFFFFF",
                      fontSize: 18,
                    }}
                  >
                    {t("ModalBuyIn.min")}
                  </Text>
                  <Text
                    style={{
                      textAlign: "left",
                      color: "#FFFFFF",
                      fontSize: 20,
                    }}
                  >
                    {monetaryValue(minBuyIn)}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      textAlign: "center",
                      color: "#FFFFFF",
                      fontSize: 20,
                    }}
                  >
                    {t("ModalBuyIn.buyIn")}
                  </Text>
                  <Text
                    style={{
                      textAlign: "center",
                      color: "lightblue",
                      fontSize: 22,
                    }}
                  >
                    {monetaryValue(sliderBuyIn)}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      textAlign: "right",
                      color: "#FFFFFF",
                      fontSize: 18,
                    }}
                  >
                    {t("ModalBuyIn.max")}
                  </Text>
                  <Text
                    style={{
                      textAlign: "right",
                      color: "#FFFFFF",
                      fontSize: 20,
                    }}
                  >
                    {monetaryValue(maxBuyIn)}
                  </Text>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <Slider
                  style={{ width: "100%", paddingVertical: 10 }}
                  minimumValue={sliderMinBuyIn}
                  maximumValue={sliderMaxBuyIn}
                  value={sliderBuyIn}
                  step={100}
                  onValueChange={onChangeSliderBuyIn}
                  minimumTrackTintColor={"#004bF7"}
                  maximumTrackTintColor={"#636363"}
                  thumbTintColor={"#FFF"}
                />
              </View>
              <View>
                <Text
                  style={{
                    textAlign: "center",
                    color: "#CCCCCC",
                    fontSize: 18,
                  }}
                >
                  {helpText}
                </Text>
              </View>
              <View style={{ flex: 1, paddingTop: 10 }}>
                <Pressable
                  style={styles.button}
                  onPress={() => handleSitDownPress(sliderBuyIn, tableId)}
                >
                  <Text style={[styleWithFontScale(20, 14)]}>
                    {t("ModalBuyIn.buttonBuyIn")}
                  </Text>
                </Pressable>
              </View>
            </View>
          </Modal>
        )
      ) : (
        <SignIn
          showButton={false}
          modalVisibleExternal={modalVisible}
          setModalVisibleExternal={setModalVisible}
        />
      )}
    </React.Fragment>
  );
}
