import {StyleSheet} from "react-native";
import {theme} from "../../../../util/GlobalStyles";

export const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: "100%",
    },
    panel: {
        flexDirection: "row",
        position: "absolute",
        width: '70%',
        height: "100%",
        minWidth: 330,
        backgroundColor: theme.colors.background100,
        alignSelf: "flex-start",
        borderWidth: 1,
        borderColor: theme.colors.background90,
        borderBottomWidth: 0,
        borderRightWidth: 0,
    },
    tabBar: {
        backgroundColor: theme.colors.background100,
        flexDirection: "row",
        width: "100%",
        height: 40,
        borderBottomWidth: 1,
        borderBottomColor: "#FFF3",
    },
    activeTab: {
        backgroundColor: theme.colors.background90,
        borderWidth: 1,
        borderColor: theme.colors.background80,
    },
    blockedTab: {},
    tab: {
        paddingHorizontal: 16,
        paddingVertical: 5,
        justifyContent: "center",
        alignItems: "center",
    },
    tabTitle: {
        color: "white",
    },
    contentArea: {
        backgroundColor: "#FFF2",
        flex: 1,
    },
    contentText: {
        color: "#FFF6",
        fontSize: 14,
    },
    closeTab: {
        backgroundColor: theme.colors.background100,
        height: "100%",
        maxWidth: 30,
        borderLeftWidth: 0,
        borderRightWidth: 1,
        borderTopEndRadius: 16,
        justifyContent: "center",
        alignItems: "center",
        borderColor: "#FFF3",
        marginRight: -15,
    }
})