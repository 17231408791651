import React from 'react';
import {useTable} from '../../../../../../contexts/Table';
import {ImageBackground, Pressable, Text} from 'react-native';
import { useTranslation } from 'react-i18next';

import {styleWithFontScale} from './styles';
import { PokerBettingLimit } from '../../../../../../types';

type Props = {
    title: string;
    amount: number;
    onChangeCurrentBettingValue: (amount: number) => void;
}

export default function ({title, amount, onChangeCurrentBettingValue}: Props) {
    const {t} = useTranslation();
    const {currentPlayer, table} = useTable();
    if(title === "POT" && table && table.bettingLimit === PokerBettingLimit.NO_LIMIT) {
        let playerBet = 0;
        if (currentPlayer && currentPlayer.bet) {
            playerBet = currentPlayer.bet;
        }
        let playerStackSize = 0;
        if (currentPlayer && currentPlayer.stackSize) {
            playerStackSize = currentPlayer.stackSize;
        }
        amount = playerBet + playerStackSize;
        title = "ALLIN";
    }

    return (
        <Pressable
            onPress={() => {
                onChangeCurrentBettingValue(amount)
            }}
            style={({pressed}) => [
                {
                    opacity: pressed
                        ? 1
                        : 1
                }, {width: '25%', height: '100%'}
            ]}>
            {({pressed}) => (

                <ImageBackground style={{minWidth: '100%', minHeight: '100%', alignItems: 'center', paddingTop: '20%'}}
                                 source={require('../../../../../../assets/images/theme/default/table/betting_shortcut.png')}
                                 resizeMode={'contain'} fadeDuration={0}>
                    <Text style={styleWithFontScale(20, 14)}>
                        {t("TableScreen.Controls." + title)}
                    </Text>
                </ImageBackground>
            )}
        </Pressable>
    );
}