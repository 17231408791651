import {StyleSheet, ViewStyle} from 'react-native';
import {useDimensions} from '../../../contexts/Dimensions';

export const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        //backgroundColor: 'turquoise',
        //opacity: 0.6
    },
    image: {
        width: "90%",
        height: "90%",
        resizeMode: "contain",
        marginTop: -30,
    }
});

export const styleSeatWithPosition = (seatNumber: number, numberOfSeats: number): ViewStyle => {
    return StyleSheet.flatten([styles.container, getSeatPosition(seatNumber, numberOfSeats)]);
}

export const getSeatPosition = (seatNumber: number, numberOfSeats: number): ViewStyle => {

    const {orientation} = useDimensions();

    switch (orientation) {
        case 'landscape':
            switch (numberOfSeats) {
                case 2:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '15%',
                                height: '22%',
                                left: '42.5%',
                                bottom: '4.5%',
                            }
                        case 1:
                            return {
                                width: '14%',
                                height: '20%',
                                left: '43.5%',
                                top: '4%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 6:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '15%',
                                height: '22%',
                                left: '42.5%',
                                bottom: '4.5%',
                            }
                        case 1:
                            return {
                                width: '14%',
                                height: '20%',
                                left: '13%',
                                bottom: '19%',
                            }
                        case 2:
                            return {
                                width: '14%',
                                height: '20%',
                                left: '10%',
                                top: '12%',
                            }
                        case 3:
                            return {
                                width: '14%',
                                height: '20%',
                                left: '43.5%',
                                top: '4%',
                            }
                        case 4:
                            return {
                                width: '14%',
                                height: '20%',
                                right: '10%',
                                top: '12%',
                            }
                        case 5:
                            return {
                                width: '14%',
                                height: '20%',
                                right: '13%',
                                bottom: '19%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 8:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '15%',
                                height: '22%',
                                left: '42.5%',
                                bottom: '4.5%',
                            }
                        case 1:
                            return {
                                width: '14%',
                                height: '20%',
                                left: '13%',
                                bottom: '19%',
                            }
                        case 2:
                            return {
                                width: '14%',
                                height: '20%',
                                left: '1%',
                                top: '33%',
                            }
                        case 3:
                            return {
                                width: '14%',
                                height: '20%',
                                left: '16%',
                                top: '8%',
                            }
                        case 4:
                            return {
                                width: '14%',
                                height: '20%',
                                left: '43.5%',
                                top: '4%',
                            }
                        case 5:
                            return {
                                width: '14%',
                                height: '20%',
                                right: '16%',
                                top: '8%',
                            }
                        case 6:
                            return {
                                width: '14%',
                                height: '20%',
                                right: '1%',
                                top: '33%',
                            }
                        case 7:
                            return {
                                width: '14%',
                                height: '20%',
                                right: '13%',
                                bottom: '19%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 9:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '15%',
                                height: '22%',
                                left: '42.5%',
                                bottom: '4.5%',
                            }
                        case 1:
                            return {
                                width: '14%',
                                height: '20%',
                                left: '13%',
                                bottom: '19%',
                            }
                        case 2:
                            return {
                                width: '14%',
                                height: '20%',
                                left: '1%',
                                top: '33%',
                            }
                        case 3:
                            return {
                                width: '14%',
                                height: '20%',
                                left: '11.5%',
                                top: '11%',
                            }
                        case 4:
                            return {
                                width: '14%',
                                height: '20%',
                                left: '33.5%',
                                top: '4%',
                            }
                        case 5:
                            return {
                                width: '14%',
                                height: '20%',
                                right: '33.5%',
                                top: '4%',
                            }
                        case 6:
                            return {
                                width: '14%',
                                height: '20%',
                                right: '11.5%',
                                top: '11%',
                            }
                        case 7:
                            return {
                                width: '14%',
                                height: '20%',
                                right: '1%',
                                top: '33%',
                            }
                        case 8:
                            return {
                                width: '14%',
                                height: '20%',
                                right: '13%',
                                bottom: '19%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                default:
                    return {
                        display: 'none',
                    }

            }
        case 'portrait':
            switch (numberOfSeats) {
                case 2:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '30%',
                                height: '18%',
                                left: '2.5%',
                                bottom: '12%',
                            }
                        case 1:
                            return {
                                width: '23%',
                                height: '14%',
                                left: '39%',
                                top: '4%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 6:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '30%',
                                height: '18%',
                                left: '2.5%',
                                bottom: '12%',
                            }
                        case 1:
                            return {
                                width: '23%',
                                height: '14%',
                                left: '3%',
                                bottom: '33.5%',
                            }
                        case 2:
                            return {
                                width: '23%',
                                height: '14%',
                                left: '3%',
                                top: '23.5%',
                            }
                        case 3:
                            return {
                                width: '23%',
                                height: '14%',
                                left: '39%',
                                top: '4%',
                            }
                        case 4:
                            return {
                                width: '23%',
                                height: '14%',
                                right: '3%',
                                top: '23.5%',
                            }
                        case 5:
                            return {
                                width: '23%',
                                height: '14%',
                                right: '3%',
                                bottom: '33.5%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 8:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '30%',
                                height: '18%',
                                left: '2.5%',
                                bottom: '12%',
                            }
                        case 1:
                            return {
                                width: '23%',
                                height: '14%',
                                left: '3%',
                                bottom: '33.5%',
                            }
                        case 2:
                            return {
                                width: '23%',
                                height: '14%',
                                left: '3%',
                                top: '28.5%',
                            }
                        case 3:
                            return {
                                width: '23%',
                                height: '14%',
                                left: '3%',
                                top: '15.75%',
                            }
                        case 4:
                            return {
                                width: '23%',
                                height: '14%',
                                left: '39%',
                                top: '4%',
                            }
                        case 5:
                            return {
                                width: '23%',
                                height: '14%',
                                right: '3%',
                                top: '15.75%',
                            }
                        case 6:
                            return {
                                width: '23%',
                                height: '14%',
                                right: '3%',
                                top: '28.5%',
                            }
                        case 7:
                            return {
                                width: '23%',
                                height: '14%',
                                right: '3%',
                                bottom: '33.5%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 9:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '30%',
                                height: '18%',
                                left: '2.5%',
                                bottom: '12%',
                            }
                        case 1:
                            return {
                                width: '23%',
                                height: '14%',
                                left: '3%',
                                bottom: '32%',
                            }
                        case 2:
                            return {
                                width: '23%',
                                height: '14%',
                                left: '3%',
                                top: '28.5%',
                            }
                        case 3:
                            return {
                                width: '23%',
                                height: '14%',
                                left: '3%',
                                top: '12.5%',
                            }
                        case 4:
                            return {
                                width: '23%',
                                height: '14%',
                                left: '25%',
                                top: '2%',
                            }
                        case 5:
                            return {
                                width: '23%',
                                height: '14%',
                                right: '25%',
                                top: '2%',
                            }
                        case 6:
                            return {
                                width: '23%',
                                height: '14%',
                                right: '3%',
                                top: '12.5%',
                            }
                        case 7:
                            return {
                                width: '23%',
                                height: '14%',
                                right: '3%',
                                top: '28.5%',
                            }
                        case 8:
                            return {
                                width: '23%',
                                height: '14%',
                                right: '3%',
                                bottom: '32%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                default:
                    return {
                        display: 'none',
                    }
            }
        default:
            return {
                display: 'none',
            }
    }
}