import React from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";
import { useTable } from "../../../../../../../contexts/Table";

import { PlayerProps } from "../../../../../../../types";
import { styleContainerColor, styleWithFontScale } from "./styles";

type Props = {
  action: string;
  player: PlayerProps;
};

export default function ({ action, player }: Props) {
  const { t } = useTranslation();
  //if (action === undefined || action === null || action === "") {
  //    return null;
  //}

  const { table } = useTable();
  const [delay, setDelay] = React.useState<number>(1000);
  const [currentAction, setCurrentAction] = React.useState<string>(action);
  const [timeoutAction, setTimeoutAction] = React.useState<NodeJS.Timeout>();

  React.useEffect(() => {
    setDelay(table.isShowdown || table.isShowdownBeforeRiver ? 1000 : 2500);
  }, [table]);

  React.useEffect(() => {
    if (table.lastActorId === player.id) {
      setCurrentAction(action);

      clearTimeout(timeoutAction);

      setTimeoutAction(
        setTimeout(() => {
          setCurrentAction("");
        }, delay)
      );
    } else {
      setCurrentAction("");
    }

    return () => {
      clearTimeout(timeoutAction);
    };
  }, [action, table.lastActorId, player.seatNumber]);

  return (
    currentAction !== "" && (
      <View pointerEvents="none" style={styleContainerColor(action)}>
        <Text
          numberOfLines={1}
          adjustsFontSizeToFit={true}
          allowFontScaling={true}
          lineBreakMode={"clip"}
          style={styleWithFontScale(20, 14, action)}
        >
          {t("TableScreen.PlayerPod.action." + action.toUpperCase())}
        </Text>
      </View>
    )
  );
}
