import React, { ReactNode } from 'react';
import { View } from 'react-native';
import { useTable } from '../../../../../../../contexts/Table';
import { BettingRound, PlayerProps, PokerVariant, PokerVariantQuantityCards } from '../../../../../../../types';
import HoleCard from './HoleCard';
import styles from './styles';
import RememberCardsIcon from '../RememberCardsIcon';
import { useGameOptions } from '../../../../../../../contexts/GameOptions';

type Props = {
    player: PlayerProps;
    seatNumber: number;
    cards: string[];
    foldAnimation?: boolean;
    variant: PokerVariant;
    remembering?: boolean;
    showCards: boolean;
}

export default function ({ player, seatNumber, cards, variant, remembering, showCards }: Props) {
    const { frontDeck } = useGameOptions();
    const { table, currentPlayer } = useTable();
    // const [ isRemembering, setRemembering ] = React.useState(remembering);
    const numberOfCards = cards.length;
    const auxNumberOfCards = PokerVariantQuantityCards[variant];
    const auxCards = Array.apply(null, Array(auxNumberOfCards)).map((a, index) => index + 1);

    const [holeCards, setHoleCards] = React.useState<string[]>([]);
    const [showBackCard, setShowBackCard] = React.useState<boolean>(false);
    const [dealCardsFinished, setDealCardsFinished] = React.useState<boolean>(true);
    const [dealCardsTimeout, setDealCardsTimeout] = React.useState<NodeJS.Timeout | undefined>(undefined);

    // React.useEffect(()=>{
    //     setRemembering(false);
    // },[table.handNumber])

    React.useEffect(() => {
        if (!currentPlayer || (currentPlayer && currentPlayer.id !== player.id)) {
            if (!player.showCards) {
                cards.forEach((card, index) => {
                    cards[index] = "CardBack";
                });
            }
        }

        setHoleCards(cards);

    }, [cards, player.showCards, currentPlayer]);

    React.useEffect(() => {
        let activePlayers = table.players.filter(
            (player) => player && !player.folded && !player.waitingBigBlind
        );

        let backCard = activePlayers.includes(player);

        setShowBackCard(backCard);

        //if (activePlayers.length > 1 && activePlayers.includes(player)) {
        //    setShowBackCard(true);
        //}

    }, [table.players, player]);

    React.useEffect(() => {
        setDealCardsFinished(false);
        setDealCardsTimeout(undefined);
        //console.log(`Trocou de mão para ${table.handNumber}`);
        //console.log(table)
    }, [table.handNumber]);

    function finishDealCards() {
        if (dealCardsFinished === false && dealCardsTimeout === undefined) {
            setDealCardsTimeout(
                setTimeout(() => {
                    setDealCardsFinished(true);
                }, 1500)
            )
        }
    }

    function analiseTable() {
        let tmp = false;
        if (table.currentRound === BettingRound.PRE_FLOP) tmp = true;
        else return tmp;
        if (dealCardsFinished) tmp = false;
        else if (table.currentBet && table.currentBet > table.bigBlind) tmp = false;
        else if (table.players) {
            table.players.map((player, index) => {
                if (player && player.action) tmp = false;
            })
        }
        return tmp;
    }


    const getAnimationComponent = (animation: string) => {
        let handComponent: ReactNode[] = [];
        switch (animation) {
            case 'normal':
                holeCards.map((card, cardNumber) => {
                    handComponent.push(<HoleCard key={cardNumber} frontDeck={frontDeck} cardNumber={cardNumber} card={card}
                        numberOfCards={auxNumberOfCards} seatNumber={seatNumber} />)
                })
                break;
            case 'otherNormal':
                auxCards.map((card, cardNumber) => {
                    handComponent.push(<HoleCard key={cardNumber} frontDeck={frontDeck} cardNumber={cardNumber} card={"CardBack"}
                        numberOfCards={auxNumberOfCards} seatNumber={seatNumber} />)
                })
                break;
            case 'showDown':
                holeCards.map((card, cardNumber) => {
                    if (!player.showCards && !showBackCard) return;
                    handComponent.push(<HoleCard key={cardNumber}  frontDeck={frontDeck} cardNumber={cardNumber} card={card}
                        numberOfCards={auxNumberOfCards} seatNumber={seatNumber}
                        showDownAnimation />);
                })
                break;
            case 'dealCards':
                finishDealCards();
                holeCards.map((card, cardNumber) => {
                    handComponent.push(<HoleCard key={cardNumber}  frontDeck={frontDeck} cardNumber={cardNumber} card={card}
                        numberOfCards={auxNumberOfCards} seatNumber={seatNumber}
                        dealAnimation />)
                })
                break;
            case 'otherDealCards':
                finishDealCards();
                auxCards.map((card, cardNumber) => {
                    handComponent.push(<HoleCard key={cardNumber} frontDeck={frontDeck}  cardNumber={cardNumber} card={"CardBack"}
                        numberOfCards={auxNumberOfCards} seatNumber={seatNumber}
                        dealAnimation />)
                }
                )
                break;
            case 'fold':
                auxCards.map((card, cardNumber) => {
                    handComponent.push(<HoleCard key={cardNumber} frontDeck={frontDeck}  cardNumber={cardNumber} card={"CardBack"}
                        numberOfCards={auxNumberOfCards} seatNumber={seatNumber}
                        foldAnimation />)
                })
                remembering ?
                    holeCards.map((card, cardNumber) => {
                        handComponent.push(<HoleCard key={card} frontDeck={frontDeck}  cardNumber={cardNumber} card={card}
                            numberOfCards={auxNumberOfCards} seatNumber={seatNumber} grayOut />)
                    })
                    :
                    handComponent.push(<RememberCardsIcon key={'rememberCardsIcon'} />);
                break;
            case 'otherFold':
                auxCards.map((card, cardNumber) => {
                    handComponent.push(<HoleCard key={cardNumber} frontDeck={frontDeck}  cardNumber={cardNumber} card={"CardBack"}
                        numberOfCards={auxNumberOfCards} seatNumber={seatNumber}
                        foldAnimation />)
                })
            default:
                handComponent.push(<React.Fragment key={'holeCardfragment'}></React.Fragment>)
                break;
        }
        return handComponent;
    }

    const renderAnimation = () => {
        let animationComponent: ReactNode;
        table.isShowdown ?
            (
                animationComponent = getAnimationComponent('showDown')
            )
            :
            (
                currentPlayer && currentPlayer.id === player.id ?
                    (
                        // jogador
                        player.folded ?
                            (
                                animationComponent = getAnimationComponent('fold')
                            )
                            :
                            (
                                //exibir mao
                                table.currentRound === BettingRound.PRE_FLOP && analiseTable() ?
                                    (
                                        animationComponent = getAnimationComponent('dealCards')
                                    )
                                    :
                                    (
                                        animationComponent = getAnimationComponent('normal')
                                    )
                            )
                    ) :
                    (
                        // nao jogador
                        player.folded ?
                            (
                                animationComponent = getAnimationComponent('otherfold')
                            ) :
                            (
                                table.currentRound === BettingRound.PRE_FLOP && analiseTable() ?
                                    (
                                        animationComponent = getAnimationComponent('otherDealCards')
                                    )
                                    :
                                    (
                                        animationComponent = getAnimationComponent('otherNormal')
                                    )
                            )
                    )
            )
        return animationComponent;
    }

    return (
        <View pointerEvents='none' style={[styles.container]}>
            {
                renderAnimation()
            }
        </View>
    );
}