export default {
  ace2ace_portrait: require("../assets/images/theme/default/tables/portrait/table_ace2ace.png"),
  ace2ace_landscape: require("../assets/images/theme/default/tables/landscape/table_ace2ace.png"),
  //pokerfi_portrait: require("../assets/images/theme/default/tables/portrait/table_pokerfi.png"),
  //pokerfi_landscape: require("../assets/images/theme/default/tables/landscape/table_pokerfi.png"),
  julialuz_portrait: require("../assets/images/theme/default/tables/portrait/table_julialuz.png"),
  julialuz_landscape: require("../assets/images/theme/default/tables/landscape/table_julialuz.png"),
  julialuz2_portrait: require("../assets/images/theme/default/tables/portrait/table_julialuz2.png"),
  julialuz2_landscape: require("../assets/images/theme/default/tables/landscape/table_julialuz2.png"),
} as any;
