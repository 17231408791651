import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, Text, View } from "react-native";
import { useHistory } from "../../../../contexts/History";
import { useTable } from "../../../../contexts/Table";
import { PokerBettingLimitAbbr, PokerVariantAbbr } from "../../../../types";
import { monetaryValue } from "../../../../util";
import InfoItem from "./InfoItem";
import { styles } from "./styles";

type InfoProps = {
  id: number;
  timestamp: string;
  info: string;
};

export default function InfoPanel() {
  const { t } = useTranslation();
  const { table } = useTable();
  const { tableHistory } = useHistory();
  const scrollRef = useRef<ScrollView>(null);
  const [messages, setMessages] = useState<string[][]>([]);

  React.useEffect(() => {
    let buildMessages: string[][] = [[]];
    if (table && table.history && table.history.length > 0) {
      buildMessages.unshift(table.history);
    } else {
      buildMessages.unshift(["..."]);
    }
    if (tableHistory.length >= 1 && tableHistory[0].content.history) {
      let historyElement = tableHistory[0].content.history;
      if (historyElement[historyElement.length - 1] !== " ") {
        historyElement.push("##### End of hand #####");
        historyElement.push(" ");
      }
      buildMessages.unshift(historyElement);
    }
    if (tableHistory.length >= 2 && tableHistory[1].content.history) {
      let historyElement = tableHistory[1].content.history;
      if (historyElement[historyElement.length - 1] !== " ") {
        historyElement.push("##### End of hand #####");
        historyElement.push(" ");
      }
      buildMessages.unshift(historyElement);
    }
    setMessages(buildMessages);
  }, [table.history, tableHistory]);

  return (
    <View style={styles.container}>
      <View style={styles.infoArea}>
        {/*<View style={{ flexDirection: "row" }}>
                    <Text style={[{ color: '#FFFFFF', fontSize: 14, width: "100%" }]}>Greetings!</Text>
                </View>*/}
        <View style={{ flexDirection: "row", width: "100%" }}>
          <Text
            style={[
              {
                color: "#FFFFFF",
                fontSize: 14,
                width: "40%",
              },
            ]}
          >
            {t("TableScreen.infoPanel.table")} {table.id} ({table.name}){" "}
          </Text>
          <Text
            style={[
              {
                color: "#FFFFFF",
                fontSize: 14,
              },
            ]}
          >
            {PokerBettingLimitAbbr[table.bettingLimit]}{" "}
            {PokerVariantAbbr[table.variant]}
          </Text>
        </View>
        <View style={{ flexDirection: "row", width: "100%" }}>
          <Text
            style={[
              {
                color: "#FFFFFF",
                fontSize: 14,
                width: "40%",
              },
            ]}
          >
            Blinds: {monetaryValue(table.smallBlind)}/
            {monetaryValue(table.bigBlind)}{" "}
          </Text>
          <Text
            style={[
              {
                color: "#FFFFFF",
                fontSize: 14,
              },
            ]}
          >
            Buy-in: {monetaryValue(table.minBuyIn)} -{" "}
            {monetaryValue(table.maxBuyIn)}{" "}
          </Text>
        </View>
        <View style={{ flexDirection: "row", width: "100%" }}>
          <Text style={[{ color: "#FFFFFF", fontSize: 14, width: "40%" }]}>
            {t("TableScreen.infoPanel.actionTime")}15s{" "}
          </Text>
          <Text style={[{ color: "#FFFFFF", fontSize: 14 }]}>
            {t("TableScreen.infoPanel.timeBankTime")}45s
          </Text>
        </View>
      </View>
      <ScrollView
        ref={scrollRef}
        showsVerticalScrollIndicator={false}
        onContentSizeChange={() => {
          scrollRef.current?.scrollToEnd({ animated: true });
        }}
      >
        {
          //table?.history?.map((info, index) => <InfoItem key={index} text={info}/>)
          messages.map((message) =>
            message.map((info, index) => <InfoItem key={index} text={info} />)
          )
        }
      </ScrollView>
    </View>
  );
}
