import {StyleSheet} from "react-native";

export const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    messagesArea: {},
    inputArea: {
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        height: 42,
        width: "100%",
        marginVertical: 16
    },
    inputBox: {
        flex: 1,
        backgroundColor: "#FFF2",
        borderRadius: 16,
        flexGrow: 2,
    },
    input: {
        paddingVertical: 5,
        paddingHorizontal: 10,
        fontSize: 14,
        color: "white",
        marginHorizontal: 10,
    }
})