/**
 * Learn more about using TypeScript with React Navigation:
 * https://reactnavigation.org/docs/typescript/
 */

import { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import {
  CompositeScreenProps,
  NavigatorScreenParams,
} from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Dispatch, SetStateAction } from "react";
import { ImageSourcePropType } from "react-native";

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }

  interface Window {
    __REACT_DEVTOOLS_GLOBAL_HOOK__: any;
  }
}

// Declare gTM dataLayer array.
declare global {
  interface Window {
    dataLayer: any[];
  }
}

export type RootStackParamList = {
  SignIn: undefined;
  SignUp: undefined;
  Table: { id?: number | undefined; rebuy?: boolean | undefined } | undefined;
  Root: NavigatorScreenParams<RootTabParamList> | undefined;
  Modal: undefined;
  PlayBonds: { token?: string } | undefined;
  Cashier: undefined;
  NotFound: undefined;
  Maintenance: undefined;
};

export type RootStackScreenProps<Screen extends keyof RootStackParamList> =
  NativeStackScreenProps<RootStackParamList, Screen>;

export type RootTabParamList = {
  Lobby: { token?: string } | undefined;
  Rakeback: undefined;
  Cash: undefined;
  Tournament: undefined;
  Profile: undefined;
};

export type RootTabScreenProps<Screen extends keyof RootTabParamList> =
  CompositeScreenProps<
    BottomTabScreenProps<RootTabParamList, Screen>,
    NativeStackScreenProps<RootStackParamList>
  >;

export type WalletProps = {
  id: number;
  balance: number;
  bonus: number;
};

export type StackProps = {
  id: number;
  amount: number;
};

export type TransactionProps = {
  id: number;
  userId: number;
  type: string;
  amount: number;
  externalTransactionId: string;
  createdAt: string;
};

export type UserProps = {
  id: number;
  avatar: string;
  username: string;
  email: string;
  name: string;
  document: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  phoneNumber: string;
  birthDate: string;
  profileComplete: boolean;
  acceptedTerms: boolean;
  emailMarketing: boolean;
  provider: string;
  canWithdrawDeposit: boolean;
  level: LevelType;
  points: number;
  marks: number;

  rakebackStructure: RakebackStructureProps;
};

export type TablesProps = {
  id: number;
  name: string;
  theme: TableTheme;
  variant: PokerVariant;
  bettingLimit: PokerBettingLimit;
  minBuyIn: number;
  maxBuyIn: number;
  smallBlind: number;
  bigBlind: number;
  currentPosition?: number;
  players: (PlayersProps | null)[];
  maxSeats: number;
};

export type MultitableType = {
  tableId: number;
  playerId: number;
  currentActor: boolean;
  holeCards: string[];
};

export type PlayerListType = {
  username: string;
  stacksize: number;
  level: LevelType;
  dealer: boolean;
};

export type TournamentTableListType = {
  id: number;
  tournamentTableId: number;
  name: string;
  playersCount: number;
  biggerStack: number;
  smallestStack: number;
  isOnTable: boolean;
};

export type TableListType = {
  id: number;
  name: string;
  theme: string;
  variant: PokerVariant;
  bettingLimit: PokerBettingLimit;
  stacks: string;
  type: string;
  players: string;
  buyIn: string;
  playing: boolean;
  playerList: PlayerListType[] | [];
};

export type TableHistoryProps = {
  id: number;
  handNumber: number;
  pots: TableHistoryPotProps[];
  communityCards: string[];
  history: string[];
};

export type TableHistoryPotProps = {
  id: number;
  amount: number;
  winners: TableHistoryPotWinnerProps[];
};

export type TableHistoryPotWinnerProps = {
  id: number;
  username: string;
  holeCards: string[];
};

export type TableHistory = {
  id: number;
  tableId: number;
  handNumber: number;
  content: TableHistoryProps;
  createdAt: number;
};

export type PlayersProps = {
  id: number;
  seatNumber: number;
  holeCards: string[];
};

export type ChatMessageUserProps = {
  id: number;
  username: string;
};

export type ChatMessageProps = {
  tableId: number;
  userId: number;
  user: ChatMessageUserProps;
  content: string;
  createdAt: number;
};

export type SettingProps = {
  id: number;
  production: boolean;
  appVersion: number;
  apiVersion: number;
  forceUpdate: boolean;
  maintenance: boolean;
  maintenanceMessage: string;
  scheduledMaintenance: boolean;
  scheduledMaintenanceMessage: string;
  createdAt: number;
};

export type Announcement = {
  id: number;
  content: string;
  actionDescription: string;
  actionActive: boolean;
  actionHref: string;
  active: boolean;
  createdAt: number;
};

export type EmojiProps = {
  tableId: number;
  seatNumber: number;
  emoji: string;
};

export enum PokerVariantAbbr {
  TEXAS_HOLDEM = "H",
  OMAHA = "O",
  OMAHA_5_CARD = "O-5",
}

export enum PokerBettingLimitAbbr {
  NO_LIMIT = "NL",
  POT_LIMIT = "PL",
}

export enum PokerVariantDescription {
  TEXAS_HOLDEM = "Texas Hold'em",
  OMAHA = "Omaha",
  OMAHA_5_CARD = "5 Card Omaha",
}

export enum PokerBettingLimitDescription {
  NO_LIMIT = "No Limit",
  POT_LIMIT = "Pot Limit",
}

export enum PokerVariantQuantityCards {
  TEXAS_HOLDEM = 2,
  OMAHA = 4,
  OMAHA_5_CARD = 5,
}

export type TableProps = {
  id: number;
  tournamentMode: boolean;
  tournamentId: number | null;
  tournamentTableId: number | null;
  tournamentStatus: TournamentStatus | null;
  name: string;
  //debug: boolean;
  theme: TableTheme;
  variant: PokerVariant;
  bettingLimit: PokerBettingLimit;
  minBuyIn: number;
  maxBuyIn: number;
  smallBlind: number;
  bigBlind: number;
  totalPot: number;
  previousTotalPot: number;
  currentBet: number;
  betAmounts?: BetProps;
  legalActions?: string[];
  communityCards: string[];
  currentPosition?: number;
  lastActorId?: number;
  currentRound?: BettingRound;
  dealerPosition: number;
  handNumber: number;
  players: (PlayerProps | null)[];
  pots: PotProps[];
  //winners?: WinnersProps[],
  history?: string[];
  waitingPlayers: WaitingPlayers[];
  addChipsPlayers: AddChipsPlayers[];
  //pauseNextHand: boolean;
  //showBoard: boolean;
  isShowdown: boolean;
  //_historySave: boolean;
  isShowdownBeforeRiver: boolean;
};

export type HandHistoryProps = {
  handId: string;
  board: string[];
  pots: PotProps[];
};

export type WaitingPlayers = {
  id: number;
  buyIn: number;
};

export type AddChipsPlayers = {
  id: number;
  chips: number;
};

export type WinnersProps = {
  id: number;
};

export type PotProps = {
  amount: number;
  previousAmount: number;
  eligiblePlayers: PlayerProps[] | undefined;
  winners?: PlayerProps[] | undefined;
  rake?: RakeProps | undefined;
};

export type RakeProps = {
  amount: number;
  rate: number;
};

export type PlayerProps = {
  id: number;
  seatNumber: number;
  username: string;
  avatar: string;
  level: LevelType;
  stackSize: number;
  previousStackSize?: number;
  action?: string;
  bet: number;
  folded: boolean;
  left: boolean;
  reBuy: boolean;
  sitOut: boolean;
  orbitSitOut: number;
  sitOutNextHand: boolean;
  disconnected: boolean;
  holeCards: string[];
  handName?: string;
  showCards: boolean;
  legalActions?: string[];
  betAmounts?: BetProps;
  winner?: boolean;

  useBonus: boolean;
  payBigBlind: boolean;
  waitingBigBlind: boolean;

  preAction?: PreAction;
  timeBank: number;
  useTimeBank: boolean;
  usingTimeBank: boolean;

  muckCards: boolean;
};

export type Card = {
  _rank: string;
  _suit: string;
};

export type TableEmoji = {
  id: string;
  name: string;
  code: string;
  duration: number;
  sprite: ImageSourcePropType;
};

export type AvatarProps = {
  id: string;
  name: string;
  code: string;
  sprite: ImageSourcePropType;
};

export enum PreAction {
  FOLD = "fold",
  FOLD_CHECK = "fold_check",
  CHECK = "check",
  CALL = "call",
  CALL_ANY_CHECK = "call_any_check",
}

export type ConfigOptionProps = {
  type: string;
  label: string;
  value: any;
  configType: ConfigOptionType;
  callbackFunction: (value: any) => void;
  textColor?: string;
};

export type TableDeviceProps = {
  id: string;
};

export type BetProps = {
  call: number;
  raise: RaiseProps;
};

export type RaiseProps = {
  minimum: number;
  maximum: number;
  shortcuts: ShortcutProps[];
};

export type ShortcutProps = {
  shortcut: string;
  amount: number;
};
export type LayoutProps = {
  width: number;
  height: number;
  x: number;
  y: number;
  pageX: number;
  pageY: number;
};

export type NativeEventProps = {
  layout: LayoutProps;
  target: number;
};

export type FilterOptionsProps = {
  texasHoldemOption: boolean;
  omahaOption: boolean;
  noLimitOption: boolean;
  potLimitOption: boolean;
  buyInOption: number;
};
export enum BettingRound {
  PRE_FLOP = "pre-flop",
  FLOP = "flop",
  TURN = "turn",
  RIVER = "river",
}

export enum TableTheme {
  ACE2ACE = "ace2ace",
  POKERFI = "pokerfi",
  JULIALUZ = "julialuz",
  JULIALUZ2 = "julialuz2",
}

export enum LevelType {
  GUEST = "GUEST",
  STONE = "STONE",
  IRON = "IRON",
  SILVER = "SILVER",
  GOLD = "GOLD",
  PLATINUM = "PLATINUM",
  DIAMOND = "DIAMOND",
  BLUE_ACE = "BLUE_ACE",
}

export enum ConfigOptionType {
  ALPHANUMERIC_EDIT,
  TOGGLE_EDIT,
  SLIDER_EDIT,
  PURCHASE_EDIT,
  BUTTON,
}

export type TabProps = {
  id: number;
  title: string;
  isActive: boolean;
  onPress: () => void;
};

export type RakebackRewardProps = {
  id: number;
  userId: number;
  tableId: number;
  handNumber: number;
  level: LevelType;
  points: number;
  mark: number;
  reward: number;
  randomNumber: number;
  drawnAt: string;
  rakebackStructureId: number;
  createdAt: string;
  rakebackStructure: RakebackStructureProps;
};

export type RakebackStructureProps = {
  id: number;
  level: LevelType;
  mark: number;
  totalMarks: number;
  marksToStay: number;
  dropToId: number;
  basePoints: number;
  maxPoints: number;
  nextLevel: LevelType | null;
  points: number;
  createdAt: string;
};

export type CashierTabsProps = {
  tabs: TabProps[];
  selectedTab: number;
  setSelectedTab?: Dispatch<SetStateAction<number>>;
  onTabClicked: (value: number) => void;
};

export enum PokerTournamentVariant {
  SIT_N_GO = "SIT_N_GO",
}

export enum PokerBettingLimit {
  NO_LIMIT = "NO_LIMIT",
  POT_LIMIT = "POT_LIMIT",
}

export enum PokerVariant {
  TEXAS_HOLDEM = "TEXAS_HOLDEM",
  OMAHA = "OMAHA",
  OMAHA_5_CARD = "OMAHA_5_CARD",
}

export enum TournamentStatus {
  REGISTERING = "REGISTERING",
  RUNNING = "RUNNING",
  BREAK = "BREAK",
  BALANCING = "BALANCING",
  ENDED = "ENDED",
}

/**
 * Model Tournament
 *
 */
export type Tournament = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  availableAt: Date;
  startedAt: Date | null;
  endedAt: Date | null;
  name: string;
  description: string | null;
  minBuyIn: number;
  maxBuyIn: number;
  pokerTournamentVariant: PokerTournamentVariant;
  pokerVariant: PokerVariant;
  pokerBettingLimit: PokerBettingLimit;
  quantityPlayersPerTable: number;
  minRegistrations: number;
  defaultPlayerStack: number;
  maxRebuyPerPlayer: number | null;
  biggerStack: number | null;
  averageStack: number | null;
  smallestStack: number | null;
  blindScheduleId: number | null;
  blindIncreaseTimer: number | null;
  blindStructureId: number;
  payoutStructureId: number;
  blindSchedule: BlindSchedule;
  status: TournamentStatus;
};

/**
 * Model BlindSchedule
 *
 */
export type BlindSchedule = {
  id: number;
  blindStructureId: number;
  level: number;
  createdAt: Date;
  rebuy: boolean;
  rebuyValue: number | null;
  smallBlind: number;
  bigBlind: number;
  ante: number | null;
  duration: number | null;
};

/**
 * Model PayoutStructure
 *
 */
export type PayoutSchedule = {
  id: number;
  payoutStructureId: number;
  createdAt: Date;
  position: number;
  minEntries: number;
  maxEntries: number;
  percentage: number;
};

/**
 * Model RebuyOption
 *
 */
export type RebuyOption = {
  id: number;
  createdAt: Date;
  tournamentId: number;
  value: number;
};

export enum RegistrationStatus {
  WAITING = "WAITING",
  REGISTERED = "REGISTERED",
  CANCELED = "CANCELED",
}

/**
 * Model Registration
 *
 */
export type Registration = {
  id: number;
  createdAt: Date;
  finishedAt: Date;
  tournamentId: number;
  position: number | null;
  userId: number;
  tableId: number | null;
  stack: number | null;
  award: number | null;
  user: {
    id: number;
    username: string;
  };
  table: {
    tableId: number | null;
    tournamentTableId: number | null;
  } | null;
  status: RegistrationStatus;
};

/**
 * Model AuditLog
 *
 */
export type AuditLog = {
  id: number;
  createdAt: Date;
  userId: number | null;
  tableId: number | null;
  tournamentId: number | null;
  entryType: EntryType;
};

export enum EntryType {
  OPERATIONAL = "OPERATIONAL",
  MOVING_PLAYERS = "MOVING_PLAYERS",
  PLAYER_STACK_UPDATE = "PLAYER_STACK_UPDATE",
  PLAYER_REQUESTING_INGRESS = "PLAYER_REQUESTING_INGRESS",
  PLAYER_CANCELING_INGRESS = "PLAYER_CANCELING_INGRESS",
  APPROVING_PLAYER = "APPROVING_PLAYER",
  REMOVING_PLAYER = "REMOVING_PLAYER",
  TABLE_CREATION = "TABLE_CREATION",
  PLAYER_REBUYING = "PLAYER_REBUYING",
  PLAYER_LEAVING_TOURNAMENT = "PLAYER_LEAVING_TOURNAMENT",
}
