import { StyleSheet, ViewStyle } from "react-native";
import { useDimensions } from "../../../contexts/Dimensions";
import { theme } from "../../../util/GlobalStyles";

type ModalType = "window" | "fullscreen" | "bottom" | "fullheight";

export const styleModal = (type: ModalType): ViewStyle => {
  return StyleSheet.flatten([styles.modalView, getStyleModal(type)]);
};

export const getStyleModal = (type: ModalType): ViewStyle => {
  const { orientation } = useDimensions();

  switch (orientation) {
    case "landscape":
      switch (type) {
        case "window":
          return {
            maxWidth: 400,
            width: "100%",
            height: "50%",
          };
        case "fullscreen":
          return {
            maxWidth: 400,
            width: "100%",
            height: "100%",
          };
        case "bottom":
          return {
            maxWidth: 400,
            width: "100%",
            height: "60%",
            //bottom: '-10%',
          };
        case "fullheight":
          return {
            maxWidth: 400,
            width: "100%",
            height: "90%",
          };
        default:
          return {
            display: "none",
          };
      }
    case "portrait":
      switch (type) {
        case "window":
          return {
            width: "100%",
            height: "100%",
            margin: 0,
          };
        case "fullscreen":
          return {
            width: "100%",
            height: "100%",
            margin: 0,
          };
        case "bottom":
          return {
            width: "100%",
            height: "100%",
            margin: 0,
          };
        case "fullheight":
          return {
            width: "100%",
            height: "100%",
            margin: 0,
          };
        default:
          return {
            display: "none",
          };
      }
    default:
      return {
        display: "none",
      };
  }
};

export const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    //marginTop: 22,
    backgroundColor: "rgba(0,0,0, 0.6)",
  },
  modalView: {
    width: "100%",
    height: "100%",
    margin: 20,
    backgroundColor: theme.colors.background100,
    borderRadius: 10,
    borderColor: theme.colors.primary,
    borderWidth: 2,
    //padding: 35,
    //alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.25,
    shadowRadius: 25,
    elevation: 5,
  },
  modalContent: {
    flex: 1,
    padding: 10,
  },
  button: {
    //borderRadius: 20,
    paddingHorizontal: 14,
    paddingVertical: 10,
    //elevation: 2
  },
  buttonOpen: {
    backgroundColor: "green",
  },
  buttonClose: {
    //backgroundColor: "#2196F3",
    position: "absolute",
    top: 0,
    right: 0,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalTitle: {
    flexDirection: "row",
    marginBottom: 10,
    //height: 30,
    //textAlign: "center",
    justifyContent: "space-between",
    alignItems: "center",
    //color: "white",
    // backgroundColor: theme.colors.background90,
    // borderTopLeftRadius: 8,
    // borderTopRightRadius: 8,
  },
  modalTitleText: {
    marginLeft: 15,
    textAlign: "center",
    textAlignVertical: "center",
    color: "white",
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: "bold",
  },
});
