import AsyncStorage from "@react-native-async-storage/async-storage";
import React from "react";
import { Text, View } from "react-native";
import { Socket, io } from "socket.io-client";
import customParser from "socket.io-msgpack-parser";

interface WebSocketContextProps {
  socket: Socket;
  //setToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  tableId: number | undefined;
  setTableId: (id: number | undefined) => void;
  refresh: () => void;
  connected: boolean;
  reconnect: () => void;
  disconnect: () => void;
  emitter: (message: string) => void;
}

export const WebSocketContext = React.createContext(
  {} as WebSocketContextProps
);

type WebSocketProviderProps = {
  children: React.ReactNode;
};

const SOCKET_URI = "https://api.ace2ace.poker/";

export const PERSISTENCE_KEY_ACCESS_TOKEN = "ACCESS_TOKEN";
export const PERSISTENCE_KEY_REFRESH_TOKEN = "REFRESH_TOKEN";

export function WebSocketProvider({ children }: WebSocketProviderProps) {
  const ref = React.useRef<Socket>({} as Socket);
  const [enabled, setEnabled] = React.useState<boolean>(true);
  const [connected, setConnected] = React.useState<boolean>(false);

  const [tableId, setTableId] = React.useState<number | undefined>(undefined);

  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    const socket = io(SOCKET_URI, {
      parser: customParser,
      transports: ["websocket", "polling"], // use WebSocket first, if available
      forceNew: true,
      //autoConnect: true,
      //reconnection: true,
      //reconnectionDelay: 1000,
      //reconnectionDelayMax: 5000,
      //reconnectionAttempts: Infinity,
      // reconnectionAttempts: 10,
      // path: "/echo",
      auth: async (cb) => {
        cb({
          token: await AsyncStorage.getItem(PERSISTENCE_KEY_ACCESS_TOKEN),
        });
      },
    });

    socket.on("connect_error", (err) => {
      setConnected(socket.connected);

      //setTimeout(() => {
      //    console.log("setTimeout socket.connect");
      //    socket.connect();
      //}, 2000);

      //setConnected(socket.connected);
      //console.log(err instanceof Error); // true
      //console.log(err.message); // not authorized
      // @ts-ignore
      //console.log(err.data); // { content: "Please retry later" }
    });

    /*
        socket.on("connect_error", async (err) => {
            if (err.message === "invalid credentials") {
                // @ts-ignore
                socket.auth.token = await AsyncStorage.getItem(PERSISTENCE_KEY_REFRESH_TOKEN);
                socket.connect();
            }

            // revert to classic upgrade
            // socket.io.opts.transports = ["polling", "websocket"];
        });
        */

    socket.on("connect", () => {
      setConnected(socket.connected);
      //console.log("connect: " + socket.connected); // true
      //console.log("socket: " + socket.id); // x8WIv7-mJelg7on_ALbx
    });

    socket.on("disconnect", (reason) => {
      setConnected(socket.connected);
      //console.log("connect: " + socket.connected); // true
      //console.log("disconnect: " + socket.connected); // false
      //console.log("socket: " + socket.id); // undefined

      // In the first two cases (explicit disconnection), the client will not try to reconnect and you need to manually call socket.connect().
      if (reason === "io server disconnect") {
        // The server has forcefully disconnected the socket with socket.disconnect()
        socket.connect();
      } else if (reason === "io client disconnect") {
        // The socket was manually disconnected using socket.disconnect()
        // socket.connect();
      } else if (reason === "ping timeout") {
        // The server did not send a PING within the pingInterval + pingTimeout range
      } else if (reason === "transport close") {
        // The connection was closed (example: the user has lost connection, or the network was changed from WiFi to 4G)
      } else if (reason === "transport error") {
        // The connection has encountered an error (example: the server was killed during a HTTP long-polling cycle)
      } else {
        // else the socket will automatically try to reconnect
      }
    });

    //socket.io.on("error", (error) => {
    //    console.log("error" + error);
    //});

    // attempt (Number) reconnection attempt number
    socket.io.on("reconnect_attempt", (attempt) => {
      setConnected(socket.connected);
      //console.log("reconnect_attempt " + attempt);
    });

    // attempt (Number) reconnection attempt number
    socket.io.on("reconnect", (attempt) => {
      //console.log("reconnect " + attempt);

      //socket.connect();
      setConnected(socket.connected);
      //console.log("connect: " + socket.connected); // true

      //console.log("connect: " + socket.connected); // true
      //console.log("socket: " + socket.id); // x8WIv7-mJelg7on_ALbx
    });

    socket.io.on("reconnect_error", (error) => {
      setConnected(socket.connected);
      //console.log("reconnect_error " + error);
    });

    socket.io.on("reconnect_failed", () => {
      setConnected(socket.connected);
      //console.log("reconnect_failed");
    });

    //socket.onAny((eventName, ...args) => {
    //    console.log(`eventName: ${eventName}, args: ${args.join(', ')}`);
    //});

    ref.current = socket;

    return () => {
      //console.log("socket remove all listeners and disconnect");
      //socket.removeAllListeners();
      //socket.disconnect();
    };
  }, [enabled]);

  const reconnect = React.useCallback(() => {
    //console.log("reconnect function");
    if (!ref.current.connected) {
      ref.current.connect();
    }
  }, []);

  const disconnect = React.useCallback(() => {
    //console.log("disconnect function");
    if (ref.current.connected) {
      ref.current.disconnect();
    }
  }, []);

  const emitter = React.useCallback((message: string) => {
    //console.log("emitter function");
    if (ref.current.connected) {
      ref.current.emit("message", message);
    }
  }, []);

  const refresh = React.useCallback(async () => {
    //console.log("updated connection");
    if (ref.current.connected) {
      ref.current.disconnect();
    }

    // @ts-ignore
    ref.current.auth.token = await AsyncStorage.getItem(
      PERSISTENCE_KEY_ACCESS_TOKEN
    );
    ref.current.connect();
  }, []);

  return (
    <WebSocketContext.Provider
      value={{
        socket: ref.current,
        tableId,
        setTableId,
        refresh,
        connected,
        reconnect,
        disconnect,
        emitter,
      }}
    >
      {children}
      {!connected ? (
        <View
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            padding: 5,
            backgroundColor: "black",
            borderRadius: 5,
          }}
        >
          <Text style={{ textAlign: "center", color: "#FFFFFF" }}>
            Conectando...
          </Text>
        </View>
      ) : (
        <View style={{ display: "none" }} />
      )}
    </WebSocketContext.Provider>
  );
}

export function useWebSocket() {
  const context = React.useContext(WebSocketContext);

  if (context === undefined) {
    throw new Error("Context provider undefined.");
  }

  return context;
}
