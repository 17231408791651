import React from "react";
import { Image, View } from "react-native";
import { PlayerProps } from "../../../../../../types";
import Background from "../../../../../../assets/images/theme/default/table/avatar_background.png";
import NoAvatar from "../../../../../../assets/images/theme/default/avatars/thumbnails/avatar_placeholder.png";
import styles from "./styles";
import AvatarCollection from "../../../../../../constants/AvatarCollection";

type Props = {
  player: PlayerProps;
};

export default function ({ player }: Props) {
  return (
    <View
      style={[
        styles.container,
        player.left || player.folded ? { opacity: 0.6 } : {},
      ]}
    >
      <Image
        source={Background}
        style={{
          position: "absolute",
          width: "95%",
          height: "95%",
          resizeMode: "contain",
        }}
      />
      <View
        style={{
          width: "90%",
          height: "90%",
          borderRadius: 500,
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <Image
          source={
            player.avatar !== null &&
            player.avatar !== undefined &&
            player.avatar !== "" &&
            !player.avatar.startsWith("http")
              ? AvatarCollection[Number(player.avatar)].sprite
              : NoAvatar
          }
          style={{ width: "110%", height: "110%", resizeMode: "contain" }}
        />
      </View>
    </View>
  );
}
