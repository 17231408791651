import React from 'react';
import { View, } from 'react-native';
import { useAccount } from '../../../../contexts/Account';
import { useTable } from '../../../../contexts/Table';
import JoinWaitingList from './JoinWaitingList';

import { styleActionsPosition } from './styles';

type Props = {
    currentCallValue: number;
    currentBettingValue: number;
}

export default function ({ currentCallValue, currentBettingValue }: Props) {

    const { user } = useAccount();
    const { table } = useTable();

    const [waiting, setWaiting] = React.useState<boolean>(false);
    const [position, setPosition] = React.useState<number>(0);
    const [players, setPlayers] = React.useState<number>(0);

    const [buyIn, setBuyIn] = React.useState<number>(0);

    React.useEffect(() => {

        if (user && table.waitingPlayers && table.waitingPlayers.length > 0 && table.waitingPlayers.filter(player => player && player.id === user.id).length > 0) {
            setWaiting(true);

            let waitingPlayer = table.waitingPlayers.filter(player => player && player.id === user.id);
            setPosition(table.waitingPlayers.indexOf(waitingPlayer[0]) + 1);
        } else {
            setWaiting(false);
            setPosition(0);
        }

        if (table.waitingPlayers) {
            setPlayers(table.waitingPlayers.length);
        } else {
            setPlayers(0);
        }

        setBuyIn(table.maxBuyIn);

    }, [table]);

    return (
        <View style={styleActionsPosition()}>
            <JoinWaitingList waiting={waiting} position={position} players={players} buyIn={buyIn} />
        </View>
    );
}