import {StyleSheet} from "react-native";

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        borderBottomWidth: 1,
        borderBottomColor: "#FFF2",
        maxHeight: 40,
        flexDirection: "row",
        alignItems: "center"
    },
})