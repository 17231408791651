import {StyleSheet, ViewStyle} from 'react-native';
import {useDimensions} from '../../../../../../contexts/Dimensions';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        minWidth: '100%',
        minHeight: '100%',
        width: '100%',
        height: '100%',
        position: 'absolute',
        //backgroundColor: 'yellow',
        //alignItems: 'center',
        //justifyContent: 'center',
    },
    image: {
        flex: 1,
        width: '100%',
        height: '100%',
        //marginTop: '-80%',
    }
});


export const styleChipPosition = (chipNumber: number, numberOfChips: number): ViewStyle => {
    return StyleSheet.flatten([styles.container, getChipPosition(chipNumber, numberOfChips)]);
}

export const getChipPosition = (chipNumber: number, numberOfChips: number): ViewStyle => {

    const {orientation} = useDimensions();

    switch (orientation) {
        case 'landscape':
            switch (chipNumber) {
                case 0:
                    return {
                        bottom: '0%',
                    }
                case 1:
                    return {
                        bottom: '15%',
                    }
                case 2:
                    return {
                        bottom: '30%',
                    }
                case 3:
                    return {
                        bottom: '45%',
                    }
                case 4:
                    return {
                        bottom: '60%',
                    }
                default:
                    return {
                        display: 'none',
                    }
            }
        case 'portrait':
            switch (chipNumber) {
                case 0:
                    return {
                        bottom: '0%',
                    }
                case 1:
                    return {
                        bottom: '15%',
                    }
                case 2:
                    return {
                        bottom: '30%',
                    }
                case 3:
                    return {
                        bottom: '45%',
                    }
                case 4:
                    return {
                        bottom: '60%',
                    }
                default:
                    return {
                        display: 'none',
                    }
            }
        default:
            return {
                display: 'none',
            }
    }

}
