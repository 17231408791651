import React from "react";
import {Text, View} from "react-native";
import {styles} from "./styles";

type MessageProp = {
    text: string
}

export default function InfoItem({text}: MessageProp) {
    return (
        <View style={styles.container}>
            {/*<Text style={[styles.text,{textAlignVertical:"top"}]}>{`(${timestamp}): `}</Text>*/}
            <Text style={[styles.text, {flex: 1}]}>{text}</Text>
        </View>
    )
}