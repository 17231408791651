import React from 'react';
import {Image, View} from 'react-native';
import Chips from '../../../../../../collections/Chips';
import {useDimensions} from '../../../../../../contexts/Dimensions';

import {styleChipPosition, styles} from './styles';

type Props = {
    value: number;
    chipNumber: number;
    numberOfChips: number;
}

export default function ({chipNumber, numberOfChips, value}: Props) {

    const {orientation} = useDimensions();

    return (
        <View style={styleChipPosition(chipNumber, numberOfChips)}>
            <Image
                style={styles.image}
                resizeMode={'contain'}
                source={Chips[orientation][value]}
                fadeDuration={0}
            />
        </View>
    );
}