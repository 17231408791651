import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    container: {
        flex: 1,
        width: '120%',
        //marginLeft: '-10%',
        height: '56%',
        position: 'absolute',
        bottom: '-8%',
        left: '-10%',
        //marginRight: '-10%',
        //maxHeight: '56%',
        //marginBottom: '-10%',
        //opacity: 0.6,
        alignItems: 'center',
        justifyContent: 'center',
        // alignContent: 'center',
        //backgroundColor: 'red',
        // paddingHorizontal: '10%',
        // paddingVertical: '10%'
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    image: {
        flex: 1,
        //flexDirection: 'column',
        //justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        //maxHeight: '100%',
        //paddingVertical: '10%',
        //paddingHorizontal: '10%',
    },
    content: {
        flex: 1,
        flexDirection: 'column',
        marginHorizontal: '10%',
        marginVertical: '10%',
        alignItems: 'center',
    },
    podLine: {
        flex: 1,
        height: 2,
        maxHeight: 2,
        width: '90%',
        marginLeft: '5%',
        resizeMode: 'stretch'
    },
    separator: {
        width: '80%',
        height: '2%',
        backgroundColor: '#FFF2'
    }
});