import React from 'react';
import {View} from 'react-native';
import {useTable} from '../../../../contexts/Table';
import Bet from './Bet';
import Call from './Call';
import Check from './Check';
import Fold from './Fold';
import Raise from './Raise';

import {styleActionsPosition} from './styles';

type Props = {
    currentCallValue: number;
    currentBettingValue: number;
}

export default function ({currentCallValue, currentBettingValue}: Props) {
    const {currentPlayer} = useTable();

    return (
        <View style={styleActionsPosition()}>
            {currentPlayer?.legalActions?.includes("fold") ?
                <Fold/> : <React.Fragment/>
            }

            {currentPlayer?.legalActions?.includes("check") ?
                <Check/> : <React.Fragment/>
            }

            {currentPlayer?.legalActions?.includes("call") ?
                <Call currentCallValue={currentCallValue}/> : <React.Fragment/>
            }

            {currentPlayer?.legalActions?.includes("bet") ?
                <Bet currentBettingValue={currentBettingValue}/> : <React.Fragment/>
            }

            {currentPlayer?.legalActions?.includes("raise") ?
                <Raise currentBettingValue={currentBettingValue}/> : <React.Fragment/>
            }
        </View>
    );
}