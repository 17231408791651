import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        //backgroundColor: 'black'
    },

    title: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#ffffff'
    },

    content: {
        flex: 1,
        //width: '100%',
        //height: '100%',
        //backgroundColor: 'paleturquoise',
        //opacity: 0.8,
        alignItems: 'center',
        justifyContent: 'center',
        //backgroundColor: 'pink'
        //borderWidth: 1,
        //borderColor: 'red',
    },

    communityCards_landscape: {
        position: 'absolute',
        width: '36%',
        height: '16%',
        left: '32%',
        top: '36%',
        backgroundColor: 'turquoise',
        flexDirection: 'row'
    },

    totalPot_landscape: {
        position: 'absolute',
        width: '14%',
        height: '4.5%',
        left: '43%',
        top: '30%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'turquoise',
    },

    communityCards_card_landscape: {
        width: '18%',
        height: '90%',
        marginHorizontal: '1%',
        backgroundColor: 'black',
        borderRadius: 14
    },

    pot_landscape: {
        position: 'absolute',
        width: '26%',
        height: '9%',
        left: '37%',
        bottom: '42%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'turquoise',
    },

    sidePot_landscape: {
        flexDirection: 'row',
        position: 'absolute',
        width: '40%',
        height: '9%',
        left: '30%',
        bottom: '34%',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: 'blue',
    },

    totalPot_portrait: {
        position: 'absolute',
        width: '40%',
        height: '4%',
        left: '30%',
        top: '37%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'black',
    },

    communityCards_portrait: {
        position: 'absolute',
        width: '66%',
        height: '10%',
        left: '17%',
        top: '42%',
        backgroundColor: 'turquoise',
        flexDirection: 'row'
    },

    communityCards_card_portrait: {
        width: '19%',
        height: '90%',
        marginHorizontal: '0.5%',
        backgroundColor: 'black',
        borderRadius: 10,
    },

    pot_portrait: {
        position: 'absolute',
        width: '20%',
        height: '8%',
        left: '40%',
        bottom: '36%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'blue',
    },

    sidePot_portrait: {
        flexDirection: 'column',
        position: 'absolute',
        width: '20%',
        height: '10%',
        left: '40%',
        bottom: '22%',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: 'green',
    },
});
