import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
    container: {
        width: 100,
        height: 100,
        justifyContent: "center",
        alignItems: 'center',
        borderRadius: 4,
        margin: 5
    },
    image: {
        width: "80%",
        height: "80%",
        resizeMode: "contain"
    }

})