import React from "react";
import { Image, ImageBackground, View, Text } from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { useTable } from "../../../../../../../contexts/Table";
import { Audio } from "expo-av";
import NormalFill from "../../../../../../../assets/images/theme/default/table/timer_fill.png";
import TimebankFill from "../../../../../../../assets/images/theme/default/table/timer_timebank_fill.png";
import styles from "./styles";
import { useGameOptions } from "../../../../../../../contexts/GameOptions";
import { PlayerProps } from "../../../../../../../types";

export default function ({ player }: { player: PlayerProps }) {
  const { actionTime, timebank, table, currentPlayer } = useTable();
  //const timeInMilliseconds = actionTime * 1000;
  const sliderFill = useSharedValue(0);
  const [timeReminderSound, setTimeReminderSound] =
    React.useState<Audio.Sound>();
  const { soundEnabled, soundVolume } = useGameOptions();
  const [fillWidthBase, setFillWidthBase] = React.useState<number>(0);
  //const [usingTimebank, setUsingTimebank] = React.useState<boolean>(timebank);

  //const [time, setTime] = React.useState<number>(0);

  //React.useEffect(() => {
  //    setTime(actionTime);
  //}, [actionTime]);

  async function playTimeReminder() {
    if (!soundEnabled) return;
    const { sound } = await Audio.Sound.createAsync(
      require("../../../../../../../assets/audio/sfx/time_reminder.mp3")
    );
    sound.setVolumeAsync(1 * soundVolume);
    setTimeReminderSound(sound);

    try {
      await sound.playAsync();
    } catch (e) {
      console.log("Failed to play time_reminder sfx");
      console.warn(e);
    }
  }

  const fancyTimeFormat = (duration: number) => {
    // Hours, minutes and seconds
    var hrs = Math.floor(duration / 3600);
    var mins = Math.floor((duration % 3600) / 60);
    var secs = Math.floor(duration % 60);

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  };

  React.useEffect(() => {
    return timeReminderSound
      ? () => {
          timeReminderSound.unloadAsync();
        }
      : undefined;
  }, [timeReminderSound]);

  React.useEffect(() => {
    if (actionTime === 5) {
      if (currentPlayer && table.currentPosition === currentPlayer.seatNumber) {
        playTimeReminder();
      }
    }
  }, [actionTime]);

  //React.useEffect(() => {
  //let timer: NodeJS.Timeout;
  // if (currentPlayer && table.currentPosition === currentPlayer.seatNumber) {
  //  timer = setTimeout(() => {
  //    playTimeReminder();
  //  }, 10000);
  //}
  //return () => {
  //  if (timer) clearTimeout(timer);
  //};
  //}, [table?.currentPosition, table.currentRound]);

  const fillAnimation = useAnimatedStyle(() => {
    return {
      width: `${(sliderFill.value / fillWidthBase) * 100}%`,
    };
  });

  React.useEffect(() => {
    setFillWidthBase(timebank ? 45000 : 15000);
    sliderFill.value = actionTime * 1000;
    sliderFill.value = withTiming(0, {
      duration: actionTime * 1000,
    });
  }, [table.currentRound, timebank]);

  //React.useEffect(() => {
  //  if (sliderFill.value <= 0) {
  //    setUsingTimebank(true);
  //  }
  //}, [sliderFill.value]);

  /*
  React.useEffect(() => {
    //console.log(player);
    if (timebank) {
      //let actionTimeLimit = actionTime * 1000;
      //if (actionTimeLimit > 45000) {
      //  actionTimeLimit = 45000;
      //}

      //setUsingTimebank(true);
      setFillWidthBase(45000);
      sliderFill.value = actionTime * 1000;
      sliderFill.value = withTiming(0, {
        duration: actionTime * 1000,
      });
    }
  }, [timebank]);

  React.useEffect(() => {
    sliderFill.value = actionTime * 1000;
    sliderFill.value = withTiming(0, {
      duration: actionTime * 1000,
    });
  }, [table.currentRound]);
  */

  return (
    <View style={styles.container}>
      {/*
      <View style={{ position: "absolute", top: 10, width: "100%" }}>
        <Text style={{ color: "#FFFFFF", fontWeight: "bold" }}>
          {fancyTimeFormat(actionTime)}
        </Text>
      </View>
      */}

      <ImageBackground
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
          justifyContent: "center",
        }}
        resizeMode="stretch"
        source={require("../../../../../../../assets/images/theme/default/table/timer_background.png")}
      >
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Animated.View style={[styles.fillClip, fillAnimation]}>
            <Image
              style={{ width: "100%", height: "100%", resizeMode: "cover" }}
              source={timebank || !player.timeBank ? TimebankFill : NormalFill}
              fadeDuration={0}
            />
          </Animated.View>
          <Image
            style={[styles.fillEffect]}
            resizeMode={"cover"}
            source={require("../../../../../../../assets/images/theme/default/table/timer_half.png")}
            fadeDuration={0}
          />
        </View>
      </ImageBackground>
    </View>
  );
}
