import React from 'react';
import {View,} from 'react-native';
import Stack from '../../../../Stack';
import {styleBetPosition} from './styles';
import {Audio} from "expo-av";
import {useGameOptions} from '../../../../../../contexts/GameOptions';
import {AVPlaybackSource} from 'expo-av/build/AV.types';

type Props = {
    seatNumber: number;
    numberOfSeats: number;
    betValue: number;
}

export default function ({seatNumber, numberOfSeats, betValue}: Props) {
    const {soundVolume, soundEnabled} = useGameOptions();
    const [sound, setSound] = React.useState<Audio.Sound>();

    async function loadRandomSound() {
        const rand = Math.floor(Math.random() * 8);

        const sounds = [
            require('../../../../../../assets/audio/sfx/chipLay1.mp3'),
            require('../../../../../../assets/audio/sfx/chipsCollide1.mp3'),
            require('../../../../../../assets/audio/sfx/chipsCollide2.mp3'),
            require('../../../../../../assets/audio/sfx/chipsCollide3.mp3'),
            require('../../../../../../assets/audio/sfx/chipsCollide4.mp3'),
            require('../../../../../../assets/audio/sfx/chipsHandle1.mp3'),
            require('../../../../../../assets/audio/sfx/chipsHandle6.mp3'),
            require('../../../../../../assets/audio/sfx/chipsStack1.mp3'),
            require('../../../../../../assets/audio/sfx/chipsStack3.mp3'),
        ] as AVPlaybackSource[]

        const {sound} = await Audio.Sound.createAsync(
            sounds[rand]
        );
        sound.setVolumeAsync(0.7 * soundVolume);
        setSound(sound);

        return sound
    }

    async function playSound() {
        if (!soundEnabled)
            return;

        loadRandomSound().then((sound) => {
            sound.playAsync();
        }).catch(() => {
            return;
        })

    }

    React.useEffect(() => {
        return sound
            ? () => {
                sound.unloadAsync();
            }
            : undefined;
    }, [sound]);

    React.useEffect(() => {
        playSound();
    }, [betValue])

    return (
        <View style={styleBetPosition(seatNumber, numberOfSeats)}>
            {/*
            <Chips value={betValue} />
            <Amount value={betValue} />
            */}
            <Stack size={betValue}/>
        </View>
    );
}