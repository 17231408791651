import React from "react";
import { Text, View } from "react-native";
import { useTable } from "../../../../../../../contexts/Table";
import { monetaryValue } from "../../../../../../../util";
import { getFontSize } from "../../../../../../../util/GlobalStyles";
import { useTranslation } from "react-i18next";

import { styles } from "./styles";
import { useGameOptions } from "../../../../../../../contexts/GameOptions";

type Props = {
  stackSize: number;
  reBuy: boolean;
  left: boolean;
};

export default function ({ stackSize, reBuy, left }: Props) {
  const { t } = useTranslation();
  const { showBigBlind } = useGameOptions();
  const { table, currentPlayer } = useTable();
  const tableBB = table?.bigBlind;

  const [stackLabel, setStackLabel] = React.useState<string>();

  React.useEffect(() => {
    if (reBuy) {
      setStackLabel(t("TableScreen.PlayerPod.REBUYING"));
    } else if (stackSize === 0 && !left) {
      setStackLabel(t("TableScreen.PlayerPod.ALLIN"));
    } else if (showBigBlind) {
      setStackLabel(`${Math.round((stackSize / tableBB) * 10) / 10} BB`);
    } else {
      setStackLabel(monetaryValue(stackSize));
    }
  }, [showBigBlind, stackSize, currentPlayer]);

  const fontSize = getFontSize(20, 14);
  return (
    <View style={styles.container}>
      <Text
        style={[
          styles.text,
          {
            color:
              stackSize > 0 || reBuy || left
                ? showBigBlind
                  ? "#ff9900"
                  : "#99B7FC"
                : "#ff0000",
            fontSize: fontSize,
          },
        ]}
      >
        {stackLabel}
      </Text>
    </View>
  );
}
