import React from "react";
import { View } from "react-native";
import { PlayerProps } from "../../../../../types";
import Avatar from "./Avatar";
import Bet from "./Bet";
import HandName from "./HandName";
import Pod from "./Pod";
import styles from "./styles";

type Props = {
  seatNumber: number;
  numberOfSeats: number;
  player: PlayerProps;
};

export default function ({ seatNumber, numberOfSeats, player }: Props) {
  return (
    <View style={styles.container}>
      <Avatar player={player} />
      <Pod
        seatNumber={seatNumber}
        numberOfSeats={numberOfSeats}
        player={player}
      />
      <Bet
        seatNumber={seatNumber}
        numberOfSeats={numberOfSeats}
        betValue={player.bet}
      />
    </View>
  );
}
