import React from 'react';
import {View} from 'react-native';
import Chip from './Chip';

import {styleStackPosition} from './styles';

type Props = {
    stackNumber: number;
    numberOfStacks: number;
    value: number;
    quantity: number;
}

export default function ({stackNumber, numberOfStacks, value, quantity}: Props) {

    /*
    React.useEffect(() => {
        console.log('*** CHIPS ***');
        console.log(value, quantity);
    }, [value, quantity]);
    */

    return (
        <View style={styleStackPosition(stackNumber, numberOfStacks)}>
            {[...Array(quantity)].map((_chip, index) => (
                <Chip key={index} chipNumber={index} numberOfChips={quantity} value={value}/>
            ))}
        </View>
    );
}