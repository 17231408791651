import { ScrollView } from 'moti';
import React from 'react';
import {
    ActivityIndicator,
    Button,
    NativeSyntheticEvent,
    Pressable,
    Text,
    TextInput,
    TextInputKeyPressEventData,
    View
} from 'react-native';
import { useAccount } from '../../../contexts/Account';
import Modal from '../Modal';
//import Joi, {ValidationError} from "joi";
import { styles, styleWithFontScale } from './styles';
import { useTranslation } from 'react-i18next';

type Props = {
    showButton: boolean;
    modalVisibleExternal?: boolean;
    setModalVisibleExternal?: (modalVisibleExternal: boolean) => void
}

export default function ({ showButton, modalVisibleExternal, setModalVisibleExternal }: Props) {
    const { t } = useTranslation();
    //const navigation = useNavigation();
    //const { signed } = useAccount();
    //const {table, currentPlayer} = useWebSocket();

    const { verificationCode, resetPassword, error } = useAccount();

    const [modalVisible, setModalVisible] = React.useState<boolean>(false);

    //const [modalVisibleExternal, setModalVisibleExternal] = React.useState<boolean>(false);

    const nextFocus = React.useRef<TextInput>(null);

    const focusNextField = () => {
        if (nextFocus.current != null) {
            nextFocus.current.focus();
        }
    };

    //const [error, setError] = React.useState<ValidationError | undefined>();
    const [email, onChangeEmail] = React.useState<string>('');
    const [resetCode, onChangeResetCode] = React.useState<string>('');
    const [newPassword, onChangeNewPassword] = React.useState<string>('');
    const [showPasswordForm, setShowPasswordForm] = React.useState<boolean>(false);
    const [showSendCode, setShowSendCode] = React.useState<boolean>(true);

    const [loading, setLoading] = React.useState<boolean>(false);

    //const userSignInSchema = Joi.object({
    //    email: Joi.string().email({ tlds: { allow: false } }).required(),
    //    password: Joi.string().min(6).max(20).required(),
    //});

    const codeSubmit = () => {
        //setLoading(true);
        if (email !== '') {
            setShowPasswordForm(true);
        }

        setShowSendCode(false);
        verificationCode(email);

        setTimeout(() => {
            setShowSendCode(true);
        }, 300000);

        //const { error } = userSignInSchema.validate({email, password});
        //if (error) { 
        //  setError(error);
        //} else {
        //signIn(email, newPassword);
        //}
    }

    const resetPasswordSubmit = () => {
        setLoading(true);

        resetPassword(email, resetCode, newPassword)
        //const { error } = userSignInSchema.validate({email, password});
        //if (error) { 
        //  setError(error);
        //} else {
        //signIn(email, newPassword);
        //}
    }

    const handleKeyboardEvent = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
        if (e.nativeEvent.key === "Enter") {
            resetPasswordSubmit();
        }
    }

    React.useEffect(() => {
        if (error) {
            setLoading(false);
        }
    }, [error]);

    React.useEffect(() => {
        if (!showButton && modalVisibleExternal !== undefined) {
            setModalVisible(modalVisibleExternal);
        }
    }, [modalVisibleExternal]);

    React.useEffect(() => {
        if (!showButton && setModalVisibleExternal !== undefined) {
            setModalVisibleExternal(modalVisible);
        }
    }, [modalVisible]);

    return (
        <React.Fragment>
            <Modal title={t("ModalResetPassword.title")} modalVisible={modalVisible} setModalVisible={setModalVisible}>
                <View style={{ marginBottom: 10 }}>
                    {loading ? <ActivityIndicator /> : <></>}
                    {error?.map((err, index) => (
                        <Text key={index} style={{ color: '#FFFFFF' }}>{`${(index + 1)}. ${err}`}</Text>
                    ))}
                </View>
                <ScrollView>
                    <View style={{ flex: 1, flexDirection: 'column' }}>

                        <View>
                            <Text style={{ color: 'white', fontSize: 14, marginLeft: 2 }}>{t("ModalResetPassword.insertEmail")}</Text>
                            <TextInput
                                textContentType='emailAddress'
                                keyboardType='email-address'
                                onSubmitEditing={() => focusNextField()}
                                style={styles.textInput}
                                placeholderTextColor={'#cccccc'}
                                onChangeText={onChangeEmail}
                                value={email}
                            />
                        </View>

                        <View style={{ marginBottom: 15 }}>
                            {showSendCode ?
                                <Pressable
                                    style={styles.button}
                                    onPress={codeSubmit}
                                >
                                    <Text style={[styleWithFontScale(20, 14), { textTransform: 'uppercase' }]}>
                                        {t("ModalResetPassword.sendEmail")}
                                    </Text>
                                </Pressable>
                                :
                                <View>
                                    <Text style={{ color: 'white' }}>
                                        {t("ModalResetPassword.codeSent")}
                                    </Text>
                                    <Text style={{ color: 'white' }}>
                                        {t("ModalResetPassword.waitTime")}
                                    </Text>
                                </View>
                            }
                        </View>

                        <View style={{ display: showPasswordForm ? 'flex' : 'none' }}>

                            <View>
                                <Text style={{ color: 'white', fontSize: 14, marginLeft: 2 }}>{t("ModalResetPassword.validationCode")}</Text>
                                <TextInput
                                    textContentType='oneTimeCode'
                                    keyboardType='numeric'
                                    onSubmitEditing={() => focusNextField()}
                                    style={styles.textInput}
                                    placeholderTextColor={'#cccccc'}
                                    onChangeText={onChangeResetCode}
                                    onKeyPress={handleKeyboardEvent}
                                    value={resetCode}
                                />
                            </View>


                            <View>
                                <Text style={{ color: 'white', fontSize: 14, marginLeft: 2 }}>{t("ModalResetPassword.newPassword")}</Text>
                                <TextInput
                                    textContentType='password'
                                    clearTextOnFocus={true}
                                    secureTextEntry={true}
                                    ref={nextFocus}
                                    style={styles.textInput}
                                    placeholderTextColor={'#cccccc'}
                                    onChangeText={onChangeNewPassword}
                                    onKeyPress={handleKeyboardEvent}
                                    value={newPassword}
                                />
                            </View>


                            <View style={{ marginBottom: 5 }}>
                                {/*<Button title='Login' onPress={signInSubmit} />*/}

                                <Pressable
                                    style={styles.button}
                                    onPress={resetPasswordSubmit}
                                >
                                    <Text style={[styleWithFontScale(20, 14), { textTransform: 'uppercase' }]}>
                                        {t("ModalResetPassword.submit")}
                                    </Text>
                                </Pressable>

                            </View>

                        </View>
                    </View>
                </ScrollView>
            </Modal>
            {showButton ?
                <Pressable
                    onPress={() => setModalVisible(true)}
                >
                    <Text style={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                        {t("ModalSignIn.forgotPassword")}
                    </Text>
                </Pressable>
                : null}
            {/* <Button title='Login' onPress={() => setModalVisible(!modalVisible)} /> */}

        </React.Fragment>
    );
}