import {StyleSheet, ViewStyle} from "react-native";
import {useDimensions} from "../../../contexts/Dimensions";

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        //backgroundColor: 'pink',
        position: "absolute",
    },
    image: {
        flex: 1,
        width: "100%",
        height: "100%",
    },
});

export const styleDealerPosition = (
    seatNumber: number,
    numberOfSeats: number
): ViewStyle => {
    return StyleSheet.flatten([
        styles.container,
        getDealerPosition(seatNumber, numberOfSeats),
    ]);
};

export const getDealerPosition = (
    seatNumber: number,
    numberOfSeats: number
): ViewStyle => {
    const {orientation} = useDimensions();

    switch (orientation) {
        case "landscape":
            switch (numberOfSeats) {
                case 2:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "39.5%",
                                bottom: "29.5%",
                            };
                        case 1:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "56.5%",
                                top: "26%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                case 6:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "42.5%",
                                bottom: "29.5%",
                            };
                        case 1:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "27%",
                                bottom: "30%",
                            };
                        case 2:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "24%",
                                top: "29.5%",
                            };
                        case 3:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "56.5%",
                                top: "26%",
                            };
                        case 4:
                            return {
                                width: "3%",
                                height: "3.75%",
                                right: "24%",
                                top: "29.5%",
                            };
                        case 5:
                            return {
                                width: "3%",
                                height: "3.75%",
                                right: "27%",
                                bottom: "30%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                case 8:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "39.5%",
                                bottom: "29.5%",
                            };
                        case 1:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "27%",
                                bottom: "30%",
                            };
                        case 2:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "15%",
                                top: "50%",
                            };
                        case 3:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "30%",
                                top: "25.5%",
                            };
                        case 4:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "56.5%",
                                top: "26%",
                            };
                        case 5:
                            return {
                                width: "3%",
                                height: "3.75%",
                                right: "30%",
                                top: "25.5%",
                            };
                        case 6:
                            return {
                                width: "3%",
                                height: "3.75%",
                                right: "15%",
                                top: "50%",
                            };
                        case 7:
                            return {
                                width: "3%",
                                height: "3.75%",
                                right: "27%",
                                bottom: "30%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                case 9:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "42%",
                                bottom: "28%",
                            };
                        case 1:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "28%",
                                bottom: "29%",
                            };
                        case 2:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "15%",
                                top: "50%",
                            };
                        case 3:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "25.5%",
                                top: "28%",
                            };
                        case 4:
                            return {
                                width: "3%",
                                height: "3.75%",
                                left: "46.5%",
                                top: "26%",
                            };
                        case 5:
                            return {
                                width: "3%",
                                height: "3.75%",
                                right: "46.5%",
                                top: "26%",
                            };
                        case 6:
                            return {
                                width: "3%",
                                height: "3.75%",
                                right: "25.5%",
                                top: "28%",
                            };
                        case 7:
                            return {
                                width: "3%",
                                height: "3.75%",
                                right: "15%",
                                top: "50%",
                            };
                        case 8:
                            return {
                                width: "3%",
                                height: "3.75%",
                                right: "28%",
                                bottom: "29%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                default:
                    return {
                        display: "none",
                    };
            }
        case "portrait":
            switch (numberOfSeats) {
                case 2:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "28%",
                                bottom: "28%",
                            };
                        case 1:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "40%",
                                top: "16%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                case 6:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "28%",
                                bottom: "28%",
                            };
                        case 1:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "26%",
                                bottom: "34%",
                            };
                        case 2:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "26%",
                                top: "34%",
                            };
                        case 3:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "40%",
                                top: "18.5%",
                            };
                        case 4:
                            return {
                                width: "5%",
                                height: "3%",
                                right: "26%",
                                top: "34%",
                            };
                        case 5:
                            return {
                                width: "5%",
                                height: "3%",
                                right: "26%",
                                bottom: "34%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                case 8:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "28%",
                                bottom: "28%",
                            };
                        case 1:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "27%",
                                bottom: "32%",
                            };
                        case 2:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "26%",
                                top: "37%",
                            };
                        case 3:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "26%",
                                top: "24.25%",
                            };
                        case 4:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "40%",
                                top: "16%",
                            };
                        case 5:
                            return {
                                width: "5%",
                                height: "3%",
                                right: "26%",
                                top: "24.25%",
                            };
                        case 6:
                            return {
                                width: "5%",
                                height: "3%",
                                right: "26%",
                                top: "37%",
                            };
                        case 7:
                            return {
                                width: "5%",
                                height: "3%",
                                right: "27%",
                                bottom: "32%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                case 9:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: "6%",
                                height: "4%",
                                left: "30%",
                                bottom: "26%",
                            };
                        case 1:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "27%",
                                bottom: "32%",
                            };
                        case 2:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "27%",
                                top: "39.5%",
                            };
                        case 3:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "30%",
                                top: "21%",
                            };
                        case 4:
                            return {
                                width: "5%",
                                height: "3%",
                                left: "34%",
                                top: "16.5%",
                            };
                        case 5:
                            return {
                                width: "5%",
                                height: "3%",
                                right: "34%",
                                top: "16.5%",
                            };
                        case 6:
                            return {
                                width: "5%",
                                height: "3%",
                                right: "30%",
                                top: "21%",
                            };
                        case 7:
                            return {
                                width: "5%",
                                height: "3%",
                                right: "27%",
                                top: "39.5%",
                            };
                        case 8:
                            return {
                                width: "5%",
                                height: "3%",
                                right: "27%",
                                bottom: "32%",
                            };
                        default:
                            return {
                                display: "none",
                            };
                    }
                default:
                    return {
                        display: "none",
                    };
            }
        default:
            return {
                display: "none",
            };
    }
};
