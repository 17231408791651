import React from 'react';
import {View,} from 'react-native';
import {styleBettingPosition, styles} from './styles';
import Shortcuts from './Shortcuts';
import Input from './Input';
import Slider from './Slider';
import Timebank from './Timebank';
import {useTable} from '../../../../contexts/Table';

type Props = {
    currentBettingValue: number;
    onChangeCurrentBettingValue: (amount: number) => void;
}

export default function ({currentBettingValue, onChangeCurrentBettingValue}: Props) {

    const {currentPlayer} = useTable();

    const [minBetting, setMinBetting] = React.useState<number>(0);
    const [maxBetting, setMaxBetting] = React.useState<number>(1);

    React.useEffect(() => {
        if (currentPlayer && currentPlayer.betAmounts) {
            setMinBetting(currentPlayer.betAmounts.raise.minimum);
            setMaxBetting(currentPlayer.betAmounts.raise.maximum);
        }
    }, [currentPlayer]);

    return (
        <View style={styleBettingPosition()}>
            <Timebank/>
            <Shortcuts onChangeCurrentBettingValue={onChangeCurrentBettingValue}/>
            <View style={styles.raiseOptions}>
                <Input minBetting={minBetting} maxBetting={maxBetting} currentBettingValue={currentBettingValue}
                       onChangeCurrentBettingValue={onChangeCurrentBettingValue}/>
                <Slider minBetting={minBetting} maxBetting={maxBetting} currentBettingValue={currentBettingValue}
                        onChangeCurrentBettingValue={onChangeCurrentBettingValue}/>
            </View>
        </View>
    );
}