import {ViewStyle} from 'react-native';
import {useDimensions} from '../../../../contexts/Dimensions';

export const getPrizeAnimationDestination = (seatNumber: number, numberOfSeats: number): ViewStyle => {
    const {orientation} = useDimensions();

    switch (orientation) {
        case 'landscape':
            switch (numberOfSeats) {
                case 2:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '15%',
                                height: '11%',
                                left: '42.5%',
                                bottom: '4.5%',
                            }
                        case 1:
                            return {
                                width: '14%',
                                height: '10%',
                                left: '43.5%',
                                top: '4%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 6:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '15%',
                                height: '11%',
                                left: '42.5%',
                                bottom: '2.5%',
                            }
                        case 1:
                            return {
                                width: '14%',
                                height: '10%',
                                left: '13%',
                                bottom: '17%',
                            }
                        case 2:
                            return {
                                width: '14%',
                                height: '10%',
                                left: '10%',
                                top: '24%',
                            }
                        case 3:
                            return {
                                width: '14%',
                                height: '10%',
                                left: '43.5%',
                                top: '16%',
                            }
                        case 4:
                            return {
                                width: '14%',
                                height: '10%',
                                right: '10%',
                                top: '24%',
                            }
                        case 5:
                            return {
                                width: '14%',
                                height: '10%',
                                right: '13%',
                                bottom: '17%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 8:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '15%',
                                height: '11%',
                                left: '42.5%',
                                bottom: '4.5%',
                            }
                        case 1:
                            return {
                                width: '14%',
                                height: '10%',
                                left: '13%',
                                bottom: '19%',
                            }
                        case 2:
                            return {
                                width: '14%',
                                height: '10%',
                                left: '1%',
                                top: '33%',
                            }
                        case 3:
                            return {
                                width: '14%',
                                height: '10%',
                                left: '16%',
                                top: '8%',
                            }
                        case 4:
                            return {
                                width: '14%',
                                height: '10%',
                                left: '43.5%',
                                top: '4%',
                            }
                        case 5:
                            return {
                                width: '14%',
                                height: '10%',
                                right: '16%',
                                top: '8%',
                            }
                        case 6:
                            return {
                                width: '14%',
                                height: '10%',
                                right: '1%',
                                top: '33%',
                            }
                        case 7:
                            return {
                                width: '14%',
                                height: '10%',
                                right: '13%',
                                bottom: '19%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 9:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '15%',
                                height: '11%',
                                left: '42.5%',
                                bottom: '2.5%',
                            }
                        case 1:
                            return {
                                width: '14%',
                                height: '10%',
                                left: '13%',
                                bottom: '17%',
                            }
                        case 2:
                            return {
                                width: '14%',
                                height: '10%',
                                left: '1%',
                                top: '45%',
                            }
                        case 3:
                            return {
                                width: '14%',
                                height: '10%',
                                left: '11.5%',
                                top: '23%',
                            }
                        case 4:
                            return {
                                width: '14%',
                                height: '10%',
                                left: '33.5%',
                                top: '16%',
                            }
                        case 5:
                            return {
                                width: '14%',
                                height: '10%',
                                right: '33.5%',
                                top: '16%',
                            }
                        case 6:
                            return {
                                width: '14%',
                                height: '10%',
                                right: '11.5%',
                                top: '23%',
                            }
                        case 7:
                            return {
                                width: '14%',
                                height: '10%',
                                right: '1%',
                                top: '45%',
                            }
                        case 8:
                            return {
                                width: '14%',
                                height: '10%',
                                right: '13%',
                                bottom: '17%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                default:
                    return {
                        display: 'none',
                    }

            }
        case 'portrait':
            switch (numberOfSeats) {
                case 2:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '30%',
                                height: '9%',
                                left: '2.5%',
                                bottom: '12%',
                            }
                        case 1:
                            return {
                                width: '23%',
                                height: '7%',
                                left: '39%',
                                top: '4%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 6:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '30%',
                                height: '9%',
                                left: '2.5%',
                                bottom: '10%',
                            }
                        case 1:
                            return {
                                width: '23%',
                                height: '7%',
                                left: '3%',
                                bottom: '32.5%',
                            }
                        case 2:
                            return {
                                width: '23%',
                                height: '7%',
                                left: '3%',
                                top: '31.5%',
                            }
                        case 3:
                            return {
                                width: '23%',
                                height: '7%',
                                left: '39%',
                                top: '12%',
                            }
                        case 4:
                            return {
                                width: '23%',
                                height: '7%',
                                right: '3%',
                                top: '31.5%',
                            }
                        case 5:
                            return {
                                width: '23%',
                                height: '7%',
                                right: '3%',
                                bottom: '32.5%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 8:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '30%',
                                height: '9%',
                                left: '2.5%',
                                bottom: '10%',
                            }
                        case 1:
                            return {
                                width: '23%',
                                height: '7%',
                                left: '3%',
                                bottom: '33.5%',
                            }
                        case 2:
                            return {
                                width: '23%',
                                height: '7%',
                                left: '3%',
                                top: '28.5%',
                            }
                        case 3:
                            return {
                                width: '23%',
                                height: '7%',
                                left: '3%',
                                top: '15.75%',
                            }
                        case 4:
                            return {
                                width: '23%',
                                height: '7%',
                                left: '39%',
                                top: '4%',
                            }
                        case 5:
                            return {
                                width: '23%',
                                height: '7%',
                                right: '3%',
                                top: '15.75%',
                            }
                        case 6:
                            return {
                                width: '23%',
                                height: '7%',
                                right: '3%',
                                top: '28.5%',
                            }
                        case 7:
                            return {
                                width: '23%',
                                height: '7%',
                                right: '3%',
                                bottom: '33.5%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                case 9:
                    switch (seatNumber) {
                        case 0:
                            return {
                                width: '30%',
                                height: '9%',
                                left: '2.5%',
                                bottom: '10%',
                            }
                        case 1:
                            return {
                                width: '23%',
                                height: '7%',
                                left: '3%',
                                bottom: '30.5%',
                            }
                        case 2:
                            return {
                                width: '23%',
                                height: '7%',
                                left: '3%',
                                top: '37%',
                            }
                        case 3:
                            return {
                                width: '23%',
                                height: '7%',
                                left: '3%',
                                top: '21%',
                            }
                        case 4:
                            return {
                                width: '23%',
                                height: '7%',
                                left: '25%',
                                top: '10.5%',
                            }
                        case 5:
                            return {
                                width: '23%',
                                height: '7%',
                                right: '25%',
                                top: '10.5%',
                            }
                        case 6:
                            return {
                                width: '23%',
                                height: '7%',
                                right: '3%',
                                top: '21%',

                            }
                        case 7:
                            return {
                                width: '23%',
                                height: '7%',
                                right: '3%',
                                top: '37%',
                            }
                        case 8:
                            return {
                                width: '23%',
                                height: '7%',
                                right: '3%',
                                bottom: '30.5%',
                            }
                        default:
                            return {
                                display: 'none',
                            }
                    }
                default:
                    return {
                        display: 'none',
                    }
            }
        default:
            return {
                display: 'none',
            }
    }

}