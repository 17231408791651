import { ScrollView } from "moti";
import React from "react";
import { FontAwesome } from "@expo/vector-icons";
import {
  ActivityIndicator,
  Button,
  NativeSyntheticEvent,
  Pressable,
  Text,
  TextInput,
  TextInputKeyPressEventData,
  View,
  Image,
  Platform,
} from "react-native";
import * as Clipboard from "expo-clipboard";
import { PaymentData, useAccount } from "../../../contexts/Account";
import Modal from "../Modal";
//import Joi, {ValidationError} from "joi";
import { styles, styleWithFontScale } from "./styles";
import { TextInputMask } from "react-native-masked-text";
import { useWebSocket } from "../../../contexts/WebSocket";
import * as WebBrowser from "expo-web-browser";
import { useTranslation } from "react-i18next";

type Props = {
  showButton: boolean;
  modalVisibleExternal?: boolean;
  setModalVisibleExternal?: (modalVisibleExternal: boolean) => void;
};

export default function ({
  showButton,
  modalVisibleExternal,
  setModalVisibleExternal,
}: Props) {
  const { t } = useTranslation();

  //const navigation = useNavigation();
  //const { signed } = useAccount();
  //const {table, currentPlayer} = useWebSocket();

  const { socket } = useWebSocket();
  const { user, data, setData, error, setError, handlePayment } = useAccount();

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);

  const [transactionDepositConfirmed, setTransactionDepositConfirmed] =
    React.useState<boolean>(false);

  const [pixDepositShow, setPixDepositShow] = React.useState<boolean>(true);
  const [pixQrCodeDepositShow, setPixQrCodeDepositShow] =
    React.useState<boolean>(false);
  const [pagstarWindowDepositShow, setPagstarWindowDepositShow] =
    React.useState<boolean>(false);
  const [pagstarDepositShow, setPagstarDepositShow] =
    React.useState<boolean>(false);
  const [transactionDeposit, setTransactionDeposit] = React.useState<
    PaymentData | undefined
  >(undefined);
  const [copyText, setCopyText] = React.useState("");
  const [showGeneratePayment, setShowGeneratePayment] =
    React.useState<boolean>(true);

  //const [modalVisibleExternal, setModalVisibleExternal] = React.useState<boolean>(false);

  //const [error, setError] = React.useState<ValidationError | undefined>();
  const [amount, onChangeAmount] = React.useState<string>("40,00");
  const [amountMessage, setAmountMessage] = React.useState<string>("");

  const [name, onChangeName] = React.useState<string>("");
  const [cpf, onChangeCpf] = React.useState<string>("");

  const [loading, setLoading] = React.useState<boolean>(false);

  const [result, setResult] = React.useState<WebBrowser.WebBrowserResult>();

  const nextFocus = React.useRef<TextInput>(null);

  const focusNextField = () => {
    if (nextFocus.current != null) {
      nextFocus.current.focus();
    }
  };

  const _handlePressButtonAsync = async (checkoutUrl: string) => {
    let result = await WebBrowser.openBrowserAsync(checkoutUrl);
    setResult(result);
  };

  //const userSignInSchema = Joi.object({
  //    email: Joi.string().email({ tlds: { allow: false } }).required(),
  //    password: Joi.string().min(6).max(20).required(),
  //});

  const paymentSubmit = () => {
    setLoading(true);

    let value = Number(amount.replace('.', '').replace('.', '').replace('.', '').replace(',', '').replace(',', '').replace(',', ''));
    if (value >= 10 && name && cpf) {
      //console.log(value, name, cpf);
      handlePayment(value, pixDepositShow ? "PIX" : "PAGSTAR", name, cpf);

      setShowGeneratePayment(false);

      setTimeout(() => {
        setShowGeneratePayment(true);
      }, 300000);
      //setLoading(false);
    } else {
      if (value < 10) {
        setAmountMessage(t("ModalDeposit.minimum") + " 10,00");
      }
      setLoading(false);
    }
    //const { error } = userSignInSchema.validate({email, password});
    //if (error) {
    //  setError(error);
    //} else {
    //signIn(email, password);
    //}
  };

  const handleKeyboardEvent = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    if (e.nativeEvent.key === "Enter") {
      let value = Number(amount.replace('.', '').replace('.', '').replace('.', '').replace(',', '').replace(',', '').replace(',', ''));
      if (value >= 10 && name && cpf) {
        handlePayment(value, pixDepositShow ? "PIX" : "PAGSTAR", name, cpf);

        setShowGeneratePayment(false);

        setTimeout(() => {
          setShowGeneratePayment(true);
        }, 300000);
        //setLoading(false);
      } else {
        if (value < 10) {
          setAmountMessage(t("ModalDeposit.minimum") + " 10,00");
        }
        setLoading(false);
      }
    }
  };

  const copyToClipboard = () => {
    Clipboard.setString(copyText);
  };

  React.useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  React.useEffect(() => {
    if (!showButton && modalVisibleExternal !== undefined) {
      setModalVisible(modalVisibleExternal);
    }
  }, [modalVisibleExternal]);

  React.useEffect(() => {
    if (!showButton && setModalVisibleExternal !== undefined) {
      setModalVisibleExternal(modalVisible);
    }
  }, [modalVisible]);

  React.useEffect(() => {
    //console.log(transactionDeposit);
    if (transactionDeposit) {
      setCopyText(transactionDeposit.pix_key || "");

      if (transactionDeposit.external_reference) {
        setLoading(false);

        if (pixDepositShow) {
          setPixQrCodeDepositShow(true);
        } else {
          setPagstarWindowDepositShow(true);

          if (Platform.OS == "web") {
            window.open(transactionDeposit.checkout_url || "", "_blank");
          } else {
            _handlePressButtonAsync(transactionDeposit.checkout_url || "");
          }
        }
      }
    }

    return () => {
      setPixQrCodeDepositShow(false);
      setPagstarWindowDepositShow(false);
    };
  }, [transactionDeposit]);

  React.useEffect(() => {
    if (!transactionDeposit) return;
    if (!transactionDeposit.external_reference) return;

    const { external_reference } = transactionDeposit;

    socket.on("transaction confirmation", (externalReference: string) => {
      if (externalReference == external_reference) {
        socket.emit("wallet");
        setTransactionDepositConfirmed(true);

        clearInterval(interval);
        socket.off("transaction confirmation");
      }
    });

    const interval = setInterval(() => {
      //console.log("check transaction: " + external_reference);
      socket.emit("check transaction", external_reference);
    }, 10000);

    if (modalVisible == false) {
      clearInterval(interval);
      socket.off("transaction confirmation");
    }

    return () => {
      clearInterval(interval);
      setTransactionDepositConfirmed(false);
      socket.off("transaction confirmation");
    };
  }, [transactionDeposit, modalVisible]);

  React.useEffect(() => {
    setTransactionDeposit(data);

    return () => {
      setTransactionDeposit(undefined);
    };
  }, [data]);

  React.useEffect(() => {
    if (modalVisible === false) {
      setError(undefined);
      setData(undefined);
      setTransactionDeposit(undefined);
      setTransactionDepositConfirmed(false);
      setPixQrCodeDepositShow(false);
      setPagstarWindowDepositShow(false);
    }
  }, [modalVisible]);

  React.useEffect(() => {
    if (user) {
      onChangeName(user.name);
      onChangeCpf(user.document);
    }
  }, [user]);

  return (
    <React.Fragment>
      <Modal
        title={t("ModalDeposit.deposit")}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        type="fullheight"
      >
        <View style={{ marginBottom: 10 }}>
          {loading ? <ActivityIndicator /> : <></>}
          {error?.map((err, index) => (
            <Text key={index} style={{ color: "#FFFFFF" }}>{`${err}`}</Text>
          ))}
        </View>
        <View
          style={{
            display: transactionDepositConfirmed ? "flex" : "none",
            flex: 1,
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ fontSize: 30, textAlign: "center", color: "white" }}>
            {t("ModalDeposit.completed")}
          </Text>
        </View>
        <ScrollView
          style={{ display: transactionDepositConfirmed ? "none" : "flex" }}
        >
          <View style={{ flex: 1, paddingHorizontal: 10 }}>
            <View>
              <View style={{ display: pixQrCodeDepositShow ? "flex" : "none" }}>
                <View
                  style={{
                    paddingVertical: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Image
                      style={{ width: 120, height: 42 }}
                      source={require("../../../assets/images/pix-bc.png")}
                    />
                  </View>
                  <View>
                    <Text style={{ color: "#FFFFFF", fontSize: 30 }}>
                      {amount}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 20,
                  }}
                >
                  <Image
                    style={{ width: 150, height: 150 }}
                    source={{
                      uri: transactionDeposit?.qr_code_url,
                    }}
                  />
                </View>
                <View style={{ paddingVertical: 15, alignItems: "center" }}>
                  <Text
                    style={{
                      color: "#FFFFFF",
                      textAlign: "center",
                      fontSize: 11,
                    }}
                  >
                    {transactionDeposit?.pix_key}
                  </Text>
                  <Pressable style={{ padding: 2 }} onPress={copyToClipboard}>
                    <FontAwesome name="copy" size={20} color="white" />
                  </Pressable>
                </View>
                <View
                  style={{
                    paddingVertical: 15,
                    borderTopWidth: 1,
                    borderTopColor: "rgba(255, 255, 255, 0.2)",
                  }}
                >
                  <Text style={{ color: "#FFFFFF", paddingVertical: 5 }}>
                    {t("ModalDeposit.instruction1")}
                  </Text>
                  <Text style={{ color: "#FFFFFF", paddingVertical: 5 }}>
                    {t("ModalDeposit.instruction2")}
                  </Text>
                  <Text style={{ color: "#FFFFFF", paddingVertical: 5 }}>
                    {t("ModalDeposit.instruction3")}
                  </Text>
                </View>
                <View>
                  <Pressable style={styles.button} onPress={copyToClipboard}>
                    <Text style={[styleWithFontScale(20, 14)]}>
                      {t("ModalDeposit.copyCode")}
                    </Text>
                  </Pressable>
                </View>
              </View>
              <View
                style={{ display: pagstarWindowDepositShow ? "flex" : "none" }}
              >
                <View>
                  <Pressable
                    style={styles.button}
                    onPress={() => {
                      if (Platform.OS == "web") {
                        window.open(
                          transactionDeposit?.checkout_url || "",
                          "_blank"
                        );
                      } else {
                        _handlePressButtonAsync(
                          transactionDeposit?.checkout_url || ""
                        );
                      }
                    }}
                  >
                    <Text style={[styleWithFontScale(20, 14)]}>
                      {t("ModalDeposit.openPagstar")}
                    </Text>
                  </Pressable>
                </View>
              </View>
              <View
                style={{
                  display:
                    pixQrCodeDepositShow || pagstarWindowDepositShow
                      ? "none"
                      : "flex",
                }}
              >
                <View style={{ paddingVertical: 5 }}>
                  <Text
                    style={{
                      color: "#FFFFFF",
                      fontSize: 18,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {t("ModalDeposit.choseMethod")}
                  </Text>
                </View>
                <View style={{ flexDirection: "column" }}>
                  <Pressable
                    style={{ flex: 1, marginVertical: 5 }}
                    onPress={() => {
                      setPixDepositShow(true);
                      setPagstarDepositShow(false);
                    }}
                  >
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: "rgba(255, 255, 255, 0.2)",
                        borderRadius: 4,
                        paddingHorizontal: 10,
                        paddingVertical: 10,
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Image
                          style={{ width: 70, height: 25 }}
                          source={require("../../../assets/images/pix-bc.png")}
                        />
                      </View>
                      <View
                        style={{ display: pixDepositShow ? "flex" : "none" }}
                      >
                        <FontAwesome name="check" size={20} color="white" />
                      </View>
                    </View>
                  </Pressable>
                  <Pressable
                    style={{ flex: 1, marginVertical: 5 }}
                    onPress={() => {
                      setPagstarDepositShow(true);
                      setPixDepositShow(false);
                    }}
                  >
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: "rgba(255, 255, 255, 0.2)",
                        borderRadius: 4,
                        paddingHorizontal: 10,
                        paddingVertical: 10,
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <Image
                          style={{ width: 110, height: 25 }}
                          source={require("../../../assets/images/pagstar.png")}
                        />
                      </View>

                      <View
                        style={{
                          display: pagstarDepositShow ? "flex" : "none",
                        }}
                      >
                        <FontAwesome name="check" size={20} color="white" />
                      </View>
                    </View>
                  </Pressable>
                </View>
                <View
                  style={{
                    flex: 1,
                    display:
                      pixDepositShow || pagstarDepositShow ? "flex" : "none",
                  }}
                >
                  <View style={{ flexDirection: "column" }}>
                    <View style={{ paddingVertical: 15 }}>
                      <View
                        style={{ display: pixDepositShow ? "flex" : "none" }}
                      >
                        <View>
                          <Text
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            {t("ModalDeposit.pixDetails")}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          display: pagstarDepositShow ? "flex" : "none",
                        }}
                      >
                        <View>
                          <Text
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            {t("ModalDeposit.pagstarDetails")}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View>
                      <Text
                        style={{ color: "white", fontSize: 14, marginLeft: 2 }}
                      >
                        {t("ModalPersonalInfo.CPF")}{" "}
                        {`(${t("ModalDeposit.Mandatory")})`}
                      </Text>
                      <TextInputMask
                        type={"cpf"}
                        onSubmitEditing={() => focusNextField()}
                        style={styles.textInput}
                        placeholderTextColor={"#cccccc"}
                        onChangeText={onChangeCpf}
                        maxLength={255}
                        value={cpf}
                        editable={!user?.document}
                      />
                    </View>

                    <View>
                      <Text
                        style={{ color: "white", fontSize: 14, marginLeft: 2 }}
                      >
                        {t("ModalPersonalInfo.name")}{" "}
                        {`(${t("ModalDeposit.Mandatory")})`}
                      </Text>
                      <TextInput
                        style={styles.textInput}
                        onSubmitEditing={() => focusNextField()}
                        placeholderTextColor={"#cccccc"}
                        onChangeText={onChangeName}
                        maxLength={255}
                        value={name}
                        editable={!user?.name}
                      />
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-around",
                        marginTop: 30,
                        marginBottom: 10,
                      }}
                    >
                      <Pressable
                        onPress={() => {
                          onChangeAmount("120,00");
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            borderWidth: 1,
                            borderColor: "rgba(255, 255, 255, 0.2)",
                            borderRadius: 4,
                            paddingHorizontal: 30,
                            paddingVertical: 10,
                          }}
                        >
                          <Text style={{ color: "#FFFFFF" }}>120</Text>
                        </View>
                      </Pressable>

                      <Pressable
                        onPress={() => {
                          onChangeAmount("240,00");
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            borderWidth: 1,
                            borderColor: "rgba(255, 255, 255, 0.2)",
                            borderRadius: 4,
                            paddingHorizontal: 30,
                            paddingVertical: 10,
                          }}
                        >
                          <Text style={{ color: "#FFFFFF" }}>240</Text>
                        </View>
                      </Pressable>

                      <Pressable
                        onPress={() => {
                          onChangeAmount("600,00");
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            borderWidth: 1,
                            borderColor: "rgba(255, 255, 255, 0.2)",
                            borderRadius: 4,
                            paddingHorizontal: 30,
                            paddingVertical: 10,
                          }}
                        >
                          <Text style={{ color: "#FFFFFF" }}>600</Text>
                        </View>
                      </Pressable>
                    </View>

                    <TextInputMask
                      type={"money"}
                      options={{
                        precision: 2,
                        separator: ",",
                        delimiter: ".",
                        unit: "",
                        suffixUnit: "",
                      }}
                      keyboardType="numbers-and-punctuation"
                      onSubmitEditing={() => focusNextField()}
                      placeholder="40,00"
                      style={[
                        styles.textInput,
                        {
                          borderWidth: 1,
                          borderColor: amountMessage ? "red" : "white",
                          marginBottom: 0,
                        },
                      ]}
                      placeholderTextColor={"#cccccc"}
                      onChangeText={onChangeAmount}
                      onKeyPress={handleKeyboardEvent}
                      value={amount}
                    />
                    <View style={{ marginBottom: 15 }}>
                      <Text style={{ color: "red" }}>{amountMessage}</Text>
                    </View>
                  </View>

                  <View style={{ marginBottom: 5 }}>
                    {showGeneratePayment ? (
                      <Pressable style={styles.button} onPress={paymentSubmit}>
                        <Text style={[styleWithFontScale(20, 14)]}>
                          {t("ModalDeposit.deposit")}
                        </Text>
                      </Pressable>
                    ) : (
                      <Text style={{ color: "white" }}>
                        {t("ModalDeposit.loading")}
                      </Text>
                    )}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal>
      {showButton ? (
        <Pressable style={styles.button} onPress={() => setModalVisible(true)}>
          <Text style={[styleWithFontScale(20, 14)]}>
            {t("ModalDeposit.deposit")}
          </Text>
        </Pressable>
      ) : null}
      {/* <Button title='Login' onPress={() => setModalVisible(!modalVisible)} /> */}
    </React.Fragment>
  );
}
