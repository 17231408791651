import { FontAwesome } from "@expo/vector-icons";
import React, { createContext, useContext } from "react";
import { Platform, Pressable, Text, View } from "react-native";
import { SettingProps } from "../types";
import { useWebSocket } from "./WebSocket";

type SettingContextProps = {
  settings: SettingProps;
};

export const SettingContext = createContext({} as SettingContextProps);

type SettingProviderProps = {
  children: React.ReactNode;
};

export function SettingProvider({ children }: SettingProviderProps) {
  const { socket } = useWebSocket();

  const [appVersion, setAppVersion] = React.useState<number>(1061);

  const [playersOnline, setPlayersOnline] = React.useState<number>(0);

  const [newVersionAvailable, setNewVersionAvailable] =
    React.useState<boolean>(false);

  const [production, setProduction] = React.useState<boolean>(true);

  const [settings, setSettings] = React.useState<SettingProps>(
    {} as SettingProps
  );

  React.useEffect(() => {
    if (!socket.connected) {
      return;
    }

    socket.on("configuration", (data: SettingProps) => {
      //console.log(data);
      setSettings(data);

      setProduction(data.production);

      if (data.id && appVersion !== data.appVersion) {
        setNewVersionAvailable(true);
      }

      if (data.id && data.forceUpdate === true) {
        if (Platform.OS === "web") {
          window.location.reload();
        }
      }
    });

    socket.on("players online", (players: number) => {
      setPlayersOnline(players);
    });

    socket.emit("configuration");
    socket.emit("players online");

    const interval = setInterval(() => {
      if (socket.connected) {
        socket.emit("configuration");
        socket.emit("players online");
        //console.log("setting");
      }
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [socket]);

  return (
    <SettingContext.Provider value={{ settings }}>
      {children}
      <View
        pointerEvents={"none"}
        style={{
          position: "absolute",
          bottom: 0,
          right: 15,
          //backgroundColor: "rgba(0, 0, 0, 0.2)",
          //borderRadius: 2,
        }}
      >
        <Text style={{ color: "rgba(255, 255, 255, 0.3)", fontSize: 8 }}>
          1.0.61/{playersOnline}
        </Text>
      </View>

      {!production ? (
        <View
          pointerEvents={"none"}
          style={{
            position: "absolute",
            top: 82,
            right: 10,
            backgroundColor: "orange",
            borderRadius: 3,
            padding: 5,
          }}
        >
          <Text style={{ color: "black", fontSize: 12, fontWeight: "bold" }}>
            SANDBOX
          </Text>
        </View>
      ) : null}

      {newVersionAvailable ? (
        <Pressable
          style={{
            position: "absolute",
            top: 8,
            alignSelf: "center",
            //right: 10,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            borderColor: "rgba(255, 255, 255, 0.2)",
            borderWidth: 2,
            borderRadius: 5,
            paddingHorizontal: 10,
            paddingVertical: 10,
          }}
          onPress={() => {
            if (Platform.OS === "web") {
              window.location.reload();
            }
          }}
        >
          <Text
            style={{
              color: "rgba(255, 255, 255, 1)",
              fontSize: 14,
            }}
          >
            <FontAwesome
              name="refresh"
              size={16}
              color="white"
              style={{ marginRight: 10 }}
            />
            New version available
          </Text>
        </Pressable>
      ) : (
        <View style={{ display: "none" }} />
      )}
    </SettingContext.Provider>
  );
}

export function useSetting() {
  const context = useContext(SettingContext);

  if (context === undefined) {
    throw new Error("Context provider undefined.");
  }

  return context;
}
