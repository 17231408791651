import { Platform, StyleSheet, TextStyle, ViewStyle } from "react-native";
import { useDimensions } from "../../../contexts/Dimensions";
import { getFontSize } from "../../../util/GlobalStyles";

export const styles = StyleSheet.create({
  container: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor: 'turquoise',
  },
  image: {
    flex: 1,
    width: "100%",
    height: "100%",
    textAlign: "center",
    //alignItems: 'center',
    justifyContent: "center",
  },
  title: {
    fontWeight: "500",
    color: "#FFB800",
    //width: '100%',
    //height: '100%',
    textAlign: "center",
    //backgroundColor: 'blue'
    //textAlignVertical: 'center'
    backgroundColor: "rgba(0,0,0, 0.2)",
    borderRadius: 30,
    paddingVertical: 4,
    paddingHorizontal: 15,
    textAlignVertical: "center",
  },
  subtitle: {
    fontWeight: "bold",
    color: "#FFB800",
    top: -5,
    textShadowColor: "#000",
    textShadowOffset: {
      width: 1,
      height: 1,
    },
    textShadowRadius: 6,
  },
});

export const styleTotalPotPosition = (): ViewStyle => {
  return StyleSheet.flatten([styles.container, getTotalPotPosition()]);
};

export const getTotalPotPosition = (): ViewStyle => {
  const { orientation } = useDimensions();

  switch (orientation) {
    case "landscape":
      return {
        width: "30%",
        height: "6%",
        left: "35%",
        top: "34%",
      };
    case "portrait":
      return {
        width: "40%",
        height: "4%",
        left: "30%",
        top: "38%",
      };
    default:
      return {
        display: "none",
      };
  }
};

export const styleWithFontScale = (
  fontSizeWeb: number,
  fontSizeDefault: number
): TextStyle => {
  return StyleSheet.flatten([
    styles.title,
    { fontSize: getFontSize(fontSizeWeb, fontSizeDefault) },
  ]);
};

export function textSize(fontSizeWeb: number, fontSizeDefault: number) {
  const { orientation, width, height } = useDimensions();

  if (Platform.OS === "web") {
    if (orientation === "landscape") {
      if (height >= 780 && height <= 880) {
        fontSizeWeb -= 4;
      } else if (height >= 680 && height < 780) {
        fontSizeWeb -= 7;
      } else if (height < 680) {
        fontSizeWeb -= 12;
      }
    } else {
      if (width > 360 && width < 540) {
        fontSizeWeb -= 8;
      } else if (width <= 360) {
        fontSizeWeb -= 12;
      }
    }

    return fontSizeWeb;
  }

  return fontSizeDefault;
}

// export const getFontSize = (fontSizeWeb: number, fontSizeDefault: number): TextStyle => {

//     let font = textSize(fontSizeWeb, fontSizeDefault);

//     return {
//         fontSize: font
//     }
// }
