import { StyleSheet, ViewStyle } from "react-native";
import { useDimensions } from "../../../../../../contexts/Dimensions";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    borderRadius: 8,
  },
  title: {
    fontSize: 22,
    fontWeight: "500",
    color: "#FFFFFF",
    textAlign: "center",
  },
});

export const styleHandNamePosition = (): ViewStyle => {
  return StyleSheet.flatten([styles.container, getHandNamePosition()]);
};

export const getHandNamePosition = (): ViewStyle => {
  const { orientation } = useDimensions();

  switch (orientation) {
    case "landscape":
      return {
        width: "100%",
        //height: "38%",
        top: "125%",
      };
    case "portrait":
      return {
        width: "100%",
        //height: "38%",
        left: "112%",
        top: "-130%",
      };
    default:
      return {
        display: "none",
      };
  }
};
