import React from "react";
import { MotiView } from "moti";
import { Image } from 'react-native';
import { useTable } from "../../../../../../../contexts/Table";
import { PokerVariant } from "../../../../../../../types";

export default function () {
    const { table } = useTable();
    return (
        <MotiView
            pointerEvents="none"
            style={{ position: 'absolute', width: '100%', height: '100%', justifyContent: 'flex-end' }}
            from={{ top: -20, opacity: 0 }}
            animate={{ top: -25, opacity: 1 }}
            transition={{ type: 'timing', duration: 1000 }}
        >
            {
                table.variant === PokerVariant.TEXAS_HOLDEM ? (
                    <Image
                        source={require('../../../../../../../assets/images/theme/default/table/folded-th.png')}
                        style={{ width: '100%', height: '50%', resizeMode: 'contain' }}
                        fadeDuration={0}
                    />
                ) : table.variant === PokerVariant.OMAHA ? (
                    <Image
                        source={require('../../../../../../../assets/images/theme/default/table/folded-o.png')}
                        style={{ width: '100%', height: '50%', resizeMode: 'contain' }}
                        fadeDuration={0}
                    />
                ) : table.variant === PokerVariant.OMAHA_5_CARD ? (
                    <Image
                        source={require('../../../../../../../assets/images/theme/default/table/folded-o5.png')}
                        style={{ width: '100%', height: '50%', resizeMode: 'contain' }}
                        fadeDuration={0}
                    />
                ) : (<React.Fragment></React.Fragment>)
            }

        </MotiView>
    )
}