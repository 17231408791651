import React, { createContext, useContext } from "react";
import { PlayerProps, PreAction, TableProps } from "../types";
import { useAccount } from "./Account";
import { useWebSocket } from "./WebSocket";

type TableContextProps = {
  //setState: (value: string) => void;
  table: TableProps;
  actionTime: number;
  timebank: boolean;
  currentPlayer: PlayerProps | null;
  panelVisible: boolean;
  panelSelectedTab: number;
  showMinimumStayModal: boolean;
  manualSetTimeBank: boolean;
  modalBuyInVisible: boolean;
  handleSitDownPress: (buyIn: number, seatNumber: number) => void;
  handleStandUpPress: () => void;
  handleCallActionPress: () => void;
  handleCheckActionPress: () => void;
  handleBetActionPress: (betAmount: number) => void;
  handleRaiseActionPress: (betAmount: number) => void;
  handleFoldActionPress: () => void;
  handlePayBigBlindActionPress: () => void;
  handleUseTimeBankActionPress: () => void;
  handlePreActionPress: (preAction: PreAction) => void;
  handleMuckCardsPress: () => void;
  handleSitOutPress: () => void;
  handleJoinWaitingPress: (buyIn: number) => void;
  setPanelVisible: (panelVisible: boolean) => void;
  setPanelSelectedTab: (tabNumber: number) => void;
  setShowMinimumStayModal: (value: boolean) => void;
  setManualSetTimeBank: (manualSetTimeBank: boolean) => void;
  setModalBuyInVisible: (modalBuyInVisible: boolean) => void;
};

export const TableContext = createContext({} as TableContextProps);

type TableProviderProps = {
  children: React.ReactNode;
};

export function TableProvider({ children }: TableProviderProps) {
  const { socket, tableId } = useWebSocket();
  const { signed, user } = useAccount();

  const [table, setTable] = React.useState<TableProps>({} as TableProps);
  const [actionTime, setActionTime] = React.useState<number>(0);
  const [timebank, setTimebank] = React.useState<boolean>(false);
  const [currentPlayer, setCurrentPlayer] = React.useState<PlayerProps | null>(
    null
  );

  const [panelVisible, setPanelVisible] = React.useState<boolean>(false);
  const [panelSelectedTab, setPanelSelectedTab] = React.useState<number>(0);
  const [showMinimumStayModal, setShowMinimumStayModal] =
    React.useState<boolean>(false);
  const [manualSetTimeBank, setManualSetTimeBank] =
    React.useState<boolean>(false);
  const [modalBuyInVisible, setModalBuyInVisible] =
    React.useState<boolean>(false);

  const handleSitDownPress = (buyIn: number, seatNumber: number | null) => {
    if (!socket.connected) return;
    if (!user) return;
    socket.emit("sit down", tableId, buyIn, seatNumber);
  };

  const handleStandUpPress = () => {
    if (!socket.connected) return;
    if (!user) return;
    socket.emit("stand up", tableId);
    socket.emit("multitable");
    //console.log('stand up ' + tableId);
  };

  const handleCallActionPress = () => {
    if (!socket.connected) return;
    if (!user) return;
    socket.emit("call action", tableId);
    //console.log('call action ' + tableId);
  };

  const handleCheckActionPress = () => {
    if (!socket.connected) return;
    if (!user) return;
    socket.emit("check action", tableId);
    //console.log('check action ' + tableId);
  };

  const handleBetActionPress = (betAmount: number) => {
    if (!socket.connected) return;
    if (!user) return;
    socket.emit("bet action", tableId, betAmount);
    //console.log('bet action ' + tableId);
  };

  const handleRaiseActionPress = (betAmount: number) => {
    if (!socket.connected) return;
    if (!user) return;
    socket.emit("raise action", tableId, betAmount);
    //console.log('raise action ' + tableId);
  };

  const handleFoldActionPress = () => {
    if (!socket.connected) return;
    if (!user) return;
    socket.emit("fold action", tableId);
  };

  const handlePayBigBlindActionPress = () => {
    if (!socket.connected) return;
    if (!user) return;
    socket.emit("pay big blind action", tableId);

    //console.log('pay big blind action ' + tableId);
  };

  const handleUseTimeBankActionPress = () => {
    if (!socket.connected) return;
    if (!user) return;
    setManualSetTimeBank(true);
    socket.emit("timebank", tableId);

    //console.log('timebank table' + tableId);
  };

  const handlePreActionPress = (preAction: PreAction) => {
    if (!socket.connected) return;
    if (!user) return;
    socket.emit("pre action", tableId, preAction);

    //console.log('pre action ' + preAction);
  };

  const handleMuckCardsPress = () => {
    if (!socket.connected) return;
    if (!user) return;
    socket.emit("muck cards", tableId);
    //console.log('muck cards');
  };

  const handleSitOutPress = () => {
    if (!socket.connected) return;
    if (!user) return;
    socket.emit("sitOut next hand", tableId);
    //console.log('muck cards');
  };

  const handleJoinWaitingPress = (buyIn: number) => {
    if (!socket.connected) return;
    if (!user) return;
    socket.emit("join waiting", buyIn, tableId);
    //console.log('join waiting', buyIn, tableId);
  };

  React.useEffect(() => {
    if (!socket.connected) {
      return;
    }

    if (tableId !== undefined) {
      socket.on("update table", (data: TableProps) => {
        if (user) {
          const player = data.players.find(
            (player) => player && player.id === user.id
          );

          if (player) {
            setCurrentPlayer(player);
          } else {
            setCurrentPlayer(null);
          }
        } else {
          setCurrentPlayer(null);
        }

        //console.log(data);

        setTable(data);
      });

      socket.emit("join table", tableId);

      socket.on("action time", (time: number, timebank: boolean) => {
        //console.log(`Time: ${time}`);
        setActionTime(time);
        setTimebank(timebank);
      });
    }

    return () => {
      socket.off("update table");
      socket.off("action time");
      //socket.off("chat");
      socket.off("emoji");
      socket.emit("leave table", tableId);
    };
  }, [socket.connected, tableId, signed]);

  /*
    React.useEffect(() => {

    }, [table]);
    */

  return (
    <TableContext.Provider
      value={{
        table,
        actionTime,
        timebank,
        currentPlayer,
        panelVisible,
        panelSelectedTab,
        showMinimumStayModal,
        manualSetTimeBank,
        modalBuyInVisible,
        setShowMinimumStayModal,
        handleSitDownPress,
        handleStandUpPress,
        handleCallActionPress,
        handleCheckActionPress,
        handleBetActionPress,
        handleRaiseActionPress,
        handleFoldActionPress,
        handlePayBigBlindActionPress,
        handleUseTimeBankActionPress,
        handlePreActionPress,
        handleMuckCardsPress,
        handleSitOutPress,
        handleJoinWaitingPress,
        setPanelVisible,
        setPanelSelectedTab,
        setManualSetTimeBank,
        setModalBuyInVisible,
      }}
    >
      {children}
    </TableContext.Provider>
  );
}

export function useTable() {
  const context = useContext(TableContext);

  if (context === undefined) {
    throw new Error("Context provider undefined.");
  }

  return context;
}
