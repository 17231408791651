import Slider from "@react-native-community/slider";
import React from "react";
import { Pressable, Text, TextInput, View } from "react-native";
import { useAccount } from "../../../contexts/Account";
import { useWebSocket } from "../../../contexts/WebSocket";
import { monetaryValue } from "../../../util";
import Modal from "../Modal";
import { useTranslation } from "react-i18next";
import { useTable } from "../../../contexts/Table";

import { styles, styleWithFontScale } from "./styles";

type Props = {
  minBuyIn: number;
  maxBuyIn: number;
  tableId: number | undefined;
};

function Rebuy({ minBuyIn, maxBuyIn, tableId }: Props) {
  const { t } = useTranslation();
  const { socket } = useWebSocket();
  const { signed, wallet } = useAccount();
  const { handleStandUpPress, currentPlayer } = useTable();

  const inputRef = React.useRef<TextInput>(null);

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);

  const [sliderChanged, setSliderChanged] = React.useState<boolean>(false);
  const [bonus, setBonus] = React.useState<boolean>(false);

  const [sliderMinBuyIn, setSliderMinBuyIn] = React.useState<number>(0);
  const [sliderMaxBuyIn, setSliderMaxBuyIn] = React.useState<number>(0);
  const [sliderBuyIn, setSliderBuyIn] = React.useState<number>(0);
  const [inputBuyIn, setInputBuyIn] = React.useState<number>(0);

  const [helpText, setHelpText] = React.useState<string>(
    t("ModalAddChips.youAreBuyingInFor", {
      value: monetaryValue(sliderBuyIn).toString(),
    })
  );

  const handleAddChipsPress = (chips: number, tableId: number | undefined) => {
    if (tableId === undefined) return;
    if (!socket.connected) return;
    if (!signed) return;

    socket.emit("rebuy", chips, tableId);
  };

  const handleInputBuyIn = (text: string) => {
    let cleanText = text.replace(/[^\d.]/g, "");
    while (cleanText.includes(",")) cleanText = cleanText.replace(",", "");
    while (cleanText.includes(".")) cleanText = cleanText.replace(".", "");

    setInputBuyIn(Number(cleanText));
  };

  const onChangeSliderBuyIn = (amount: number) => {
    //console.log("changed", sliderMinBuyIn, sliderBuyIn, sliderMaxBuyIn, amount);

    setSliderChanged(true);
    const value = Math.round(Number(amount));
    setSliderBuyIn(value);
    setInputBuyIn(value / 100);
  };

  const handleInputSliderBuyInOnEndEdit = () => {
    // console.log(
    //   "changed input",
    //   sliderMinBuyIn,
    //   sliderBuyIn,
    //   sliderMaxBuyIn,
    //   inputBuyIn
    //);

    setSliderChanged(true);
    const number = Number(inputBuyIn);

    if (isNaN(number)) {
      setInputBuyIn(maxBuyIn / 100);
      setSliderBuyIn(maxBuyIn);
      return;
    }

    const value = Math.round(number * 100);

    if (value < minBuyIn) {
      setInputBuyIn(minBuyIn / 100);
      setSliderBuyIn(minBuyIn);
    } else if (value > maxBuyIn) {
      setInputBuyIn(maxBuyIn / 100);
      setSliderBuyIn(maxBuyIn);
    } else {
      setInputBuyIn(value / 100);
      setSliderBuyIn(value);
    }
  };

  React.useEffect(() => {
    if (!inputRef.current?.isFocused()) {
      handleInputSliderBuyInOnEndEdit();
    }
  }, [inputRef.current?.isFocused()]);

  React.useEffect(() => {
    if (modalVisible) {
      setSliderChanged(false);
    }
  }, [modalVisible]);

  React.useEffect(() => {
    //console.log("useEffect", sliderMinBuyIn, sliderBuyIn, sliderMaxBuyIn);

    if (minBuyIn && maxBuyIn) {
      setSliderMinBuyIn(minBuyIn);
      setSliderMaxBuyIn(maxBuyIn);

      if (!sliderChanged || sliderBuyIn < minBuyIn || sliderBuyIn > maxBuyIn) {
        setSliderBuyIn(maxBuyIn);
        setInputBuyIn(maxBuyIn / 100);
        // console.log(
        //  "!sliderChanged",
        //   sliderMinBuyIn,
        //  sliderBuyIn,
        //   sliderMaxBuyIn
        // );
      }

      if (wallet !== undefined && wallet.balance > minBuyIn) {
        if (wallet !== undefined && wallet.balance < maxBuyIn) {
          setSliderMaxBuyIn(wallet.balance);
          setSliderBuyIn(wallet.balance);
          setInputBuyIn(wallet.balance / 100);
          //console.log("wallet", sliderMinBuyIn, sliderBuyIn, sliderMaxBuyIn);
        }
      } else {
        setBonus(true);
        if (wallet !== undefined && wallet.bonus < maxBuyIn) {
          setSliderMaxBuyIn(wallet.bonus);
          setSliderBuyIn(wallet.bonus);
          setInputBuyIn(wallet.bonus / 100);
          //console.log("wallet", sliderMinBuyIn, sliderBuyIn, sliderMaxBuyIn);
        }
      }
    }
  }, [wallet, minBuyIn, maxBuyIn]);

  React.useEffect(() => {
    setHelpText(
      t("ModalAddChips.youAreAdding", {
        value: monetaryValue(sliderBuyIn).toString(),
      })
    );
  }, [sliderBuyIn]);

  React.useEffect(() => {
    if (!modalVisible && currentPlayer && currentPlayer.reBuy) {
      setModalVisible(true);
    } else if (modalVisible && (!currentPlayer || !currentPlayer.reBuy)) {
      setModalVisible(false);
    }
  }, [currentPlayer]);

  return (
    <React.Fragment>
      <Modal
        title={`${t("TableScreen.sideMenu.buyin")} (30s)`}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        animationType="none"
        showClose={false}
      >
        <View style={{ flex: 1 }}>
          <View style={{ flex: 1 }}>
            <Text
              style={{
                textAlign: "center",
                color: "#FFFFFF",
                fontSize: 22,
              }}
            >
              {t("ModalAddChips.currentBalance")}
            </Text>
            <Text
              style={{
                textAlign: "center",
                color: "lightblue",
                fontSize: 20,
              }}
            >
              {bonus ? monetaryValue(wallet?.bonus || 0) : monetaryValue(wallet?.balance || 0)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 18,
                }}
              >
                {t("ModalAddChips.min")}
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontSize: 20,
                }}
              >
                {monetaryValue(minBuyIn)}
              </Text>
            </View>
            <View>
              <Text
                style={{
                  textAlign: "center",
                  color: "#FFFFFF",
                  fontSize: 20,
                }}
              >
                {t("ModalAddChips.add")}
              </Text>

              <TextInput
                ref={inputRef}
                autoFocus={true}
                value={inputBuyIn.toString()}
                onChangeText={handleInputBuyIn}
                onEndEditing={() => handleInputSliderBuyInOnEndEdit()}
                style={{
                  textAlign: "center",
                  color: "lightblue",
                  fontSize: 22,
                  borderWidth: 1,
                  maxWidth: "50%",
                  borderColor: "white",
                  padding: 0,
                  alignSelf: "center",
                  borderRadius: 15,
                }}
                keyboardType="numeric"
              />

              {/*
              <Text
                style={{
                  textAlign: "center",
                  color: "lightblue",
                  fontSize: 22,
                }}
              >
                {monetaryValue(sliderBuyIn)}
              </Text>
              */}
            </View>
            <View>
              <Text
                style={{
                  textAlign: "right",
                  color: "#FFFFFF",
                  fontSize: 18,
                }}
              >
                {t("ModalAddChips.max")}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  color: "#FFFFFF",
                  fontSize: 20,
                }}
              >
                {monetaryValue(maxBuyIn)}
              </Text>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <Slider
              style={{ width: "100%", paddingVertical: 10 }}
              minimumValue={sliderMinBuyIn}
              maximumValue={sliderMaxBuyIn}
              value={sliderBuyIn}
              step={100}
              onValueChange={onChangeSliderBuyIn}
              minimumTrackTintColor={"#004bF7"}
              maximumTrackTintColor={"#636363"}
              thumbTintColor={"#FFF"}
            />
          </View>
          <View>
            <Text
              style={{
                textAlign: "center",
                color: "#CCCCCC",
                fontSize: 18,
              }}
            >
              {helpText}
            </Text>
          </View>
          <View>
            <Text
              style={{
                textAlign: "center",
                color: "#CCCCCC",
                fontSize: 18,
              }}
            >
              {`\n`}
              {t("ModalAddChips.instruction")}
            </Text>
          </View>
          <View style={{ flex: 1, paddingTop: 10 }}>
            <Pressable
              style={styles.button}
              onPress={() => {
                handleInputSliderBuyInOnEndEdit();
                handleAddChipsPress(sliderBuyIn, tableId);
              }}
            >
              <Text style={[styleWithFontScale(20, 14)]}>
                {t("ModalAddChips.buttonAdd")}
              </Text>
            </Pressable>
          </View>
          <View style={{ flex: 1, paddingTop: 10 }}>
            <Pressable
              style={[styles.button, { backgroundColor: "#333333" }]}
              onPress={() => handleStandUpPress()}
            >
              <Text
                style={[
                  styleWithFontScale(20, 14),
                  { textTransform: "uppercase" },
                ]}
              >
                {t("TableScreen.sideMenu.leaveGame")}
              </Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    </React.Fragment>
  );
}

export default Rebuy;
