import {TableEmoji} from '../types';
import { useTranslation } from 'react-i18next';

export default function tableEmoji() {
    const { t } = useTranslation();
    return [
        {
            id: '1',
            name: t("EmojiCollection.ThumbsUp"),
            code: 'a0',
            sprite: require('../assets/images/theme/default/emoji/demo/thumbs.png')
        },
        {
            id: '2',
            name: t("EmojiCollection.Angry"),
            code: 'a1',
            sprite: require('../assets/images/theme/default/emoji/demo/angry.png')
        },
        {
            id: '3',
            name: t("EmojiCollection.Sad"),
            code: 'a2',
            sprite: require('../assets/images/theme/default/emoji/demo/sad.png')
        },
        {
            id: '4',
            name: t("EmojiCollection.Laughing"),
            code: 'a3',
            sprite: require('../assets/images/theme/default/emoji/demo/laugh.png')
        },
        {
            id: '5',
            name: t("EmojiCollection.Wow!"),
            code: 'a4',
            sprite: require('../assets/images/theme/default/emoji/demo/amazed.png')
        },  
    ] as TableEmoji[]
};