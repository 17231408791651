import { Audio } from "expo-av";
import React from "react";
import { Image, ImageBackground, Pressable, View } from "react-native";
import { useGameOptions } from "../../../../../../contexts/GameOptions";
import { useTable } from "../../../../../../contexts/Table";
import { PlayerProps } from "../../../../../../types";
import HandName from "../HandName";
import Action from "./Action";
import HoleCards from "./HoleCards";
import PlayerPodEffect from "./PlayerPodEffect";
import SliderTime from "./SliderTime";
import Stack from "./Stack";
import Username from "./Username";
import styles from "./styles";

import Level from "../../../../../../collections/Level";

type Props = {
  seatNumber: number;
  numberOfSeats: number;
  player: PlayerProps;
};

export default function ({ seatNumber, numberOfSeats, player }: Props) {
  const {
    showLevel,
    soundEnabled,
    soundVolume,
    showBigBlind,
    handleSetShowBigBlind,
  } = useGameOptions();
  const { table, currentPlayer } = useTable();

  const earlyShowdown = table.isShowdownBeforeRiver;
  const delay = earlyShowdown ? 5000 : 0;
  const [playerStackValue, setPlayerStackValue] = React.useState<number>(0);

  const [rememberCards, setRememberCards] = React.useState<boolean>(false);
  const [sound, setSound] = React.useState<Audio.Sound>();
  const action = player.action;

  async function playFoldSfx() {
    if (!soundEnabled) return;

    const { sound } = await Audio.Sound.createAsync(
      require("../../../../../../assets/audio/sfx/fold_other.mp3")
    );
    sound.setVolumeAsync(0.7 * soundVolume);
    setSound(sound);

    try {
      await sound.playAsync();
    } catch (e) {
      console.log("Failed to play fold_other sfx");
      console.warn(e);
    }
  }

  async function playCheckSfx() {
    if (!soundEnabled) return;

    const { sound } = await Audio.Sound.createAsync(
      require("../../../../../../assets/audio/sfx/check2.mp3")
    );
    setSound(sound);
    sound.setVolumeAsync(0.7 * soundVolume);

    try {
      await sound.playAsync();
    } catch (e) {
      console.log("Failed to play check2 sfx");
      console.warn(e);
    }
  }

  React.useEffect(() => {
    return sound
      ? () => {
          //console.log('Unloading Sound');
          sound.unloadAsync();
        }
      : undefined;
  }, [sound]);

  React.useEffect(() => {
    if (action === "check") {
      playCheckSfx();
    } else if (action === "fold") {
      playFoldSfx();
    }
  }, [player.action]);

  const handleToggleShowBB = () => {
    handleSetShowBigBlind(!showBigBlind);
  };

  const handleRememberButton = () => {
    setRememberCards(!rememberCards);
  };

  React.useEffect(() => {
    setRememberCards(false);
  }, [table.currentRound]);

  React.useEffect(() => {
    let timeOut: NodeJS.Timeout;
    if (table.isShowdownBeforeRiver) {
      timeOut = setTimeout(() => {
        setPlayerStackValue(player.stackSize);
      }, delay);
    } else {
      setPlayerStackValue(player.stackSize);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [table.pots]);

  React.useEffect(() => {
    //if (player.previousStackSize) {
    //setPlayerStackValue(player.previousStackSize || player.stackSize);
    //} else {
    if (player.previousStackSize !== undefined && !player.folded) {
      setPlayerStackValue(player.previousStackSize);
    } else {
      setPlayerStackValue(player.stackSize);
    }

    //}
  }, [player]);

  return (
    <View
      style={[
        styles.container,
        player.left || player.folded ? { opacity: 0.8 } : {},
      ]}
    >
      {showLevel && (
        <View
          style={{
            position: "absolute",
            top: -18,
            right: 15,
            width: 25,
            height: 32,
            //backgroundColor: "pink",
          }}
        >
          <Image
            style={{
              width: "100%",
              height: "100%",
            }}
            source={Level[player.level]}
          />
        </View>
      )}

      {!player.holeCards || player.holeCards.length === 0 ? null : (
        <HoleCards
          player={player}
          cards={player.holeCards}
          showCards={player.showCards}
          seatNumber={seatNumber}
          variant={table.variant}
          remembering={rememberCards}
        />
      )}

      <ImageBackground
        fadeDuration={0}
        style={styles.image}
        resizeMode={"contain"}
        source={require("../../../../../../assets/images/theme/default/table/pod.png")}
      >
        <View style={styles.content}>
          <HandName handName={player.handName} />
          <Username username={`${player.username}`} />
          {/* <Image
                        style={styles.podLine}
                        source={require('../../../../../../assets/images/theme/default/table/pod_line.png')}
                    /> */}
          <View style={styles.separator} />
          <Stack
            stackSize={playerStackValue}
            reBuy={player.reBuy}
            left={player.left}
          />
        </View>
      </ImageBackground>
      {/* {
                player.winner &&(
                    <MotiImage
                        style={{position:'absolute',flex:1,width:'94%',height:'100%',resizeMode:'stretch'}} 
                        source={require('../../../../../../assets/images/theme/default/table/pod_winnerEffect.png')}
                        from={{opacity:0}}
                        animate={{opacity:1}}
                        transition={{type:'timing',duration:500,delay:delay}}
                    />
                )}
            <MotiImage 
                style={{position:'absolute',flex:1,width:'94%',height:'100%',resizeMode:'stretch'}} 
                source={
                    table.currentPosition === player.seatNumber ? 
                        require('../../../../../../assets/images/theme/default/table/pod_activeEffect.png')
                    :null
                }
                from={{opacity:0}}
                animate={{opacity:[0,1]}}
                transition={{type:'timing',duration:500,loop:true}}
                
            /> */}

      <PlayerPodEffect player={player} />

      <Action
        action={
          player.left ? "left" : player.sitOut ? "sitout" : player.action || ""
        }
        player={player}
      />

      {table.currentPosition == player.seatNumber && !player.sitOut && (
        <SliderTime player={player} />
      )}

      {/* {player.folded && !player.waitingBigBlind ? currentPlayer?.id === player.id ? 
                <Pressable 
                    style={[{top:'-35%',width:'50%',height:"80%",position:'absolute',zIndex:0.9}]}
                    onPress={()=>setRememberHand(!rememberHand)}
                    >
                </Pressable>
                :<></>:<></>
            }  */}
      {player.folded && !player.waitingBigBlind ? (
        currentPlayer?.id === player.id ? (
          <Pressable
            style={[
              {
                top: "-55%",
                width: "50%",
                height: "120%",
                position: "absolute",
                zIndex: 0.8,
              },
            ]}
            onPress={handleRememberButton}
          ></Pressable>
        ) : null
      ) : null}
      <Pressable
        style={[
          {
            top: "50%",
            width: "50%",
            height: "30%",
            position: "absolute",
            zIndex: 0.9,
          },
        ]}
        onPress={handleToggleShowBB}
      ></Pressable>
    </View>
  );
}
