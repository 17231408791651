import React from 'react';
import { View, } from 'react-native';
import { useTable } from '../../../../contexts/Table';
import MuckCards from './MuckCards';

import { styleActionsPosition } from './styles';

export default function () {
    const { currentPlayer } = useTable();

    const [muckCards, setMuckCards] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (currentPlayer) {
            setMuckCards(currentPlayer.muckCards);
        }
    }, [currentPlayer]);

    return (
        <View style={styleActionsPosition()}>
            <MuckCards muckCards={muckCards} />
        </View>
    );
}