import React from 'react';
import {View,} from 'react-native';
import {useTable} from '../../../../contexts/Table';
import PayBigBlind from './PayBigBlind';

import {styleActionsPosition} from './styles';

type Props = {
    currentCallValue: number;
    currentBettingValue: number;
}

export default function ({currentCallValue, currentBettingValue}: Props) {
    const {currentPlayer} = useTable();

    const [payBigBlind, setPayBigBlind] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (currentPlayer) {
            setPayBigBlind(currentPlayer.payBigBlind);
        }
    }, [currentPlayer]);

    return (
        <View style={styleActionsPosition()}>
            <PayBigBlind payBigBlind={payBigBlind}/>
        </View>
    );
}