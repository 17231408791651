import React from 'react';
import {MotiImage} from 'moti';
import {View} from 'react-native';
import Cards1 from '../../../../collections/Cards1';
import Cards2 from '../../../../collections/Cards2';
import Cards3 from '../../../../collections/Cards3';
import styles from './styles';

type Props = {
    frontDeck: number;
    card: string;
    cardNumber: number;
    delay?: number;
    animate?: boolean;
}

export default function ({frontDeck, card, cardNumber, delay, animate}: Props) {
    const [Cards, setCards] = React.useState<any>(Cards1);

    const showdownFlopDelay = delay ? delay : 0;
    const delayResult = (cardNumber < 3 ? 100 * cardNumber : 0) + showdownFlopDelay;
    const animationFrom = animate ? {top: 100, left: (2 - cardNumber) * 70, opacity: 0} : {top: 0, left: 0, opacity: 1};

   React.useEffect(() => {
    if (frontDeck === 2) {
        setCards(Cards2);
    } else if (frontDeck === 3) {
        setCards(Cards3);
    } else {
        setCards(Cards1);
    }
   }, [frontDeck]);

    return (
        <View style={styles.container}>
            <MotiImage
                resizeMode={'contain'}
                style={[styles.image]}
                source={Cards[card]}
                from={animationFrom}
                animate={{top: 0, left: 0, opacity: 1}}
                transition={{type: 'timing', duration: 250}}
                delay={delayResult}
            />
        </View>
    );
}