import * as React from "react";
import {
  ImageBackground,
  StyleSheet,
  View,
  Text,
  ActivityIndicator,
  ScrollView,
  Pressable,
} from "react-native";
import Seat from "./Seats/Seat";
import { useDimensions } from "../../contexts/Dimensions";
import styles from "./styles";
import Controls from "./Controls";
import Dealer from "./Dealer";
import Tables from "../../collections/Tables";
import CommunityCards from "./CommunityCards";
import TotalPot from "./TotalPot";
import Pot from "./Pots/Pot";
import { useTable } from "../../contexts/Table";
import { BettingRound, PokerBettingLimit, PokerBettingLimitAbbr, PokerVariantAbbr, Registration, TournamentStatus } from "../../types";
import CardDealAnimation from "./Animations/CardDealAnimation";
import PrizeAnimation from "./Animations/PrizeAnimation";
import { Audio } from "expo-av";
import { useGameOptions } from "../../contexts/GameOptions";
import Emoji from "./Emoji";
import { useSetting } from "../../contexts/Setting";
import { useNavigation } from "@react-navigation/native";
import { monetaryValue } from "../../util";
import { useWebSocket } from "../../contexts/WebSocket";

export default function Table() {
  const { soundVolume, soundEnabled } = useGameOptions();
  const { width, height, orientation } = useDimensions();
  const { settings } = useSetting();
  const { socket } = useWebSocket();
  const { table, currentPlayer } = useTable();
  const navigation = useNavigation();
  const [playPrizeAnimation, setPlayPrizeAnimation] = React.useState(false);
  const [playCardDealAnimation, setPlayCardDealAnimation] =
    React.useState<boolean>(false);
  const [timeoutList, setTimeoutList] = React.useState<NodeJS.Timeout[]>([]);
  const [myTurnSound, setMyTurnSound] = React.useState<Audio.Sound>();
  const [maintenance, setMaintenance] = React.useState<boolean>(false);
  const [maintenanceMessage, setMaintenanceMessage] =
    React.useState<string>("");

  const [registrations, setRegistrations] = React.useState<Registration[]>([]);

  const players = table.players || [];
  const pots = table.pots || [];

  const numberOfSeats = players.length;
  const numberOfPots = pots.length;

  const styleTableContainer = StyleSheet.flatten([
    styles.content,
    {
      minWidth: width,
      minHeight: height,
      width: width,
      height: height,
      maxWidth: width,
      maxHeight: height,
    },
  ]);

  async function playMyTurnSound() {
    if (!soundEnabled) return;

    const { sound } = await Audio.Sound.createAsync(
      require("../../assets/audio/sfx/yourTurn.mp3")
    );
    sound.setVolumeAsync(0.7 * soundVolume);
    setMyTurnSound(sound);

    if (myTurnSound) {
      try {
        await sound.playAsync();
      } catch (e) {
        console.log("Failed to play yourTurn sfx");
        console.warn(e);
      }
    }
  }

  function analiseTable() {
    let tmp = false;
    if (table && table.currentRound === BettingRound.PRE_FLOP) tmp = true;
    else return tmp;
    if (table && table.currentBet && table.currentBet > table.bigBlind)
      tmp = false;
    else if (table && table.players) {
      table.players.map((player, index) => {
        if (player && player.action) tmp = false;
      });
    }
    return tmp;
  }

  React.useEffect(() => {
    return myTurnSound
      ? () => {
          myTurnSound.unloadAsync();
        }
      : undefined;
  }, [myTurnSound]);

  React.useEffect(() => {
    if (currentPlayer && table.currentPosition === currentPlayer.seatNumber) {
      playMyTurnSound();
    }
  }, [table.currentPosition]);

  React.useEffect(() => {
    return () => {
      timeoutList.map((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, []);

  React.useEffect(() => {
    if (table.tournamentMode && table.tournamentStatus === TournamentStatus.ENDED) {
        socket.on("tournament:registration:list", (listRegistrations: Registration[]) => {
          if (settings.maintenance) {
            setRegistrations([]);
          } else {
            setRegistrations(listRegistrations);
          }

          //console.log(listRegistrations);
        });
        socket.emit("tournament:registration:list", table.tournamentId);
    }

    return () => {
      if (socket.connected) {
        socket.off("tournament:registration:list");
      }
    }
  }, [socket, table.tournamentStatus]);

  React.useEffect(() => {
    setPlayPrizeAnimation(false);
    if (table.isShowdownBeforeRiver) {
      timeoutList.push(
        setTimeout(() => {
          setPlayPrizeAnimation(true);
        }, 5000)
      );
      setTimeoutList(timeoutList);
    } else {
      if (table.isShowdown) setPlayPrizeAnimation(true);
    }
    if (analiseTable()) {
      if (!playCardDealAnimation) setPlayCardDealAnimation(true);
    } else {
      setPlayCardDealAnimation(false);
    }
  }, [table.currentRound]);

  React.useEffect(() => {
    if (settings.maintenance) {
      setMaintenance(settings.maintenance);
      setMaintenanceMessage(settings.maintenanceMessage);
    } else {
      setMaintenance(false);
      setMaintenanceMessage("");
    }
  }, [settings]);

  React.useEffect(() => {
    if (table && table.id) {
      let tableName = table.name;

      let tableId = table.id;

      navigation.setOptions({
        title: `${PokerBettingLimitAbbr[table.bettingLimit]}${PokerVariantAbbr[table.variant]} ${tableName} ${tableId} - ${monetaryValue(
          table.minBuyIn
        )} / ${monetaryValue(table.maxBuyIn)}`,
      });
    }
    //console.log({ date: new Date().getTime(), table});
  }, [table]);

  return (
    <ImageBackground
      source={Tables[`${table.theme}_${orientation}`]}
      resizeMode={"contain"}
      style={styleTableContainer}
      fadeDuration={0}
    >
      {!table.currentRound && !table.isShowdown && table.tournamentMode && table.tournamentStatus === TournamentStatus.ENDED && (
        <View
        style={[
          {
            position: "absolute",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            borderRadius: 5,
            paddingHorizontal: 5,
            paddingVertical: 5,
            justifyContent: "center",
          },
          orientation === "landscape"
            ? {
                top: "10%",
                left: "30%",
                width: "40%",
                height: "80%",
              }
            : { top: "10%", left: "10%", width: "80%", height: "80%" },
        ]}
        >
                <View style={{ paddingHorizontal: 3 }}>
                <View
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.20)",
                    borderBottomWidth: 2,
                    borderBottomColor: "rgba(255, 255, 255, 0.10)",
                    marginBottom: 5,
                    padding: 10,
                    borderRadius: 5,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                    }}
                  >
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      RANK
                    </Text>
                  </View>
                  <View style={{ flex: 1, justifyContent: "center" }}>
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      PLAYER
                    </Text>
                  </View>

                  <View style={{ flex: 1, justifyContent: "center" }}>
                    <Text style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      AWARD
                    </Text>
                  </View>
                </View>
              </View>
              <ScrollView
                style={{ flex: 1, paddingHorizontal: 3, marginVertical: 1 }}
              >
                {registrations.map((registration, index) => (
                  <View key={registration.id}>
                    <Pressable onPress={() => {}}>
                      <View
                        style={[
                          {
                            backgroundColor: currentPlayer && registration.userId === currentPlayer.id
                              ? "rgba(255, 255, 255, 1)"
                              : "rgba(255, 255, 255, 0.1)",
                            borderBottomWidth: 2,
                            borderBottomColor: "rgba(255, 255, 255, 0.1)",
                            marginBottom: 2,
                            paddingHorizontal: 5,
                            paddingVertical: 5,
                            borderRadius: 5,
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }
                        ]}
                      >
                        <View
                          style={{
                            flex: 1,
                            justifyContent: "center",
                            paddingLeft: 5,
                          }}
                        >
                          <Text style={{ color: currentPlayer && registration.userId === currentPlayer.id
                              ? "black"
                              : "white", textAlign: "center" }}>
                          {registration.position ?? (index + 1)}
                          </Text>
                        </View>

                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Text style={{ color: currentPlayer && registration.userId === currentPlayer.id
                              ? "black"
                              : "white", textAlign: "center" }}>{registration.user.username}</Text>
                        </View>

                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Text style={{ color: currentPlayer && registration.userId === currentPlayer.id
                              ? "black"
                              : "white", textAlign: "center" }}>{monetaryValue(registration.award || 0)}</Text>
                        </View>
                      </View>
                    </Pressable>
                  </View>
                ))}
              </ScrollView>
        </View>
      )}
      {!table.currentRound && !table.isShowdown && maintenance && (
        <View
          style={[
            {
              position: "absolute",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: 5,
              paddingHorizontal: 5,
              paddingVertical: 5,
              justifyContent: "center",
            },
            orientation === "landscape"
              ? {
                  top: "40%",
                  left: "20%",
                  width: "60%",
                  height: "16%",
                }
              : { top: "32%", left: "25%", width: "50%", height: "30%" },
          ]}
        >
          <Text style={{ color: "white", textAlign: "center" }}>
            {maintenanceMessage}
          </Text>
        </View>
      )}

      <Dealer
        key={table.dealerPosition}
        seatNumber={table.dealerPosition}
        numberOfSeats={numberOfSeats}
      />
      <Controls />

      {players.map((player, seatNumber) => (
        <Seat
          key={seatNumber}
          seatNumber={seatNumber}
          numberOfSeats={numberOfSeats}
          player={player}
          currentPlayer={currentPlayer}
          disableSit={maintenance || table.tournamentMode}
        />
      ))}
      <Emoji numberOfSeats={numberOfSeats} />
      {table.totalPot > 0 && <TotalPot totalPot={table.previousTotalPot} />}
      <CommunityCards />
      {!playPrizeAnimation &&
        pots.map((pot, potNumber) => (
          <Pot
            key={potNumber}
            potNumber={potNumber}
            numberOfPots={numberOfPots}
            pot={pot}
          />
        ))}

      {playPrizeAnimation &&
        table.isShowdown &&
        pots.map((pot, potNumber) => (
          <PrizeAnimation
            key={potNumber}
            players={players}
            numberOfSeats={numberOfSeats}
            pot={pot}
            delay={300 * potNumber}
          />
        ))}
      {analiseTable() && (
        <CardDealAnimation
          onFinishedAnimating={() => {
            setPlayCardDealAnimation(false);
          }}
        />
      )}
    </ImageBackground>
  );
}
