import React from 'react';
import { MotiView, useDynamicAnimation } from 'moti';
import { Image } from 'react-native';
import { getImageAngleString, styleCardAnimationContainer } from './styles';
import { getDestinationStyle } from '../styles';
import { useTable } from '../../../../../contexts/Table';
import { Audio } from "expo-av";
import { useGameOptions } from '../../../../../contexts/GameOptions';


export default ({
    cardNumber,
    handSize,
    seatNumber,
    onFinishedAnimation
}: { cardNumber: number, handSize: number, seatNumber: number, onFinishedAnimation: (timeOut: NodeJS.Timeout) => void }) => {
    const { table } = useTable();
    const { soundVolume, soundEnabled } = useGameOptions();
    const containerStyle = styleCardAnimationContainer();
    const destination = getDestinationStyle(seatNumber, cardNumber, table.players.length);
    const imageAngleString = getImageAngleString(cardNumber, handSize);
    const delay = (100 * seatNumber) + (100 * cardNumber);
    const [finishedAnimating, setFinishedAnimating] = React.useState<boolean>(false);
    const [sound, setSound] = React.useState<Audio.Sound>();

    async function playSound() {
        if (!soundEnabled) return;

        const { sound } = await Audio.Sound.createAsync(
            require('../../../../../assets/audio/sfx/cardSlide1.mp3')
        );
        sound.setVolumeAsync(0.7 * soundVolume);
        setSound(sound);

        try {
            await sound.playAsync();
        }
        catch (e) {
            console.log("Failed to play cardSlide1 sfx");
            console.warn(e);
        }
    }

    React.useEffect(() => {
        return sound
            ? () => {

                sound.unloadAsync();
            }
            : undefined;
    }, [sound]);

    const containerAnimation = useDynamicAnimation(() => {
        return {
            bottom: containerStyle.bottom,
            left: containerStyle.left
        }
    })

    React.useEffect(() => {

        containerAnimation.animateTo({
            bottom: destination.bottom,
            left: destination.left
        })
        const timeOut = setTimeout(() => {
            if (!finishedAnimating) {
                playSound();
                setFinishedAnimating(true);
                onFinishedAnimation(timeOut);

            }
        }, 300 + delay)
        return () => {
            clearTimeout(timeOut);
        }
    }, [])

    return (
        finishedAnimating ? <></> :
            <MotiView
                style={containerStyle}
                state={containerAnimation}
                transition={{ type: 'timing', duration: 300 }}
                delay={delay}
            >
                <Image
                    source={require('../../../../../assets/images/theme/default/cards/1/cardback.png')}
                    style={{ width: '100%', height: '100%', resizeMode: 'contain' }}
                    // from={{transform:[{rotateZ:'0deg'}]}}
                    // animate={{transform:[{rotateZ:imageAngleString}]}}
                    // transition={{type:'timing',duration:300,delay:delay}}
                    fadeDuration={0}
                />
            </MotiView>
    )
}
