import React from 'react';
import {View} from 'react-native';
import {useTable} from '../../../../../contexts/Table';
import Shortcut from './Shortcut';

type Props = {
    onChangeCurrentBettingValue: (amount: number) => void;
}

export default function ({onChangeCurrentBettingValue}: Props) {
    const {currentPlayer} = useTable();

    return (
        <View style={{flex: 1, maxWidth: '100%', flexDirection: 'row', justifyContent: 'space-between',}}>
            {currentPlayer?.betAmounts?.raise.shortcuts.map((shortcut, index) => (
                <Shortcut key={index} title={shortcut.shortcut} amount={shortcut.amount}
                          onChangeCurrentBettingValue={onChangeCurrentBettingValue}/>
            ))}
        </View>
    );
}