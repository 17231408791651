import React, { useRef } from "react";
import { ImageBackground, TextInput, View } from "react-native";
import { useGameOptions } from "../../../../../contexts/GameOptions";
import { useTable } from "../../../../../contexts/Table";
import { monetaryValue } from "../../../../../util";

import { styleWithFontScale } from "./styles";

type Props = {
  minBetting: number;
  maxBetting: number;
  currentBettingValue: number;
  onChangeCurrentBettingValue: (amount: number) => void;
};

const InputInterpreter = (input: string) => {
  /* //Interpretação do input na digitação bancário
    let formatStr = input;
    let formatNumber: number = Number(input);
    if (isNaN(formatNumber) || formatNumber <= 0) return '0';
    if (formatStr.includes(".") && formatStr.split('.')[1].length <= 1) formatStr = formatStr.replace(".", '');
    if (formatStr.includes(".") == false) return (Number(formatStr) / 100).toString();
    if (formatStr.split('.')[1].length == 2) return input;
    formatNumber = parseInt(input.replace(/[\D]+/g, ''));
    formatStr = formatNumber.toString().replace(/([0-9]{2})$/g, ".$1");
    return formatStr;
    */
  //Interpretação do input na digitação normal
  let formatStr = input;
  while (formatStr.includes(",")) formatStr = formatStr.replace(",", ".");
  if (formatStr.includes(".") && formatStr.split(".").length > 2) {
    formatStr = formatStr
      .replace(".", ",")
      .replaceAll(".", "")
      .replace(",", ".");
  }
  formatStr = formatStr.replace(/[^\d.]/g, "");
  if (formatStr === "") return "";
  if (formatStr.includes(".") && formatStr.split(".")[0].length == 0)
    formatStr = "0" + formatStr;
  if (formatStr.includes("."))
    formatStr = [
      String(Number(formatStr.split(".")[0])),
      formatStr.split(".")[1],
    ]?.join(".");
  else formatStr = String(Number(formatStr.split(".")[0]));
  if (formatStr.includes(".") && formatStr.split(".")[1].length > 2)
    formatStr = String(Math.floor(Number(formatStr) * 100) / 100);
  return formatStr;
};

export default function ({
  minBetting,
  maxBetting,
  currentBettingValue,
  onChangeCurrentBettingValue,
}: Props) {
  const [inputBetting, setInputBetting] = React.useState<string>("");
  const { showBigBlind } = useGameOptions();
  const { table } = useTable();

  const inputRef = useRef<TextInput>(null);
  const checkInputFocus = () => {
    return inputRef.current?.isFocused();
  };

  /* 
    let InputTest = [
        ["+1", "1"],
        ["-1", "1"],
        ["a", ""],
        ["1a2", "12"],
        ["0", "0"],
        ["0.", "0."],
        ["0.0", "0.0"],
        ["0.00", "0.00"],
        ["0.01", "0.01"],
        ["0.012", "0.01"],
        ["0.1", "0.1"],
        ["0.10", "0.10"],
        [".", "0."],
        [",", "0."],
        [".0", "0.0"],
        ["1", "1"],
        ["12", "12"],
        ["12.", "12."],
        ["12.3", "12.3"],
        ["12..", "12."],
        ["12..3", "12.3"],
        ["12.,", "12."],
        ["12,.3", "12.3"],
        ["12.34", "12.34"],
        ["12.345", "12.34"],
        ["123.45", "123.45"],
        ["00", "0"],
        ["/1a2b,3.4cd5,", "12.34"],
        ["1234567890", "1234567890"],
        ["abcdefghijklmnopqrstuvwxyz", ""],
        [",a1b2c3d4@5^ç[]{'0", "0.12"],
    ]
    for (let i = 0; i < InputTest.length; i++) {
        let resultado = InputInterpreter(InputTest[i][0]);
        console.log(resultado === InputTest[i][1] ? 
            "[Passou]" : 
            "[Falhou] " + InputTest[i][0] + " => " + resultado + " [Esperado: " + InputTest[i][1] + "]")
    }
    */

  const onChangeInputBetting = (amount: string) => {
    let Interpretado = InputInterpreter(amount);
    let RealValue = showBigBlind
      ? Number(Interpretado) * table.bigBlind
      : Number(Interpretado) * 100;
    let maxBettingShowBB = showBigBlind
      ? maxBetting / table.bigBlind
      : maxBetting;
    let minBettingShowBB = showBigBlind
      ? minBetting / table.bigBlind
      : minBetting;

    //if (value < minBetting) {
    //    return;
    //}

    setInputBetting(Interpretado);
    //setSliderBetting(value);
    onChangeCurrentBettingValue(RealValue);

    if (RealValue > maxBetting) {
      setInputBetting(
        showBigBlind
          ? maxBettingShowBB.toString()
          : monetaryValue(maxBetting).toString()
      );
      //setSliderBetting(maxSliderBetting);
      onChangeCurrentBettingValue(maxBetting);
      return;
    } else if (RealValue < minBetting) {
      onChangeCurrentBettingValue(minBetting);
    }
  };

  React.useEffect(() => {
    if (checkInputFocus() === false)
      setInputBetting(
        showBigBlind
          ? (currentBettingValue / table.bigBlind).toFixed(2)
          : monetaryValue(currentBettingValue)
      );
  }, [currentBettingValue]);

  return (
    <View style={{ flex: 1, maxWidth: "40%" }}>
      <ImageBackground
        style={{
          minHeight: "114%",
          marginTop: "-7%" /*marginLeft: '-14%',*/,
          width: "100%",
          alignItems: "center",
        }}
        source={require("../../../../../assets/images/theme/default/table/betting_input.png")}
        resizeMode={"contain"}
        fadeDuration={0}
      >
        <TextInput
          ref={inputRef}
          style={styleWithFontScale(20, 16)}
          onChangeText={(text: string) => onChangeInputBetting(text)}
          value={inputBetting}
          placeholder="0"
          keyboardType="numeric"
        />
      </ImageBackground>
    </View>
  );
}
