import React, { createContext, useContext } from "react";
import { Text, useWindowDimensions, View } from "react-native";

export type ScreenOrientation = "landscape" | "portrait";
export type ScreenProportion = "4:3" | "16:9" | "9:16";

type DimensionsContextProps = {
  //state: string | undefined;
  width: number;
  height: number;
  orientation: ScreenOrientation;
  proportion: ScreenProportion;
};

export const DimensionsContext = createContext({} as DimensionsContextProps);

type DimensionsProviderProps = {
  children: React.ReactNode;
};

export function DimensionsProvider({ children }: DimensionsProviderProps) {
  //const [state, setState] = React.useState<string>();

  const window = useWindowDimensions();

  // landscape
  let width: number = Math.round(window.height / 3) * 4;

  //if (width > window.width) {
  //    width = window.width;
  //}

  let height: number = window.height;

  let proportion: ScreenProportion = "4:3";

  if (width > window.width) {
    width = Math.round(window.height / 16) * 9;

    if (width > window.width) {
      width = window.width;
    }

    height = Math.round(width / 9) * 16;

    //if (height > window.height) {
    //    height = window.height;
    //}

    proportion = "9:16";
  } else {
    let width_16x9: number = Math.round(window.height / 9) * 16;

    if (window.width >= width_16x9) {
      //width = width_16x9;
      //height = Math.round((width / 16)) * 9;

      proportion = "16:9";
    }
  }

  //let barWidth: number = (height / 3) * 4;

  //if (width > window.width) {
  //    width = window.width;
  //    height = (window.width / 9) * 16;
  //}
  //    width = window.width;
  //    height = (width / 16) * 9;

  //barWidth = (window.height / 16) * 9;
  //}

  let orientation: ScreenOrientation =
    width > height ? "landscape" : "portrait";

  return (
    <DimensionsContext.Provider
      value={{ width, height, orientation, proportion }}
    >
      {children}
    </DimensionsContext.Provider>
  );
}

export function useDimensions() {
  const context = useContext(DimensionsContext);

  if (context === undefined) {
    throw new Error("Context provider undefined.");
  }

  return context;
}
